import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './ScoreBadge.scss';
import { qaTags } from '../../constants/qaConstants';
import { renderString } from '../../utils/renderUtils';
import Tooltip from '../Tooltip';

export const scoreLevels = Object.freeze({
  SCORE_HIGH: 90,
  SCORE_LOW: 79
});

const ScoreBadge = ({ score }) => {
  const classnames = cx('scoreBadge', {
    'scoreBadge--high': score >= scoreLevels.SCORE_HIGH,
    'scoreBadge--medium': score < scoreLevels.SCORE_HIGH && score > scoreLevels.SCORE_LOW,
    'scoreBadge--low': score <= scoreLevels.SCORE_LOW
  });
  return (
    <>
      <Tooltip enterDelay={500} title={renderString('tooltip.label.score')} placement="bottom-start">
        <div name={qaTags.SCORE_LEVEL} className={classnames}>
          {score}
        </div>
      </Tooltip>
    </>
  );
};
ScoreBadge.propTypes = {
  score: PropTypes.number.isRequired
};

export default ScoreBadge;
