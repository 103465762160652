import { createSelector } from 'reselect';
import { memoize } from 'lodash';

import { getMessages } from '../Quarantine/ducks/messageReducer';
import { groupQuarantineMsgsByDate } from './dateUtils';
import { isEmpty } from './strUtils';
import { moduleType } from '../ducks/appTypes';

const emptyObject = {};

class FilterUtils {
  constructor(filterModuleType) {
    this.filterModuleType = filterModuleType;
  }

  removeHiddenItemsFromMap = (hiddenItems, items) => {
    let filtered = items || emptyObject;
    if (!isEmpty(hiddenItems)) {
      filtered = Object.keys(filtered).reduce((acc, currKey) => {
        if (!hiddenItems[currKey]) {
          acc[currKey] = items[currKey];
        }
        return acc;
      }, {});
    }
    return filtered;
  };

  getFilteredItemsFromState(state) {
    return state.filter[this.filterModuleType].filteredItems;
  }

  getFilteredRowItemsFromState = (state) => {
    if (this.filterModuleType === moduleType.MESSAGE_LIST) {
      return getMessages(state);
    }
    return this.getFilteredItemsFromState(state);
  };

  getSelectedItemIdsFromState = (state) => state.filter[this.filterModuleType].selectedItems;

  getHiddenItemsFromState = (state) => state.filter[this.filterModuleType].hiddenItems;

  getFilterStringFromState = (state) => state.filter[this.filterModuleType].filterString;

  getFilterStartDateFromState = (state) => state.filter[this.filterModuleType].startDate;

  getFilterEndDateFromState = (state) => state.filter[this.filterModuleType].endDate;

  getTotalHiddenMessages = createSelector(
    this.getHiddenItemsFromState,
    (hiddenItems) => Object.keys(hiddenItems).length
  );

  getVisibleMessages = createSelector(
    this.getHiddenItemsFromState,
    this.getFilteredRowItemsFromState,
    (hiddenItems, filteredItems) => this.removeHiddenItemsFromMap(hiddenItems, filteredItems)
  );

  getMessageIdFromProps = (state, { messageId }) => messageId;

  getItem = createSelector(
    this.getFilteredRowItemsFromState,
    this.getMessageIdFromProps,
    (rows, itemId) => {
      return rows[itemId];
    }
  );

  /**
   * Gets the visible selected row items
   */
  getSelectedRowItems = createSelector(
    this.getVisibleMessages,
    this.getSelectedItemIdsFromState,
    (visibleItems, selectedItemIds) => {
      let filtered = emptyObject;
      if (!isEmpty(selectedItemIds)) {
        filtered = selectedItemIds.reduce((acc, currKey) => {
          if (visibleItems[currKey]) {
            acc[currKey] = visibleItems[currKey];
          }
          return acc;
        }, {});
      }
      return filtered;
    }
  );

  groupMessagesByDate = createSelector(
    this.getVisibleMessages,
    (messages) => groupQuarantineMsgsByDate(messages)
  );

  /**
   * Gets the ids of the visible selected row items
   */
  getSelectedRowItemIds = createSelector(
    this.getSelectedRowItems,
    (selectedItems) => {
      return Object.keys(selectedItems);
    }
  );

  isItemDeleted = (state, itemId) => state.filter[this.filterModuleType].hiddenItems[itemId] !== undefined;
}

export const messageListFilterUtils = new FilterUtils(moduleType.MESSAGE_LIST);
export const senderListFilterUtils = new FilterUtils(moduleType.SENDER_LIST);

export const getFilterUtil = memoize((filterModuleType) => {
  switch (filterModuleType) {
    case moduleType.MESSAGE_LIST:
      return messageListFilterUtils;
    case moduleType.SENDER_LIST:
      return senderListFilterUtils;
    default:
      return new FilterUtils(filterModuleType);
  }
});
