/**
 * normalizeString() converts string to a desired format by removing spaces,
 * replacing them with a different separator,
 * converting to upper- or lowercase
 * @param {String} str - source string.
 * @param {String} spacer - Optional. If provided, string to replace spaces
 *   with.
 * @param {boolean} isLower - Optional. If true, convert string to lower case.
 *   Takes precedence over isUpper.
 * @param {boolean} isUpper - Optional. If true, convert string to upper case.
 * @param {boolean} isDontTrim - Optional. If true - dont's trim leading and
 *   trailing spaces.
 *
 * @returns {String} - normalized string
 */
export const normalizeString = ({ str, spacer, isLower, isUpper, isDontTrim }) => {
  if (!str) {
    return '';
  }
  let normalized;
  if (!isDontTrim) {
    normalized = str.trim();
  } else {
    normalized = str;
  }
  const newSpacer = spacer || ' ';
  normalized = normalized.replace(/\s+/g, newSpacer);
  if (isLower) {
    normalized = normalized.toLowerCase();
  } else if (isUpper) {
    normalized = normalized.toUpperCase();
  }
  return normalized;
};

/**
 * isEmpty() checks if input is null or undefined or has not content.
 * @param {Object | Array | String} obj - object to check.
 *
 * @returns {boolean} - true, if input parameter is null or undefined or has
 *   not content
 */

export const isEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  if (typeof obj === 'string') {
    return !obj.length;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }
  return true;
};

/**
 * contains() safely checks if input string contains provided substring,
 * optionally, in a case-insensitive way.
 * @param {String} str - input string.
 * @param {String} substring - substring to check.
 * @param {boolean} isCaseInsensitive - if true, perform a case-insensitive
 *   check.
 *
 * @returns {boolean} true, if substring is contained withing str.
 */

export const contains = (str, substring, isCaseInsensitive) => {
  if (isEmpty(str) || isEmpty(substring) || str.length < substring.length) {
    return false;
  }
  let workerStr = str;
  let workerSubstr = substring;
  if (isCaseInsensitive) {
    workerStr = str.toLowerCase();
    workerSubstr = substring.toLowerCase();
  }
  return workerStr.indexOf(workerSubstr) >= 0;
};

const DEFAULT_DELIMITER = ',';
// Get a delimiter-separated string from storage (local or session) and convert it to an array
// If not specified, default delimiter is set to ','
export const getArrayFromStorage = (storage, key, delimiter) => {
  const item = storage.getItem(key);
  if (isEmpty(item)) {
    return [];
  }
  return item.split(delimiter || DEFAULT_DELIMITER);
};

// Save an array to system or local storage as a delimiter-separated string;
// If not specified, default delimiter is set to ','
export const putArrayToStorage = (storage, key, values, delimiter) => {
  const arr = values || [];
  const item = arr.reduce((acc, current, index) => {
    let separator = delimiter || DEFAULT_DELIMITER;
    if (index === 0) {
      separator = '';
    }
    return `${acc}${separator}${current}`;
  }, '');
  storage.setItem(key, item);
  return item;
};

// Remove an item from system or local storage
// If not specified, default delimiter is set to ','
export const removeItemfromStorage = (storage, key) => {
  storage.removeItem(key);
};

export const strArrayToMap = (arr) =>
  arr.reduce((acc, current) => {
    acc[current] = current;
    return acc;
  }, {});

export const stringToParagraph = (string = null) => {
  const paragraph = {
    intro: undefined,
    subIntro: undefined,
    body: []
  };
  if (!string) {
    return paragraph;
  }
  const stringAsArray = string.split('.');
  let lineCount = 0;
  stringAsArray.forEach((sentence) => {
    lineCount += 1;
    if (!sentence) {
      return;
    }
    switch (lineCount) {
      case 1:
        paragraph.intro = `${sentence.trim()}.`;
        break;
      case 2:
        paragraph.subIntro = `${sentence.trim()}.`;
        break;
      default:
        paragraph.body.push(`${sentence.trim()}.`);
        break;
    }
  });

  return paragraph;
};

/**
 * isBase64() checks if a str is encoded,
 * @param {String} str - source string.
 * @returns {boolean} - true, if input parameter is base-64 encoded
 */

export const isBase64 = (str = '') => {
  if (str === '' || str.trim() === '') {
    return false;
  }
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};
/**
 * debounce() debounces the method execution
 * Debounces execution of a call to the method
 * method should take a single object as input parameter
 * if id is empty, debounce tracker will be reset for all
 * if id is not empty and method is empty, pending executions will be cancelled
 * for this id repeated calls to the debouncer with the same id will reset the
 * pending execution and schedule a new one
 * @param {String} id - instance id. Any string will do.
 * @param {Function} method - method to be debounced.
 * @param {Number} wait - time span for debouncing.
 * @param {Object} params - object with parameters to be passed to the method.
 * @returns {boolean} - true, if input parameter is base-64 encoded
 */

let debounceTracker = {};
export const debounce = ({ id, method, wait = 0, params = {} }) => {
  if (!id) {
    // if no id  - reset debounce tracker map
    debounceTracker = {};
    return;
  }
  if (method) {
    // stop previous request
    if (debounceTracker[id]) {
      clearTimeout(debounceTracker[id]);
      // and schedule a mew execution
      debounceTracker[id] = setTimeout(() => {
        method(params);
        delete debounceTracker[id];
      }, wait);
    } else {
      // we always execute first request right away
      method(params);
      // and we set an empty timer for subcequent requests
      debounceTracker[id] = setTimeout(() => {
        delete debounceTracker[id];
      }, wait);
    }
  } else {
    // if no method specified - clear request
    clearTimeout(debounceTracker[id]);
  }
};

// validate domain name only - no protocol
export const isValidDomain = (str) => {
  if (isEmpty(str)) return false;
  const pattern = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
  return pattern.test(str);
};

export const isValidEmail = (str) => {
  if (isEmpty(str)) return false;
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase());
};

// Set item from storage (session or local) 
/**
 *
 * @param {object} storage - sessionStorage or localStorage
 * @param {string} key  - optional, item key in storage. 
 */

export const putStringToStorage = (storage, key, str) => {
  return storage.setItem(key, str);
};

// Get item from storage (session or local) 
/**
 *
 * @param {object} storage - sessionStorage or localStorage
 * @param {string} key  - optional, item key in storage. 
 */

export const getStringFromStorage = (storage, key) => {
  return storage.getItem(key);
};

// Remove item from storage (session or local) or simply clear whole storage.
/**
 *
 * @param {object} storage - sessionStorage or localStorage
 * @param {string} key  - optional, item key in storage. If not specified, clean all storage keys.
 */
 export const removeFromStorage = (storage, key) => {
  if (!storage) return;
  if (key) {
    storage.removeItem(key);
  } else {
    storage.clear();
  }
};
