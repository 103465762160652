import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getFilterUtil } from '../../../../utils/filterUtils';
import { getSenderList } from '../ducks/senderListReducer';
import { moduleType } from '../../../../ducks/appTypes';
import { removeSettingsPath } from '../../../../ducks/uiStatusActions';
import { submitConfig } from '../../../../ducks/configActions';
import AddSenderlistItem from './AddSenderListItem';

class AddSenderListItemContainer extends Component {
  hideAddUiFunc = () => {
    const { hideAddEditUiClick } = this.props;

    const { removeSettingsPathDispatch } = this.props;
    removeSettingsPathDispatch('add');
    hideAddEditUiClick();
  };

  hideSettingsFunc = () => {
    const { removeSettingsPathDispatch } = this.props;
    removeSettingsPathDispatch();
  };

  render() {
    const { senderListType, submitConfigDispatch, addressList, initialAddress } = this.props;
    return (
      <AddSenderlistItem
        configProperty={senderListType}
        closeFunc={this.hideSettingsFunc}
        backFunc={this.hideAddUiFunc}
        addConfigFunc={submitConfigDispatch}
        addressList={addressList}
        initialAddress={initialAddress}
      />
    );
  }
}

AddSenderListItemContainer.propTypes = {
  senderListType: PropTypes.string.isRequired,
  removeSettingsPathDispatch: PropTypes.func.isRequired,
  submitConfigDispatch: PropTypes.func.isRequired,
  addressList: PropTypes.object.isRequired,
  initialAddress: PropTypes.string.isRequired,
  hideAddEditUiClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.addressList = getSenderList(state);
  props.initialAddress = getFilterUtil(moduleType.SENDER_LIST).getFilterStringFromState(state);

  return props;
};

export default connect(mapStateToProps, {
  submitConfigDispatch: submitConfig,
  removeSettingsPathDispatch: removeSettingsPath
})(AddSenderListItemContainer);
