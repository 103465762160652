/**
 * The exposed, namespaced available API actions.
 */
export const digestActions = Object.freeze({
  RESET_DIGEST: 'RESET_DIGEST'
});

export const digestResetCommand = () => ({
  type: digestActions.RESET_DIGEST
});
