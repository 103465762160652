// This file contains id of Redux actions to be used by actions, reducers, etc.

export const defaultActions = Object.freeze({
  CATCH_ALL: '_CATCH_ALL_'
});

export const intlActions = Object.freeze({
  SET_LOCALE: 'SET_LOCALE',
  SET_DEFAULT_LANGUAGE: 'SET_DEFAULT_LANGUAGE'
});

export const commandActions = Object.freeze({
  SCHEDULE_FOLDER_COMMAND: 'SCHEDULE_FOLDER_COMMAND',
  SCHEDULE_DIGEST_FOLDER_COMMAND: 'SCHEDULE_DIGEST_FOLDER_COMMAND',
  SCHEDULE_CONFIG_COMMAND: 'SCHEDULE_CONFIG_COMMAND'
});

export const uiStatusActions = Object.freeze({
  SET_CONTENT_UPDATE: 'SET_CONTENT_UPDATE',
  SET_COMPONENT_DISABLE: 'SET_COMPONENT_DISABLE',
  SHOW_MOBILE_VIEW_SIDEBAR: 'SHOW_MOBILE_VIEW_SIDEBAR',
  TOGGLE_MOBILE_SIDEBAR: 'TOGGLE_MOBILE_SIDEBAR',
  TOGGLE_MOBILE_SEARCH: 'TOGGLE_MOBILE_SEARCH',
  ADD_SETTINGS_PATH: 'ADD_SETTINGS_PATH',
  REMOVE_SETTINGS_PATH: 'REMOVE_SETTINGS_PATH',
  ADD_SELECTED_SETTINGS_OPTION: 'ADD_SELECTED_SETTINGS_OPTION',
  CLOSE_MOBILE_VIEW_SIDEBAR: 'CLOSE_MOBILE_VIEW_SIDEBAR',
  SET_CURRENT_APP_TITLE: 'SET_CURRENT_APP_TITLE',
  SET_ENCRYPTION_ANIMATION_DIRECTION: 'SET_ENCRYPTION_ANIMATION_DIRECTION',
  TOGGLE_SINGLE_MESSAGE_ACTIONS: 'TOGGLE_SINGLE_MESSAGE_ACTIONS',
  SHOW_APP_SWITCHER: 'SHOW_APP_SWITCHER',
  SET_CURRENT_KEYBOARD_FOCUS: 'SET_CURRENT_KEYBOARD_FOCUS',
  SET_FOLDER_LIST_KEYBOARD_FOCUS: 'SET_FOLDER_LIST_KEYBOARD_FOCUS',
  SET_MESSAGE_LIST_KEYBOARD_FOCUS: 'SET_MESSAGE_LIST_KEYBOARD_FOCUS',
  SET_SENDER_LIST_KEYBOARD_FOCUS: 'SET_SENDER_LIST_KEYBOARD_FOCUS'
});

export const moduleType = Object.freeze({
  MESSAGE_LIST: 'MESSAGE_LIST',
  SENDER_LIST: 'SENDER_LIST'
});

export const configActions = Object.freeze({
  SUBMIT_CONFIG: 'SUBMIT_CONFIG',
  DELETE_CONFIG: 'DELETE_CONFIG',
  RETRIEVE_CONFIG: 'RETRIEVE_CONFIG',
  EDIT_CONFIG: 'EDIT_CONFIG'
});

export const brandingType = Object.freeze({
  RETRIEVE_LOGO: 'RETRIEVE_LOGO'
});

export const featureActions = Object.freeze({
  SET_FEATURES: 'SET_FEATURES'
});

export const profileActions = Object.freeze({
  SET_MANAGING_DELEGATE: 'SET_MANAGING_DELEGATE',
  RESET_MANAGING_DELEGATE: 'RESET_MANAGING_DELEGATE'
});
