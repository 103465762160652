import { Button } from '@material-ui/core';
import Dialog from '@proofpoint/fuse-ui/components/Dialog';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './ConfirmationDialog.scss';


class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.getDialogContent = this.getDialogContent.bind(this);
    this.getActionComponent = this.getActionComponent.bind(this);    
  }

  getDialogContent(dialogContent) {
    return (
      <>
        {dialogContent}
      </>
    ); 
  }

  getActionComponent() {
    const { handleAction, handleClose } = this.props;
    return (
      <>
        <Button onClick={()=>{handleClose()}} size="medium" variant="text">
          <FormattedMessage id='stop.managing.confirm.dialog.no' />
        </Button>
        <Button autoFocus onClick={()=>{handleAction(); handleClose()}} color="primary" variant="contained" size="medium">
          <FormattedMessage id='stop.managing.confirm.dialog.yes' />
        </Button>
      </>
    );
  }

  render() {
    
    const { dialogContent, open, handleClose } = this.props;
    return (
      <div>
        <Dialog
          autoFocus
          dialogActions={this.getActionComponent()}
          dialogTitle={this.getDialogContent(dialogContent)}
          dialogTitleProps={{classes: { root: 'dialog-title-props'}}}
          maxWidth="md"
          onClose={()=>handleClose()}
          open={open}
        />
      </div>
    );
  }
}

ConfirmationDialog.defaultProps = {
};

ConfirmationDialog.propTypes = {
  dialogContent: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired, 
  handleClose: PropTypes.func.isRequired, 
  open: PropTypes.bool.isRequired
};

export default ConfirmationDialog;
