import { actionAttempt, apiActions } from '../../ducks/apiConstants';
import { createReducer } from '../../utils/reducerUtils';
import { isEmpty } from '../../utils/strUtils';
import { profileTypes } from './profileTypes';

export const moduleName = 'profile';

const INITIAL_STATE = {
  displayName: '',
  givenName: '',
  id: '',
  primaryEmailAddress: '',
  surname: '',
  aliases: [],
  spamPolicyOptionDefault: 0,
  spamPolicies: [],
  delegatedEmailAddresses: [],
  managingDelegate: '',
  loggedInUserEmailAddress:'',
  loggedInUserName: '',
  isLoading: false,
  isReady: false
};

function onFetchProfileAttempt(state) {
  return {
    ...state,
    isLoading: true,
    isReady: false
  };
}

function setProfile(state, action) {    
  const loggedInUserEmail=(isEmpty(state.managingDelegate))? action.user.primaryEmailAddress: state.primaryEmailAddress;
  const loggedInUser=(isEmpty(state.managingDelegate))? action.user.displayName: state.displayName;
  const delegatedEmailAddress=(isEmpty(state.managingDelegate))? action.user.delegatedEmailAddresses: state.delegatedEmailAddresses;
 return { ...state, ...action.user, loggedInUserName: loggedInUser, loggedInUserEmailAddress:  loggedInUserEmail, delegatedEmailAddresses: delegatedEmailAddress, isLoading: false, isReady: true}
}


function setManagingDelegate(state, action) {
  return { ...state, managingDelegate: action.delegate };  
}

export default createReducer(INITIAL_STATE, {
  [profileTypes.SET_PROFILE]: setProfile,
  [actionAttempt(apiActions.FETCH_PROFILE)]: onFetchProfileAttempt,
  [profileTypes.SET_SPAM_POLICY_OPTION_DEFAULT]: (state, action) => ({
    ...state,
    spamPolicyOptionDefault: action.spamPolicyOptionDefault
  }),
  [profileTypes.SUBMIT_MANAGE_DELEGATE_CHANGE]: setManagingDelegate
});

export const allProfile = (state) => state[moduleName];

export function getCurrentEmailAddressFromState(state) {
  return allProfile(state).primaryEmailAddress;
}

export function getLoggedInUserEmailAddressFromState(state) {
  return allProfile(state).loggedInUserEmailAddress;
}

export function getLoggedInUserName(state) {
  return allProfile(state).loggedInUserName;
}

export function getDelegates(state){
  return allProfile(state).delegatedEmailAddresses;
}

// Getting the full delegate object {displayname, userId, email} from either email or userId
// this is required since digest url will not have the display name or userId of the delegates
// and only userId is stored in session storage and fetched when the page is refreshed.

export const getDelegateObj = (state, delegate) => {
  const delegatedEmailAddresses = getDelegates(state);
  const { email, userId } = delegate;
  const delegateObj = delegatedEmailAddresses.filter((_delegate) =>  (_delegate.email === email || _delegate.userId === userId));        
  return delegateObj[0];
};

export function getDisplayName(state) {
  return allProfile(state).displayName;
}

export function getAlternateEmailAddresses(state) {
  return allProfile(state).alternateEmailAddresses
}

export function getDelegatedUsers(state) {
  return allProfile(state).delegatedEmailAddresses;
}

export function getManagingDelegate(state) {
  return allProfile(state).managingDelegate;
}


export function getProfileLoadingStatus(state) {
  return allProfile(state).isLoading;
}