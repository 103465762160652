import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';

import './EllipsisMenu.scss';
import { buttonElementRetriever, sortButtonsByGroup } from '../../../utils/buttonUtils';
import { commonIds } from '../../../constants/uiConstants';
import { getShowMessageActions } from '../../../Quarantine/ducks/messageReducer';
import { renderString } from '../../../utils/renderUtils';
import { showMessageActionsMenu } from '../../../Quarantine/ducks/messageActions';
import Tooltip from '../../Tooltip';
import withPrimaryStyles from '../../withPrimaryStyles';

class EllipsisMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.iconBtn = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { showMessageActions } = nextProps;
    return {
      ...prevState,
      isOpen: showMessageActions
    };
  }

  handleMenuOpen = () => {
    const { showMessageActionsDispatch } = this.props;
    showMessageActionsDispatch(true);
  };

  handleMenuClose = () => {
    const { showMessageActionsDispatch } = this.props;
    showMessageActionsDispatch(false);
  };

  renderActions = () => {
    const { isDisabled, classes, menuItems, targetGuids, handleMessageAction } = this.props;
    const ellipsisMenuItemClass = cx('ellipsis-menu__item', {
      [`${classes.darkSecondaryColor10}`]: classes.darkSecondaryColor10
    });
    // Ignore PPS ordering and order by group relevance
    const commandGroups = [...(menuItems || [])].sort(sortButtonsByGroup);

    const menuItem = [];
    let prevGroupIndex = 0;

    commandGroups.forEach((command) => {
      const { intlId, icon, groupIndex } = buttonElementRetriever(command);
      // Visually split actions into groups
      if (prevGroupIndex > 0 && prevGroupIndex !== groupIndex) {
        menuItem.push(<Divider role="presentation" key={`divider-${command}`} />);
      }
      menuItem.push(
        <MenuItem
          key={`action-${command}`}
          onClick={() => {
            handleMessageAction(command, targetGuids);
            this.handleMenuClose();
          }}
          disabled={isDisabled}
          className={ellipsisMenuItemClass}
          role="option"
        >
          {icon} {renderString(intlId)}
        </MenuItem>
      );
      prevGroupIndex = groupIndex;
    });

    return menuItem;
  };

  render() {
    const { wrapperClass, isDisabled, intl } = this.props;
    const { isOpen } = this.state;
    const tooltip = isDisabled ? 'action.disabled' : 'tooltip.button.actions';
    return (
      <>
        <Tooltip title={renderString(tooltip)} alwaysRender={!isDisabled}>
          <div ref={this.iconBtn} className={wrapperClass}>
            <IconButton
              name={commonIds.moreActions}
              aria-label={intl.formatMessage({ id: 'action.multiselect.subtitle' })}
              aria-haspopup="true"
              onClick={() => this.handleMenuOpen()}
              disabled={isDisabled}
              disableRipple
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </Tooltip>
        {isOpen && (
          <MenuList
            anchorEl={this.iconBtn.current}
            classes={{ paper: 'ellipsis-menu' }}
            open={isOpen}
            autoFocus
            MenuListProps={{
              role: 'listbox',
              'aria-label': 'Message Options'
            }}
            anchorReference="anchorEl"
            transformOrigin={{ horizontal: 40, vertical: 0 }}
            disabled={isDisabled}
            onClose={() => this.handleMenuClose()}
          >
            {this.renderActions()}
          </MenuList>
        )}
      </>
    );
  }
}

EllipsisMenu.defaultProps = {
  menuItems: null,
  isDisabled: false,
  wrapperClass: 'ellipsis-menu__button',
  targetGuids: [],
  handleMessageAction: null,
  showMessageActions: false
};

EllipsisMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  wrapperClass: PropTypes.string,
  targetGuids: PropTypes.arrayOf(PropTypes.string),
  handleMessageAction: PropTypes.func,
  showMessageActions: PropTypes.bool,
  showMessageActionsDispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  showMessageActions: getShowMessageActions(state)
});
export default connect(mapStateToProps, { showMessageActionsDispatch: showMessageActionsMenu })(
  injectIntl(withPrimaryStyles(EllipsisMenu))
);
