import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { contentTypes, keyCodes, measures } from '../../../constants/uiConstants';
import { debounce } from '../../../utils/strUtils';
import { handleKeyboardKeyDown } from '../../../utils/accessibilityUtils';

/**
 *
 * @param {Object} WrappedComponent
 * @return {(Object|null)}
 */
export default function(WrappedComponent) {
  class roveFocusHOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        listHasSplKeyShortcut: false,
        specialKeys: {
          MESSAGE_LIST: [keyCodes.KEY_PERIOD, keyCodes.KEY_X],
          SENDER_LIST: [keyCodes.KEY_PERIOD]
        },
        handleSplKeyShortcutClicked: null
      };
    }

    // If the list in the wrappedcomponent has a special key shortcut present then it has to callback to registerHandleSplKeyShortcutClicked
    // and register its handler function with roveFocusHOC if not listHasSplKeyShortcut will remain false and handleSplKeyShortcutClicked will not be called on special key clicked.
    // Eg: Messagelist Container has 'X' key as a special key shortcut for bulk selecting messages when there are executable actions/commands on the messages.

    registerHandleSplKeyShortcutClicked = (fn) => {
      this.setState({ listHasSplKeyShortcut: true });
      this.setState({ handleSplKeyShortcutClicked: fn });
    };

    handleKeyDown = (e) => {
      const params = { ...this.props, ...this.state, event: e };
      // Note: As of now Keyboard accessibility is written and tested only for quarantine page.
      // Encryption page needs to follow similar pattern in future. "isQuarantine" condition needs to be removed then.
      const method = ({
        event,
        lenOfListToDisplay,
        isQuarantine,
        listType,
        currentFocus,
        setCurrentKeyboardFocus,
        firstFocussableElemInList,
        lastVisibleIndex,
        firstVisibleIndex,
        specialKeys,
        listHasSplKeyShortcut,
        handleSplKeyShortcutClicked
      }) => {
        if (
          listType === contentTypes.FOLDER_LIST ||
          listType === contentTypes.SENDER_LIST ||
          (listType === contentTypes.MESSAGE_LIST && isQuarantine)
        ) {
          if (listHasSplKeyShortcut && specialKeys[listType].includes(e.keyCode)) {
            handleSplKeyShortcutClicked(e);
          }
          const isEdgeElement = handleKeyboardKeyDown(
            event,
            firstFocussableElemInList,
            lenOfListToDisplay,
            currentFocus,
            setCurrentKeyboardFocus,
            lastVisibleIndex,
            firstVisibleIndex
          );
          if (!isEdgeElement) {
            event.target.removeEventListener('keydown', this.handleKeyDown);
          }
        }
      };
      if (e.keyCode !== keyCodes.KEY_TAB) e.preventDefault();
      debounce({ id: 'keyDown', method, wait: measures.DEBOUNCE_SHORT, params });
    };

    handleOnFocus = (e) => {
      // Note: As of now Keyboard accessibility is written and tested only for quarantine page.
      // Encryption page needs to follow similar pattern in future. "isQuarantine" condition needs to be removed then.
      const { isQuarantine, listType } = this.props;
      if (
        listType === contentTypes.FOLDER_LIST ||
        listType === contentTypes.SENDER_LIST ||
        (listType === contentTypes.MESSAGE_LIST && isQuarantine)
      ) {
        if (e.target) {
          e.target.addEventListener('keydown', this.handleKeyDown);
        }
      }
    };

    handleOnBlur = (e) => {
      // Note: As of now Keyboard accessibility is written and tested only for quarantine page.
      // Encryption page needs to follow similar pattern in future. "isQuarantine" condition needs to be removed then.
      e.target.removeEventListener('keydown', this.handleKeyDown);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props} // eslint-disable-line react/jsx-props-no-spreading
          registerHandleSplKeyShortcutClicked={this.registerHandleSplKeyShortcutClicked}
          handleOnBlur={this.handleOnBlur}
          handleOnFocus={this.handleOnFocus}
        />
      );
    }
  }

  roveFocusHOC.defaultProps = {
    isQuarantine: false,
    commands: [],
    listType: '',
    firstFocussableElemInList: 0,
    lastVisibleIndex: 0,
    firstVisibleIndex: 0
  };

  roveFocusHOC.propTypes = {
    lenOfListToDisplay: PropTypes.number.isRequired,
    currentFocus: PropTypes.number.isRequired,
    setCurrentKeyboardFocus: PropTypes.func.isRequired,
    isQuarantine: PropTypes.bool,
    commands: PropTypes.arrayOf(PropTypes.string),
    listType: PropTypes.string,
    firstFocussableElemInList: PropTypes.number,
    lastVisibleIndex: PropTypes.number,
    firstVisibleIndex: PropTypes.number
  };

  return roveFocusHOC;
}
