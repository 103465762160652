import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Schedule from '@material-ui/icons/Schedule';
import cx from 'classnames';
import moment from 'moment';

import '../ExpiryOptions.scss';
import { isFutureExpiry } from '../../../../utils/dateUtils';
import { renderExpiryTime, renderString } from '../../../../utils/renderUtils';
import DatePickerContainer from '../../../../common/DatePicker/DatePickerContainer';
import FullScreenDialog from '../../../../common/Mobile/FullScreenDialog';
import withPrimaryStyles from '../../../../common/withPrimaryStyles';

const ExpiryOptions = ({
  toggleMessageActions,
  isOpen,
  updateExpiryDispatch,
  handleMessageStatus,
  currentExpiry,
  encryptedMessageSubject,
  isRevoked,
  classes
}) => {
  const expiryOptionClasses = cx('expiry-option--action', {
    [`${classes.primaryColor}`]: classes.primaryColor
  });

  return (
    <>
      <FullScreenDialog
        isOpen={isOpen}
        toggleDialog={toggleMessageActions}
        dialogClassName="mobile-message-options-dialog"
        navClassName="basic-nav--innerpage"
        buttonLeft={{
          icon: 'close',
          handleAction: toggleMessageActions
        }}
        mainTitle={<span>{encryptedMessageSubject}</span>}
        dialogContent={
          <List className="mobile-message-options">
            <div className="expiry-dropdown__header">{renderString('encryption.mobile.expiry.options.header')}</div>
            <DatePickerContainer
              hasButtonInput
              buttonInputClass="expiry-option-custom-date"
              buttonInputContent={
                <ListItem className="expiry-option">
                  <Schedule />
                  {isFutureExpiry(currentExpiry) && currentExpiry > 0 && (
                    <>
                      {renderString('encryption.expiry.message.expires')}
                      <span className="expiry-option-expiry-time expiry-option-expiry-time--expiresIn">
                        {renderExpiryTime(currentExpiry, 'encryption.message.options.expiresIn')}
                        <ArrowForward />
                      </span>
                    </>
                  )}
                  {!isFutureExpiry(currentExpiry) && currentExpiry === 0 && (
                    <>
                      {renderString('encryption.expiry.message.expires')}
                      <span className="expiry-option-expiry-time">
                        {renderString('encryption.message.options.neverExpires')}
                        <ArrowForward />
                      </span>
                    </>
                  )}
                  {!isFutureExpiry(currentExpiry) && currentExpiry > 0 && (
                    <>
                      {renderString('encryption.expiry.option.set.new.expiry')}
                      <span className="expiry-option-expiry-time">
                        <span />
                        <ArrowForward />
                      </span>
                    </>
                  )}
                </ListItem>
              }
              updateDateActionDispatch={updateExpiryDispatch}
              showCustomActionButton
              customActionButton={
                <span color="primary" onClick={() => updateExpiryDispatch(0)} role="button" tabIndex="-1">
                  {renderString('encryption.expiry.option.never.expire')}
                </span>
              }
            />
            {(currentExpiry === 0 || isFutureExpiry(currentExpiry)) && (
              <>
                <Divider />
                <ListItem className="expiry-option" onClick={() => updateExpiryDispatch(moment().unix())}>
                  <ListItemText className={expiryOptionClasses}>
                    {renderString('encryption.expiry.option.expire.message')}
                  </ListItemText>
                </ListItem>
              </>
            )}
            <div className="expiry-dropdown__header">{renderString('encryption.message.options.access.settings')}</div>
            <ListItem className="expiry-option" onClick={() => handleMessageStatus()}>
              <ListItemText className={expiryOptionClasses}>
                {!isRevoked && renderString('encryption.expiry.option.revoke.all')}
                {isRevoked && renderString('encryption.expiry.option.restore.all')}
              </ListItemText>
            </ListItem>
          </List>
        }
      />
    </>
  );
};

ExpiryOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMessageActions: PropTypes.func.isRequired,
  updateExpiryDispatch: PropTypes.func.isRequired,
  handleMessageStatus: PropTypes.func.isRequired,
  currentExpiry: PropTypes.number.isRequired,
  encryptedMessageSubject: PropTypes.string.isRequired,
  isRevoked: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(ExpiryOptions);
