export const messageLoadStatus = Object.freeze({
  LOADING: 1,
  LOADED: 2
});

export const messageList = Object.freeze({
  CHECKBOX_LIMIT: 50,
  INFINITE_LOADING_THRESHOLD_DESKTOP: 50,
  DEBOUNCE_DELAY: 600
});

export default messageLoadStatus;
