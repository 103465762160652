/**
 * Exports the shared logging utility.
 *
 * @module tools/logger
 */

import { ConsoleFormattedStream, createLogger, stdSerializers } from 'browser-bunyan';

/*
 * The log levels in bunyan are as follows.
 *     - fatal : The service/app is going to stop or become unusable now.
 *               An operator should definitely look into this soon.
 *     - error : Fatal for a particular request, but the service/app continues servicing other requests.
 *               An operator should look at this soon(ish).
 *     - warn  : A note on something that should probably be looked at by an operator eventually.
 *     - info  : Detail on regular operation.
 *     - debug : Anything else, i.e. too verbose to be included in "info" level.
 *     - trace : Logging from external libraries used by your app or very detailed application logging.
 */
function getLogLevel() {
  // TODO: future customization based on log level (need to passed dynamically from url)
  return 'info';
}

const streams = [
  {
    level: getLogLevel(),
    stream: new ConsoleFormattedStream({ logByLevel: true })
  }
];

/**
 * This is the root logger for the application.
 *
 * The allowed log level is defined at build time with the variable "logLevel" in the configuration and can be one
 * of trace, debug, info, warn, error, fatal.
 *
 * ```javascript
 * // a simple usage example
 * import logger from '../tools/logger';
 *
 * const log = logger.child({childName: 'storeUtil'});
 * // ...
 * log.info('initialized');
 * // -> [10:42:47:0408] INFO: euweb-client/storeUtil: initialized
 * ```
 *
 * Here are a few things to remember:
 * - child logger to specialize a logger for a sub-component of your application, i.e. to create a new logger with
 *   additional bound fields that will be included in its log records. A child logger is created with log.child(...)
 * - Use "debug" sparingly.
 * - Information that will be useful to debug errors post mortem should usually be included in "info" messages
 *   if it's generally relevant or else with the corresponding "error" event.
 * - For information only useful when debugging a particular component use `debug`
 * - For additional debugging information use `trace`
 */
const logger = createLogger({
  name: 'euweb-client',
  streams,
  serializers: stdSerializers
});

export default logger;
