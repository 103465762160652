import { CLUSTER_REGION_MAPPING } from '../ducks/appConstants';
import { isEmpty } from './strUtils';

/* eslint-disable import/prefer-default-export */

export function isBrowserSupportedSelector() {
  // TODO: get the value from store
  return true;
}

export const getClusterRegion = (state) => {
  const { clusterInfos, clusterId } = state.profile;
  if (isEmpty(clusterId) || isEmpty(clusterInfos)) {
    return CLUSTER_REGION_MAPPING.US;
  }
  const { region } = clusterInfos.find((x) => x.clusterId === clusterId);
  return region;
};
