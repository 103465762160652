import { API_CALL, apiActions, apiEndpoints } from '../../../ducks/apiConstants';

export const getMessageByGuid = (params) => {
  const { guid } = params;
  const paramsToPass = { guid };
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.ENCRYPTED_MESSAGE_URL,
      method: 'get',
      params: paramsToPass,
      actionType: apiActions.FETCH_ENCRYPTED_MESSAGE_BY_GUID
    }
  };
};

export const postRestoreRecipient = (params, data) => {
  const { guid } = params;
  const paramsToPass = { guid };
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.ENCRYPTED_MESSAGE_URL,
      method: 'post',
      params: paramsToPass,
      data,
      actionType: apiActions.RESTORE_ENCRYPTED_RECIPIENT
    }
  };
};

export const postRevokeRecipient = (params, data) => {
  const { guid } = params;
  const paramsToPass = { guid };
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.ENCRYPTED_MESSAGE_URL,
      method: 'post',
      params: paramsToPass,
      data,
      actionType: apiActions.REVOKE_ENCRYPTED_RECIPIENT
    }
  };
};

export const postUpdateExpiry = (params, data, guid, currentExpireTime) => {
  const { username, ...paramsToPass } = params;
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.ENCRYPTED_MESSAGE_URL,
      method: 'post',
      params: paramsToPass,
      data,
      actionType: apiActions.UPDATE_EXPIRY,
      guid,
      currentExpireTime
    }
  };
};
