/**
 * @module apiMiddleware
 */
import isUndefined from 'lodash/isUndefined';

import { API_CALL } from '../app/ducks/apiConstants';
import { getClusterRegion } from '../app/utils/uiUtils';
import apiHandler from '../app/ducks/utils/apiHandler';
import logger from '../tools/logger';

const log = logger.child({
  childName: 'apiMiddleware',
  level: 'debug'
});

/**
 * A Redux middleware that interprets action fired as per below format
 * {
 *   [API_CALL]: {
 *     endpoint: 'http://www.example.com/v1/api',
 *     method: 'get' (default) | 'post' | put ,
 *     params: {},
 *     data: {}, // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
 *     headers:{},
 *     responseType: json (default)
 *   }
 * }
 *
 * @param  {Object} store - redux store
 * @param  {Object} next - dispatch function of next middleware in the chain
 * @param  {Object} action - the current action
 * @return {Object} The action to pass to the store
 */

export default (store) => (next) => (action) => {
  // API gateway hostname would be different for US and EU region.
  // check the cluster region and add region info to all request params so
  // that we can figure out the right hostname
  if (action.requestParams) {
    const clusterRegion = getClusterRegion(store.getState());
    action.requestParams.clusterRegion = clusterRegion; // eslint-disable-line no-param-reassign
  }
  if (isUndefined(action[API_CALL])) {
    return next(action);
  }
  return apiHandler(action).catch((err) => {
    log.error(err, `${action.type || action.API_CALL.actionType}: unhandled error`);
  });
};
