import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { APP_NAMES } from '../ducks/appConstants';
import { getBrandingColorPrimaryFromState } from '../ducks/configReducer';
import { getCurrentApp } from '../ducks/uiStatusReducer';
import { getDisplayName } from '../Settings/ducks/profileReducer';
import { getFeature } from '../ducks/featureReducer';
import {
  getFirstGuidFromState,
  getMostRecentEncryptedMessages,
  getReadyFromState
} from '../Encryption/MessageList/ducks/MessageListReducer';
import { getFoldersReadyStatus, getFoldersWithUnreadMessages } from '../SideBar/ducks/folderReducer';
import {
  navigateToFolder,
  navigateToLandingPage,
  navigateToSecureMessageCenter
} from '../SideBar/ducks/sideBarActions';
import { retrieveRecentEncryptedMessages, updateCurrentGuid } from '../Encryption/MessageList/ducks/MessageListActions';
import { setCurrentApp, setEncryptionAnimationDirection } from '../ducks/uiStatusActions';
import LandingPage from './LandingPage';

class LandingPageContainer extends Component {
  componentDidMount() {
    const {
      isEncryptionFeatureOn,
      retrieveRecentEncryptedMessagesDispatch,
      navigateToLandingPageDispatch,
      currentApp
    } = this.props;

    // Reset current app selections in navigateToLandingPage when back button is clicked
    // from quarantine or SMC and navigated to Landing page
    if (currentApp !== APP_NAMES.landingPage) {
      navigateToLandingPageDispatch();
    }
    // Fetch first 3 messages
    if (isEncryptionFeatureOn) {
      retrieveRecentEncryptedMessagesDispatch();
    }
  }

  handleOnMessageClick = (guid) => {
    if (guid) {
      const {
        history,
        setEncryptionAnimationDirectionDispatch,
        setCurrentAppDispatch,
        updateCurrentGuidDispatch
      } = this.props;
      history.push(`/secure-message-center#${guid}`);
      setEncryptionAnimationDirectionDispatch('left');
      setCurrentAppDispatch('keyManagement');
      updateCurrentGuidDispatch(guid);
    }
  };

  render() {
    const {
      displayName,
      navigateToSecureMessageCenterDispatch,
      isReady,
      isFoldersReady,
      encryptedMessages,
      folders,
      navigateToFolderDispatch,
      isEncryptionFeatureOn,
      isQuarantineFeatureOn,
      brandingColorPrimary
    } = this.props;
    return (
      <LandingPage
        displayName={displayName}
        navigateToSecureMessageCenter={navigateToSecureMessageCenterDispatch}
        navigateToFolder={navigateToFolderDispatch}
        handleOnMessageClick={this.handleOnMessageClick}
        encryptedMessages={encryptedMessages}
        isReady={isReady}
        isFoldersReady={isFoldersReady}
        colorPrimary={brandingColorPrimary}
        encryptedMessageCount={Object.keys(encryptedMessages).length}
        folders={folders}
        isEncryptionFeatureOn={isEncryptionFeatureOn}
        isQuarantineFeatureOn={isQuarantineFeatureOn}
      />
    );
  }
}

LandingPageContainer.defaultProps = {
  brandingColorPrimary: null
};

LandingPageContainer.propTypes = {
  displayName: PropTypes.string.isRequired,
  navigateToSecureMessageCenterDispatch: PropTypes.func.isRequired,
  navigateToFolderDispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  encryptedMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
  isReady: PropTypes.bool.isRequired,
  isFoldersReady: PropTypes.bool.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEncryptionFeatureOn: PropTypes.bool.isRequired,
  brandingColorPrimary: PropTypes.string,
  isQuarantineFeatureOn: PropTypes.bool.isRequired,
  retrieveRecentEncryptedMessagesDispatch: PropTypes.func.isRequired,
  setEncryptionAnimationDirectionDispatch: PropTypes.func.isRequired,
  setCurrentAppDispatch: PropTypes.func.isRequired,
  updateCurrentGuidDispatch: PropTypes.func.isRequired,
  navigateToLandingPageDispatch: PropTypes.func.isRequired,
  currentApp: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  displayName: getDisplayName(state),
  encryptedMessages: getMostRecentEncryptedMessages(state),
  isReady: getReadyFromState(state),
  brandingColorPrimary: getBrandingColorPrimaryFromState(state),
  firstGuid: getFirstGuidFromState(state),
  folders: getFoldersWithUnreadMessages(state),
  isFoldersReady: getFoldersReadyStatus(state),
  isEncryptionFeatureOn: getFeature(state, 'keyManagement'),
  isQuarantineFeatureOn: getFeature(state, 'quarantine'),
  currentApp: getCurrentApp(state)
});

const mapDispatchToProps = () => ({
  navigateToSecureMessageCenterDispatch: navigateToSecureMessageCenter,
  navigateToFolderDispatch: navigateToFolder,
  retrieveRecentEncryptedMessagesDispatch: retrieveRecentEncryptedMessages,
  setEncryptionAnimationDirectionDispatch: setEncryptionAnimationDirection,
  setCurrentAppDispatch: setCurrentApp,
  updateCurrentGuidDispatch: updateCurrentGuid,
  navigateToLandingPageDispatch: navigateToLandingPage
});

export default connect(mapStateToProps, mapDispatchToProps())(LandingPageContainer);
