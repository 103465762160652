import { createSelector } from 'reselect';

import { actionSuccess, apiActions } from '../../../../ducks/apiConstants';
import { createReducer } from '../../../../utils/reducerUtils';
import { emailListToMap } from '../../../../utils/senderListUtils';
import { senderListTypes } from './senderListTypes';

export const moduleName = 'senderList';

const INITIAL_STATE = {}; // {key1: {}, key2:{}, key3:{}}

function onFetchSenderListSuccess(state, action) {
  return action.response.entities.emails || INITIAL_STATE;
}

function senderListAdd(state, action) {
  const newState = {};
  const { emails, property } = action;
  // convert email list to map
  const emailsToAdd = emailListToMap(emails, property);

  // Sort
  const unorderedState = { ...state, ...emailsToAdd };
  Object.keys(unorderedState)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .forEach((key) => {
      newState[key] = unorderedState[key];
    });

  return { ...newState };
}

function senderListRemove(state, action) {
  const newState = { ...state };
  action.emails.forEach((email) => {
    delete newState[email];
  });
  return { ...newState };
}

function clearSenderList() {
  return { ...INITIAL_STATE };
}

export default createReducer(INITIAL_STATE, {
  [actionSuccess(apiActions.FETCH_SENDERLIST)]: onFetchSenderListSuccess,
  [senderListTypes.SENDERLIST_REMOVE]: senderListRemove,
  [senderListTypes.SENDERLIST_ADD]: senderListAdd,
  [senderListTypes.CLEAR_SENDER_LIST]: clearSenderList
});

// Selectors

export const getSenderList = (state) => state[moduleName];

export const getSenderListIds = createSelector(getSenderList, (senderList) => Object.keys(senderList));
