import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import UnfoldLess from '@material-ui/icons/UnfoldLess';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import cx from 'classnames';
import isUndefined from 'lodash/isUndefined';

import './MessageHeaders.scss';
import { renderLocalDateStr, renderString, renderTime } from '../../../utils/renderUtils';
import UserBadge from '../../UserBadge';
import withPrimaryStyles from '../../withPrimaryStyles';
import { isEmpty } from '../../../utils/strUtils';

function MessageHeaders({
  classes,
  headerSender,
  envelopeSender,
  date,
  emailHeaders,
  isRawHeadersOpen,
  toggleRawHeaders,
  managedDelegate
}) {
  const rawHeadersContainer = cx('message-headers__raw', {
    'is-hidden': !isRawHeadersOpen
  });

  const recipientStringBtnClass = cx('recipient-string-btn', {
    [classes.primaryBorderColor]: true
  });

  // Dont return cc'd address if unavailable or raw headers are displayed
  let ccHeadersDisplay = '';
  let ccHeaders = [];
  if (!isUndefined(emailHeaders) && !isUndefined(emailHeaders.cc)) {
    if (!isRawHeadersOpen) {
      ccHeadersDisplay = `, ${emailHeaders.cc}`;
    }
    ccHeaders = emailHeaders.cc.split(',');
  }

  const messageHeaders = cx('message-headers', {
    'managed-delegate': !isEmpty(managedDelegate),
  });   


  return (
    <div className={messageHeaders}>
      <div className="message-headers__info">
        <div className="message-headers__badge">
          <UserBadge name={headerSender || envelopeSender} />
        </div>
        <div className="message-headers__headers">
          <ul>
            <li>
              <button
                data-test="recipient-string-btn"
                aria-expanded={isRawHeadersOpen}
                className={recipientStringBtnClass}
                tabIndex={0}
                type="button"
                onClick={() => toggleRawHeaders()}
              >
                {headerSender || envelopeSender} {renderString('recipient.to')}
                <span>{ccHeadersDisplay}</span>
                {isRawHeadersOpen ? <UnfoldLess /> : <UnfoldMore />}
              </button>
            </li>
          </ul>
        </div>
        <div className="message-headers__date">
          <ReactPlaceholder ready={Boolean(date)} type="textRow" className="placeholder placeholder--datetime">
            <span>
              {renderLocalDateStr(date)} {renderTime(date)}
            </span>
          </ReactPlaceholder>
        </div>
      </div>

      <ul className={rawHeadersContainer}>
        {!isUndefined(emailHeaders) && !isUndefined(emailHeaders.to) && (
          <li>
            <span>To:</span>
            {emailHeaders.to}
          </li>
        )}
        {!isUndefined(emailHeaders) && !isUndefined(emailHeaders.cc) && (
          <li>
            <span>Cc:</span>
            <ul>
              {ccHeaders.map((user) => (
                <li key={user}>{user}</li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    </div>
  );
}

MessageHeaders.defaultProps = {
  headerSender: '',
  envelopeSender: '',
  isRawHeadersOpen: false,
  date: null,
  emailHeaders: {}
};

MessageHeaders.propTypes = {
  headerSender: PropTypes.string,
  envelopeSender: PropTypes.string,
  isRawHeadersOpen: PropTypes.bool,
  toggleRawHeaders: PropTypes.func.isRequired,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  emailHeaders: PropTypes.object,
  managedDelegate: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,

};

export default withPrimaryStyles(MessageHeaders);
