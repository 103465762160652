import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';

import './SideBarContent.scss';
import { getApplicationTitle } from '../../utils/appUtils';
import { isSecureMessageCenterPath } from '../../utils/routeUtils';
import { renderString } from '../../utils/renderUtils';
import EmptyState from '../../common/EmptyState';
import HelperText from '../../common/HelperText';
import SideBarFolderListItemContainer from '../FolderListItem';
import SidebarList from '../../common/List/SidebarList';

const SideBarContent = ({ folderIds, isError }) => {
  function renderFolders() {
    if (isSecureMessageCenterPath(window.location.pathname)) {
      return <SideBarFolderListItemContainer folderId="folder.SecureMessageCenter" />;
    }

    // If Config Center is down
    if (isError) {
      return (
        <EmptyState className="empty-state--list" body={renderString('folder.not.found')}>
          <WarningIcon />
        </EmptyState>
      );
    }

    if (folderIds && folderIds.length < 1) {
      return null;
    }
    return <>{folderIds && folderIds.map((folderId) => <SidebarList key={folderId} folderId={folderId} />)}</>;
  }

  function renderAppName() {
    return (
      <div className="sidebar-mobile-app-title">
        <HelperText title={getApplicationTitle()} />
      </div>
    );
  }
  return (
    <>
      <Hidden lgUp>
        {renderAppName()}
        <List role="tablist" dense className="sidebar-mobile-folder-list">
          {renderFolders()}
        </List>
      </Hidden>
      <Hidden mdDown>
        <List dense>{renderFolders()}</List>
      </Hidden>
    </>
  );
};

SideBarContent.defaultProps = {
  isError: false
};

SideBarContent.propTypes = {
  isError: PropTypes.bool,
  folderIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SideBarContent;
