import { notifierTypes } from './notifierTypes';

export const pushNotification = ({
  intlId,
  values,
  actionIntlId,
  onAction,
  timeout,
  onTimeout,
  verticalPosition,
  horizontalPosition,
  isError = false
}) => ({
  type: notifierTypes.PUSH_NOTIFICATION,
  notification: {
    intlId,
    values,
    isError,
    actionIntlId,
    onAction,
    timeout,
    onTimeout,
    verticalPosition,
    horizontalPosition
  }
});

export const removeNotification = () => ({
  type: notifierTypes.DEQUEUE_NOTIFICATION
});

export const closeNotifier = () => ({
  type: notifierTypes.CLOSE_NOTIFICATION
});

export const notificationOnTimeout = (id) => ({
  type: notifierTypes.NOTIFICATION_ON_TIME_OUT,
  id
});

export const notificationOnUndoAction = (id) => ({
  type: notifierTypes.NOTIFICATION_ON_UNDO_ACTION,
  id
});
