import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import { contentTypes, keyCodes } from '../../../constants/uiConstants';
import {
  getCurrentFolderId,
  isComponentDisabled,
  isQuarantineApp,
  isSecureMessageCenterApp
} from '../../../ducks/uiStatusReducer';
import {
  getEncryptedTotalCheckedGuids,
  getEncryptionMetaByGuid
} from '../../../Encryption/MessageList/ducks/MessageListReducer';
import { getMetaKeyByGuid, getTotalCheckedGuids } from '../../../Quarantine/ducks/messageReducer';
import { getNotificationCountInQueue } from '../../Notifier/ducks/notifierReducer';
import { getQuickActions } from '../../../SideBar/ducks/folderReducer';
import { markEncryptedMessageAsChecked } from '../../../Encryption/MessageList/ducks/MessageListActions';
import { markMessageAsChecked } from '../../../Quarantine/ducks/messageActions';
import { setComponentDisable } from '../../../ducks/uiStatusActions';
import SingleMessageRow from './SingleMessageRow';
import roveFocusHOC from '../../Hoc/roveFocusHOC';

class SingleMessageRowContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      areActionsVisible: false
    };
    if (props.isQuarantine) {
      props.registerHandleSplKeyShortcutClicked(this.handleSplKeyShortcutClicked);
    }
  }

  componentDidUpdate = (prevProps) => {
    const { isSelected, setCurrentKeyboardFocus, index } = this.props;
    if (isSelected && isSelected !== prevProps.isSelected) {
      setCurrentKeyboardFocus(index);
    }
  };

  handleRowHover = (isHovering) => {
    const { showCheckboxOnHover } = this.props;
    // todo: withWidth causing weird message row overlaps. Removed for now.
    if (isMobile || !showCheckboxOnHover) {
      return;
    }
    this.setState({ isHovering });
  };

  handleQuickActionsHover = (areActionsVisible) => {
    if (isMobile) {
      return;
    }
    this.setState({ areActionsVisible });
  };

  messageClicked = () => {
    const { guid, handleOnMessageClick, setCurrentKeyboardFocus, index } = this.props;
    handleOnMessageClick(guid);
    setCurrentKeyboardFocus(index);
  };

  messageActions = (command) => {
    const { guid, handleMessageAction } = this.props;
    handleMessageAction(command, [guid]);
  };

  handleToggleCheckbox = () => {
    const {
      guid,
      isChecked,
      totalChecked,
      handleCheckQuarantineMessage,
      handleCheckEncrypedMessage,
      setComponentDisableDispatch,
      isQuarantine
    } = this.props;

    // Get updated count
    let totalSelectedMessages = totalChecked;
    totalSelectedMessages = isChecked ? totalSelectedMessages - 1 : totalSelectedMessages + 1;

    if (isQuarantine) {
      handleCheckQuarantineMessage(guid, !isChecked);
      setComponentDisableDispatch('ActionButton', totalSelectedMessages > 0);
    } else {
      handleCheckEncrypedMessage(guid, !isChecked);
      setComponentDisableDispatch('SingleEncryptionActions', totalSelectedMessages > 0);
    }
  };

  handleSplKeyShortcutClicked = (e) => {
    // (commands.length > 0) condition is to ensure the messagelist in the folder has actionable items
    // i.e., checkbox to be enabled on hover.
    // If there is, key 'X' will activate the checkbox for the currently focussed message.
    const { commands } = this.props;
    if (commands.length > 0 && e.keyCode === keyCodes.KEY_X) {
      this.handleToggleCheckbox();
      this.messageClicked();
    }
  };

  handleOnTouchStart = () => {
    const { canBeSelected } = this.props;
    // Touch screen only
    if (!isMobile || !canBeSelected) return;
    // Prevent messages being selected if no actions are available
    const { isEncrypted, commands } = this.props;
    if (!isEncrypted && commands.length === 0) {
      return;
    }
    this.buttonPressTimer = setTimeout(() => this.handleToggleCheckbox(), 1000);
  };

  handleOnTouchEnd = () => {
    clearTimeout(this.buttonPressTimer);
  };

  render() {
    const {
      index,
      guid,
      active,
      senderAddress,
      recipients,
      subject,
      deliveryDate,
      expiryDate,
      brandingColorPrimary,
      isReady,
      showCheckboxOnHover,
      isChecked,
      totalChecked,
      attachmentCount,
      isEncrypted,
      isRead,
      markedAsRead,
      isSelected,
      commands,
      areActionsDisabled,
      currentFocus,
      handleOnBlur,
      handleOnFocus,
      lastVisibleIndex,
      firstVisibleIndex,
      ariaLabel,
      ariaLabelChecked,
      notificationCount
    } = this.props;
    const { isHovering, areActionsVisible } = this.state;
    return (
      <SingleMessageRow
        guid={guid}
        notificationCount={notificationCount}
        active={active}
        isFocussed={currentFocus === index}
        senderAddress={senderAddress}
        recipients={recipients}
        subject={subject}
        deliveryDate={deliveryDate}
        firstVisibleIndex={firstVisibleIndex}
        index={index}
        expiryDate={expiryDate}
        isSelected={isSelected}
        brandingColorPrimary={brandingColorPrimary}
        isReady={isReady}
        lastVisibleIndex={lastVisibleIndex}
        showCheckboxOnHover={showCheckboxOnHover}
        isChecked={isChecked}
        isHovering={isHovering}
        handleToggleCheckbox={this.handleToggleCheckbox}
        handleOnTouchStart={this.handleOnTouchStart}
        handleOnTouchEnd={this.handleOnTouchEnd}
        handleOnMessageClick={totalChecked > 0 ? this.handleToggleCheckbox : this.messageClicked}
        showCheckbox={totalChecked > 0}
        attachmentCount={attachmentCount}
        isEncrypted={isEncrypted}
        isRead={markedAsRead || isRead}
        handleRowHover={this.handleRowHover}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        commands={commands}
        handleMessageAction={this.messageActions}
        areActionsDisabled={areActionsDisabled}
        handleQuickActionsHover={this.handleQuickActionsHover}
        areActionsVisible={areActionsVisible}
        hasMessageActions={isEncrypted ? true : commands.length > 0}
        ariaLabel={isChecked ? ariaLabelChecked : ariaLabel}
      />
    );
  }
}

SingleMessageRowContainer.defaultProps = {
  isSelected: false,
  index: 0,
  handleOnMessageClick: null,
  guid: null,
  active: false,
  senderAddress: null,
  recipients: [],
  subject: null,
  deliveryDate: null,
  expiryDate: null,
  brandingColorPrimary: null,
  isReady: false,
  isChecked: false,
  showCheckboxOnHover: false,
  totalChecked: 0,
  attachmentCount: 0,
  isEncrypted: true,
  isRead: true,
  markedAsRead: true,
  isQuarantine: false,
  commands: [],
  handleMessageAction: null,
  areActionsDisabled: false,
  canBeSelected: true,
  firstVisibleIndex: 0,
  lastVisibleIndex: 0,
  setCurrentKeyboardFocus: () => null,
  ariaLabel: '',
  ariaLabelChecked: null
};

SingleMessageRowContainer.propTypes = {
  isSelected: PropTypes.bool,
  index: PropTypes.number,
  handleOnMessageClick: PropTypes.func,
  guid: PropTypes.string,
  active: PropTypes.bool,
  recipients: PropTypes.arrayOf(PropTypes.object),
  subject: PropTypes.string,
  deliveryDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  expiryDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  brandingColorPrimary: PropTypes.string,
  isReady: PropTypes.bool,
  isChecked: PropTypes.bool,
  showCheckboxOnHover: PropTypes.bool,
  totalChecked: PropTypes.number,
  senderAddress: PropTypes.string,
  attachmentCount: PropTypes.number,
  isEncrypted: PropTypes.bool,
  isRead: PropTypes.bool,
  markedAsRead: PropTypes.bool,
  handleCheckQuarantineMessage: PropTypes.func.isRequired,
  handleCheckEncrypedMessage: PropTypes.func.isRequired,
  registerHandleSplKeyShortcutClicked: PropTypes.func.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  setComponentDisableDispatch: PropTypes.func.isRequired,
  isQuarantine: PropTypes.bool,
  commands: PropTypes.arrayOf(PropTypes.string),
  handleMessageAction: PropTypes.func,
  areActionsDisabled: PropTypes.bool,
  canBeSelected: PropTypes.bool,
  currentFocus: PropTypes.number.isRequired,
  notificationCount: PropTypes.number.isRequired,
  setCurrentKeyboardFocus: PropTypes.func,
  lastVisibleIndex: PropTypes.number,
  firstVisibleIndex: PropTypes.number,
  ariaLabel: PropTypes.string,
  ariaLabelChecked: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  const props = {};
  const isSmcApp = isSecureMessageCenterApp(state);
  props.isQuarantine = isQuarantineApp(state);
  props.listType = contentTypes.MESSAGE_LIST;
  if (props.isQuarantine) {
    props.isSelected = getMetaKeyByGuid(state, ownProps.guid, 'isSelected');
    props.markedAsRead = getMetaKeyByGuid(state, ownProps.guid, 'isRead');
    props.isChecked = getMetaKeyByGuid(state, ownProps.guid, 'isChecked');
    props.folderId = getCurrentFolderId(state);
    props.totalChecked = getTotalCheckedGuids(state);
    props.commands = getQuickActions(state, props.folderId, 4);
    props.areActionsDisabled = isComponentDisabled(state, 'ActionButton');
    props.notificationCount = getNotificationCountInQueue(state);
  } else {
    props.isSelected = isSmcApp ? getEncryptionMetaByGuid(state, ownProps.guid, 'isSelected') : false;
    props.markedAsRead = getEncryptionMetaByGuid(state, ownProps.guid, 'isRead');
    props.isChecked = getEncryptionMetaByGuid(state, ownProps.guid, 'isChecked');
    props.totalChecked = getEncryptedTotalCheckedGuids(state);
    props.areActionsDisabled = isComponentDisabled(state, 'SingleEncryptionActions');
  }
  return props;
};

export default connect(mapStateToProps, {
  handleCheckQuarantineMessage: markMessageAsChecked,
  handleCheckEncrypedMessage: markEncryptedMessageAsChecked,
  setComponentDisableDispatch: setComponentDisable
})(roveFocusHOC(SingleMessageRowContainer));
