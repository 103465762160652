import { injectIntl } from 'react-intl';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import WarningIcon from '@material-ui/icons/Warning';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import './MessageContent.scss';
import { buttonElementRetriever } from '../../utils/buttonUtils';
import { renderString } from '../../utils/renderUtils';
import ActionList from '../../common/List/ActionList/Desktop';
import ActionListMobile from '../../common/List/ActionList/Mobile';
import Alert from '../../common/Alert';
import Attachment from '../../common/Attachment';
import BasicNav from '../../common/Nav/Basic';
import EmptyState from '../../common/EmptyState';
import FullScreenDialog from '../../common/Mobile/FullScreenDialog';
import MessageHeaders from '../../common/Inbox/MessageHeaders';

function MessageContent({
  currentGuid,
  headerSender,
  envelopeSender,
  subject,
  headers,
  bodyPlain,
  date,
  attachments,
  isReady,
  isError,
  handleViewAnimation,
  isRawHeadersOpen,
  toggleRawHeaders,
  hasLinks,
  isFolderError,
  commands,
  handleMessageAction,
  isMobileSingleActionOpen,
  isLandingPageFeatureOn,
  toggleSingleMessageActions,
  intl,
  managedDelegate
}) {
  const messageBodyClass = cx('message-content__body', {
    'message-content__body--error': isError,
    'message-content__body--has-bottom-nav': isLandingPageFeatureOn
  });
  const mobileBackButton = {
    icon: 'back',
    handleAction: handleViewAnimation
  };

  const mobileEllipsisButton =
    commands.length > 0
      ? {
          icon: 'MoreVert',
          handleAction: toggleSingleMessageActions
        }
      : null;

  const mobileCloseButton = {
    icon: 'close',
    handleAction: toggleSingleMessageActions
  };

  return (
    <>
      <section
        role="document"
        aria-label={intl.formatMessage({ id: 'aria.label.message.content' })}
        className="message-content"
        name="message-content"
      >
        <Hidden smUp>
          <BasicNav
            buttonLeft={mobileBackButton}
            mainTitle={subject || ''}
            isReady={!isEmpty(subject)}
            mainClass="basic-nav-wrapper--white-bg"
            innerClass="basic-nav--innerpage"
            buttonRight={mobileEllipsisButton}
          />
        </Hidden>
        <section>
          <Hidden xsDown>
            <section className="message-content__subject" aria-label={subject}>
              <ReactPlaceholder ready={subject !==null} type="textRow" className="placeholder placeholder--title">
                <h2>{subject}</h2>
              </ReactPlaceholder>
            </section>

            <ActionList hasEllipsis handleMessageAction={handleMessageAction} />
          </Hidden>

          <MessageHeaders
            headerSender={headerSender}
            envelopeSender={envelopeSender}
            isRawHeadersOpen={isRawHeadersOpen}
            toggleRawHeaders={toggleRawHeaders}
            emailHeaders={headers}
            date={date}
            managedDelegate={managedDelegate}
          />

          {attachments && attachments.length > 0 && <Attachment multiple={attachments} />}
        </section>

        {hasLinks && <Alert message={renderString('alert.no.images')} />}

        <section /* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
          className={messageBodyClass}
          aria-label={intl.formatMessage({ id: 'aria.label.message.body' })}
        >
          {isError || isFolderError ? (
            <EmptyState
              subTitle={renderString('message.loading.error.title')}
              body={renderString('message.loading.error', { lineBreak: <br /> })}
            >
              <div className="o-circle o-circle--large">
                <WarningIcon className="o-circle__icon" />
              </div>
            </EmptyState>
          ) : (
            <ReactPlaceholder ready={isReady} rows={3} type="text" className="placeholder-rows placeholder-rows--email">
              <section>
                <pre>{bodyPlain}</pre>
              </section>
            </ReactPlaceholder>
          )}
        </section>
      </section>

      {/* Mobile Action Drawer */}
      <Hidden smUp>
        {commands.length > 0 && (
          <FullScreenDialog
            isOpen={isMobileSingleActionOpen}
            mainTitle={subject}
            toggleDialog={toggleSingleMessageActions}
            buttonLeft={mobileCloseButton}
            dialogClassName="mobile-message-options-dialog"
            navClassName="basic-nav--innerpage"
            dialogContent={
              <ActionListMobile
                title={renderString('quarantine.title.actions')}
                managedDelegate={managedDelegate}
                listItems={commands.map((command) => {
                  const { intlId } = buttonElementRetriever(command);
                  return {
                    isDelete: command === 'delete',
                    handleAction: (e) => {
                      toggleSingleMessageActions(); // Close modal
                      handleMessageAction(command, [currentGuid], e); // Trigger dispatch
                      handleViewAnimation(); // Go back to list view
                    },
                    label: renderString(intlId)
                  };
                })}
              />
            }
          />
        )}
      </Hidden>
    </>
  );
}

MessageContent.defaultProps = {
  currentGuid: '',
  headerSender: '',
  envelopeSender: '',
  subject: null,
  headers: {},
  bodyPlain: '',
  date: null,
  attachments: [],
  isRawHeadersOpen: false,
  commands: [],
  hasLinks: false,
  isMobileSingleActionOpen: false,
  isLandingPageFeatureOn: false,
  isFolderError: false
};

MessageContent.propTypes = {
  currentGuid: PropTypes.string,
  headerSender: PropTypes.string,
  envelopeSender: PropTypes.string,
  subject: PropTypes.string,
  headers: PropTypes.object,
  bodyPlain: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  attachments: PropTypes.arrayOf(PropTypes.object),
  isReady: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isFolderError: PropTypes.bool,
  handleViewAnimation: PropTypes.func.isRequired,
  isRawHeadersOpen: PropTypes.bool,
  toggleRawHeaders: PropTypes.func.isRequired,
  hasLinks: PropTypes.bool,
  commands: PropTypes.arrayOf(PropTypes.string),
  handleMessageAction: PropTypes.func.isRequired,
  isMobileSingleActionOpen: PropTypes.bool,
  toggleSingleMessageActions: PropTypes.func.isRequired,
  isLandingPageFeatureOn: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object.isRequired
};

export default injectIntl(MessageContent);
