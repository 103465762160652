import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import cx from 'classnames';

import '../LandingPage.scss';
import './QuarantineSummaryCard.scss';
import { renderFolderName, renderString } from '../../utils/renderUtils';
import Folder from '../../static/images/folder.png';
import HelperText from '../../common/HelperText';
import SummaryCard from '../../common/SummaryCard';
import withPrimaryStyles from '../../common/withPrimaryStyles';

const QuarantineSummaryCard = ({ folders, isFoldersReady, classes, navigateToFolder }) => {
  const listItemClass = cx('quarantine-summary-card__list-item', {
    [classes.primaryBorderColor]: true
  });

  return (
    <div className="landing-page-quarantine-summary-card">
      {Object.entries(folders).length > 0 && (
        <SummaryCard
          greyCardTitle
          cardTitleId="landing.page.quarantine.card.title"
          content={
            <div className="quarantine-summary-card quarantine-summary-card__list">
              {Object.values(folders).map((folder) => (
                <React.Fragment key={`quarantine-summary-card-${folder.folder}`}>
                  <ListItem
                    className={listItemClass}
                    onClick={() => navigateToFolder(folder.folder)}
                    role="button"
                    button
                    tabIndex={0}
                  >
                    <EmailOutlined className={`list-item-icon list-item-icon--envelope ${classes.secondaryColor}`} />
                    <span className="list-item-name">{renderFolderName(folder.folder)}</span>
                    <span className="list-item-count">{folder.totalUnreadCount}</span>
                    <ArrowForwardIos className="list-item-icon list-item-icon--arrow" />
                  </ListItem>
                </React.Fragment>
              ))}
            </div>
          }
        />
      )}

      {/* Displaying placeholders in quarantine card in the landing page when the folders are not yet fetched */}
      {!isFoldersReady && (       
        <SummaryCard
          greyCardTitle
          cardTitleId="landing.page.quarantine.card.title"
          content={
            <div className="quarantine-summary-card quarantine-summary-card__list">            
              <ListItem
                className={listItemClass}
              >
                <ReactPlaceholder type="textRow" ready={isFoldersReady} className="placeholder placeholder--title" />                       
                <ReactPlaceholder type="rect" ready={isFoldersReady} className="placeholder placeholder--summary-count" />                    
              </ListItem>
            </div>
          }
        />
      )}

      {Object.entries(folders).length === 0 && isFoldersReady && (
        <SummaryCard
          greyCardTitle
          cardTitleId="landing.page.quarantine.card.title"
          content={
            <div className="summary-card summary-card--empty">
              <img name="summary-card--empty__icon" src={Folder} alt="" className="summary-card--empty__icon" />
              <HelperText subtitle={renderString('landing.page.quarantine.card.empty')} />
            </div>
          }
        />
      )}
    </div>
  );
};

QuarantineSummaryCard.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFoldersReady: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  navigateToFolder: PropTypes.func.isRequired
};

export default withPrimaryStyles(QuarantineSummaryCard);
