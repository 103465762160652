import PropTypes from 'prop-types';
import React from 'react';

import logoPng from '../../static/images/proofpoint_logo.svg';

const logo = <img className="proofpoint-logo" src={logoPng} alt="Proofpoint" />;

const CompanyLogo = ({ url }) => (
  <>
    {(url && (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {logo}
      </a>
    )) ||
      logo}
  </>
);

CompanyLogo.propTypes = {
  url: PropTypes.string.isRequired
};

export default CompanyLogo;
