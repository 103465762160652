import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import invert from 'lodash/invert';

import { actionAttempt, actionError, actionSuccess, apiActions, apiConstants } from '../../ducks/apiConstants';
import { buttonElements, buttonType } from '../../constants/uiConstants';
import { createReducer } from '../../utils/reducerUtils';
import { folderIntlId } from '../../constants/folderConstants';
import { getFolderTotalCount } from '../../utils/folderUtils';
import { getPageCount } from '../../Quarantine/ducks/messageReducer';
import { sideBarTypes } from './sideBarTypes';

export const moduleName = 'folders';

const INITIAL_STATE = {
  isFoldersReady: false
};

const SUPPORTED_ACTIONS = [
  buttonType.BUTTON_ALLOW,
  buttonType.BUTTON_BLOCK,
  buttonType.BUTTON_BLOCK_DOMAIN,
  buttonType.BUTTON_BLOCK_AND_DELETE,
  buttonType.BUTTON_RELEASE,
  buttonType.BUTTON_ALLOW_AND_RELEASE,
  buttonType.MARK_AS_NOT_SPAM,
  buttonType.MARK_AS_SPAM,
  buttonType.MARK_AS_PHISH,
  buttonType.BUTTON_RECOMMEND_ALLOW_AND_RELEASE,
  buttonType.BUTTON_DELETE
];

function fetchFoldersAttempt(state) {
  return {
    ...state,
    isFoldersReady: false
  };
}

function fetchFoldersSuccess(state, action) {
  if (!action.response || action.response.length === 0) {
    return {
      isError: true
    };
  }
  const folderMap = action.response.reduce((acc, currentFolder) => {
    const sortedFolder = currentFolder;

    const validCommands = filter(sortedFolder.commands, (command) =>
      SUPPORTED_ACTIONS.includes(command) ? command : false
    );

    acc[currentFolder.folder] = {
      ...sortedFolder,
      ...{
        commands: validCommands,
        totalMessageCount: sortedFolder.totalCount
      }
    };

    return acc;
  }, {});
  Object.keys(state).forEach((elem) => {
    if (state[elem].totalUnreadCount === -1) {
      folderMap[elem].totalUnreadCount += state[elem].totalUnreadCount;
    }
  });
  return folderMap;
}

function fetchFoldersError() {
  return {
    isError: true
  };
}

function fetchFolderSuccess(state, action) {
  if (!action.response || action.response.length === 0) {
    return {
      isError: true
    };
  }

  const validCommands = filter(action.response.commands, (command) =>
    SUPPORTED_ACTIONS.includes(command) ? command : false
  );

  const folderId = action.response.folder;

  const updatedState = { ...state };
  updatedState[folderId] = {
    ...updatedState[folderId],
    commands: validCommands,
    totalMessageCount: action.response.totalCount
  };

  return updatedState;
}

function fetchFolderError() {
  return {
    isError: true
  };
}

function updateFolderMessageCount(state, action) {
  const folderMap = { ...state };
  try {
    const { scrollId } = action.response.result;
    let folderId = action.requestParams.params.folder;
    let totalCount = action.response.result.totalCount || 0;

    if (action.requestParams.actionType === apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND) {
      const { guids, folder } = action.requestParams.data;
      folderId = folder;
      const previousCount = folderMap[folderId].totalCount || 0;
      const messagesRemovedCount = guids ? guids.length : 0;
      totalCount = previousCount - messagesRemovedCount;
    }

    if (folderId && folderMap[folderId]) {
      const folder = { ...folderMap[folderId] };
      folder.totalCount = totalCount;
      // if no filter - update totalMessageCount for display
      if (!action.requestParams.params.orFields) {
        folder.totalMessageCount = totalCount;
      }
      folder.scrollId = scrollId;
      folderMap[folderId] = folder;
    }
  } catch (e) {
    // ignore
  }
  return folderMap;
}

function onIncreaseFolderCount(state, action) {
  const { count, folderId } = action;
  const { totalCount, totalMessageCount } = state[folderId];

  const updatedState = { ...state };
  updatedState[folderId] = {
    ...updatedState[folderId],
    totalCount: totalCount + count,
    totalMessageCount: totalMessageCount + count
  };

  return updatedState;
}

function onDecreaseFolderCount(state, action) {
  const { count, folderId } = action;
  const { totalCount, totalMessageCount } = state[folderId];

  const updatedState = { ...state };
  updatedState[folderId] = {
    ...updatedState[folderId],
    totalCount: totalCount - count,
    totalMessageCount: totalMessageCount - count
  };

  return updatedState;
}

function onDecreaseFolderUnreadCount(state, { folderId, count = 1 }) {
  const { totalUnreadCount = 0 } = state[folderId];

  const updatedState = { ...state };
  updatedState[folderId] = {
    ...updatedState[folderId],
    totalUnreadCount: totalUnreadCount - count
  };

  return updatedState;
}

export default createReducer(INITIAL_STATE, {
  [actionSuccess(apiActions.FETCH_FOLDERS)]: fetchFoldersSuccess,
  [actionError(apiActions.FETCH_FOLDERS)]: fetchFoldersError,
  [actionSuccess(apiActions.FETCH_FOLDERS_COUNT)]: fetchFoldersSuccess,
  [actionError(apiActions.FETCH_FOLDERS_COUNT)]: fetchFoldersError,
  [actionAttempt(apiActions.FETCH_FOLDERS_COUNT)]: fetchFoldersAttempt,

  [actionSuccess(apiActions.FETCH_FOLDER_COUNT)]: fetchFolderSuccess,
  [actionError(apiActions.FETCH_FOLDER_COUNT)]: fetchFolderError,

  [actionSuccess(apiActions.FETCH_MESSAGES)]: updateFolderMessageCount,
  [sideBarTypes.INCREASE_FOLDER_COUNT]: onIncreaseFolderCount,
  [sideBarTypes.DECREASE_FOLDER_COUNT]: onDecreaseFolderCount,
  [sideBarTypes.DECREASE_FOLDER_READ_COUNT]: onDecreaseFolderUnreadCount
});

/*
  Folder Selectors
 */
export const getFoldersFromState = (state) => state[moduleName];
export const getFolderId = (state, folderId) => folderId;
export const selectMaxFolders = (state, folderId, maxLimit) => maxLimit || false;
const defaultFolderCommands = [];

export const getFolderListFromState = createSelector(getFoldersFromState, (folders) => folders);

export const getFolderErrorStatus = createSelector(
  getFoldersFromState,
  (folder) => folder !== undefined && folder.isError
);

export const getFolderIdsFromState = createSelector(
  getFoldersFromState,
  (folder) => folder !== undefined && Object.keys(folder)
);

export const getFolderFromState = createSelector(
  getFolderListFromState,
  getFolderId,
  (folders, folderId) => folders[folderId]
);

export const getFolderCountFromState = createSelector(getFolderFromState, (folder) => folder.totalMessageCount);

export const getFolderUnreadCountFromState = createSelector(getFolderFromState, (folder) => folder.totalUnreadCount);

export const getFolderCommands = createSelector(getFolderFromState, selectMaxFolders, (folder, limit) => {
  const validActions = [];
  const supportedActions = invert(buttonType);
  if (!folder || !folder.commands || folder.commands.length === 0) {
    return defaultFolderCommands;
  }
  /* eslint-disable array-callback-return */
  folder.commands.map((command) => {
    if (supportedActions[command]) {
      validActions.push(command);
    }
  });

  /* eslint-enable array-callback-return */

  return limit ? validActions.slice(0, limit) : validActions;
});

export const getQuickActions = createSelector(getFolderFromState, selectMaxFolders, (folder, limit) => {
  const validActions = [];
  const quickActionsLowPriorityActions = [];
  const supportedActions = invert(buttonType);
  if (!folder || !folder.commands) {
    return defaultFolderCommands;
  }
  // Only return actions that have an icon
  /* eslint-disable array-callback-return */
  folder.commands.map((command) => {
    if (supportedActions[command] && buttonElements[command].icon) {
      if (buttonElements[command].quickActionsLowPriority) quickActionsLowPriorityActions.push(command);
      else validActions.push(command);
    }
  });

  // Adding lower priority quick action buttons to the end of quick actions list
  validActions.push(...quickActionsLowPriorityActions);
  /* eslint-enable array-callback-return */

  return limit ? validActions.slice(0, limit) : validActions;
});

export const getFoldersReadyStatus = createSelector(getFoldersFromState, (folders) => folders.isFoldersReady);

export const getDefaultQuarantineFolder = createSelector(getFolderIdsFromState, (folders) => {
  if (folders && folders.length > 0 && folders[0]) {
    // Validate folder name against supported UI folders
    const folderId = folders[0];
    if (folderIntlId[folderId]) {
      return folderId;
    }
  }
  return '';
});

export const getFoldersWithMessages = createSelector(getFolderListFromState, (folders) =>
  Object.values(folders).filter((folder) => folder.totalMessageCount > 0)
);

export const getFoldersWithUnreadMessages = createSelector(getFolderListFromState, (folders) =>
  Object.values(folders).filter((folder) => folder.totalUnreadCount > 0)
);

export function getTotalPageCount(state, folderId) {
  const folderMap = getFoldersFromState(state);
  return Math.ceil(getFolderTotalCount(folderMap, folderId) / apiConstants.pageSize);
}

export function isMaxPages(state, folderId) {
  const pageCount = getPageCount(state);
  const totalPageCount = getTotalPageCount(state, folderId);
  if (pageCount === 1 && totalPageCount === 1) {
    return false;
  }
  return totalPageCount <= pageCount;
}
