import React, { useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { StylesProvider, ThemeProvider, createGenerateClassName, createTheme } from '@material-ui/core/styles';
import { alpha, darken, getContrastRatio, lighten } from '@material-ui/core/styles/colorManipulator';
import { create } from 'jss';
import { themeOptions as fuseThemeOptions } from '@proofpoint/fuse-ui/theme/theme-provider';
import { isMobileOnly } from 'react-device-detect';
import { merge, set } from 'lodash';
import FuseTheme from '@proofpoint/fuse-ui/components/FuseTheme';
import PropTypes from 'prop-types';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import cx from 'classnames';
import preset from 'jss-preset-default';

import './App.scss';
import { RootContext } from './RootContext';
import { appSettings } from './ducks/appConstants';
import {
  folderRootPath,
  getDigestPath,
  getEncryptionPath,
  getQuarantinePath,
  getSecureMessageCenterPath
} from './utils/routeUtils';
import { moduleType } from './ducks/appTypes';
import { removeItemfromStorage } from './utils/strUtils';
import AppSwitcherContainer from './common/AppSwitcher/AppSwitcherContainer';
import DigestPage from './Digest';
import Encryption from './Encryption';
// import FuseTheme from './common/FuseTheme';
import LandingPage from './LandingPage';
import LandingPageSpinner from './Quarantine/LandingPageSpinner/LandingPageSpinner';
import NavContainer from './common/Nav';
import Notifier from './common/Notifier';
import Quarantine from './Quarantine';
import SettingsView from './Settings';

// Inject the insertion-point-jss at the beginning.
/* eslint-disable no-underscore-dangle */
if (!global.__INSERTION_POINT__) {
  global.__INSERTION_POINT__ = true;
  const styleNode = document.createComment('insertion-point-jss');

  if (document.head) {
    document.head.insertBefore(styleNode, document.head.firstChild);
  }
}
/* eslint-enable */

const generateClassName = createGenerateClassName();
const jss = create(preset());
const breakpoints = createBreakpoints({});

// We define a custom insertion point JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = 'insertion-point-jss';

function App({
  colorPrimary,
  showLandingPage,
  isEncryptionFeatureOn,
  featuresEnabledCount,
  showMobileSearch,
  isLandingPage,
  userEmailAddress,
  managedDelegate
}) {
  let lightMuiTheme;
  const appRef = useRef();
  if (colorPrimary) {
    lightMuiTheme = createTheme(
      merge(
        { ...fuseThemeOptions },
        {
          typography: {
            htmlFontSize: 10,
            useNextVariants: true
          },
          palette: {
            primary: { main: colorPrimary },
            action: { disabled: 'rgb(117, 117, 117, 0.5)', disabledBackground: 'transparent' }, // color-grey-600
            secondary: { main: colorPrimary }
          },
          overrides: {
            MuiToolbar: {
              regular: {
                [breakpoints.up('sm')]: {
                  minHeight: 'fit-content'
                }
              }
            },
            MuiButton: {
              contained: {
                '&.$Mui-disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)'
                }
              }
            },
            MuiSnackbarContent:{
              root: {
                [breakpoints.up('md')]: {
                  maxHeight: 'fit-content'
                }
              }
            }
          }
        }
      )
    );
    // Update color if the contrast is too low
    if (getContrastRatio('#FFFFFF', lightMuiTheme.palette.primary.main) < 4.5) {
      lightMuiTheme.palette.primary.light = darken(lightMuiTheme.palette.primary.main, 0.38);
      lightMuiTheme.palette.secondary.main = darken(lightMuiTheme.palette.primary.main, 0.68);
    }
    if (getContrastRatio('#000', lightMuiTheme.palette.primary.dark) < 1.5) {
      lightMuiTheme.palette.primary.dark = lighten(lightMuiTheme.palette.primary.main, 0.38);
      lightMuiTheme.palette.secondary.main = darken(lightMuiTheme.palette.primary.main, 0.68);
    }

    const lightPaletteColor = lightMuiTheme.palette.secondary.main;
    const contrastTitleColor =
      getContrastRatio('#000', colorPrimary) < getContrastRatio('#fff', colorPrimary) ? '#fff' : lightPaletteColor;

    set(lightMuiTheme, 'overrides.MuiButton.textPrimary.color', lightPaletteColor);
    set(lightMuiTheme, 'overrides.MuiCheckbox.checked.color', lightPaletteColor);
    set(lightMuiTheme, 'overrides.MuiButton.root.color', lightPaletteColor);
    set(lightMuiTheme, 'overrides.MuiMenuItem.root.color', lightPaletteColor);
    set(lightMuiTheme, 'overrides.MuiPickersToolbarButton.toolbarBtnSelected.color', contrastTitleColor);
    set(lightMuiTheme, 'overrides.MuiPickersToolbarButton.toolbarBtnSelected.opacity', '1');
    set(lightMuiTheme, 'overrides.MuiPickersToolbarButton.toolbarBtn.color', contrastTitleColor);
    set(lightMuiTheme, 'overrides.MuiPickersToolbarButton.toolbarBtn.opacity', '0.54');
    set(lightMuiTheme, 'overrides.MuiPickersDay.isSelected.color', contrastTitleColor);
    set(lightMuiTheme, 'overrides.MuiTypography.colorTextSecondary.color', '#6e6e6e');
    if (getContrastRatio('#FFFFFF', alpha(lightMuiTheme.palette.primary.main, 0.86)) < 5) {
      set(
        lightMuiTheme,
        'overrides.MuiButton.containedPrimary[&:hover].backgroundColor',
        darken(lightMuiTheme.palette.primary.main, 0.1)
      );
    }
  }

  const regionClass = cx('region-main', {
    'region-main--scroll': showLandingPage && isLandingPage,
    'region-main--withBottomNav': showLandingPage && !showMobileSearch
  });

  // withPrimaryStyles doesnt have the correct primary color at this stage
  // Apply an inline style with the top 50% background color for momentum scrolling
  let regionBackgroundColor = {};
  if (isMobileOnly && isLandingPage) {
    regionBackgroundColor = {
      background: `linear-gradient(180deg, ${colorPrimary} 50%, transparent 50%)`
    };
  }
  const HIDDEN_ITEMS_KEY = 'hiddenItems';
  const APP_KEY = appSettings.APP_ID;
  removeItemfromStorage(sessionStorage, `${APP_KEY}.${userEmailAddress}.${moduleType.MESSAGE_LIST}.${HIDDEN_ITEMS_KEY}`);
  return (
    // JssProvider allows customizing the JSS styling solution.
    <StylesProvider jss={jss} generateClassName={generateClassName} injectFirst>
      <ThemeProvider theme={lightMuiTheme}>
        <RootContext.Provider value={{ appRef }}>
          <FuseTheme forceLoad={false} fonts={false} />
          {/* MuiThemeProvider makes the theme available down the React tree thanks to React context. */}
          {colorPrimary && (
            <>
              <div className="App" ref={appRef}>
                {/* AppBar */}
                <NavContainer managedDelegate={managedDelegate} showLandingPage={showLandingPage} />

                {/* Settings Drawer */}
                {/* <FuseTheme brandingColorSecondary={lightMuiTheme.palette.secondary.main}> */}
                <SettingsView managedDelegate={managedDelegate} container={appRef} />
                {/* </FuseTheme> */}

                {/* Feature Container */}
                <div className={regionClass} style={regionBackgroundColor}>
                  {featuresEnabledCount === 0 && <LandingPageSpinner showSpinner />}
                  {featuresEnabledCount > 0 && (
                    <Switch>
                      {showLandingPage && <Route exact path="/" component={LandingPage} />}
                      {isEncryptionFeatureOn && <Route path={getEncryptionPath()} component={Encryption} />}
                      <Route path={getDigestPath()} component={DigestPage} />
                      {isEncryptionFeatureOn && <Route path={getSecureMessageCenterPath()} component={Encryption} />}
                      <Route path={getQuarantinePath()} component={Quarantine} />
                      <Route render={() => <Redirect to={folderRootPath()} />} />
                    </Switch>
                  )}
                </div>

                {/* Snackbar Notifications */}
                <Notifier />
              </div>

              {/* Mobile Bottom Nav */}
              {showLandingPage && <AppSwitcherContainer bottomNav mobileSwitcher />}
            </>
          )}
          {/* Cant load until we know the color to use */}
          {!colorPrimary && (
            <div className="splash-screen">
              <div className="splash-screen-animation">
                <div className="splash-screen-loading-bar">
                  <div className="splash-screen-blue-bar" />
                </div>
              </div>
            </div>
          )}
        </RootContext.Provider>
      </ThemeProvider>
    </StylesProvider>
  );
}

App.defaultProps = {
  colorPrimary: null,
  showMobileSearch: false,
  isLandingPage: false
};

App.propTypes = {
  colorPrimary: PropTypes.string,
  showLandingPage: PropTypes.bool.isRequired,
  isEncryptionFeatureOn: PropTypes.bool.isRequired,
  featuresEnabledCount: PropTypes.number.isRequired,
  showMobileSearch: PropTypes.bool,
  isLandingPage: PropTypes.bool,
  userEmailAddress: PropTypes.string.isRequired,
  managedDelegate: PropTypes.object.isRequired
};

export default App;
