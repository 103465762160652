/* eslint-disable no-unused-vars */
import { appSettings } from '../../../../ducks/appConstants';
import { createReducer } from '../../../../utils/reducerUtils';
import { getArrayFromStorage, putArrayToStorage, strArrayToMap } from '../../../../utils/strUtils';
import { rowItemTypes } from '../../../RowItem/ducks/rowItemTypes';
import { searchTypes } from './searchTypes';

const HIDDEN_ITEMS_KEY = 'hiddenItems';
const APP_KEY = appSettings.APP_ID;
const getHiddenItemsList = (userName, moduleType) =>
  strArrayToMap(getArrayFromStorage(sessionStorage, `${APP_KEY}.${userName}.${moduleType}.${HIDDEN_ITEMS_KEY}`));
const INITIAL_STATE = {
  MESSAGE_LIST: {
    filteredItems: {},
    selectedItems: [],
    filterString: '',
    filteredModuleType: '',
    [HIDDEN_ITEMS_KEY]: {}
  },
  SENDER_LIST: {
    filteredItems: {},
    selectedItems: [],
    filterString: '',
    filteredModuleType: '',
    [HIDDEN_ITEMS_KEY]: {}
  }
};

function updateFilteredItems(state, action) {
  const object = {
    ...state,
    [`${action.moduleType}`]: {
      ...state[`${action.moduleType}`],
      filteredItems: action.filteredItems
    }
  };
  return object;
}

function updateSelectedItems(state, action) {
  const object = {
    ...state,
    [`${action.moduleType}`]: {
      ...state[`${action.moduleType}`],
      selectedItems: action.selectedItems
    }
  };
  return object;
}

function addFilterString(state, action) {
  const object = {
    ...state,
    [`${action.moduleType}`]: {
      ...state[`${action.moduleType}`],
      filterString: action.filterStr
    }
  };
  return object;
}

function addFilterByDate(state, action) {
  const object = {
    ...state,
    [`${action.moduleType}`]: {
      ...state[`${action.moduleType}`],
      filterStartDate: action.startDate,
      filterEndDate: action.endDate
    }
  };
  return object;
}

function addToHiddenItemsList(state, action) {
  const guidsMap = strArrayToMap(action.guids);
  const updatedHiddenItems = { ...state[action.moduleType][HIDDEN_ITEMS_KEY], ...guidsMap };
  if (!action.noStorageCache) {
    putArrayToStorage(
      sessionStorage,
      `${APP_KEY}.${action.userName}.${action.moduleType}.${HIDDEN_ITEMS_KEY}`,
      Object.keys(updatedHiddenItems)
    );
  }
  const object = {
    ...state,
    [`${action.moduleType}`]: {
      ...state[`${action.moduleType}`],
      [HIDDEN_ITEMS_KEY]: updatedHiddenItems
    }
  };
  return object;
}

function removeFromHiddenItemsList(state, action) {
  const guidsMap = action.guids.reduce(
    (acc, current) => {
      if (acc[current]) {
        delete acc[current];
      }
      return acc;
    },
    { ...state[action.moduleType][HIDDEN_ITEMS_KEY] }
  );
  putArrayToStorage(
    sessionStorage,
    `${APP_KEY}.${action.userName}.${action.moduleType}.${HIDDEN_ITEMS_KEY}`,
    Object.keys(guidsMap)
  );
  const object = {
    ...state,
    [`${action.moduleType}`]: {
      ...state[`${action.moduleType}`],
      [HIDDEN_ITEMS_KEY]: guidsMap
    }
  };
  return object;
}

function loadHiddenRowItems(state, action) {
  const object = {
    ...state,
    [`${action.moduleType}`]: {
      ...state[action.moduleType],
      [HIDDEN_ITEMS_KEY]: getHiddenItemsList(action.userName, action.moduleType)
    }
  };
  return object;
}

export default createReducer(INITIAL_STATE, {
  [searchTypes.FILTERED_ROW_ITEMS_UPDATE]: updateFilteredItems,
  [searchTypes.FILTER_STRING_ADD]: addFilterString,
  [searchTypes.FILTER_BY_DATE]: addFilterByDate,
  [rowItemTypes.UPDATE_SELECTED_ROW_ITEMS]: updateSelectedItems,
  [rowItemTypes.HIDE_LIST_ITEMS]: addToHiddenItemsList,
  [rowItemTypes.REVEAL_LIST_ITEMS]: removeFromHiddenItemsList,
  [rowItemTypes.LOAD_HIDDEN_ROW_ITEMS]: loadHiddenRowItems
});
