import { all, call, put, select, spawn, take, takeLatest } from 'redux-saga/effects';

import { actionError, actionSuccess, apiActions, apiConstants } from '../app/ducks/apiConstants';
import { getCurrentEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import {
  getFilterByFromState,
  getReadyFromState,
  getSearchStringFromState,
  getTotalResultsFromState
} from '../app/Encryption/MessageList/ducks/MessageListReducer';
import {
  getMessageByGuid,
  postRestoreRecipient,
  postRevokeRecipient,
  postUpdateExpiry
} from '../app/Encryption/MessageOptions/ducks/MessageOptionsApi';
import { getMessagesByType, getTotalMessages } from '../app/Encryption/MessageList/ducks/MessageListApi';
import { getPPRememberMeCookie } from '../app/utils/cookieUtils';
import { isFutureExpiry } from '../app/utils/dateUtils';
import { messageListTypes } from '../app/Encryption/MessageList/ducks/MessageListTypes';
import { pushNotification } from '../app/common/Notifier/ducks/notifierActions';
import {
  restoreMessages,
  restoreUser,
  revokeMessages,
  revokeUser,
  updateExpiry
} from '../app/Encryption/MessageOptions/ducks/MessageOptionsActions';
import {
  retrieveEncryptedMessages,
  setMessageReadyStatus
} from '../app/Encryption/MessageList/ducks/MessageListActions';
import apiHandler from '../app/ducks/utils/apiHandler';
import logger from '../tools/logger';

const log = logger.child({ childName: 'encryptionSaga' });

export function* fetchTotalMessages() {
  const username = yield select(getCurrentEmailAddressFromState);
  const params = {
    username,
    limit: 1
  };
  try {
    yield call(apiHandler, getTotalMessages(params));
  } catch (err) {
    log.error(`fetching sender error: ${username}: ${err}`);
  }
}

export function* fetchMessagesByType(action) {
  if (!getPPRememberMeCookie()) {
    yield put(
      pushNotification({
        intlId: 'signin.session.expired',
        isError: true
      })
    );
  }
  const { offset, status, type, searchString, limit } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  const params = {
    username,
    limit: limit || apiConstants.pageSize,
    offset,
    status
  };

  // Wildcard search at either side of string
  if (searchString) {
    params.toUser = `*${searchString}*`;
  }

  try {
    yield call(apiHandler, getMessagesByType(params, type));
  } catch (err) {
    log.error(`fetching sender error: ${username}: ${err}`);
  }
}

export function* restoreRecipientStatus(action) {
  if (!getPPRememberMeCookie()) {
    yield put(
      pushNotification({
        intlId: 'signin.session.expired',
        isError: true
      })
    );
  }
  const { guid, selectedEmails, showNotification, updateMessageStatus } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  const params = {
    username,
    guid
  };
  const recipientCount = Object.keys(selectedEmails).length;

  // Request Payload
  const data = {
    restoreRecipients: Object.keys(selectedEmails)
  };
  if (updateMessageStatus) {
    data.isKeyActive = true;
    data.newExpireTime = 0; // Never expire
  }

  yield put(postRestoreRecipient(params, data));

  if (showNotification) {
    yield put(
      pushNotification({
        intlId: 'encryption.recipients.restored.success',
        values: {
          recipientCount
        },
        onAction: revokeUser({
          guid,
          selectedEmails,
          updateMessageStatus
        }),
        actionIntlId: 'notification.button.undo'
      })
    );
  }

  // wait for success or failure
  const result = yield take([
    actionSuccess(apiActions.RESTORE_ENCRYPTED_RECIPIENT),
    actionError(apiActions.RESTORE_ENCRYPTED_RECIPIENT)
  ]);

  if (result.type === actionSuccess(apiActions.RESTORE_ENCRYPTED_RECIPIENT)) {
    try {
      yield call(apiHandler, getMessageByGuid(params));
    } catch (err) {
      log.error(err, action);
    }
    if (updateMessageStatus) {
      // Update the message list if the 'Revoked' filter is active
      const filterBy = yield select(getFilterByFromState);
      const searchString = yield select(getSearchStringFromState);
      if (filterBy === apiActions.FETCH_REVOKED_ENCRYPTED_MESSAGES) {
        const status = filterBy.split('_')[1];
        yield put(
          retrieveEncryptedMessages({
            offset: 0,
            type: filterBy,
            searchString,
            status
          })
        );
      }
    }
  }
}

export function* revokeRecipientStatus(action) {
  if (!getPPRememberMeCookie()) {
    yield put(
      pushNotification({
        intlId: 'signin.session.expired',
        isError: true
      })
    );
  }
  const { guid, selectedEmails, showNotification, updateMessageStatus } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  const params = {
    username,
    guid
  };
  const recipientCount = Object.keys(selectedEmails).length;

  // Request Payload
  const data = {
    revokeRecipients: Object.keys(selectedEmails)
  };
  if (updateMessageStatus) {
    data.isKeyActive = false;
  }

  yield put(postRevokeRecipient(params, data));

  if (showNotification) {
    yield put(
      pushNotification({
        intlId: 'encryption.recipients.revoked.success',
        values: {
          recipientCount
        },
        onAction: restoreUser({
          guid,
          selectedEmails,
          updateMessageStatus
        }),
        actionIntlId: 'notification.button.undo'
      })
    );
  }

  // wait for success or failure
  const result = yield take([
    actionSuccess(apiActions.REVOKE_ENCRYPTED_RECIPIENT),
    actionError(apiActions.REVOKE_ENCRYPTED_RECIPIENT)
  ]);

  if (result.type === actionSuccess(apiActions.REVOKE_ENCRYPTED_RECIPIENT)) {
    try {
      yield call(apiHandler, getMessageByGuid(params));
    } catch (err) {
      log.error(err, action);
    }
    if (updateMessageStatus) {
      // Update the message list if the 'Active' filter is active
      const filterBy = yield select(getFilterByFromState);
      const searchString = yield select(getSearchStringFromState);
      if (filterBy === apiActions.FETCH_ACTIVE_ENCRYPTED_MESSAGES) {
        const status = filterBy.split('_')[1];
        yield put(
          retrieveEncryptedMessages({
            offset: 0,
            type: filterBy,
            searchString,
            status
          })
        );
      }
    }
  }
}

export function* updateEncryptedMessageExpiry(action) {
  if (!getPPRememberMeCookie()) {
    yield put(
      pushNotification({
        intlId: 'signin.session.expired',
        isError: true
      })
    );
  }
  const { guid, newExpireTime, currentExpireTime, showNotification } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  const params = {
    username,
    guid
  };

  const intlId = 'encryption.expiry.update.success';
  if (showNotification) {
    yield put(
      pushNotification({
        intlId,
        isError: false,
        onAction: updateExpiry({ guid, newExpireTime: currentExpireTime, currentExpireTime, showNotification: false }),
        actionIntlId: 'notification.button.undo'
      })
    );
  }

  try {
    yield call(apiHandler, postUpdateExpiry(params, { newExpireTime }, guid, currentExpireTime));
  } catch (err) {
    log.error(err, action);
    yield put(
      pushNotification({
        intlId: 'encryption.expiry.update.error',
        isError: true
      })
    );
  }

  // Update the message list if the 'Active' or 'Revoked' filter is active
  if (!isFutureExpiry(newExpireTime)) {
    const filterBy = yield select(getFilterByFromState);
    const searchString = yield select(getSearchStringFromState);
    if (filterBy !== apiActions.FETCH_ALL_ENCRYPTED_MESSAGES) {
      const status = filterBy.split('_')[1];
      yield put(
        retrieveEncryptedMessages({
          offset: 0,
          type: filterBy,
          searchString,
          status
        })
      );
    }
  }
}

export function* restoreBatchMessages(action) {
  if (!getPPRememberMeCookie()) {
    yield put(
      pushNotification({
        intlId: 'signin.session.expired',
        isError: true
      })
    );
  }
  const { guids, showNotification } = action;
  const totalCount = Object.keys(guids).length;

  // Alert user that batch is being processed.
  if (showNotification) {
    yield put(
      pushNotification({
        intlId: 'encryption.message.restored.processed',
        values: {
          totalCount
        }
      })
    );
  }

  try {
    yield all(
      Object.keys(guids).map((guid) =>
        call(restoreRecipientStatus, {
          guid,
          selectedEmails: guids[guid],
          showNotification: false,
          updateMessageStatus: true
        })
      )
    );
    // Success
    yield put(
      pushNotification({
        intlId: 'encryption.messages.restored.success',
        values: {
          messageCount: totalCount
        },
        onAction: revokeMessages({
          guids,
          showNotification: false
        }),
        actionIntlId: 'notification.button.undo'
      })
    );
  } catch (err) {
    log.error(err, action);
    // Alert user of error
    yield put(
      pushNotification({
        intlId: 'encryption.message.restored.error',
        isError: true
      })
    );
  }
}

export function* revokeBatchMessages(action) {
  if (!getPPRememberMeCookie()) {
    yield put(
      pushNotification({
        intlId: 'signin.session.expired',
        isError: true
      })
    );
  }
  const { guids, showNotification } = action;
  const totalCount = Object.keys(guids).length;

  // Alert user that batch is being processed.
  if (showNotification) {
    yield put(
      pushNotification({
        intlId: 'encryption.message.revoked.processed',
        values: {
          totalCount: Object.keys(guids).length
        }
      })
    );
  }

  try {
    yield all(
      Object.keys(guids).map((guid) =>
        call(revokeRecipientStatus, {
          guid,
          selectedEmails: guids[guid],
          showNotification: false,
          updateMessageStatus: true
        })
      )
    );
    // Success
    yield put(
      pushNotification({
        intlId: 'encryption.messages.revoked.success',
        values: {
          messageCount: totalCount
        },
        onAction: restoreMessages({
          guids,
          showNotification: false
        }),
        actionIntlId: 'notification.button.undo'
      })
    );
  } catch (err) {
    log.error(err, action);
    // Alert user of error
    yield put(
      pushNotification({
        intlId: 'encryption.message.revoked.error',
        isError: true
      })
    );
  }
}

export function* fetchRecentMessages() {
  const totalMessages = yield select(getTotalResultsFromState);
  const currentMessageFilter = yield select(getFilterByFromState);
  const isReady = yield select(getReadyFromState);

  const hasMessages = totalMessages > 0;
  const hasSearchString = yield select(getSearchStringFromState);
  const hasCorrectFilter = currentMessageFilter === apiActions.FETCH_ALL_ENCRYPTED_MESSAGES;

  // Correct messages already in state (no search or filters applied)
  if (hasMessages && !hasSearchString && hasCorrectFilter) {
    return;
  }

  // Show placeholder as the filtered messages need reset
  if (isReady) {
    yield put(setMessageReadyStatus());
  }

  // Clear filters and fetch messages from API
  yield put(
    retrieveEncryptedMessages({
      offset: 0,
      type: apiActions.FETCH_ALL_ENCRYPTED_MESSAGES,
      status: 'ALL'
    })
  );
}

export function* watchFetchMessageTotal() {
  yield takeLatest(apiActions.FETCH_TOTAL_SENT_MESSAGES, fetchTotalMessages);
}

export function* watchFetchMessages() {
  yield takeLatest(apiActions.FETCH_ALL_ENCRYPTED_MESSAGES, fetchMessagesByType);
  yield takeLatest(apiActions.FETCH_ACTIVE_ENCRYPTED_MESSAGES, fetchMessagesByType);
  yield takeLatest(apiActions.FETCH_EXPIRED_ENCRYPTED_MESSAGES, fetchMessagesByType);
  yield takeLatest(apiActions.FETCH_REVOKED_ENCRYPTED_MESSAGES, fetchMessagesByType);
}

export function* watchRestoreRecipientStatus() {
  yield takeLatest(apiActions.RESTORE_ENCRYPTED_RECIPIENT, restoreRecipientStatus);
}

export function* watchRevokeRecipientStatus() {
  yield takeLatest(apiActions.REVOKE_ENCRYPTED_RECIPIENT, revokeRecipientStatus);
}

export function* watchUpdateExpiry() {
  yield takeLatest(apiActions.UPDATE_EXPIRY, updateEncryptedMessageExpiry);
}

export function* watchRestoreMessageStatus() {
  yield takeLatest(apiActions.RESTORE_ENCRYPTED_MESSAGE, restoreBatchMessages);
}

export function* watchRevokeMessageStatus() {
  yield takeLatest(apiActions.REVOKE_ENCRYPTED_MESSAGE, revokeBatchMessages);
}

export function* watchFetchRecentMessages() {
  yield takeLatest(messageListTypes.FETCH_RECENT_ENCRYPTED_MESSAGES, fetchRecentMessages);
}

export default function* encryptionSaga() {
  yield all([
    spawn(watchFetchMessages),
    spawn(watchRestoreRecipientStatus),
    spawn(watchRevokeRecipientStatus),
    spawn(watchUpdateExpiry),
    spawn(watchRestoreMessageStatus),
    spawn(watchRevokeMessageStatus),
    spawn(watchFetchMessageTotal),
    spawn(watchFetchRecentMessages)
  ]);
}
