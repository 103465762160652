import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const Hamburger = () => (
  <>
    <SvgIcon viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="3" width="18" height="2" rx="1" ry="1" />
      <rect x="0" y="8" width="14" height="2" rx="1" ry="1" />
      <rect x="0" y="13" width="10" height="2" rx="1" ry="1" />
    </SvgIcon>
  </>
);

export default Hamburger;
