import { createSelector } from 'reselect';

import { createReducer } from '../utils/reducerUtils';
import { featureActions } from './appTypes';
import { getFeaturesForDisplay } from '../utils/featureUtils';

export const moduleName = 'features';

// Default access to /secure-message-center is false as not many customers have this feature
// If the API endpoint is down the initial state will prevent user access
// Note: Order of these features is the order in which they will display in the UI
const INITIAL_STATE = {
  enabled: {
    landingPage: false,
    keyManagement: false,
    quarantine: false
  },
  dataLoaded: false
};

function setFeatures(state, action) {
  const { landingPage, keyManagement, quarantine, misCqsEnabled } = action.features;
  // Force feature order
  const orderedFeatures = { landingPage, keyManagement, quarantine, misCqsEnabled };
  return { ...state, enabled: { ...state.enabled, ...orderedFeatures }, dataLoaded: true };
}

export default createReducer(INITIAL_STATE, {
  [featureActions.SET_FEATURES]: setFeatures
});

// Selectors

function getFeaturesFromState(state) {
  return state[moduleName];
}

export function getFeature(state, feature) {
  return getFeaturesFromState(state).enabled && getFeaturesFromState(state).enabled[feature];
}

export function getEnabledFeaturesLoaded(state) {
  const featureSet = getFeaturesFromState(state);
  return featureSet.dataLoaded;
}

export function getEnabledFeaturesCount(state) {
  const featureSet = getFeaturesFromState(state).enabled;
  const enabledFeatures = Object.entries(featureSet).filter(
    ([feature, value]) => value && feature !== 'landingPage'
  );
  return enabledFeatures.length;
}

export function isMisCqsEnabled(state) {
  return getFeature(state, 'misCqsEnabled');
}

export function showLandingPage(state) {
  return getFeature(state, 'landingPage');
}

export const getAppOptionsForDisplay = createSelector(
  getFeaturesFromState,
  showLandingPage,
  (features, shouldShowLandingPage) => {
    const featureSet = features.enabled;
    return Object.entries(featureSet)
      .filter(
        ([feature, value]) =>
          value &&
          (shouldShowLandingPage || feature !== 'landingPage') &&
          feature !== 'misCqsEnabled'
      )
      .map(([feature]) => getFeaturesForDisplay(feature));
  }
);
