import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './SignIn.scss';
import { getEnabledFeaturesCount, getEnabledFeaturesLoaded } from '../ducks/featureReducer';
import { isIntlReadySelector } from '../ducks/intlReducer';
import { wasLoggedInSelector } from './ducks/authReducer';
import SignIn from './SignIn';

class SignInContainer extends PureComponent {
  render() {
    const { isReady, wasLoggedIn, noFeatureEnabled } = this.props;
    return isReady && <SignIn wasLoggedIn={wasLoggedIn} noFeatureEnabled={noFeatureEnabled} />;
  }
}

SignInContainer.propTypes = {
  isReady: PropTypes.bool.isRequired,
  wasLoggedIn: PropTypes.bool.isRequired,
  noFeatureEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isReady: isIntlReadySelector(state),
  wasLoggedIn: wasLoggedInSelector(state),
  noFeatureEnabled: !getEnabledFeaturesCount(state) && getEnabledFeaturesLoaded(state)
});

export default connect(mapStateToProps, null)(SignInContainer);
