import AccountCircle from '@material-ui/icons/AccountCircle';
import Drawer from '@material-ui/core/Drawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import cx from 'classnames';
import HelpIcon from '@material-ui/icons/Help';
import { injectIntl } from 'react-intl';
import './SideBarAppDrawer.scss';
import AppSwitcherContainer from '../../AppSwitcher/AppSwitcherContainer';
import HelperText from '../../HelperText';
import SideBarListItem from '../../../SideBar/ListItem/SideBarListItem';

function renderSettings(settingsOnClick, logoutOnClick, redirectToHelpPage) {
  return (
    <div className="app-drawer__settings">
       <div>
        <SideBarListItem
          onClick={() => redirectToHelpPage()}
          leftIcon={
            <ListItemIcon>
              <HelpIcon color="inherit" />
            </ListItemIcon>
          }
        />
      </div>
      <div>
        <SideBarListItem
          onClick={() => settingsOnClick()}
          leftIcon={
            <ListItemIcon>
              <SettingsIcon color="inherit" />
            </ListItemIcon>
          }
        />
      </div>
      <SideBarListItem
        isSelected={false}
        onClick={() => logoutOnClick()}
        leftIcon={
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
        }
      />
    </div>
  );
}

const SideBarAppDrawer = ({
  shouldShowMobileView,
  settingsOnClick,
  logoutOnClick,
  isSingleTier,
  displayName,
  username,
  intl,
  redirectToHelpPage
}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  // const [openDialog, setOpenDialog] = useState(false);
  


  const handleClose = () => {
    setAnchorEl(null)    
  };
  
  const onAccountDropdownClicked = (evt) => {
    if(anchorEl)
    handleClose(null);
    else
    setAnchorEl(evt.currentTarget)
    

  };
  const appDrawerClass = cx('app-drawer', {
    'app-drawer--expanded': isSingleTier
  });
  return (
    <Drawer
      className="mobile-sidebar-drawer"
      open={shouldShowMobileView}
      ModalProps={{
        style: {
          width: isSingleTier ? 330 : 72
        },
        BackdropProps: {
          open: false,
          invisible: true,
          style: {
            width: isSingleTier ? 330 : 72
          }
        }
      }}
      PaperProps={{ style: { boxShadow: 'none' } }}
      transitionDuration={400}
    >
      <div className={appDrawerClass}>
        <div className="app-drawer__avatar">
          <div             
            aria-label={intl.formatMessage({ id: 'tooltip.button.user.profile' })}
            role="button"
            tabIndex={0}
            className="app-drawer__avatar-icon"
            onClick={onAccountDropdownClicked}
          >
            <AccountCircle />
          </div>
          {isSingleTier && (
            <div className="app-drawer__avatar-username">
              <HelperText title={displayName} subtitle={username} />
            </div>
          )}
        </div>
        <div className="app-drawer__content">
          {isSingleTier && <AppSwitcherContainer mobileSwitcher showAppNames />}
          {!isSingleTier && <AppSwitcherContainer mobileSwitcher />}
          {renderSettings(settingsOnClick, logoutOnClick, redirectToHelpPage)}
        </div>
      </div>
    </Drawer>
  );
};

SideBarAppDrawer.propTypes = {
  shouldShowMobileView: PropTypes.bool.isRequired,
  redirectToHelpPage: PropTypes.func.isRequired,
  settingsOnClick: PropTypes.func.isRequired,
  logoutOnClick: PropTypes.func.isRequired,
  isSingleTier: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
};

export default injectIntl(SideBarAppDrawer);
