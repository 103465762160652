import filter from 'lodash/filter';

export function getActiveRecipients(recipients) {
  const result = filter(recipients, (value, key) => {
    if (value.active) {
      return recipients[key];
    }
    return false;
  });
  return result;
}

export function getRevokedRecipients(recipients) {
  const result = filter(recipients, (value, key) => {
    if (!value.active) {
      return recipients[key];
    }
    return false;
  });
  return result;
}

export function getRecipientsEmail(recipients) {
  const emailArray = [];
  Object.keys(recipients).forEach((key) => {
    emailArray.push(recipients[key].email);
  });
  return emailArray;
}

export function getFilteredEmails(allRecipients, selectedRecipients) {
  const filteredEmails = [];
  filter(allRecipients, (value) => {
    if (selectedRecipients[value.email]) {
      filteredEmails[value.email] = value.email;
    }
    return false;
  });
  return filteredEmails;
}

export function isValidGuid(guid, encryptedMessages) {
  const encryptedMessageKeys = Object.keys(encryptedMessages);
  return encryptedMessageKeys.filter((key) => key === guid).length > 0;
}
