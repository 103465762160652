import { all, call, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchFolders, fetchFoldersCount } from '../app/Quarantine/ducks/folderApi';
import { folderTypes } from '../app/Quarantine/ducks/folderTypes';
import { getCurrentEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import apiHandler from '../app/ducks/utils/apiHandler';
import logger from '../tools/logger';
import { profileActions } from '../app/ducks/appTypes';

const log = logger.child({ childName: 'folderSaga' });

/**
 * API actions are broken down into two calls:
 *
 * 1. Fetch folders from Config Center
 * 2. Fetch cached folders from CC with mail counts from CQS
 *
 * If CQS is down the folders still appear, whereas previously none would.
 *
 * @param {Object} action
 */
export function* fetchQuarantineFolders(action) {
  const { includeTotalUnreadMessageCount = true } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  try {
    yield call(apiHandler, fetchFolders({ username }));
    yield call(
      apiHandler,
      fetchFoldersCount({
        username,
        includeTotalUnreadMessageCount
      })
    );
  } catch (err) {
    log.error(`fetching folders error: ${username}: ${err}`);
  }
}

export function* watchFetchFolders() {
  yield takeLatest(folderTypes.FETCH_QUARANTINE_FOLDERS, fetchQuarantineFolders);
  yield takeEvery([profileActions.SET_MANAGING_DELEGATE, profileActions.RESET_MANAGING_DELEGATE], fetchQuarantineFolders)

}

export default function* folderSaga() {
  yield all([spawn(watchFetchFolders)]);
}
