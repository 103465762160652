import { compose } from 'redux';

/* eslint-disable no-underscore-dangle */

/**
 * Wrapper around Redux compose to optionally return uplifted REDUX_DEVTOOLS compose
 * https://github.com/gaearon/redux-devtools
 * https://github.com/zalmoxisus/redux-devtools-extension
 */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        // for example - serialize: { immutable: Immutable }
      })
    : compose;
/* eslint-enable */

export default composeEnhancers;
