import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './Notifier.scss';
import { closeNotifier, removeNotification } from './ducks/notifierActions';
import { getCloseNotifierFromState, getTopNotificationFromState } from './ducks/notifierReducer';
import { getCurrentApp, getSettingsPath } from '../../ducks/uiStatusReducer';
import { isEmpty } from '../../utils/strUtils';
import Notifier from './Notifier';

class NotifierContainer extends PureComponent {
  render() {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Notifier {...this.props} />;
  }
}

NotifierContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  notification: PropTypes.object,
  removeNotification: PropTypes.func.isRequired,
  closeNotifier: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

NotifierContainer.defaultProps = {
  notification: undefined
};

const mapStateToProps = (state) => {
  const notification = getTopNotificationFromState(state);
  const isCloseNotifier = getCloseNotifierFromState(state);
  const settingsPath = getSettingsPath(state);
  const currentApp = getCurrentApp(state);
  return {
    isOpen: !isEmpty(notification) && !isCloseNotifier,
    notification,
    settingsPath,
    currentApp
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ removeNotification, closeNotifier }, dispatch),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(NotifierContainer);
