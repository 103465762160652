import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { contentTypes } from '../../constants/uiConstants';
import { getCurrentFolderListFocus } from '../../ducks/uiStatusReducer';
import { getFolderErrorStatus, getFolderIdsFromState } from '../../SideBar/ducks/folderReducer';
import { setCurrentKeyboardFocus } from '../../ducks/uiStatusActions';
import MessageFolders from './MessageFolders';

class MessageFoldersContainer extends Component {
  setFocus = (value) => {
    const { setCurrentKeyboardFocusDispatch } = this.props;
    setCurrentKeyboardFocusDispatch(value, contentTypes.FOLDER_LIST);
  };

  render() {
    const { isError, currentFocus, folderIds } = this.props;
    return (
      <MessageFolders
        currentFocus={currentFocus}
        folderIds={folderIds}
        setCurrentKeyboardFocus={this.setFocus}
        isError={isError}
      />
    );
  }
}

MessageFoldersContainer.defaultProps = {
  isError: false,
  folderIds: null,
  currentFocus: 0
};

MessageFoldersContainer.propTypes = {
  isError: PropTypes.bool,
  folderIds: PropTypes.arrayOf(PropTypes.string),
  currentFocus: PropTypes.number,
  setCurrentKeyboardFocusDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isError: getFolderErrorStatus(state),
  folderIds: getFolderIdsFromState(state),
  currentFocus: getCurrentFolderListFocus(state)
});

export default connect(mapStateToProps, {
  setCurrentKeyboardFocusDispatch: setCurrentKeyboardFocus
})(MessageFoldersContainer);
