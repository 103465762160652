import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { decodeMessageId } from '../utils/messageIdConverterUtil';
import { digestFolderSelector, digestOperationSelector, digestSuccessSelector } from './ducks/digestReducer';
import { digestResetCommand } from './ducks/digestActions';
import { scheduleDigestFolderCommand } from '../ducks/commandActions';
import DigestPage from './DigestPage';

class DigestPageContainer extends Component {
  componentDidMount() {
    const { location, scheduleDigestFolderCommandDispatch, digestResetCommandDispatch } = this.props;

    digestResetCommandDispatch();
    // get query params
    const urlParams = new URLSearchParams(location.search);
    const msgId = urlParams.get('msg_id');
    const module = urlParams.get('module');
    const cmd = urlParams.get('cmd');
    const msgGuid = decodeMessageId(msgId);
    scheduleDigestFolderCommandDispatch({ command: cmd, folderId: module, guids: [msgGuid] });
  }

  render() {
    return <DigestPage />;
  }
}

DigestPageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  scheduleDigestFolderCommandDispatch: PropTypes.func.isRequired,
  digestResetCommandDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.folder = digestFolderSelector(state);
  props.operation = digestOperationSelector(state);
  props.success = digestSuccessSelector(state);
  return props;
};

export default connect(mapStateToProps, {
  scheduleDigestFolderCommandDispatch: scheduleDigestFolderCommand,
  digestResetCommandDispatch: digestResetCommand
})(DigestPageContainer);
