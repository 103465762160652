import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import './ExpiryOptions.scss';
import { renderString } from '../../../utils/renderUtils';
import DatePickerContainer from '../../../common/DatePicker/DatePickerContainer';
import withPrimaryStyles from '../../../common/withPrimaryStyles';

const ExpiryOptions = ({
  toggleExpiryDropdown,
  isOpen,
  anchorEl,
  updateExpiryDispatch,
  handleMessageStatus,
  isExpired,
  isRevoked
}) => {
  const menuItemClasses = cx('expiry-option', {
    'expiry-option--expired': isExpired
  });

  return (
    <>
      <Menu
        id="expiry-dropdown"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={toggleExpiryDropdown}
        PaperProps={{
          style: {
            minWidth: 220,
            maxWidth: 260,
            borderRadius: 'unset'
          }
        }}
      >
        <div className="expiry-dropdown__header">{renderString('encryption.expiry.options.header')}</div>
        <div className="expiry-actions">
          <DatePickerContainer
            hasButtonInput
            buttonInputClass="expiry-option-custom-date"
            buttonInputContent={
              <MenuItem className={menuItemClasses}>
                {isExpired && renderString('encryption.expiry.option.set.new.expiry')}
                {!isExpired && renderString('encryption.expiry.option.change.expiry')}
              </MenuItem>
            }
            updateDateActionDispatch={updateExpiryDispatch}
            showCustomActionButton
            customActionButton={
              <span color="primary" onClick={() => updateExpiryDispatch(0)} role="button" tabIndex="-1">
                {renderString('encryption.expiry.option.never.expire')}
              </span>
            }
          />
          {!isExpired && (
            <>
              <MenuItem className={menuItemClasses} onClick={() => updateExpiryDispatch(moment().unix())}>
                {renderString('encryption.expiry.option.expire.message')}
              </MenuItem>
              <MenuItem className={menuItemClasses} onClick={() => handleMessageStatus()}>
                {!isRevoked && renderString('encryption.expiry.option.revoke.all')}
                {isRevoked && renderString('encryption.expiry.option.restore.all')}
              </MenuItem>
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

ExpiryOptions.defaultProps = {
  anchorEl: null
};

ExpiryOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleExpiryDropdown: PropTypes.func.isRequired,
  updateExpiryDispatch: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  handleMessageStatus: PropTypes.func.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isRevoked: PropTypes.bool.isRequired
};

export default withPrimaryStyles(ExpiryOptions);
