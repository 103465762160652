import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';

import BottomActions from './Mobile/BottomActions';
import MessageActivity from './MessageActivity';
import MessageList from './MessageList';
import MessageOptions from './MessageOptions';
import SideBarContainer from '../common/SideBar/SideBarContainer';

function Encryption({
  handleOnMessageClick,
  viewAnimateDirection,
  encryptedMessages,
  brandingColorPrimary,
  handleViewAnimation,
  isReady,
  isEmpty,
  showMobileSearch,
  encryptedMessageCount,
  toggleSingleMessageActions,
  showSingleMessageActions,
  totalSearchResults
}) {
  let isSingleMessageReady = isReady;
  if (totalSearchResults > 0) {
    isSingleMessageReady = isReady && !isEmpty;
  }

  return (
    <>
      <Hidden lgUp>
        <SideBarContainer />
      </Hidden>
      <div className="message-container">
        <MessageList
          handleOnMessageClick={handleOnMessageClick}
          encryptedMessages={encryptedMessages}
          isReady={isReady}
          isEmpty={isEmpty}
          brandingColorPrimary={brandingColorPrimary}
          viewAnimateDirection={viewAnimateDirection}
          showMobileSearch={showMobileSearch}
          totalSearchResults={totalSearchResults}
          encryptedMessageCount={encryptedMessageCount}
          toggleSingleMessageActions={toggleSingleMessageActions}
        />
        <MessageOptions
          viewAnimateDirection={viewAnimateDirection}
          handleViewAnimation={handleViewAnimation}
          isReady={isSingleMessageReady}
          isEmpty={isEmpty}
          brandingColorPrimary={brandingColorPrimary}
          encryptedMessageCount={totalSearchResults}
          showSingleMessageActions={showSingleMessageActions}
        />
        {!isEmpty && encryptedMessageCount !== undefined && (
          <aside className="message-timeline">
            <MessageActivity isReady={isReady} />
          </aside>
        )}
      </div>

      {/* Mobile multi-select actions */}
      <Hidden smUp>
        <BottomActions />
      </Hidden>
    </>
  );
}

Encryption.defaultProps = {
  showMobileSearch: false
};

Encryption.propTypes = {
  handleOnMessageClick: PropTypes.func.isRequired,
  handleViewAnimation: PropTypes.func.isRequired,
  viewAnimateDirection: PropTypes.string.isRequired,
  encryptedMessages: PropTypes.object.isRequired,
  brandingColorPrimary: PropTypes.string.isRequired,
  isReady: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  showMobileSearch: PropTypes.bool,
  encryptedMessageCount: PropTypes.number.isRequired,
  toggleSingleMessageActions: PropTypes.func.isRequired,
  showSingleMessageActions: PropTypes.bool.isRequired,
  totalSearchResults: PropTypes.number.isRequired
};

export default Encryption;
