import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const SecureMessageCenter = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <path
      d="M10,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,8 C22,6.9 21.1,6 20,6 L12,6 L10,4 Z"
      id="path-1"
    />
    <g transform="translate(12.000000, 9.000000)" fill="#FFFFFF" fillRule="nonzero">
      <path
        d="M6.175,3.16666667 L5.77916667,3.16666667 L5.77916667,2.375 C5.77916667,1.2825 4.8925,0.395833333 3.8,0.395833333 C2.7075,0.395833333 1.82083333,1.2825 1.82083333,2.375 L1.82083333,3.16666667 L1.425,3.16666667 C0.989583333,3.16666667 0.633333333,3.52291667 0.633333333,3.95833333 L0.633333333,7.91666667 C0.633333333,8.35208333 0.989583333,8.70833333 1.425,8.70833333 L6.175,8.70833333 C6.61041667,8.70833333 6.96666667,8.35208333 6.96666667,7.91666667 L6.96666667,3.95833333 C6.96666667,3.52291667 6.61041667,3.16666667 6.175,3.16666667 Z M3.8,6.72916667 C3.36458333,6.72916667 3.00833333,6.37291667 3.00833333,5.9375 C3.00833333,5.50208333 3.36458333,5.14583333 3.8,5.14583333 C4.23541667,5.14583333 4.59166667,5.50208333 4.59166667,5.9375 C4.59166667,6.37291667 4.23541667,6.72916667 3.8,6.72916667 Z M5.02708333,3.16666667 L2.57291667,3.16666667 L2.57291667,2.375 C2.57291667,1.698125 3.123125,1.14791667 3.8,1.14791667 C4.476875,1.14791667 5.02708333,1.698125 5.02708333,2.375 L5.02708333,3.16666667 Z"
        id="path-3"
      />
    </g>
  </SvgIcon>
);
SecureMessageCenter.defaultProps = {
  className: ''
};

SecureMessageCenter.propTypes = {
  className: PropTypes.string
};

export default SecureMessageCenter;
