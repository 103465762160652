import { senderListTypes } from './senderListTypes';

export const senderListAdd = (emails, property) => ({
  type: senderListTypes.SENDERLIST_ADD,
  emails,
  property
});

export const senderListRemove = (emails) => ({
  type: senderListTypes.SENDERLIST_REMOVE,
  emails
});

export const clearSenderList = () => ({
  type: senderListTypes.CLEAR_SENDER_LIST
});

export function loadSenderList(senderId) {
  return {
    type: senderListTypes.LOAD_SENDERLIST,
    senderId
  };
}
