import { messageTypes } from './messageTypes';

export const showMessageBody = (msgId, folderId) => ({
  type: messageTypes.SHOW_MESSAGE_BODY,
  msgId,
  folderId
});

export const updateMessageBody = (msgId, message) => ({
  type: messageTypes.UPDATE_MESSAGE_BODY,
  msgId,
  message
});

export const fetchMessages = ({ folderId, offset, filterString, scrollId, forceRefresh, searchCursor }) => ({
  type: messageTypes.FETCH_MESSAGES,
  folderId,
  offset,
  filterString,
  scrollId,
  forceRefresh,
  searchCursor
});

export const updateCurrentFolderId = (folderId) => ({
  type: messageTypes.UPDATE_CURRENT_FOLDER_ID,
  folderId
});

export const goToMessage = ({ guid, folderId }) => ({
  type: messageTypes.GO_TO_MESSAGE,
  guid,
  folderId
});

export const updateCurrentGuid = (nextGuid, filteredMessages = []) => ({
  type: messageTypes.UPDATE_CURRENT_GUID,
  nextGuid,
  filteredMessages
});

export const resetMessages = () => ({
  type: messageTypes.RESET_MESSAGES
});

export const resetMessagesAndMeta = () => ({
  type: messageTypes.RESET_MESSAGES_AND_META
})
export const setMessageReadyStatus = () => ({
  type: messageTypes.SET_MESSAGE_READY
});

export const markMessageAsChecked = (guid, isChecked = false) => ({
  type: messageTypes.MARK_MESSAGE_AS_CHECKED,
  guid,
  isChecked
});

export const resetCheckedMessages = () => ({
  type: messageTypes.RESET_CHECKED_MESSAGES
});

export const setMessageEmptyStatus = () => ({
  type: messageTypes.SET_MESSAGE_EMPTY
});

export const resetMessageEmptyStatus = () => ({
  type: messageTypes.RESET_MESSAGE_EMPTY
})

export const updateMessageCount = (filteredMessages = []) => ({
  type: messageTypes.UPDATE_FILTER_COUNT,
  filteredMessages
});

export const showMessageActionsMenu = (showMessageActions = true) => ({
  type: messageTypes.SHOW_MESSAGE_ACTIONS,
  showMessageActions
});
