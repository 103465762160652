import { RectShape } from 'react-placeholder/lib/placeholders';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';

import './MessageGroup.scss';
import { renderString } from '../../../utils/renderUtils';
import withPrimaryStyles from '../../withPrimaryStyles';

const MessageGroup = ({ title, style, isReady, className, classes }) => {
  const awesomePlaceholder = (
    <div role="cell" className="custom-placeholder--message-group">
      <RectShape />
    </div>
  );
  return (
    <ReactPlaceholder
      type="textRow"
      customPlaceholder={awesomePlaceholder}
      ready={isReady}
      className="placeholder placeholder--message-group"
      style={{ width: 50 }}
    >
      <p role="cell" className={`message-group ${className} ${classes.secondaryColor}`} style={style}>
        <span role="heading" aria-level={2}>
          {renderString(title)}
        </span>
      </p>
    </ReactPlaceholder>
  );
};

MessageGroup.defaultProps = {
  style: {},
  isReady: false,
  className: ''
};

MessageGroup.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
  isReady: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(MessageGroup);
