import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { renderRecipientsAsString } from '../../../utils/renderUtils';
import UserBadge from '../../UserBadge';
import withPrimaryStyles from '../../withPrimaryStyles';

function RecipientAvatars({ recipients, maxLength, classes }) {
  const visibleAvatars = recipients.slice(0, maxLength);
  const invisibleAvatars = recipients.slice(maxLength);
  const invisibleAvatarCount = recipients.length - maxLength;
  return (
    <>
      <ul className="singleMessage__avatars">
        {/* Inline avatars with initials */}
        {visibleAvatars &&
          visibleAvatars.map((recipient) => {
            const badgeClassName = cx('userBadge--recipients', {
              [`userBadge--recipients--read`]: recipient.firstRetrievalTime,
              [`userBadge--recipients--unread ${classes.darkSecondaryColor10}`]: !recipient.firstRetrievalTime,
              [`${classes.secondaryBackgroundColor}`]: classes.secondaryBackgroundColor,
              [`${classes.primaryBorderColor50}`]: classes.primaryBorderColor50
            });
            return (
              <li key={`recipient-${btoa(recipient.email)}`}>
                <UserBadge className={badgeClassName} name={recipient.email} tooltip={recipient.email} />
              </li>
            );
          })}
        {/* Hidden recipients within counter avatar */}
        {invisibleAvatarCount > 0 && (
          <li key="recipient-hidden">
            <UserBadge
              className="userBadge--recipients userBadge--more"
              count={invisibleAvatarCount}
              tooltip={renderRecipientsAsString(invisibleAvatars)}
            />
          </li>
        )}
      </ul>
    </>
  );
}

RecipientAvatars.defaultProps = {
  maxLength: 4
};

RecipientAvatars.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxLength: PropTypes.number,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(RecipientAvatars);
