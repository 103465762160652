import { createSelector } from 'reselect';

// This is registered by react router
const moduleName = 'router';

function getRouter(state) {
  return state[moduleName];
}

export const getCurrentPathLocation = createSelector(
  getRouter,
  (route) => route.location.pathname
);

function removeLastDirectoryPartOf(url) {
  const newUrl = url.split('/');
  newUrl.pop();
  return newUrl.join('/');
}

export const getCurrentPathLocationWithoutMsgId = createSelector(
  getCurrentPathLocation,
  (pathname) => removeLastDirectoryPartOf(pathname)
);
