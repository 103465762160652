import React from 'react';

import PropTypes from 'prop-types';

import './GeneralSettings.scss';
import GeneralSettings from './GeneralSettings';

const GeneralSettingsContainer = (props) => {
  const {managedDelegate, primaryEmailAddress, displayName, aliases, closeFunc, backFunc, isProfileUpdating } = props;
    return (
      <>
        <GeneralSettings
          primaryEmailAddress={primaryEmailAddress}
          displayName={displayName}
          aliases={aliases}
          managedDelegate={managedDelegate}
          isProfileUpdating={isProfileUpdating}
          closeFunc={closeFunc}
          backFunc={backFunc}
        />
      </>
    );
  }




GeneralSettingsContainer.propTypes = {
  primaryEmailAddress: PropTypes.string.isRequired,
  isProfileUpdating: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
  aliases: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeFunc: PropTypes.func.isRequired,
  backFunc: PropTypes.func.isRequired,
  managedDelegate: PropTypes.object.isRequired
};

export default GeneralSettingsContainer;
