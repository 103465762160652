import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCheckedGuids, getCurrentGuid, getTotalCheckedGuids } from '../../../Quarantine/ducks/messageReducer';
import { getCurrentFolderId, isQuarantineApp } from '../../../ducks/uiStatusReducer';
import {
  getEncryptedCheckedGuids,
  getEncryptedTotalCheckedGuids
} from '../../../Encryption/MessageList/ducks/MessageListReducer';
import { getFolderCommands } from '../../../SideBar/ducks/folderReducer';
import { messageList } from '../../../constants/messageConstants';
import { resetCheckedEncryptedMessages } from '../../../Encryption/MessageList/ducks/MessageListActions';
import { resetCheckedMessages } from '../../../Quarantine/ducks/messageActions';
import { restoreMessages, revokeMessages } from '../../../Encryption/MessageOptions/ducks/MessageOptionsActions';
import { setComponentDisable } from '../../../ducks/uiStatusActions';
import MessageActions from './MessageActions';

class MessageActionsContainer extends PureComponent {
  handleCancelSelected = () => {
    const {
      handleResetQuarantineMessagesDispatch,
      handleResetEncryptedMessagesDispatch,
      setComponentDisableDispatch,
      isQuarantine
    } = this.props;

    if (isQuarantine) {
      handleResetQuarantineMessagesDispatch();
      setComponentDisableDispatch('ActionButton', false);
    } else {
      handleResetEncryptedMessagesDispatch();
      setComponentDisableDispatch('SingleEncryptionActions', false);
    }
  };

  handleBulkQuarantine = (command) => {
    const { handleMessageAction, checkedGuids } = this.props;
    // Trigger action
    handleMessageAction(command, Object.keys(checkedGuids));
    // Clear list
    this.handleCancelSelected();
  };

  handleBulkEncyption = (command) => {
    const {
      restoreMessageDispatch,
      revokeMessageDispatch,
      handleResetEncryptedMessagesDispatch,
      checkedGuids
    } = this.props;

    // Validation
    if (checkedGuids.length === 0) {
      return;
    }

    switch (command) {
      case 'revoke':
        revokeMessageDispatch({
          guids: checkedGuids
        });
        break;
      case 'restore':
        restoreMessageDispatch({
          guids: checkedGuids
        });
        break;
      default:
        break;
    }

    // Clear selected keys
    handleResetEncryptedMessagesDispatch();
  };

  render() {
    const { totalChecked, commands, isQuarantine, wrapperClass } = this.props;

    return (
      <MessageActions
        wrapperClass={wrapperClass}
        totalChecked={totalChecked}
        cancelButton={{
          label: 'action.button.cancel',
          handleAction: this.handleCancelSelected
        }}
        actionButtons={commands}
        handleBulkAction={isQuarantine ? this.handleBulkQuarantine : this.handleBulkEncyption}
        maxLimit={messageList.CHECKBOX_LIMIT}
        isMobile={false}
        isQuarantine={isQuarantine}
      />
    );
  }
}

MessageActionsContainer.defaultProps = {
  totalChecked: 0,
  commands: [],
  checkedGuids: {},
  wrapperClass: 'c-message-actions',
  isQuarantine: false
};

MessageActionsContainer.propTypes = {
  handleMessageAction: PropTypes.func.isRequired,
  totalChecked: PropTypes.number,
  commands: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  checkedGuids: PropTypes.object,
  handleResetQuarantineMessagesDispatch: PropTypes.func.isRequired,
  handleResetEncryptedMessagesDispatch: PropTypes.func.isRequired,
  setComponentDisableDispatch: PropTypes.func.isRequired,
  wrapperClass: PropTypes.string,
  isQuarantine: PropTypes.bool,
  revokeMessageDispatch: PropTypes.func.isRequired,
  restoreMessageDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.isQuarantine = isQuarantineApp(state);
  if (props.isQuarantine) {
    props.totalChecked = getTotalCheckedGuids(state);
    props.checkedGuids = getCheckedGuids(state);
    props.currentGuid = getCurrentGuid(state);
    props.commands = getFolderCommands(state, getCurrentFolderId(state));
  } else {
    props.totalChecked = getEncryptedTotalCheckedGuids(state);
    props.checkedGuids = getEncryptedCheckedGuids(state);
  }
  return props;
};

export default connect(mapStateToProps, {
  handleResetQuarantineMessagesDispatch: resetCheckedMessages,
  handleResetEncryptedMessagesDispatch: resetCheckedEncryptedMessages,
  setComponentDisableDispatch: setComponentDisable,
  revokeMessageDispatch: revokeMessages,
  restoreMessageDispatch: restoreMessages
})(MessageActionsContainer);
