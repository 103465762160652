// This file contains constants used to tag components on screen to facilitate QA work

export const qaTags = Object.freeze({
  SESSION_EXPIRED_TEXT: 'SESSION_EXPIRED_TEXT',
  SINGIN_EMAIL_ERROR_TEXT: 'SINGIN_EMAIL_ERROR_TEXT',
  LOGIN_SIGNIN_BUTTON: 'LOGIN_SIGNIN_BUTTON',
  ACTIONBAR_BUTTON: 'ACTIONBAR_BUTTON',
  ACTIONBAR_SEARCH: 'ACTIONBAR_SEARCH',
  ACTIONBAR_SEARCH_CLEAR_BUTTON: 'ACTIONBAR_SEARCH_CLEAR_BUTTON',
  ACTIONBAR_SELECT_ALL_MESSAGES_CHECKBOX: 'ACTIONBAR_SELECT_ALL_MESSAGES_CHECKBOX',
  ALL_MESSAGES_CHECKBOX: 'ALL_MESSAGES_CHECKBOX',
  LIST_GROUP_HEADER: 'LIST_GROUP_HEADER',
  MESSAGE_BODY: 'MESSAGE_BODY',
  MESSAGE_BODY_TEXT: 'MESSAGE_BODY_TEXT',
  ROWITEM_LIST_CHECKBOX: 'ROWITEM_LIST_CHECKBOX',
  ACTION_BAR_BUTTON: 'ACTION_BAR_BUTTON',
  ACTION_BAR_BUTTON_BACK: 'ACTION_BAR_BUTTON_BACK',
  DROP_DOWN_MENU: 'DROP_DOWN_MENU',
  ALIAS_LIST: 'ALIAS_LIST',
  PROFILE_TILE: 'PROFILE_TILE',
  TILE_TEXT_INPUT: 'TILE_TEXT_INPUT',
  PROFILE_SIDE_BAR: 'PROFILE_SIDE_BAR',
  PROFILE_GENERAL_SETTINGS: 'PROFILE_GENERAL_SETTINGS',
  PROFILE_EMAIL_SETTINGS: 'PROFILE_EMAIL_SETTINGS',
  PROFILE_SUBMIT_BUTTON: 'PROFILE_SUBMIT_BUTTON',
  PROFILE_RESET_BUTTON: 'PROFILE_RESET_BUTTON',
  PROFILE_DUMMY_INPUT: 'PROFILE_DUMMY_INPUT',
  MESSAGE_LIST_ITEM: 'MESSAGE_LIST_ITEM',
  MESSAGE_LIST_ITEM_EMPTY: 'MESSAGE_LIST_ITEM_EMPTY',
  SIDEBAR_LIST_ITEM: 'SIDEBAR_LIST_ITEM',
  SIDEBAR_LIST_ITEM_LEFT_TEXT: 'SIDEBAR_LIST_ITEM_LEFT_TEXT',
  SIDEBAR_LIST_ITEM_MESSAGE_COUNT: 'SIDEBAR_LIST_ITEM_MESSAGE_COUNT',
  NAV_APP_SWITCHER_BUTTON: 'NAV_APP_SWITCHER_BUTTON',
  APP_LOGO: 'APP_LOGO',
  SCORE_LEVEL: 'SCORE_LEVEL',
  NAV_USER_ACCOUNT: 'NAV_USER_ACCOUNT',
  NAV_SETTINGS: 'NAV_SETTINGS',
  NAV_USER_ACCOUNT_ARROW: 'NAV_USER_ACCOUNT_ARROW',
  NAV_USER_MENU_PROFILE: 'NAV_USER_MENU_PROFILE',
  NAV_USER_MENU_LOGOUT: 'NAV_USER_MENU_LOGOUT',
  NAV_USER_DISPLAY_NAME: 'NAV_USER_DISPLAY_NAME',
  LOAD_MORE_BUTTON: 'LOAD_MORE_BUTTON'
});

export default qaTags;
