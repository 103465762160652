import { defaultActions } from '../ducks/appTypes';

/* eslint-disable import/prefer-default-export */

/**
 * HoF function for reducers as an object mapping from action types to handlers
 * @param {Object} initialState - this reducers initialState
 * @param {Object} handlers - a Object of key (Action) and value (case functions)
 * @returns {Object} - constructed state
 */
export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (action.type && handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    if (action.type && handlers[defaultActions.CATCH_ALL]) {
      return handlers[defaultActions.CATCH_ALL](state, action);
    }
    return state;
  };
}
