import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@proofpoint/fuse-ui/components/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { getCurrentEmailAddressFromState } from '../../../Settings/ducks/profileReducer';
import './FullNav.scss';
import { getAppName } from '../../../utils/featureUtils';
import { qaTags } from '../../../constants/qaConstants';
import AppSwitcherContainer from '../../AppSwitcher/AppSwitcherContainer';
import Hamburger from '../../Icons/Hamburger';
import Logo from '../../../static/icons/Logo.svg';
import withPrimaryStyles from '../../withPrimaryStyles';
import { fetchProfile, resetManagingDelegate, setManagingDelegate } from '../../../Settings/ducks/profileActions';
import ConfirmationDialog from '../../Dialogs/Confirmation';
import NavRightContainer from '../NavRightContainer';

const styles = {
  grow: {
    flexGrow: 1
  }
};

class FullNav extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false
    };
  }

  openStopManagementConfirmationDialog = () => {    
    this.setState({openDialog:true});
  }

  handleDialogClose = () => {
    this.setState({openDialog: false});
  }

  renderMainMenu() {
    const { showMobileViewSideBar } = this.props;
    return (
      <IconButton
        classes={{ root: 'hamburger-btn' }}
        color="inherit"
        aria-label="open sidebar"
        onClick={showMobileViewSideBar}
      >
        <Hamburger />
      </IconButton>
    );
  }

  renderLogo() {
    let { image } = this.props;
    const { intl } = this.props;
    if (image === null) {
      image = Logo;
    }
    return <img name={qaTags.APP_LOGO} src={image} alt={intl.formatMessage({ id: 'app.logo' })} className="nav-logo" />;
  }

  render() {
    const { toggleShowSettings, redirectToHelpPage, logout, currentApp, fetchProfileDispatch, userEmailAddress,  managedDelegate, intl, stopManagingDispatch } = this.props;
    const { openDialog } = this.state;
    
    const stopManagingConfirmDialog = intl.formatMessage({id: 'stop.managing.confirm.dialog.content'},{username: managedDelegate.displayName});
    return (
      <div className="region-nav">
        <AppBar
          position="fixed"
          classes={{ root: 'region-nav-header' }}
          productName={currentApp === 'landingPage' ? 'Personal Security Hub' : getAppName(currentApp)}
          companyLogo={this.renderLogo()}
          appSwitcher={
            <Hidden mdDown>
              <AppSwitcherContainer />
            </Hidden>
          }
        >
          <Toolbar disableGutters classes={{ root: 'region-nav-header-toolbar' }} className="nav-toolbar">

            {/* Left Icon */}
            <Hidden lgUp>{this.renderMainMenu()}</Hidden>

            {/* Right Nav */}
            <span className='show-nav-right-container-class'>
              <NavRightContainer 
                toggleShowSettings={toggleShowSettings}
                redirectToHelpPage={redirectToHelpPage}
                managedDelegate={managedDelegate}
                logout={logout}
                intl={intl}
                openStopManagementConfirmationDialog={this.openStopManagementConfirmationDialog}
              />
            </span>
          </Toolbar>
        </AppBar>
        {openDialog && <ConfirmationDialog open={openDialog} handleClose={this.handleDialogClose} handleAction={()=>{stopManagingDispatch();fetchProfileDispatch(userEmailAddress);}} dialogContent={stopManagingConfirmDialog} />}
      </div>
    );
  }
}

FullNav.defaultProps = {
  image: undefined,
  currentApp: '',  
  userEmailAddress: '',  
  managedDelegate: {},

};

FullNav.propTypes = {
  showMobileViewSideBar: PropTypes.func.isRequired,
  toggleShowSettings: PropTypes.func.isRequired,
  redirectToHelpPage: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object,
  image: PropTypes.string,
  currentApp: PropTypes.string,
  userEmailAddress: PropTypes.string,
  fetchProfileDispatch: PropTypes.func.isRequired,
  stopManagingDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const props = {}; 
  props.userEmailAddress= getCurrentEmailAddressFromState(state)
  return props;
};

const mapDispatchToProps = () => ({
  setManagingDelegateDispatch: setManagingDelegate,
  stopManagingDispatch: resetManagingDelegate,
  fetchProfileDispatch: fetchProfile,
})

export default connect(mapStateToProps, mapDispatchToProps ())(injectIntl(withStyles(styles)(withPrimaryStyles(FullNav))));

