import AppBar from '@material-ui/core/AppBar';
import Button from '@proofpoint/fuse-ui/components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import cx from 'classnames';

import './MessageActions.scss';
import { renderString } from '../../../utils/renderUtils';
import EllipsisMenu from '../../Menu/EllipsisMenu';
import Tooltip from '../../Tooltip';
import withPrimaryStyles from '../../withPrimaryStyles';

function MessageActions({
  totalChecked,
  cancelButton,
  actionButtons,
  maxLimit,
  isMobile,
  wrapperClass,
  handleBulkAction,
  isQuarantine,
  classes
}) {
  const isError = maxLimit && totalChecked > maxLimit;
  const wrapperClasses = cx(wrapperClass, {
    [`${wrapperClass}--visible`]: totalChecked > 0
  });
  const labelClass = cx(`${wrapperClass}__title`, {
    [`${wrapperClass}__title--error`]: isError
  });
  const buttonClass = cx(`${wrapperClass}__button`, {
    [classes.primaryColor]: classes.primaryColor
  });
  return (
    <AppBar position="fixed" classes={{ root: wrapperClasses }}>
      {/* Message Count */}
      <Tooltip
        title={renderString('encryption.message.limit.error', { counter: maxLimit })}
        placement="top"
        alwaysRender={isError && !isMobile}
      >
        <Typography className={labelClass}>{renderString('selection.counter', { counter: totalChecked })}</Typography>
      </Tooltip>

      {/* Cancel Button */}
      {!isMobile && Object.keys(cancelButton).length > 0 && (
        <Button onClick={() => cancelButton.handleAction()} color="inherit" classes={{ root: `${wrapperClass}__cancelButton` }}>
          {renderString(cancelButton.label)}
        </Button>
      )}

      {/* Action Buttons */}
      {!isQuarantine &&
        actionButtons.map(
          (button) =>
            Object.keys(button).length > 0 && (
              <Button
                key={button.command}
                onClick={() => handleBulkAction(button.command)}
                classes={{ root: buttonClass }}
                aria-label={`action button ${renderString(button.intlId)}`}
              >
                {renderString(button.intlId)}
              </Button>
            )
        )}

      {/* Action Dropdown */}
      {totalChecked > 0 && isQuarantine && actionButtons.length > 0 && (
        <EllipsisMenu totalChecked={totalChecked} handleMessageAction={handleBulkAction} menuItems={actionButtons} />
      )}
    </AppBar>
  );
}

MessageActions.defaultProps = {
  maxLimit: false,
  cancelButton: {},
  isMobile: false,
  actionButtons: [],
  handleBulkAction: null,
  isQuarantine: false
};

MessageActions.propTypes = {
  wrapperClass: PropTypes.string.isRequired,
  totalChecked: PropTypes.number.isRequired,
  cancelButton: PropTypes.object,
  actionButtons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  maxLimit: PropTypes.number,
  isMobile: PropTypes.bool,
  handleBulkAction: PropTypes.func,
  classes: PropTypes.object.isRequired,
  isQuarantine: PropTypes.bool
};

export default withPrimaryStyles(MessageActions);
