/**
 * App Entry Point
 * @module index
 */

import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';

import './polyfills';
import './app/static/sass/global.scss';
import './app/static/sass/definitions/_font.scss';
import { createReduxStore } from './store/storeUtil';
import { getLanguageId } from './app/utils/jsUtils';
import { init } from './app/ducks/appActions';
import { intlActions } from './app/ducks/appTypes';
import { lang, messages } from './resources/en';
import { validateExistenceToken } from './app/SignIn/ducks/authApi';
import Root from './app/Root';


import logger from './tools/logger';
// import('@welldone-software/why-did-you-render').then(( whyDidYouRender ) => {
//   whyDidYouRender.wdyrStore(React, {
//     trackAllPureComponents: true,
//   });
// });

const log = logger.child({ childName: 'index' });

function startApp() {
  return createReduxStore()
    .then(async ({ store, history }) => {
      await validateExistenceToken(store);
      return { store, history };
    })
    .then(async ({ store, history }) => {
      const langId = getLanguageId();
      // Ensure locale-data is loaded for hyphenated locale tags e.g. load 'en' data for 'en-gb'
      const localeCode = langId.split('-')[0];

      store.dispatch({
        type: intlActions.SET_DEFAULT_LANGUAGE,
        payload: { lang, messages }
      });

      /* imported from intl-relativetimeformat polyfill */
      await import(`@formatjs/intl-relativetimeformat/dist/locale-data/${localeCode}`).catch((error) => {
        log.error(error);
      });

      await import(`./resources/${langId}`)
        .then((localeData) => {
          store.dispatch({
            type: intlActions.SET_LOCALE,
            payload: { lang: localeData.lang, messages: localeData.messages }
          });
        })
        .catch((error) => {
          log.error(error);
        });
      return { store, history };
    })
    .then(({ store, history }) => {
      // notify application of intention to start
      store.dispatch(init());
      ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'));
    })
    .catch((error) => {
      log.error(error, 'App failed to initialize');
    });
}

// Turn on to view all the components which rerendered
const shouldLogUpdates = false;
if (shouldLogUpdates && process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require,import/no-extraneous-dependencies
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    onlyLogs: true,
    titleColor: "green",
    diffNameColor: "darkturquoise",
    logOnDifferentValues:true,
  });

}

startApp();
