import { getStore } from '../../../store/storeUtil';
import { API_CALL, apiActions, apiEndpoints } from '../../ducks/apiConstants';
import { getManagingDelegate } from '../../Settings/ducks/profileReducer';
import { isEmpty } from '../../utils/strUtils';

export function fetchFolders() {
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.FOLDERS_URL,
      method: 'post',
      actionType: apiActions.FETCH_FOLDERS
    }
  };
}

export function fetchFoldersCount(payload) {
  const { username, ...data } = payload;
  const dataToPass = { ...data, includeTotalMessageCount: true };
  const managingDelegate = getManagingDelegate(getStore().getState());

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.FOLDERS_URL,
      method: 'post', 
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },     
      data:  !isEmpty(managingDelegate.email)? { ...dataToPass, onBehalfOf:managingDelegate.email } : dataToPass,
      actionType: apiActions.FETCH_FOLDERS_COUNT
    }
  };
}

export function fetchFolderCount(payload) {
  const { username, ...data } = payload;
  const { folderId } = data;
  const dataToPass = { ...data, includeTotalMessageCount: true };
  const managingDelegate = getManagingDelegate(getStore().getState());
  const params = { folderId, isDelegateUser: !isEmpty(managingDelegate.email) };

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.FOLDER_URL,
      method: 'post',
      params,
      data:  !isEmpty(managingDelegate.email)? { ...dataToPass, onBehalfOf:managingDelegate.email } : dataToPass,
      actionType: apiActions.FETCH_FOLDER_COUNT
    }
  };
}

// API to be called to fetch messages when MIS CQS feature is disabled
export function fetchMessagesCqsDisabled(payload) {
  const { username, ...data } = payload;
  const managingDelegate = getManagingDelegate(getStore().getState());

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.FETCH_MESSAGES_CQS_DISABLED,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },     
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.FETCH_MESSAGES
    }
  };
}

// API to be called to fetch messages when MIS CQS feature is enabled
export function fetchMessagesCqsEnabled(payload) {
  const { username, ...data } = payload;
  const managingDelegate = getManagingDelegate(getStore().getState());

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.FETCH_MESSAGES_CQS_ENABLED,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },     
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,      actionType: apiActions.FETCH_MESSAGES
    }
  };
}

// API to be called to fetch messages while scrolling when MIS CQS feature is enabled
export function scrollSearchMessagesCqsEnabled(payload) {
  const { username, ...data } = payload;
  const managingDelegate = getManagingDelegate(getStore().getState());

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.SCROLL_SEARCH_MESSAGES_CQS_ENABLED,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },     
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.FETCH_MESSAGES
    }
  };
}

export function fetchMessageBody(params) {
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.MESSAGE_BODY_URL,
      method: 'get',
      params,
      actionType: apiActions.FETCH_MESSAGE_BODY
    }
  };
}

export function fetchMessageBodyText(data) {
  const { folder, guid } = data;
  const managingDelegate = getManagingDelegate(getStore().getState());
  const dataToPass =  !isEmpty(managingDelegate.email)? { folder , onBehalfOf:managingDelegate.email } : { folder };

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.MESSAGE_BODY_TEXT_URL,
      method: 'post',
      data: dataToPass,
      params: { guid ,isDelegateUser: !isEmpty(managingDelegate.email) },
      actionType: apiActions.FETCH_MESSAGE_BODY_TEXT
    }
  };
}

export function executeMessageFolderCommand(params, data) {
  const managingDelegate = getManagingDelegate(getStore().getState());
  const paramsToPass = { operation: data.operation, isDelegateUser: !isEmpty(managingDelegate.email) };

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.FETCH_MESSAGES_CQS_DISABLED,
      method: 'put',
      params: paramsToPass,
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND
    }
  };
}

export function executeConfigFolderCommand(params, data) {
  const managingDelegate = getManagingDelegate(getStore().getState());

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.CONFIG_URL,
      method: 'put',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.EXECUTE_CONFIG_FOLDER_COMMAND
    }
  };
}
