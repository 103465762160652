import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './Quarantine.scss';
import { renderString } from '../utils/renderUtils';
import BottomActions from './Mobile/BottomActions';
import EmptyState from '../common/EmptyState';
import MessageActions from '../common/Inbox/MessageActions';
import MessageContent from './MessageContent';
import MessageFolders from './MessageFolders';
import MessageList from './MessageList';
import MessageSearch from '../common/Inbox/MessageSearch';
import QuarantineIcon from '../common/Icons/Quarantine';
import SideBarContainer from '../common/SideBar/SideBarContainer';

function Quarantine({
  isReady,
  isEmpty,
  showMobileSearch,
  viewAnimateDirection,
  handleViewAnimation,
  handleOnMessageClick,
  handleMessageAction,
  fetchMessagesDispatch,
  isFolderError,
  managedDelegate
}) {
  const messageListClass = cx('region-main__list', {
    'animate animate--slideListLeft': !showMobileSearch && viewAnimateDirection === 'left',
    'region-main__list--search': showMobileSearch
  });

  const messageContentClass = cx('region-main__content', {
    'animate animate--slideContentLeft': viewAnimateDirection === 'left',
    'animate animate--fadeOut': viewAnimateDirection === 'right'
  });

  return (
    <>
      {/* Nav Drawer */}
      <Hidden lgUp>
        <SideBarContainer />
      </Hidden>

      {/* Folders */}
      <Hidden mdDown>
        <nav className="region-main__nav" tabIndex="-1">
          <MessageFolders />
        </nav>
      </Hidden>

      <aside className={messageListClass}>
        {/* Message Search */}
        <MessageSearch
          showMobileSearch={showMobileSearch}
          fetchMessagesDispatch={fetchMessagesDispatch}
          placeholderString="quarantine.search.placeholder"
          placeholderSuggestion="quarantine.search.placeholder.suggestion"
        />
        {/* Message Actions */}
        <MessageActions handleMessageAction={handleMessageAction} />
        {/* Message List */}

        <MessageList
          fetchMessagesDispatch={fetchMessagesDispatch}
          handleOnMessageClick={handleOnMessageClick}
          isReady={isReady}
          isEmpty={isEmpty}
          handleMessageAction={handleMessageAction}
        />
      </aside>

      {/* Email Body */}
      <section className={messageContentClass}>
        {/* Display Message not found and error loading msg when folder api fails */}
        {isFolderError && (
          <EmptyState
            title={renderString('message.loading.error.title')}
            body={renderString('message.loading.error', { lineBreak: <br /> })}
          >
            <QuarantineIcon className="o-icon o-icon--large" />
          </EmptyState>
        )}

        {/* Display 'Welcome to your Quarantine' when the messages api and folder api are successfully called but message list returned is empty */}
        {isEmpty && !isFolderError && (
          <EmptyState title={renderString('quarantine.welcome')} body={renderString('quarantine.welcome.subTitle')}>
            <QuarantineIcon className="o-icon o-icon--large" />
          </EmptyState>
        )}

        {!isEmpty && !isFolderError && (
          <MessageContent
            managedDelegate={managedDelegate}
            handleViewAnimation={handleViewAnimation}
            isEmpty={isEmpty}
            handleMessageAction={handleMessageAction}
          />
        )}
      </section>

      {/* Mobile multi-select actions */}
      <Hidden smUp>
        <BottomActions handleMessageAction={handleMessageAction} />
      </Hidden>
    </>
  );
}

Quarantine.defaultProps = {
  showMobileSearch: false,
  isFolderError: false
};

Quarantine.propTypes = {
  handleOnMessageClick: PropTypes.func.isRequired,
  viewAnimateDirection: PropTypes.string.isRequired,
  handleViewAnimation: PropTypes.func.isRequired,
  showMobileSearch: PropTypes.bool,
  isReady: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  handleMessageAction: PropTypes.func.isRequired,
  fetchMessagesDispatch: PropTypes.func.isRequired,
  isFolderError: PropTypes.bool,
  managedDelegate: PropTypes.object.isRequired
};

export default Quarantine;
