import { rowItemTypes } from '../../../RowItem/ducks/rowItemTypes';
import { searchTypes } from './searchTypes';
/* action */
export const addFilterString = (moduleType, filterStr = '', folderId) => ({
  type: searchTypes.FILTER_STRING_ADD,
  moduleType,
  filterStr,
  folderId
});

/* saga */

export const updateFilteredRowItems = (moduleType, filteredItems = {}) => ({
  type: searchTypes.FILTERED_ROW_ITEMS_UPDATE,
  moduleType,
  filteredItems
});

export const updateSelectedRowItems = (moduleType, selectedItems = []) => ({
  type: rowItemTypes.UPDATE_SELECTED_ROW_ITEMS,
  moduleType,
  selectedItems
});

export const filterByDate = (moduleType, startDate = '', endDate = '', folderId, filterStr = '') => ({
  type: searchTypes.FILTER_BY_DATE,
  moduleType,
  startDate,
  endDate,
  folderId,
  filterStr
});
