import { createReducer } from '../../../utils/reducerUtils';
import { notifierTypes } from './notifierTypes';

export const moduleName = 'notifier';

const INITIAL_STATE = {
  notifications: [],
  isCloseNotifier: false
};

function closeNotifier(state) {
  return { ...state, isCloseNotifier: true };
}

function dequeueNotification(state) {
  const notifications = state.notifications.slice();
  notifications.shift();
  return { ...state, notifications, isCloseNotifier: false };
}

function pushNotification(state, action) {
  const notifications = state.notifications.slice();
  const { notification } = action;
  notification.values = notification.values || {};
  notifications.push(notification);
  return { ...state, notifications, isCloseNotifier: false };
}

export default createReducer(INITIAL_STATE, {
  [notifierTypes.PUSH_NOTIFICATION]: pushNotification,
  [notifierTypes.DEQUEUE_NOTIFICATION]: dequeueNotification,
  [notifierTypes.CLOSE_NOTIFICATION]: closeNotifier
});

// Selectors

function allNotifier(state) {
  return state[moduleName];
}

export const getTopNotificationFromState = (state) =>
  allNotifier(state).notifications && allNotifier(state).notifications[0];
export const getNotificationCountInQueue = (state) =>
  allNotifier(state).notifications && allNotifier(state).notifications.length;
export const getCloseNotifierFromState = (state) => allNotifier(state).isCloseNotifier;
