import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
import ClearIcon from '@material-ui/icons/Clear';
import Event from '@material-ui/icons/Event';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';

import './ActionBarSearch.scss';
import { dateSearchOptions, dateSearchValues } from './ducks/searchConstants';
import { isEmpty } from '../../../utils/strUtils';
import { qaTags } from '../../../constants/qaConstants';
import Select from '../../Select';

const ActionBarSearch = ({
  match,
  filterModuleType,
  filterStr,
  addFilterString,
  showDateSearch,
  filterDate,
  searchByDate,
  intl,
  fullScreen
}) => {
  const inputRef = useRef();
  const onInputChange = (evt) => {
    addFilterString(filterModuleType, evt.target.value, match.params.folderId);
  };

  const onClickClear = () => {
    addFilterString(filterModuleType, '', match.params.folderId);
    inputRef.current.focus();
  };

  const renderClearIcon = () => {
    if (isEmpty(filterStr)) return null;
    return (
      <InputAdornment onClick={onClickClear}>
        <IconButton name={qaTags.ACTIONBAR_SEARCH_CLEAR_BUTTON} className="clearButton" aria-label="clear the filter">
          <ClearIcon className="clearIcon" />
        </IconButton>
      </InputAdornment>
    );
  };

  const currentDate = new Date().toISOString();

  const getStartDate = (evt) => {
    if (!evt || evt === '0') {
      return '';
    }
    return dateSearchValues[evt].toISOString();
  };

  const onDateRangeSelect = (evt) => {
    if (evt === '0') {
      searchByDate('', '', match.params.folderId);
    }
    searchByDate(getStartDate(evt), currentDate, match.params.folderId, filterStr);
  };

  const renderSearchIcon = () => (
    <InputAdornment position="end" className="searchIcon">
      <SearchIcon />
    </InputAdornment>
  );

  return (
    <>
      <Input
        inputRef={inputRef}
        name={qaTags.ACTIONBAR_SEARCH}
        className={cx('search', { search__fullscreen: fullScreen })}
        type="search"
        onChange={onInputChange}
        placeholder={intl.formatMessage({ id: 'search.placeholder' })}
        value={filterStr}
        disableUnderline
        inputProps={{ 'aria-label': 'Search' }}
        startAdornment={renderSearchIcon()}
        endAdornment={renderClearIcon()}
      />
      {showDateSearch && (
        <Select
          onChangeEvent={(evt) => {
            onDateRangeSelect(evt);
          }}
          appliedClasses="search-date-dropdownmenu"
          defaultIndex={filterDate}
          options={dateSearchOptions}
          mobilePlaceholderIcon={<Event />}
        />
      )}
    </>
  );
};

ActionBarSearch.defaultProps = {
  filterStr: '',
  showDateSearch: false,
  searchByDate: undefined,
  filterDate: '0',
  fullScreen: false
};

ActionBarSearch.propTypes = {
  filterModuleType: PropTypes.string.isRequired,
  filterStr: PropTypes.string,
  addFilterString: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  showDateSearch: PropTypes.bool,
  searchByDate: PropTypes.func,
  filterDate: PropTypes.string,
  intl: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool
};

export default injectIntl(ActionBarSearch);
