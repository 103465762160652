import { apiActions } from '../../../ducks/apiConstants';

export const restoreUser = ({ guid, selectedEmails, showNotification = false, updateMessageStatus = false }) => ({
  type: apiActions.RESTORE_ENCRYPTED_RECIPIENT,
  guid,
  selectedEmails,
  showNotification,
  updateMessageStatus
});

export const revokeUser = ({ guid, selectedEmails, showNotification = false, updateMessageStatus = false }) => ({
  type: apiActions.REVOKE_ENCRYPTED_RECIPIENT,
  guid,
  selectedEmails,
  showNotification,
  updateMessageStatus
});

export const updateExpiry = ({ guid, newExpireTime, currentExpireTime, showNotification = true }) => ({
  type: apiActions.UPDATE_EXPIRY,
  guid,
  newExpireTime,
  currentExpireTime,
  showNotification
});

export const restoreMessages = ({ guids, showNotification = true }) => ({
  type: apiActions.RESTORE_ENCRYPTED_MESSAGE,
  guids,
  showNotification
});

export const revokeMessages = ({ guids, showNotification = true }) => ({
  type: apiActions.REVOKE_ENCRYPTED_MESSAGE,
  guids,
  showNotification
});
