import { configActions } from './appTypes';

export const submitConfig = (formData) => ({
  type: configActions.SUBMIT_CONFIG,
  formData
});

export const deleteConfig = (property) => ({
  type: configActions.DELETE_CONFIG,
  property
});

export const editConfig = (formData) => ({
  type: configActions.EDIT_CONFIG,
  formData
});

export const retrieveConfig = (property) => ({
  type: configActions.RETRIEVE_CONFIG,
  property
});
