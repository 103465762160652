import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Portal } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/BlockSharp';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import ExpandLess from '@material-ui/icons/ExpandLessSharp';
import ExpandMore from '@material-ui/icons/ExpandMoreSharp';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import RootRef from '@material-ui/core/RootRef';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import withWidth from '@material-ui/core/withWidth';
import cx from 'classnames';

import './Settings.scss';
import { RootContext } from '../RootContext';
import { renderString } from '../utils/renderUtils';
import BasicNav from '../common/Nav/Basic';
import GeneralSettings from './General';
import MobileDrawer from '../common/MobileDrawer';
import QuarantineGeneralSettings from './Quarantine/General';
import SenderListView from './Quarantine/SenderListView';
import withPrimaryStyles from '../common/withPrimaryStyles';
import { isEmpty } from '../utils/strUtils';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quarantineDropDownOpen: false
    };
    this.settingsProfileRef = React.createRef();
    this.settingsQuarantineRef = React.createRef();
    this.settingsQuarantineGeneralRef = React.createRef();
  }

  componentDidUpdate() {
    const { showSettings, settingsPath, selectedSettingsOption, width } = this.props;
    // If we are opening for the 1st time set the visible action based on the url
    if (width !== 'xs' && showSettings && settingsPath === null) {   
      this.onClickSelectedSetting('settings.general');   
    }

    // Focus should be on the selected Settings Menu option
    let el;
    if (showSettings && selectedSettingsOption === 'settings.general') {
      el = this.settingsProfileRef.current;
    } else if (showSettings && selectedSettingsOption === 'settings.quarantine') {
      el = this.settingsQuarantineRef.current;
    } else if (showSettings && selectedSettingsOption === 'settings.quarantine.general') {
      el = this.settingsQuarantineGeneralRef.current;
    }
    if (el) el.focus();
  }

  onClickSelectedSetting(key) {
    const { settingsPath, addSettingsPath, removeSettingsPath, addSelectedSettingsOption } = this.props;

    if (settingsPath !== key) {
      if (settingsPath !== null) {
        removeSettingsPath(settingsPath);
      }
      addSettingsPath(key);
      addSelectedSettingsOption(key);
    }
  }

  onClickRemoveSetting(key) {
    const { removeSettingsPath } = this.props;
    removeSettingsPath(key);
  }

  onClickQuarantineDropDown() {
    const { quarantineDropDownOpen } = this.state;
    const { addSelectedSettingsOption } = this.props;
    this.setState({ quarantineDropDownOpen: !quarantineDropDownOpen });
    addSelectedSettingsOption('settings.quarantine');
  }

  isPressed = (idStr) => {
    const { settingsPath } = this.props;
    return settingsPath && settingsPath.includes(idStr);
  };

  closeFuncClick = () => {
    const { closeFunc } = this.props;
    closeFunc();
  };

  backClick = () => {
    const { settingsPath } = this.props;
    this.onClickRemoveSetting(settingsPath);
  };

  renderSelectedItem() {
    const {  managedDelegate, closeFunc, settingsPath, displayName, primaryEmailAddress, aliases,isProfileUpdating } = this.props;

    return [
      <MobileDrawer shouldShow={settingsPath === 'settings.general'} key="settings.general" location="left">
        <GeneralSettings 
          closeFunc={closeFunc} 
          backFunc={this.backClick}    
          managedDelegate={managedDelegate}       
          displayName={displayName}
          primaryEmailAddress={primaryEmailAddress}
          aliases={aliases}
          isProfileUpdating={isProfileUpdating}
        />      </MobileDrawer>,
      <MobileDrawer
        shouldShow={settingsPath === 'settings.quarantine.general'}
        key="settings.quarantine.general"
        location="left"
      >
        <QuarantineGeneralSettings managedDelegate={managedDelegate} closeFunc={closeFunc} backFunc={this.backClick} />
      </MobileDrawer>,
      <MobileDrawer
        shouldShow={settingsPath === 'settings.quarantine.allowList'}
        key="settings.quarantine.allowList"
        location="left"
      >
        <SenderListView senderListType="safeList" closeFunc={closeFunc} backFunc={this.backClick} />
      </MobileDrawer>,
      <MobileDrawer
        shouldShow={settingsPath === 'settings.quarantine.blockList'}
        key="settings.quarantine.blockList"
        location="left"
      >
        <SenderListView senderListType="blockList" closeFunc={closeFunc} backFunc={this.backClick} />
      </MobileDrawer>
    ];
  }

  render() {
    const { classes, showSettings, intl, managedDelegate } = this.props;
    const { quarantineDropDownOpen } = this.state;

    const mobileSettingList = cx('app-switcher-content mobile-setting-list', {
      'managed-delegate': !isEmpty(managedDelegate),
    });   

    return (
      <RootContext.Consumer>
        {(value) => {
          return (
            <>
              <Hidden smUp>
                <Portal container={value &&  value.appRef && value.appRef.current}>
                  <Drawer
                    open={showSettings}
                    onClose={this.closeFuncClick}
                    anchor="bottom"
                    classes={{
                      root: 'mobileDrawerContainer',
                      paper: 'mobileDrawer'
                    }}
                  >
                    <>
                      <BasicNav
                        buttonLeft={{
                          icon: 'close',
                          handleAction: this.closeFuncClick
                        }}
                        mainClass="basic-nav-wrapper--white-bg"
                        innerClass="basic-nav--innerpage"
                        mainTitle={<FormattedMessage id="settings.settings" />}
                      />
                      <List className={mobileSettingList}>
                        <ListItem component="li" classes={{ root: 'listitemHeader' }} key="settings.generalSettings">
                          <ListItemText
                            classes={{ root: 'listItemText listItemText--header' }}
                            primary={renderString('settings.generalSettings')}
                          />
                        </ListItem>
                        <ListItem
                          button
                          component="li"
                          aria-pressed={this.isPressed('settings.general')}
                          classes={{ root: 'listitem' }}
                          key="settings.general"
                          selected={this.isPressed('settings.general')}
                          onClick={() => this.onClickSelectedSetting('settings.general')}
                        >
                          <ListItemText
                            classes={{ root: 'listItemText' }}
                            primary={renderString('settings.general.profile')}
                          />
                          <ExpandLess className="expandLess" />
                        </ListItem>
                        <ListItem classes={{ root: 'listitemHeader' }} key="settings.quarantine">
                          <ListItemText
                            classes={{ root: 'listItemText listItemText--header' }}
                            primary={renderString('settings.quarantine')}
                          />
                        </ListItem>
                        <ListItem
                          button
                          component="li"
                          aria-pressed={this.isPressed('settings.quarantine.general')}
                          classes={{ root: 'listitem' }}
                          key="settings.quarantine.general"
                          selected={this.isPressed('settings.quarantine.general')}
                          onClick={() => this.onClickSelectedSetting('settings.quarantine.general')}
                        >
                          <ListItemText classes={{ root: 'listItemText' }} primary={renderString('settings.general')} />
                          <ExpandLess className="expandLess" />
                        </ListItem>
                        <ListItem
                          button
                          component="li"
                          aria-pressed={this.isPressed('settings.quarantine.allowList')}
                          classes={{ root: 'listitem' }}
                          key="settings.quarantine.allowList"
                          selected={this.isPressed('settings.quarantine.allowList')}
                          onClick={() => this.onClickSelectedSetting('settings.quarantine.allowList')}
                        >
                          <ListItemText
                            classes={{ root: 'listItemText' }}
                            primary={renderString('settings.safeList')}
                          />
                          <ExpandLess className="expandLess" />
                        </ListItem>
                        <ListItem
                          button
                          component="li"
                          aria-pressed={this.isPressed('settings.quarantine.blockList')}
                          classes={{ root: 'listitem' }}
                          key="settings.quarantine.blockList"
                          selected={this.isPressed('settings.quarantine.blockList')}
                          onClick={() => this.onClickSelectedSetting('settings.quarantine.blockList')}
                        >
                          <ListItemText
                            classes={{ root: 'listItemText' }}
                            primary={renderString('settings.blockList')}
                          />
                          <ExpandLess className="expandLess" />
                        </ListItem>
                      </List>
                    </>
                    {this.renderSelectedItem()}
                  </Drawer>
                </Portal>
              </Hidden>
              <Hidden xsDown>
                <Portal container={value && value.appRef && value.appRef.current}>
                  <Drawer
                    open={showSettings}
                    onClose={this.closeFuncClick}
                    anchor="right"
                    classes={{ root: 'outerDrawerRoot', modal: 'drawerModal' }}
                    PaperProps={{
                      'aria-label': 'Settings Dialog',
                      style: {
                        maxWidth: '100%',
                        width: 730,
                        display: 'flex',
                        flexDirection: 'row'
                      }
                    }}
                  >
                    <Drawer
                      className="innerDrawer"
                      variant="permanent"
                      classes={{
                        paper: 'innerDrawerPaper'
                      }}
                      anchor="left"
                    >
                      <>
                        <Typography variant="h5" className="settings__title">
                          {renderString('settings.settings')}
                        </Typography>
                        <List className="app-switcher-content">
                          <RootRef rootRef={this.settingsProfileRef}>
                            <ListItem
                              button
                              component="li"
                              aria-pressed={this.isPressed('settings.general')}
                              aria-label={intl.formatMessage({ id: 'settings.general.profile' })}
                              classes={{ root: 'listitem', selected: `listItemSelected ${classes.selectedState}` }}
                              key="settings.general"
                              selected={this.isPressed('settings.general')}
                              onClick={() => this.onClickSelectedSetting('settings.general')}
                            >
                              <SettingsIcon />
                              <ListItemText
                                classes={{ root: 'listitem__listitemtext-root' }}
                                primary={renderString('settings.general.profile')}
                              />
                            </ListItem>
                          </RootRef>
                          <RootRef rootRef={this.settingsQuarantineRef}>
                            <ListItem
                              button
                              component="li"
                              aria-expanded={quarantineDropDownOpen}
                              aria-describedby="expandInfo"
                              aria-label={intl.formatMessage({ id: 'settings.quarantine' })}
                              classes={{ root: 'listitem' }}
                              key="settings.quarantine"
                              onClick={() => this.onClickQuarantineDropDown()}
                            >
                              <p className="sr-only" id="expandInfo">
                                Quarantine menu. Select button and Press tab to navigate to submenu{' '}
                              </p>
                              :
                              <BlockIcon />
                              <ListItemText
                                classes={{ root: 'listitem__listitemtext-root' }}
                                primary={renderString('settings.quarantine')}
                              />
                              {quarantineDropDownOpen ? (
                                <ExpandMore className="expandMore" />
                              ) : (
                                <ExpandLess className="expandLess" />
                              )}
                            </ListItem>
                          </RootRef>
                          <div role="listitem">
                            <Collapse in={quarantineDropDownOpen} timeout="auto" unmountOnExit>
                              <List disablePadding>
                                <RootRef rootRef={this.settingsQuarantineGeneralRef}>
                                  <ListItem
                                    button
                                    component="li"
                                    aria-pressed={this.isPressed('settings.quarantine.general')}
                                    aria-label={intl.formatMessage({ id: 'settings.general' })}
                                    className="nestedList"
                                    classes={{ selected: `listItemSelected ${classes.selectedState}` }}
                                    key="settings.quarantine.general"
                                    selected={this.isPressed('settings.quarantine.general')}
                                    onClick={() => this.onClickSelectedSetting('settings.quarantine.general')}
                                  >
                                    <ListItemText
                                      primary={renderString('settings.general')}
                                      className="nestedListText"
                                    />
                                  </ListItem>
                                </RootRef>
                                <ListItem
                                  button
                                  component="li"
                                  aria-label={intl.formatMessage({ id: 'settings.safeList' })}
                                  aria-pressed={this.isPressed('settings.quarantine.allowList')}
                                  className="nestedList"
                                  classes={{ selected: `listItemSelected ${classes.selectedState}` }}
                                  key="settings.quarantine.allowList"
                                  selected={this.isPressed('settings.quarantine.allowList')}
                                  onClick={() => this.onClickSelectedSetting('settings.quarantine.allowList')}
                                >
                                  <ListItemText
                                    primary={renderString('settings.safeList')}
                                    className="nestedListText"
                                  />
                                </ListItem>
                                <ListItem
                                  button
                                  component="li"
                                  aria-pressed={this.isPressed('settings.quarantine.blockList')}
                                  aria-label={intl.formatMessage({ id: 'settings.blockList' })}
                                  className="nestedList"
                                  classes={{ selected: `listItemSelected ${classes.selectedState}` }}
                                  key="settings.quarantine.blockList"
                                  selected={this.isPressed('settings.quarantine.blockList')}
                                  onClick={() => this.onClickSelectedSetting('settings.quarantine.blockList')}
                                >
                                  <ListItemText
                                    primary={renderString('settings.blockList')}
                                    className="nestedListText"
                                  />
                                </ListItem>
                              </List>
                            </Collapse>
                          </div>
                        </List>
                      </>
                    </Drawer>
                    <div className="settingsPanel">{this.renderSelectedItem()}</div>
                  </Drawer>
                </Portal>
              </Hidden>
            </>
          );
        }}
      </RootContext.Consumer>
    );
  }
}

Settings.defaultProps = {
  settingsPath: null,
  selectedSettingsOption: ''
};

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  showSettings: PropTypes.bool.isRequired,
  isProfileUpdating: PropTypes.bool.isRequired,
  settingsPath: PropTypes.string,
  closeFunc: PropTypes.func.isRequired,
  addSettingsPath: PropTypes.func.isRequired,
  removeSettingsPath: PropTypes.func.isRequired,
  selectedSettingsOption: PropTypes.string,
  addSelectedSettingsOption: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  primaryEmailAddress: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  aliases: PropTypes.arrayOf(PropTypes.string).isRequired,
  managedDelegate: PropTypes.object.isRequired
};

// TODO  when we upgarde to material-ui 4 switch to using useMediaQuery instead of withWidth
export default withWidth()(withPrimaryStyles(injectIntl(Settings)));
