import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import './ActionBarSearch.scss';
import { addFilterString, filterByDate } from './ducks/searchActions';
import { getFilterUtil } from '../../../utils/filterUtils';
import ActionBarSearch from './ActionBarSearch';

class ActionBarSearchContainer extends PureComponent {
  render() {
    const {
      filterModuleType,
      filterStr,
      match,
      addFilterStringDispatch,
      showDateSearch,
      filterDate,
      searchByDate,
      fullScreen
    } = this.props;
    return (
      <ActionBarSearch
        filterModuleType={filterModuleType}
        filterStr={filterStr}
        match={match}
        addFilterString={addFilterStringDispatch}
        showDateSearch={showDateSearch}
        filterDate={filterDate}
        searchByDate={searchByDate}
        fullScreen={fullScreen}
      />
    );
  }
}

ActionBarSearchContainer.defaultProps = {
  filterStr: '',
  showDateSearch: false,
  filterDate: '0',
  searchByDate: undefined,
  fullScreen: false
};

ActionBarSearchContainer.propTypes = {
  filterModuleType: PropTypes.string.isRequired,
  filterStr: PropTypes.string,
  addFilterStringDispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  showDateSearch: PropTypes.bool,
  filterDate: PropTypes.string,
  searchByDate: PropTypes.func,
  fullScreen: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  return {
    filterStr: getFilterUtil(props.filterModuleType).getFilterStringFromState(state),
    startDate: getFilterUtil(props.filterModuleType).getFilterStartDateFromState(state),
    endDate: getFilterUtil(props.filterModuleType).getFilterEndDateFromState(state)
  };
};

export default connect(
  mapStateToProps,
  { addFilterStringDispatch: addFilterString, searchByDate: filterByDate }
)(withRouter(ActionBarSearchContainer));
