import { applyMiddleware, createStore } from 'redux';

import composeEnhancers from './utils/composeEnhancers';
import getMiddleware from '../middlewares';
import getRootReducer from '../app/ducks';
import history from '../app/utils/historyUtils';
import logger from '../tools/logger';
import rootSaga from '../sagas';

const log = logger.child({ childName: 'storeUtil' });

let store = null;

export function setStore(newStore) {
  store = newStore;
  return store;
}

/**
 * creates and configures redux store
 *
 * @returns {Promise} Promise that resolves to the newly created & configured store
 */
export function createReduxStore() {
  return new Promise((resolve, reject) => {
    if (store) {
      reject(new Error('store already configured!!!'));
    }

    const { middleware, sagaMiddleware } = getMiddleware();

    // create redux store
    store = createStore(
      getRootReducer(history),
      undefined,
      composeEnhancers(
        applyMiddleware(...middleware)
        // add other store enhancers if any
      )
    );

    // Set the store singleton for the rest of the app to access
    setStore(store);

    // inject our sagas into the middleware
    log.info('createReduxStore() - starting app sagas');
    sagaMiddleware.run(rootSaga);

    log.info('createReduxStore() - returning app store');
    resolve({ store, history });
  });
}

export function getStore() {
  if (!store) {
    throw new Error('store is not configured.');
  }
  return store;
}
