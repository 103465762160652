import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { APP_NAMES } from '../../ducks/appConstants';
import { closeMobileViewSideBar, showAppSwitcher } from '../../ducks/uiStatusActions';
import { getAppName } from '../../utils/featureUtils';
import { getAppOptionsForDisplay, showLandingPage } from '../../ducks/featureReducer';
import { getCurrentApp, getMobileSearchStatus, isQuarantineApp } from '../../ducks/uiStatusReducer';
import { getDefaultQuarantineFolder } from '../../SideBar/ducks/folderReducer';
import { getEncryptedTotalCheckedGuids } from '../../Encryption/MessageList/ducks/MessageListReducer';
import { getTotalCheckedGuids } from '../../Quarantine/ducks/messageReducer';
import {
  navigateToFolder,
  navigateToLandingPage,
  navigateToSecureMessageCenter
} from '../../SideBar/ducks/sideBarActions';
import { renderString } from '../../utils/renderUtils';
import AppSwitcher from './AppSwitcher';
import BottomNav from './BottomNav/BottomNav';
import QuickSwitcher from './QuickSwitcher';

class AppSwitcherContainer extends Component {
  getAppOnClickHandler(appOptions) {
    const {
      folderName,
      navigateToFolderDispatch,
      currentApp,
      navigateToLandingPageDispatch,
      landingPageEnabled
    } = this.props;
    return appOptions.map((app) => {
      const appInfo = app;
      if (app.text === getAppName(APP_NAMES.quarantine)) {
        appInfo.handleOnClick = () => navigateToFolderDispatch(folderName);
        appInfo.selected = currentApp === APP_NAMES.quarantine;
      }
      if (app.text === getAppName(APP_NAMES.keyManagement)) {
        appInfo.handleOnClick = () => this.secureMessageCenterAppOnClick();
        appInfo.selected = currentApp === APP_NAMES.keyManagement;
      }
      if (landingPageEnabled && app.text === getAppName(APP_NAMES.landingPage)) {
        appInfo.selected = currentApp === APP_NAMES.landingPage;
        appInfo.handleOnClick = () => this.navigateToApp(navigateToLandingPageDispatch);
      }
      return appInfo;
    });
  }

  navigateToApp(navigateDispatch) {
    const { closeMobileViewSideBarDispatch, mobileSwitcher } = this.props;
    navigateDispatch();
    if (mobileSwitcher) {
      closeMobileViewSideBarDispatch();
    }
  }

  secureMessageCenterAppOnClick() {
    const { navigateToSecureMessageCenterDispatch } = this.props;
    navigateToSecureMessageCenterDispatch();
  }

  render() {
    const {
      appsForDisplay,
      mobileSwitcher,
      horizontal,
      landingPageEnabled,
      showAppNames,
      bottomNav,
      totalCheckedMessages,
      showAppSwitcherDispatch,
      showMobileSearch
    } = this.props;

    const appOptions = [
      {
        header: renderString('app.switcher.applications.header'),
        options: this.getAppOnClickHandler(appsForDisplay, landingPageEnabled)
      }
    ];

    // Multi-select edit mode hides primary nav components
    if (mobileSwitcher && bottomNav && totalCheckedMessages > 0) {
      return null;
    }

    if (showMobileSearch) {
      return null;
    }

    return (
      <>
        {mobileSwitcher && !bottomNav && (
          <QuickSwitcher appOptions={appOptions} horizontal={horizontal} showAppNames={showAppNames} />
        )}
        {mobileSwitcher && bottomNav && landingPageEnabled && <BottomNav appOptions={appOptions} />}
        {!mobileSwitcher && <AppSwitcher handleRequestOpen={showAppSwitcherDispatch} appOptions={appOptions} />}
      </>
    );
  }
}

AppSwitcherContainer.defaultProps = {
  mobileSwitcher: false,
  horizontal: false,
  currentApp: '',
  showAppNames: false,
  bottomNav: false,
  totalCheckedMessages: 0,
  showMobileSearch: false
};

AppSwitcherContainer.propTypes = {
  navigateToSecureMessageCenterDispatch: PropTypes.func.isRequired,
  navigateToFolderDispatch: PropTypes.func.isRequired,
  navigateToLandingPageDispatch: PropTypes.func.isRequired,
  appsForDisplay: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        icon: PropTypes.object,
        text: PropTypes.string,
        handleOnClick: PropTypes.func,
        selected: PropTypes.bool
      })
    ])
  ).isRequired,
  folderName: PropTypes.string.isRequired,
  mobileSwitcher: PropTypes.bool,
  horizontal: PropTypes.bool,
  currentApp: PropTypes.string,
  landingPageEnabled: PropTypes.bool.isRequired,
  showAppNames: PropTypes.bool,
  bottomNav: PropTypes.bool,
  closeMobileViewSideBarDispatch: PropTypes.func.isRequired,
  totalCheckedMessages: PropTypes.number,
  showAppSwitcherDispatch: PropTypes.func.isRequired,
  showMobileSearch: PropTypes.bool
};

const mapStateToProps = (state) => {
  const props = {};
  props.isQuarantine = isQuarantineApp(state);
  if (props.isQuarantine) {
    props.totalCheckedMessages = getTotalCheckedGuids(state);
  } else {
    props.totalCheckedMessages = getEncryptedTotalCheckedGuids(state);
  }
  props.folderName = getDefaultQuarantineFolder(state);
  props.appsForDisplay = getAppOptionsForDisplay(state);
  props.currentApp = getCurrentApp(state);
  props.landingPageEnabled = showLandingPage(state);
  props.showMobileSearch = getMobileSearchStatus(state);
  return props;
};

const mapDispatchToProps = () => ({
  navigateToSecureMessageCenterDispatch: navigateToSecureMessageCenter,
  navigateToFolderDispatch: navigateToFolder,
  navigateToLandingPageDispatch: navigateToLandingPage,
  closeMobileViewSideBarDispatch: closeMobileViewSideBar,
  showAppSwitcherDispatch: showAppSwitcher
});

export default connect(mapStateToProps, mapDispatchToProps())(AppSwitcherContainer);
