import { API_CALL, apiActions, apiEndpoints } from '../../ducks/apiConstants';
import { authActions } from './authConstants';
import { featureActions } from '../../ducks/appTypes';
import { isEmpty } from '../../utils/strUtils';
import { profileTypes } from '../../Settings/ducks/profileTypes';
import { setPPRememberMeCookie } from '../../utils/cookieUtils';
import apiHandler from '../../ducks/utils/apiHandler';
import logger from '../../../tools/logger';

const log = logger.child({ childName: 'authApi' });

export function getValidateExistenceToken(payload) {
  const { username, ...data } = payload;
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.USER_URL,
      method: 'post',
      data,
      actionType: apiActions.FETCH_PROFILE
    }
  };
}

export async function validateExistenceToken(store) {
  try {
    const user = await apiHandler(getValidateExistenceToken({ isLogin: true }));
    if (!isEmpty(user)) {
      setPPRememberMeCookie(user.primaryEmailAddress);
      const { features } = user;
      delete user.features;
      store.dispatch({ type: authActions.AUTHENTICATED });
      store.dispatch({ type: authActions.TOGGLEWASLOGGEDIN });
      store.dispatch({ type: profileTypes.SET_PROFILE, user });
      store.dispatch({ type: featureActions.SET_FEATURES, features });
    } else {
      store.dispatch({ type: authActions.UNAUTHENTICATED });
    }
  } catch (error) {
    store.dispatch({ type: authActions.UNAUTHENTICATED });
    log.error(error);
  }
}
