import AttachFileIcon from '@material-ui/icons/AttachFile';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactPlaceholder from 'react-placeholder';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import cx from 'classnames';

import './Attachment.scss';
import { renderString } from '../../utils/renderUtils';
import Tooltip from '../Tooltip';
import withPrimaryStyles from '../withPrimaryStyles';

class Attachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDownloadOption: null,
      isAttachmentListOpen: false,
      maxCount: 0
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    const attachmentWrapper = document.getElementById('message-attachments');
    const attachmentWidth = 140;
    if (attachmentWrapper) {
      const maxCount = Math.round(attachmentWrapper.clientWidth / attachmentWidth) - 1;
      this.setState({ maxCount });
    }
  };

  toggleAttachmentList = () => {
    const { isAttachmentListOpen } = this.state;
    this.setState({ isAttachmentListOpen: !isAttachmentListOpen });
  };

  renderListView = () => {
    const { totalCount } = this.props;
    return (
      <section className="attachment attachment--list">
        <span>{totalCount}</span> <AttachFileIcon />
      </section>
    );
  };

  renderAttachment = (name, size, id) => {
    const { fileName, fileSize } = this.props;
    const { showDownloadOption } = this.state;

    let attachmentName = fileName;
    let attachmentSize = fileSize;
    let attachmentId = btoa(fileName);

    if (name) {
      attachmentName = name;
    }

    if (size) {
      attachmentSize = size;
    }

    if (id) {
      attachmentId = id;
    }

    return (
      <>
        <ReactPlaceholder
          ready={Boolean(attachmentName && attachmentName.length > 0)}
          type="textRow"
          className="placeholder placeholder--attachment"
          style={{ width: 100, margin: 0 }}
        >
          <Tooltip title={renderString('tooltip.attachment.access.denied')} placement="bottom">
            <button type="button" className="attachment attachment--inline">
              <Grid container direction="row" alignItems="center">
                <Grid item>{showDownloadOption === attachmentId ? <SaveAltIcon /> : <AttachFileIcon />}</Grid>
                <Grid item>
                  <span className="attachment__filename">{attachmentName}</span>
                  {attachmentSize && <span className="attachment__filesize">{attachmentSize}</span>}
                </Grid>
              </Grid>
            </button>
          </Tooltip>
        </ReactPlaceholder>
      </>
    );
  };

  renderMoreButton = () => {
    const { multiple, classes } = this.props;
    const { maxCount, isAttachmentListOpen } = this.state;

    const totalCount = multiple.length;

    if (totalCount <= maxCount) {
      return '';
    }

    const attachmentButton = cx('attachment-btn', {
      [`${classes.primaryColor}`]: classes.primaryColor,
      'attachment-btn--close': isAttachmentListOpen
    });

    return (
      <li key="more-button">
        <Button
          className={attachmentButton}
          classes={{}}
          disableRipple
          disableFocusRipple
          onClick={() => this.toggleAttachmentList()}
        >
          {isAttachmentListOpen ? (
            <span>{renderString('tooltip.button.close')}</span>
          ) : (
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <MoreHoriz />
              </Grid>
              <Grid item>
                <span className="more">
                  {renderString('action.button.more.attachments', { count: totalCount - maxCount })}
                </span>
              </Grid>
            </Grid>
          )}
        </Button>
      </li>
    );
  };

  renderMultipleAttachments = () => {
    const { isAttachmentListOpen, maxCount } = this.state;
    const { multiple } = this.props;
    let attachmentCount = 0;

    const attachmentsClass = cx('message-content__attachments', {
      'message-content__attachments--limited': !isAttachmentListOpen,
      'message-content__attachments--all': isAttachmentListOpen
    });

    let attachmentId;

    return (
      <ul id="message-attachments" className={attachmentsClass}>
        {multiple.map((attachment) => {
          attachmentCount += 1;
          attachmentId = `${btoa(attachment.name)}-${attachmentCount}`;
          return (
            <li key={attachmentId} className={attachmentCount > maxCount ? 'is-hidden' : ''}>
              {this.renderAttachment(attachment.name, attachment.size, attachmentId)}
            </li>
          );
        })}
        {this.renderMoreButton()}
      </ul>
    );
  };

  render() {
    const { totalCount, multiple } = this.props;

    // List view
    if (totalCount) {
      return this.renderListView();
    }

    // Multiple attachments
    if (multiple) {
      return this.renderMultipleAttachments();
    }

    // Body view
    return this.renderAttachment();
  }
}

Attachment.defaultProps = {
  fileName: '',
  fileSize: null,
  multiple: null,
  totalCount: 0
};

Attachment.propTypes = {
  fileName: PropTypes.string,
  fileSize: PropTypes.string,
  multiple: PropTypes.arrayOf(PropTypes.object),
  totalCount: PropTypes.number,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(Attachment);
