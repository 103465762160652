import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import './ActionListMobile.scss';
import { renderString } from '../../../../utils/renderUtils';
import withPrimaryStyles from '../../../withPrimaryStyles';
import { isEmpty } from '../../../../utils/strUtils';

function ActionListMobile({ title, managedDelegate, listItems, classes }) {
  const wrapperClass = cx('folder-actions', {
    [`${classes.primaryColor}`]: classes.primaryColor,
    'managed-delegate': !isEmpty(managedDelegate),

  });

  return (
    <List className={wrapperClass} component="nav" aria-label={title}>
      {title && (
        <ListItem className="folder-actions__title" key="FolderActions__title">
          <ListItemText primary={title} />
        </ListItem>
      )}
      {listItems.length > 0 ? (
        listItems.map((listItem, index) => {
          const buttonWrapperClass = cx('folder-actions__button', {
            [`${classes.primaryColor}`]: classes.primaryColor,
            'folder-actions__button--delete': listItem.isDelete
          });
          const listKey = `FolderActions__${index}`;
          return (
            <ListItem className={buttonWrapperClass} onClick={() => listItem.handleAction()} key={listKey} button>
              <ListItemText primary={listItem.label} />
            </ListItem>
          );
        })
      ) : (
        <ListItem className="folder-actions__disabled" key="FolderActions__disabled">
          <ListItemText primary={renderString('action.disabled')} />
        </ListItem>
      )}
    </List>
  );
}

ActionListMobile.defaultProps = {
  title: null,
  listItems: []
};

ActionListMobile.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  listItems: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object.isRequired
};

export default withPrimaryStyles(ActionListMobile);
