import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { editConfig } from '../../../../ducks/configActions';
import { getSenderList } from '../ducks/senderListReducer';
import { getSettingsPath } from '../../../../ducks/uiStatusReducer';
import { removeSettingsPath } from '../../../../ducks/uiStatusActions';
import EditSenderListItem from './EditSenderListItem';

class EditSenderListItemContainer extends Component {
  hideEditUiFunc = () => {
    const { removeSettingsPathDispatch, id } = this.props;
    const { hideAddEditUiClick } = this.props;

    removeSettingsPathDispatch(`edit[${id}]`, true);
    hideAddEditUiClick();
  };

  hideSettingsFunc = () => {
    const { removeSettingsPathDispatch } = this.props;
    removeSettingsPathDispatch();
  };

  render() {
    const { configProperty, editConfigDispatch, addressList, id } = this.props;
    return (
      <EditSenderListItem
        id={id}
        configProperty={configProperty}
        closeFunc={this.hideSettingsFunc}
        backFunc={this.hideEditUiFunc}
        editConfigFunc={editConfigDispatch}
        addressList={addressList}
      />
    );
  }
}

EditSenderListItemContainer.propTypes = {
  id: PropTypes.string.isRequired,
  configProperty: PropTypes.string.isRequired,
  removeSettingsPathDispatch: PropTypes.func.isRequired,
  editConfigDispatch: PropTypes.func.isRequired,
  addressList: PropTypes.object.isRequired,
  hideAddEditUiClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.addressList = getSenderList(state);

  const settingsPath = getSettingsPath(state);
  const settingPathIdMatch = settingsPath.match(/\/edit\[(.*)\]/);
  if (settingPathIdMatch) {
    const idIndex = 1;
    props.id = settingPathIdMatch[idIndex];
  }

  return props;
};

export default connect(mapStateToProps, {
  editConfigDispatch: editConfig,
  removeSettingsPathDispatch: removeSettingsPath
})(EditSenderListItemContainer);
