import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './QuickSwitcher.scss';
import { renderString } from '../../../utils/renderUtils';
import HelperText from '../../HelperText';
import Tooltip from '../../Tooltip';
import withPrimaryStyles from '../../withPrimaryStyles';

const QuickSwitcher = ({ appOptions, classes, horizontal, showAppNames }) => {
  return (
    <div className={horizontal ? 'quick-switcher--horizontal' : 'quick-switcher--vertical'}>
      <List className="quick-switcher-content">
        {appOptions.map((app) => (
          <div className="quick-switcher-content__section" key={`quick-switcher-content__section-${app.header}`}>
            {app.header && horizontal && <h1 className="quick-switcher-content__header">{app.header}</h1>}
            {app.options.map((option) => {
              const appliedClasses = cx('quick-switcher-content__item', {
                [`quick-switcher-content__item--selected ${classes.selectedState}`]: option.selected,
                [classes.primaryBorderColor]: true
              });
              return (
                <div
                  className={`quick-switcher-content__item-wrapper  ${option.selector}`}
                  key={`quick-switcher-content__item-${option.text}`}
                >
                  <ListItem
                    className={appliedClasses}
                    onClick={() => option.handleOnClick()}
                    aria-label={option.text}
                    button
                  >
                    <Tooltip title={renderString(option.textId)}>
                      <ListItemIcon
                        className={`quick-switcher-content__icon ${option.selected && classes.primaryColor}`}
                      >
                        {option.icon}
                      </ListItemIcon>
                    </Tooltip>
                    {showAppNames && (
                      <ListItemText className="quick-switcher-content__text" primary={renderString(option.textId)} />
                    )}
                  </ListItem>
                  {horizontal && (
                    <HelperText subtitle={option.label ? renderString(option.label) : renderString(option.textId)} />
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </List>
    </div>
  );
};

QuickSwitcher.defaultProps = {
  horizontal: false,
  showAppNames: false
};

QuickSwitcher.propTypes = {
  appOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        icon: PropTypes.object,
        text: PropTypes.string,
        handleOnClick: PropTypes.func,
        selected: PropTypes.bool
      })
    ])
  ).isRequired,
  classes: PropTypes.object.isRequired,
  horizontal: PropTypes.bool,
  showAppNames: PropTypes.bool
};

export default withPrimaryStyles(QuickSwitcher);
