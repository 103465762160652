import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const LowPriorityQuarantined = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <g id="Quarantined_Spam_small" transform="translate(0.000000, 4.000000)">
      <g transform="translate(0.000000, -0.000000)">
        <mask id="Quarantined_mask-2" fill="white">
          <path d="M 10.8 0 L 1.2 0 C 0.54 0 0.006 0.538 0.006 1.197 L 0 8.377 C 0 9.035 0.54 9.573 1.2 9.573 L 10.8 9.573 C 11.46 9.573 12 9.035 12 8.377 L 12 1.197 C 12 0.538 11.46 0 10.8 0 Z M 10.8 8.377 L 1.2 8.377 L 1.2 2.393 L 3.884 4.066 C 5.179 4.873 6.821 4.873 8.116 4.066 L 10.8 2.393 L 10.8 8.377 Z M 3.884 2.87 L 1.2 1.197 L 10.8 1.197 L 8.116 2.87 C 6.821 3.677 5.179 3.677 3.884 2.87 Z" />
        </mask>
        <g id="Quarantined_c/#000000/0.54" mask="url(#Quarantined_mask-2)">
          <g transform="translate(-1.200000, -2.422145)" id="Quarantined_Rectangle" />
        </g>
      </g>
    </g>
    <g id="Quarantined_Spam_large" transform="translate(4.000000, 10.000000)">
      <g transform="translate(0.000000, -0.000000)">
        <path d="M 16.2 0 L 1.8 0 C 0.81 0 0.009 0.808 0.009 1.795 L 0 12.565 C 0 13.552 0.81 14.36 1.8 14.36 L 16.2 14.36 C 17.19 14.36 18 13.552 18 12.565 L 18 1.795 C 18 0.808 17.19 0 16.2 0 Z M 16.2 12.565 L 1.8 12.565 L 1.8 3.59 L 6.884 6.759 C 8.179 7.566 9.821 7.566 11.116 6.759 L 16.2 3.59 L 16.2 12.565 Z M 6.884 4.964 L 1.8 1.795 L 16.2 1.795 L 11.116 4.964 C 9.821 5.771 8.179 5.771 6.884 4.964 Z" />
      </g>
    </g>
    <g id="Quarantined_g-1" transform="matrix(0.679971, 0, 0, 0.679971, 0, 4)">
      <g transform="translate(0.000000, -0.000000)">
        <path d="M 16.2 0 L 1.8 0 C 0.81 0 0.009 0.808 0.009 1.795 L 0 12.565 C 0 13.552 0.81 14.36 1.8 14.36 L 6.039 14.361 C 5.525 13.359 10.121 7.188 18.002 11.45 L 18 1.795 C 18 0.808 17.19 0 16.2 0 M 16.177 9.503 C 5.883 12.178 8.182 12.566 1.8 12.565 L 1.8 3.59 L 6.884 6.759 C 8.179 7.566 9.821 7.566 11.116 6.759 L 16.2 3.59 L 16.177 11.45 M 6.884 4.964 L 1.8 1.795 L 16.2 1.795 L 11.116 4.964 C 9.821 5.771 8.179 5.771 6.884 4.964" />
      </g>
    </g>
    <g id="Quarantined_icon/content/block_24px" transform="matrix(0.67556, 0, 0, 0.67556, 14.597813, 1.421904)">
      <path d="M 6 0.167 C 2.78 0.167 0.167 2.78 0.167 6 C 0.167 9.22 2.78 11.833 6 11.833 C 9.22 11.833 11.833 9.22 11.833 6 C 11.833 2.78 9.22 0.167 6 0.167 Z M 1.333 6 C 1.333 3.422 3.422 1.333 6 1.333 C 7.079 1.333 8.071 1.701 8.858 2.319 L 2.319 8.858 C 1.701 8.071 1.333 7.079 1.333 6 Z M 6 10.667 C 4.921 10.667 3.929 10.299 3.142 9.681 L 9.681 3.142 C 10.299 3.929 10.667 4.921 10.667 6 C 10.667 8.578 8.578 10.667 6 10.667 Z" />
    </g>
  </SvgIcon>
);

LowPriorityQuarantined.defaultProps = {
  className: ''
};

LowPriorityQuarantined.propTypes = {
  className: PropTypes.string
};

export default LowPriorityQuarantined;
