import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addSettingsPath, removeSettingsPath, setCurrentKeyboardFocus } from '../../../ducks/uiStatusActions';
import { clearSenderList, loadSenderList } from './ducks/senderListActions';
import { contentTypes } from '../../../constants/uiConstants';
import { getCurrentSenderListFocus, getSettingsPath, isContentUpdatingSelector } from '../../../ducks/uiStatusReducer';
import { getCurrentEmailAddressFromState, getManagingDelegate } from '../../ducks/profileReducer';
import { getSenderList } from './ducks/senderListReducer';
import { isEmpty } from '../../../utils/strUtils';
import { isSettingsPath } from '../../../utils/uiStatusUtils';
import { senderListFilterUtils } from '../../../utils/filterUtils';
import SenderListView from './SenderListView';

class SenderListViewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addButtonAutoFocus: true
    };
    this.setFocus = this.setFocus.bind(this);
  }

  componentDidMount() {
    const { loadSenderListDispatch, senderListType } = this.props;
    loadSenderListDispatch(senderListType);
    this.setFocus(0);
  }

  componentDidUpdate(prevProps) {
    const { senderListType, loadSenderListDispatch } = this.props;
    if (prevProps.senderListType !== senderListType) {
      loadSenderListDispatch(senderListType);
    }
  }

  setFocus = (value) => {
    const { setCurrentKeyboardFocusDispatch } = this.props;
    setCurrentKeyboardFocusDispatch(value, contentTypes.SENDER_LIST);
  };

  hideAddEditUiClick = () => {
    this.setState({ addButtonAutoFocus: true });
  };

  showAddUiFunc = () => {
    const { addSettingsPathDispatch } = this.props;
    addSettingsPathDispatch('add');
  };

  render() {
    const {
      itemCount,
      isBeingFiltered,
      isContentUpdating,
      visibleSenders,
      senderListType,
      shouldShowAddUi,
      shouldShowEditUi,
      closeFunc,
      backFunc,
      managedDelegate,
      currentFocus,
      clearSenderListDispatch
    } = this.props;
    const { addButtonAutoFocus } = this.state;
    const senderList = visibleSenders;
    return (
      <SenderListView
        isBeingFiltered={isBeingFiltered}
        totalItemCount={itemCount}
        senderList={senderList}
        managedDelegate={managedDelegate}
        setCurrentKeyboardFocus={this.setFocus}
        configProperty={senderListType}
        isContentUpdating={isContentUpdating}
        closeFunc={closeFunc}
        currentFocus={currentFocus}
        backFunc={backFunc}
        hideAddEditUiClick={this.hideAddEditUiClick}
        addFunc={this.showAddUiFunc}
        addButtonAutoFocus={addButtonAutoFocus}
        shouldShowAddUi={shouldShowAddUi}
        shouldShowEditUi={shouldShowEditUi}
        clearSenderListDispatch={clearSenderListDispatch}
      />
    );
  }
}

SenderListViewContainer.propTypes = {
  visibleSenders: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object.isRequired,
  itemCount: PropTypes.number.isRequired,
  isBeingFiltered: PropTypes.bool.isRequired,
  isContentUpdating: PropTypes.bool.isRequired,
  loadSenderListDispatch: PropTypes.func.isRequired,
  senderListType: PropTypes.string.isRequired,
  addSettingsPathDispatch: PropTypes.func.isRequired,
  shouldShowAddUi: PropTypes.bool.isRequired,
  shouldShowEditUi: PropTypes.bool.isRequired,
  currentFocus: PropTypes.number.isRequired,
  // Passed in
  closeFunc: PropTypes.func.isRequired,
  backFunc: PropTypes.func.isRequired,
  setCurrentKeyboardFocusDispatch: PropTypes.func.isRequired,
  clearSenderListDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.visibleSenders = senderListFilterUtils.getVisibleMessages(state);
  props.username = getCurrentEmailAddressFromState(state);
  props.isBeingFiltered = !isEmpty(senderListFilterUtils.getFilterStringFromState(state));
  const senderList = getSenderList(state);
  props.itemCount = (senderList && Object.keys(senderList).length) || 0;
  props.isContentUpdating = isContentUpdatingSelector(state);
  props.currentFocus = getCurrentSenderListFocus(state);
  props.shouldShowAddUi = isSettingsPath(getSettingsPath(state), '/add');
  props.shouldShowEditUi = isSettingsPath(getSettingsPath(state), '/edit');
  props.managedDelegate = getManagingDelegate(state)


  return props;
};

export default connect(mapStateToProps, {
  loadSenderListDispatch: loadSenderList,
  addSettingsPathDispatch: addSettingsPath,
  removeSettingsPathDispatch: removeSettingsPath,
  clearSenderListDispatch: clearSenderList,
  setCurrentKeyboardFocusDispatch: setCurrentKeyboardFocus
})(SenderListViewContainer);
