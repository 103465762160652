import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import './MessageFilter.scss';
import { messageFilterStatus } from '../../../constants/encryptionConstants';
import { renderString } from '../../../utils/renderUtils';

const MessageFilter = ({ messageStatus, handleMessageFilterChange }) => (
  <AppBar
    classes={{ root: 'message-list__tabs', colorPrimary: 'message-list__tabs--background' }}
    position="static"
    color="primary"
    companyLogo=""
  >
    <Tabs
      value={messageStatus}
      onChange={handleMessageFilterChange}
      className="filter-tabs-wrapper"
      indicatorColor="primary"
      classes={{
        indicator: 'filter-tabs-wrapper--indicator'
      }}
    >
      <Tab
        className="filter-tab"
        value={messageFilterStatus.ALL}
        label={renderString('encryption.status.all')}
        classes={{
          labelWrapped: 'filter-tab__labelWrapped',
          root: 'filter-tab__root',
          wrapper: 'filter-tab__wrapper'
        }}
      />
      <Tab
        className="filter-tab"
        value={messageFilterStatus.ACTIVE}
        label={renderString('encryption.status.active')}
        classes={{
          labelWrapped: 'filter-tab__labelWrapped',
          root: 'filter-tab__root',
          wrapper: 'filter-tab__wrapper'
        }}
      />
      <Tab
        className="filter-tab"
        value={messageFilterStatus.EXPIRED}
        label={renderString('encryption.status.expired')}
        classes={{
          labelWrapped: 'filter-tab__labelWrapped',
          root: 'filter-tab__root',
          wrapper: 'filter-tab__wrapper'
        }}
      />
      <Tab
        className="filter-tab"
        value={messageFilterStatus.REVOKED}
        label={renderString('encryption.status.revoked')}
        classes={{
          labelWrapped: 'filter-tab__labelWrapped',
          root: 'filter-tab__root',
          wrapper: 'filter-tab__wrapper'
        }}
      />
    </Tabs>
  </AppBar>
);

MessageFilter.propTypes = {
  messageStatus: PropTypes.string.isRequired,
  handleMessageFilterChange: PropTypes.func.isRequired
};

export default MessageFilter;
