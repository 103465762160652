import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  addSettingsPath,
  removeSettingsPath,
  showMobileViewSideBar,
  toggleMobileSearch,
  toggleSingleMessageActions
} from '../../ducks/uiStatusActions';
import { getBrandingLogoSmallFromState } from '../../ducks/configReducer';
import { getCurrentApp, getSettingsPath } from '../../ducks/uiStatusReducer';
import { isSettingsPath } from '../../utils/uiStatusUtils';
import { logout } from '../../SideBar/ducks/sideBarActions';
import { setSelectedGuids } from '../../Encryption/MessageList/ducks/MessageListActions';
import Nav from './Nav';
import { redirectToMindtouchPage } from './helpApi';
import { getHelpPageRequestingStatus } from './ducks/helpReducer';

class NavContainer extends PureComponent {
  toggleShowSettings = () => {
    const { isSettingsOpen, addSettingsPathDispatch, removeSettingsPathDispatch } = this.props;
    if (isSettingsOpen) {
      removeSettingsPathDispatch();
    } else {
      addSettingsPathDispatch('settings');
    }
  };

  redirectToHelpPage = (helpPath) => {
    const { isRequestingHelpPage, redirectToMindtouchPageDispatch } = this.props;
    if (!isRequestingHelpPage) {
      redirectToMindtouchPageDispatch(helpPath);
    }
  };
  
  render() {
    const {
      logoutDispatch,
      showMobileViewSideBarDispatch,
      toggleMobileSearchDispatch,
      managedDelegate,
      image,
      selectedMessageGuids,
      setSelectedGuidsDispatch,
      currentApp,
      toggleSingleMessageActionsDispatch,
      showLandingPage
    } = this.props;
    return (
      <Nav
        logout={logoutDispatch}
        redirectToHelpPage={this.redirectToHelpPage}
        showLandingPage={showLandingPage}
        showMobileViewSideBar={showMobileViewSideBarDispatch}
        toggleMobileSearch={toggleMobileSearchDispatch}
        image={image}
        selectedMessageGuids={selectedMessageGuids}
        setSelectedGuids={setSelectedGuidsDispatch}
        currentApp={currentApp}
        managedDelegate={managedDelegate}
        toggleShowSettings={this.toggleShowSettings}
        toggleSingleMessageActions={toggleSingleMessageActionsDispatch}
      />
    );
  }
}

NavContainer.defaultProps = {
  image: undefined,
  selectedMessageGuids: {},
  currentApp: '',
  showLandingPage: false,
  isRequestingHelpPage: false
};

NavContainer.propTypes = {
  logoutDispatch: PropTypes.func.isRequired,
  showMobileViewSideBarDispatch: PropTypes.func.isRequired,
  isSettingsOpen: PropTypes.bool.isRequired,
  addSettingsPathDispatch: PropTypes.func.isRequired,
  removeSettingsPathDispatch: PropTypes.func.isRequired,
  toggleMobileSearchDispatch: PropTypes.func.isRequired,
  image: PropTypes.string,
  selectedMessageGuids: PropTypes.object,
  setSelectedGuidsDispatch: PropTypes.func.isRequired,
  currentApp: PropTypes.string,
  toggleSingleMessageActionsDispatch: PropTypes.func.isRequired,
  showLandingPage: PropTypes.bool,
  managedDelegate: PropTypes.object.isRequired,
  isRequestingHelpPage: PropTypes.bool,
  redirectToMindtouchPageDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.image = getBrandingLogoSmallFromState(state);
  props.isSettingsOpen = isSettingsPath(getSettingsPath(state), /\/settings/g);
  props.currentApp = getCurrentApp(state);
  props.isRequestingHelpPage = getHelpPageRequestingStatus(state);

  return props;
};

export default connect(mapStateToProps, {
  showMobileViewSideBarDispatch: showMobileViewSideBar,
  addSettingsPathDispatch: addSettingsPath,
  removeSettingsPathDispatch: removeSettingsPath,
  redirectToMindtouchPageDispatch: redirectToMindtouchPage,
  toggleMobileSearchDispatch: toggleMobileSearch,
  logoutDispatch: logout,
  setSelectedGuidsDispatch: setSelectedGuids,
  toggleSingleMessageActionsDispatch: toggleSingleMessageActions
})(NavContainer);
