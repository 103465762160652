import PropTypes from 'prop-types';
import React from 'react';

import './BottomNav.scss';
import BasicNav from '../Basic';
import withPrimaryStyles from '../../withPrimaryStyles';

function BottomNav({ buttonLeft, buttonCenter, buttonRight, classes }) {
  return (
    <BasicNav
      position="bottom"
      buttonLeft={buttonLeft}
      buttonRight={buttonRight}
      buttonCenter={buttonCenter}
      mainClass={`c-basic-bottom-nav ${classes.primaryColor}`}
      innerClass="c-basic-bottom-nav--inner"
      isPrimaryNav={false}
    />
  );
}

BottomNav.defaultProps = {
  buttonLeft: null,
  buttonCenter: null,
  buttonRight: null
};

BottomNav.propTypes = {
  buttonLeft: PropTypes.shape([PropTypes.string, PropTypes.func]),
  buttonCenter: PropTypes.shape([PropTypes.string, PropTypes.func]),
  buttonRight: PropTypes.shape([PropTypes.string, PropTypes.func]),
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(BottomNav);
