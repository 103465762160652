import PropTypes from 'prop-types';
import React from 'react';

import './FullScreenActions.scss';
import { renderString } from '../../../utils/renderUtils';

function FullScreenActions({ messageActions, totalCheckedMessages }) {
  return (
    <section className="full-screen-actions">
      <div>
        <h2>{renderString('selection.counter.items', { counter: totalCheckedMessages })}</h2>
        <p>{renderString('action.multiselect.subtitle')}</p>
        <div role="menu" className="full-screen-actions__action-list">
          {messageActions}
        </div>
      </div>
    </section>
  );
}

FullScreenActions.propTypes = {
  totalCheckedMessages: PropTypes.number.isRequired,
  messageActions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])).isRequired
};

export default FullScreenActions;
