import { isBase64 } from './strUtils';

const GUID_LENGTH = 27;
const LEGACY_GUID_LENGTH = 11;

/* eslint-disable import/prefer-default-export */
export function decodeMessageId(messageId) {
  let guid;
  let realId = messageId
    .trim()
    .replace(/^\(/, '')
    .replace(/\)$/, '');

  if (realId.startsWith('V_2')) {
    realId = realId.substring(3);
    guid = realId.substring(GUID_LENGTH);
    return isBase64(guid) ? atob(guid) : guid;
  }
  guid = isBase64(realId) ? atob(realId) : realId;

  if (guid.length > LEGACY_GUID_LENGTH) {
    return guid.substring(LEGACY_GUID_LENGTH);
  }
  return guid;
}
