import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import './Tooltip.scss';
import { isEmpty } from '../../utils/strUtils';

class TooltipWrap extends Component {
  constructor(props) {
    super(props);
    const { alwaysRender } = this.props;
    this.state = {
      isTooltipRequired: alwaysRender
    };
    this.updateOverflow = this.updateOverflow.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { alwaysRender, title } = nextProps;
    return {
      ...prevState,
      isTooltipRequired: alwaysRender && !isEmpty(title)
    };
  }

  updateOverflow(e) {
    const el = e.target;
    const { isTooltipRequired } = this.state;

    if (!isTooltipRequired && el.scrollWidth > el.clientWidth) {
      this.setState({ isTooltipRequired: true });
    } else {
      this.setState({ isTooltipRequired: false });
    }
  }

  render() {
    const { title, enterDelay, placement, children, id } = this.props;
    const { isTooltipRequired } = this.state;

    // Do not render tooltip component for mobile/tablet devices
    // Only render inner child components
    if (isMobile) {
      return children;
    }

    // Render tooltip as normal
    if (isTooltipRequired) {
      return (
        <Tooltip
          id={id}
          classes={{ tooltip: 'dsp-tooltip' }}
          title={title}
          enterDelay={enterDelay}
          placement={placement}
        >
          {children}
        </Tooltip>
      );
    }

    // Determines whether to render tooltip if string is truncated
    return <span onMouseEnter={this.updateOverflow}>{children}</span>;
  }
}

TooltipWrap.defaultProps = {
  enterDelay: 0,
  placement: 'bottom-start',
  alwaysRender: true,
  id: undefined
};

TooltipWrap.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  enterDelay: PropTypes.number,
  placement: PropTypes.string,
  alwaysRender: PropTypes.bool,
  children: PropTypes.object.isRequired
};

export default TooltipWrap;
