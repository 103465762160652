export const rowItemTypes = Object.freeze({
  ROW_ITEM_SELECT: 'ROW_ITEM_SELECT',
  ROW_ITEM_SELECT_ALL: 'ROW_ITEM_SELECT_ALL',
  UPDATE_SELECTED_ROW_ITEMS: 'UPDATE_SELECTED_ROW_ITEMS',
  HIDE_LIST_ITEMS: 'HIDE_LIST_ITEMS',
  REVEAL_LIST_ITEMS: 'REVEAL_LIST_ITEMS',
  UNSELECT_LIST_ITEMS: 'UNSELECT_LIST_ITEMS',
  LOAD_HIDDEN_ROW_ITEMS: 'LOAD_HIDDEN_ROW_ITEMS'
});

export default rowItemTypes;
