import { FormattedMessage } from 'react-intl';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './Select.scss';
import { qaTags } from '../../constants/qaConstants';
import withPrimaryStyles from '../withPrimaryStyles';

class Select extends Component {
  constructor(props) {
    super(props);
    const { defaultIndex } = this.props;
    this.state = {
      anchorEl: null,
      selectedIndex: defaultIndex || 0
    };

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  handleClickListItem = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    const { onChangeEvent } = this.props;
    this.setState({ selectedIndex: index, anchorEl: null });
    if (onChangeEvent) {
      onChangeEvent(index);
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, selectedIndex } = this.state;
    const { options, displayName, appliedClasses, mobilePlaceholderIcon, classes } = this.props;

    return (
      <div name={qaTags.DROP_DOWN_MENU} className={appliedClasses}>
        <List component="nav" className="select-nav">
          <ListItem
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-owns={anchorEl ? 'select-dropdown' : null}
            onClick={this.handleClickListItem}
            className="select-item"
          >
            <Hidden mdDown>
              {displayName && (
                <ListItemText
                  className="select-item--header"
                  primary={<FormattedMessage id={displayName} />}
                  secondary={<FormattedMessage id={`${options[selectedIndex]}.selected`} />}
                />
              )}
              {!displayName && (
                <ListItemText
                  className="select-item--header"
                  secondary={<FormattedMessage id={`${options[selectedIndex]}.selected`} />}
                />
              )}
            </Hidden>
            <Hidden lgUp>
              <ListItemText
                className="select-item--header select-item-header-mobile"
                secondary={mobilePlaceholderIcon}
              />
            </Hidden>
            <ArrowDropDown />
          </ListItem>
        </List>
        <Hidden mdDown>
          <Menu
            className="select-dropdown"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                width: 200
              }
            }}
            anchorReference={null}
          >
            <MenuItem value="-" disabled className="select-dropdown-header">
              <FormattedMessage id={`${options[selectedIndex]}.selected`} />
              <ArrowDropUp />
            </MenuItem>
            {Object.entries(options).map((option) => (
              <MenuItem
                key={option[1]}
                selected={option[0] === selectedIndex}
                onChange={this.handleMenuItemClick}
                onClick={(event) => this.handleMenuItemClick(event, option[0])}
                className="select-dropdown-item"
                classes={{ selected: `select-dropdown-item--selected ${classes.lightColorLeftBorder}` }}
              >
                <FormattedMessage id={option[1]} />
              </MenuItem>
            ))}
          </Menu>
        </Hidden>
        <Hidden lgUp>
          <Menu
            className="select-dropdown"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxWidth: '140px'
              }
            }}
            anchorReference={null}
          >
            <MenuItem value="-" disabled className="select-dropdown-header--mobile">
              {mobilePlaceholderIcon}
              <ArrowDropUp />
            </MenuItem>
            {Object.entries(options).map((option) => (
              <MenuItem
                key={option[1]}
                selected={option[0] === selectedIndex}
                onChange={this.handleMenuItemClick}
                onClick={(event) => this.handleMenuItemClick(event, option[0])}
                className="select-dropdown-item"
                classes={{ selected: `select-dropdown-item--selected ${classes.lightColorLeftBorder}` }}
              >
                <FormattedMessage id={option[1]} />
              </MenuItem>
            ))}
          </Menu>
        </Hidden>
      </div>
    );
  }
}

Select.defaultProps = {
  onChangeEvent: undefined,
  displayName: '',
  mobilePlaceholderIcon: null
};

Select.propTypes = {
  options: PropTypes.object.isRequired,
  displayName: PropTypes.string,
  defaultIndex: PropTypes.string.isRequired,
  appliedClasses: PropTypes.string.isRequired,
  onChangeEvent: PropTypes.func,
  mobilePlaceholderIcon: PropTypes.object,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(Select);
