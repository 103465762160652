import { all, call, delay, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import without from 'lodash/without';

import { apiConstants } from '../app/ducks/apiConstants';
import { contains, isEmpty } from '../app/utils/strUtils';
import { fetchMessagesCqsDisabled, fetchMessagesCqsEnabled } from '../app/Quarantine/ducks/folderApi';
import { getCurrentEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import { getFilterUtil } from '../app/utils/filterUtils';
import { getMessages } from '../app/Quarantine/ducks/messageReducer';
import { getSenderList } from '../app/Settings/Quarantine/SenderListView/ducks/senderListReducer';
import { isMisCqsEnabled } from '../app/ducks/featureReducer';
import { messageList } from '../app/constants/messageConstants';
import { moduleType } from '../app/ducks/appTypes';
import { rowItemTypes } from '../app/common/RowItem/ducks/rowItemTypes';
import { searchTypes } from '../app/common/ActionBar/Search/ducks/searchTypes';
import { updateFilteredRowItems, updateSelectedRowItems } from '../app/common/ActionBar/Search/ducks/searchActions';
import logger from '../tools/logger';

const log = logger.child({ childName: 'searchSaga' });

/**
 * @description return filtered items list based on sender email or subject
 * @export
 * @param {any} action action object
 * @returns filtered messages object
 */
export function* getFilteredItems(action) {
  const type = action.moduleType;
  let items = {};
  if (type === moduleType.MESSAGE_LIST) {
    return yield select(getMessages);
  }
  if (type === moduleType.SENDER_LIST) {
    items = yield select(getSenderList);
    if (!action.filterStr) {
      return items;
    }
    return Object.keys(items).reduce((acc, currKey) => {
      const message = items[currKey];
      const isMatch = contains(currKey, action.filterStr, true);
      if (isMatch) {
        acc[currKey] = message;
      }
      return acc;
    }, {});
  }
  return items;
}

export function* fetchFilteredMessages(action) {
  const misCqsEnabled = yield select(isMisCqsEnabled);
  if (!action.folderId) {
    return;
  }
  const username = yield select(getCurrentEmailAddressFromState);
  try {
    let params = {};

    // Data to be passed to API to fetch messages when MIS CQS feature is enabled
    if (misCqsEnabled) {
      params = { ...params, module: action.folderId, count: apiConstants.pageSize };
      if (!isEmpty(action.filterStr)) {
        params = { ...params, q: action.filterStr };
      }
      yield put(fetchMessagesCqsEnabled(params));
    } else {
      // Data to be passed to API to fetch messages when MIS CQS feature is disabled
      params = { ...params, username, limit: apiConstants.pageSize, folder: action.folderId };
      if (!isEmpty(action.filterStr)) {
        params = { ...params, subject: action.filterStr, sender: action.filterStr, orFields: 'sender,subject' };
      }
      if (!isEmpty(action.startDate) && !isEmpty(action.endDate)) {
        params = { ...params, startDate: action.startDate, endDate: action.endDate };
      }
      yield put(fetchMessagesCqsDisabled(params));
    }
  } catch (err) {
    log.error(err);
  }
}

export function* filterStringAddHandler(action) {
  // debounce
  if (!isEmpty(action.filterStr)) {
    yield delay(messageList.DEBOUNCE_DELAY);
  }
  const type = action.moduleType;
  if (type === moduleType.MESSAGE_LIST) {
    yield call(fetchFilteredMessages, action);
    return;
  }
  const filteredItems = yield call(getFilteredItems, action);
  yield put(updateFilteredRowItems(type, filteredItems));
}

export function* filterByDateHandler(action) {
  try {
    yield call(fetchFilteredMessages, action);
  } catch (err) {
    log.error(err);
  }
}

export function* allRowItemsSelectionHandler(action) {
  let selectedItems = [];
  // only if selection is true
  if (action.select) {
    const allItems = yield select(getFilterUtil(action.moduleType).getFilteredRowItemsFromState);
    selectedItems = Object.keys(allItems);
  }
  yield put(updateSelectedRowItems(action.moduleType, selectedItems));
}

export function* rowItemSelectionHandler(action) {
  const existingSelectedItems = yield select(getFilterUtil(action.moduleType).getSelectedItemIdsFromState);
  // Get the new selected item list
  const selectedItems = action.select
    ? [...existingSelectedItems, action.id]
    : without(existingSelectedItems, action.id);
  yield put(updateSelectedRowItems(action.moduleType, selectedItems));
}

export function* watchFilterChange() {
  yield takeLatest(searchTypes.FILTER_STRING_ADD, filterStringAddHandler);
}

export function* watchFilterByDate() {
  yield takeLatest(searchTypes.FILTER_BY_DATE, filterByDateHandler);
}

export function* watchAllItemSelection() {
  yield takeEvery(rowItemTypes.ROW_ITEM_SELECT_ALL, allRowItemsSelectionHandler);
}

export function* watchRowItemAddOrRemove() {
  yield takeEvery(rowItemTypes.ROW_ITEM_SELECT, rowItemSelectionHandler);
}

/* root search Saga */
export default function* searchSaga() {
  yield all([
    spawn(watchFilterChange),
    spawn(watchAllItemSelection),
    spawn(watchRowItemAddOrRemove),
    spawn(watchFilterByDate)
  ]);
}
