import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './ActionList.scss';
import { getCurrentFolderId, isComponentDisabled, isQuarantineApp } from '../../../../ducks/uiStatusReducer';
import { getCurrentGuid, getTotalCheckedGuids } from '../../../../Quarantine/ducks/messageReducer';
import { getEncryptedTotalCheckedGuids } from '../../../../Encryption/MessageList/ducks/MessageListReducer';
import { getFolderCommands } from '../../../../SideBar/ducks/folderReducer';
import ActionList from './ActionList';

class ActionListContainer extends PureComponent {
  render() {
    const {
      commands,
      commandsByGroup,
      areActionsDisabled,
      hasEllipsis,
      currentGuid,
      handleMessageAction,
      totalChecked
    } = this.props;
    return (
      <ActionList
        commands={commands}
        commandsByGroup={commandsByGroup}
        areActionsDisabled={areActionsDisabled}
        hasEllipsis={hasEllipsis}
        currentGuid={currentGuid}
        handleMessageAction={handleMessageAction}
        totalChecked={totalChecked}
      />
    );
  }
}

ActionListContainer.defaultProps = {
  commands: [],
  commandsByGroup: [],
  areActionsDisabled: false,
  hasEllipsis: false,
  handleMessageAction: null,
  currentGuid: null,
  totalChecked: 0
};

ActionListContainer.propTypes = {
  commands: PropTypes.arrayOf(PropTypes.string),
  commandsByGroup: PropTypes.arrayOf(PropTypes.string),
  areActionsDisabled: PropTypes.bool,
  hasEllipsis: PropTypes.bool,
  handleMessageAction: PropTypes.func,
  currentGuid: PropTypes.string,
  totalChecked: PropTypes.number
};

const mapStateToProps = (state) => {
  const props = {};
  props.areActionsDisabled = isComponentDisabled(state, 'ActionButton');
  props.commands = getFolderCommands(state, getCurrentFolderId(state));
  props.currentGuid = getCurrentGuid(state);
  props.isQuarantine = isQuarantineApp(state);
  if (props.isQuarantine) {
    props.totalChecked = getTotalCheckedGuids(state);
  } else {
    props.totalChecked = getEncryptedTotalCheckedGuids(state);
  }
  return props;
};

export default connect(mapStateToProps)(ActionListContainer);
