import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './BottomNav.scss';
import { renderString } from '../../../utils/renderUtils';
import withPrimaryStyles from '../../withPrimaryStyles';

function getSelectedValue(appOptions) {
  let selectedValue = 0;
  appOptions[0].options.forEach((option, index) => {
    if (option.selected) {
      selectedValue = index;
    }
  });
  return selectedValue;
}

function BottomNav({ appOptions, classes }) {
  const selectedOption = getSelectedValue(appOptions);
  return (
    <BottomNavigation value={selectedOption} showLabels className="bottom-nav">
      {appOptions[0].options.map((option, index) => {
        const actionClasses = cx('bottom-nav-action', {
          [`bottom-nav-action--selected ${classes.primaryColor}`]: selectedOption === index
        });
        return (
          <BottomNavigationAction
            key={`bottom-nav-action-${option.label ? option.label : option.text}`}
            label={option.label ? renderString(option.label) : renderString(option.textId)}
            icon={option.icon}
            onClick={option.handleOnClick}
            aria-label={option.label ? option.label : option.text}
            className={actionClasses}
          />
        );
      })}
    </BottomNavigation>
  );
}

BottomNav.propTypes = {
  appOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        icon: PropTypes.object,
        text: PropTypes.string,
        handleOnClick: PropTypes.func,
        selected: PropTypes.bool
      })
    ])
  ).isRequired,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(BottomNav);
