import React, { PureComponent } from 'react';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';

import { isHomePath } from '../../utils/routeUtils';
import BasicNav from './Basic';
import FullNav from './Full';

class Nav extends PureComponent {
  render() {
    const {
      showMobileViewSideBar,
      showLandingPage,
      toggleMobileSearch,
      toggleShowSettings,
      redirectToHelpPage,
      currentApp,
      image,
      managedDelegate,
      logout
    } = this.props;
    const {
      location: { pathname }
    } = window;

    return (
      <>
        <Hidden smUp>
          {isHomePath(pathname) ? (
            <BasicNav
              buttonLeft={{ icon: 'default', text: false, handleAction: showMobileViewSideBar }}
              buttonRight={showLandingPage ? undefined : { icon: 'search', handleAction: toggleMobileSearch }}
              innerClass="basic-nav--top-nav"
              toggleShowSettings={toggleShowSettings}
              logout={logout}
            />
          ) : (
            <BasicNav
              buttonLeft={{ icon: 'default', handleAction: showMobileViewSideBar }}
              buttonRight={{ icon: 'search', handleAction: toggleMobileSearch }}
              innerClass="basic-nav--top-nav"
              toggleShowSettings={toggleShowSettings}
              logout={logout}
            />
          )}
        </Hidden>
        <Hidden xsDown>
          <FullNav
            logout={logout}
            showMobileViewSideBar={showMobileViewSideBar}
            toggleShowSettings={toggleShowSettings}
            redirectToHelpPage={redirectToHelpPage}
            image={image}
            managedDelegate={managedDelegate}
            currentApp={currentApp}
          />
        </Hidden>
      </>
    );
  }
}

Nav.defaultProps = {
  image: undefined,
  currentApp: '',
  showLandingPage: false
};

Nav.propTypes = {
  logout: PropTypes.func.isRequired,
  showMobileViewSideBar: PropTypes.func.isRequired,
  toggleShowSettings: PropTypes.func.isRequired,
  redirectToHelpPage: PropTypes.func.isRequired,
  toggleMobileSearch: PropTypes.func.isRequired,
  image: PropTypes.string,
  currentApp: PropTypes.string,
  showLandingPage: PropTypes.bool,
  managedDelegate: PropTypes.object.isRequired
};

export default Nav;
