import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import FormLabel from '@material-ui/core/FormLabel';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';

import './MessageSearch.scss';
import { renderString } from '../../../utils/renderUtils';
import withPrimaryStyles from '../../withPrimaryStyles';

function MessageSearch({
  handleListSearch,
  handleClearSearch,
  handleSearchValue,
  handleCancelSearch,
  searchString,
  showMobileSearch,
  placeholderString,
  placeholderSuggestion,
  intl,
  isQuarantine,
  classes
}) {
  const className = cx('message-search-container', {
    'message-search-container--visible': showMobileSearch,
    'message-search-container--smc-results': searchString.length > 3 && !isQuarantine,
    'message-search-container--quarantine-results': searchString.length > 3 && isQuarantine
  });
  const cancelButtonClass = cx('message-search-wrapper__button', {
    [classes.primaryColor]: classes.primaryColor
  });
  return (
    <>
      <div className={className}>
        <div className="message-search-wrapper message-search-wrapper--search-input message-search-wrapper--trim">
          <Paper className="message-search">
            <IconButton
              disableRipple
              onClick={() => handleListSearch(searchString)}
              className="message-search__searchBtn"
              tabIndex={-1}
              aria-label={intl.formatMessage({ id: placeholderString })}
            >
              <SearchIcon />
            </IconButton>

            <FormLabel className="sr-only" htmlFor="search">
              Search
            </FormLabel>
            <InputBase
              type="search"
              placeholder={intl.formatMessage({ id: placeholderString })}
              aria-label={intl.formatMessage({ id: placeholderString })}
              inputProps={{ id: 'search', inputHiddenLabel: 'search' }}
              onChange={(e) => handleSearchValue(e.target.value)}
              onKeyUp={(e) => handleListSearch(e.target.value)}
              value={searchString}
              className="message-search__input"
            />
            {searchString.length > 0 && (
              <Hidden smDown>
                <IconButton
                  disableRipple
                  onClick={handleClearSearch}
                  className="message-search__clearBtn"
                  aria-label="Clear"
                >
                  <CloseIcon />
                </IconButton>
              </Hidden>
            )}
          </Paper>
          <Button onClick={() => handleCancelSearch()} classes={{ root: cancelButtonClass }}>
            {renderString('action.button.cancel')}
          </Button>
        </div>
        <Hidden smUp>
          {searchString.length < 3 && (
            <div className="message-search-wrapper">
              <p className="message-search-wrapper__no-results">{renderString(placeholderSuggestion)}</p>
            </div>
          )}
        </Hidden>
      </div>
    </>
  );
}

MessageSearch.defaultProps = {
  showMobileSearch: false,
  placeholderString: 'search.placeholder',
  placeholderSuggestion: 'search.suggestion',
  isQuarantine: false
};

MessageSearch.propTypes = {
  handleListSearch: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
  handleSearchValue: PropTypes.func.isRequired,
  handleCancelSearch: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  placeholderString: PropTypes.string,
  placeholderSuggestion: PropTypes.string,
  showMobileSearch: PropTypes.bool,
  isQuarantine: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default injectIntl(withPrimaryStyles(MessageSearch));
