import { isEmpty } from 'lodash';
import { getStore } from '../../store/storeUtil';
import { getManagingDelegate } from '../Settings/ducks/profileReducer';
import { API_CALL, apiActions, apiEndpoints } from './apiConstants';

export const callConfig = (method, params, data) => {
  const managingDelegate = getManagingDelegate(getStore().getState());
  return {
  [API_CALL]: {
    endpoint: apiEndpoints.CONFIG_URL,
    method,
    params: { isDelegateUser: !isEmpty(managingDelegate.email) },
    data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
    actionType: apiActions.UPDATE_CONFIG
  }
}};

export const getConfig = (payload) => {
  const managingDelegate = getManagingDelegate(getStore().getState());
  const { username, ...data } = payload;

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.CONFIG_URL,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },      
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.GET_CONFIG
    }
  };
};
