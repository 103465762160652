import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import cx from 'classnames';

import './MessageOptions.scss';
import { getActiveRecipients, getRevokedRecipients } from '../../utils/encryptionUtils';
import { isFutureExpiry } from '../../utils/dateUtils';
import { renderExpiryDate, renderMessageStatus, renderString } from '../../utils/renderUtils';
import AccessList from '../../common/AccessList';
import BasicNav from '../../common/Nav/Basic';
import EmptyState from '../../common/EmptyState';
import ExpiryOptionsContainer from './Expiry/ExpiryOptionsContainer';
import MessageActivity from '../MessageActivity';
import SecureMessageCenter from '../../common/Icons/SecureMessageCenter';
import withPrimaryStyles from '../../common/withPrimaryStyles';

function MessageOptions({
  activeTabIndex,
  handleTabChange,
  viewAnimateDirection,
  handleViewAnimation,
  encryptedMessage,
  isReady,
  isEmpty,
  toggleExpiryMenu,
  toggleMobileExpiryMenu,
  showExpiryOptions,
  showMobileExpiryOptions,
  anchorEl,
  encryptedMessageCount,
  showSingleMessageActions,
  classes
}) {
  const isExpired = !isFutureExpiry(encryptedMessage.expireTime);
  const isRevoked = !encryptedMessage.active;
  const messageOptionsClass = cx('message-options', {
    'message-options--slideLeft': viewAnimateDirection === 'left',
    'message-options--fadeOut': viewAnimateDirection === 'right'
  });
  const messageOptionsTabsClass = cx('message-options__tabs', {
    [`${classes.primaryColor}`]: classes.primaryColor
  });
  const activeRecipients = getActiveRecipients(encryptedMessage.recipients);
  const revokedRecipients = getRevokedRecipients(encryptedMessage.recipients);
  let expiryTime = renderMessageStatus(encryptedMessage.active, encryptedMessage.expireTime, true);
  if (!isRevoked && encryptedMessage.expireTime > 0) {
    expiryTime = renderExpiryDate(encryptedMessage.expireTime, isExpired);
  }

  // No sent messages
  if (isEmpty && isReady && (encryptedMessageCount === undefined || encryptedMessageCount === 0)) {
    return (
      <>
        <Hidden xsDown>
          <EmptyState
            className="welcome-hero-message"
            title={renderString('encryption.welcome.title')}
            body={renderString('encryption.welcome.subtitle')}
          >
            <SecureMessageCenter className="o-icon o-icon--large" />
          </EmptyState>
        </Hidden>
      </>
    );
  }

  return (
    <main className={messageOptionsClass}>
      <Hidden smUp>
        <BasicNav
          buttonLeft={{
            icon: 'back',
            handleAction: handleViewAnimation
          }}
          mainTitle={expiryTime}
          isReady={isReady}
          mainClass="basic-nav-wrapper--white-bg"
          innerClass="basic-nav--innerpage"
          buttonRight={{
            icon: 'MoreVert',
            handleAction: toggleMobileExpiryMenu
          }}
        />
      </Hidden>
      <section className="message-options__actions">
        <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--title">
          <h1>{encryptedMessage.subject}</h1>
        </ReactPlaceholder>
        <Hidden xsDown>
          <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--subtitle">
            {expiryTime}
          </ReactPlaceholder>
        </Hidden>
        {encryptedMessage.guid && showSingleMessageActions && (
          <>
            <Hidden xsDown>
              <IconButton
                className="message-options__expiry-options"
                aria-label="Expiry Options"
                aria-haspopup="false"
                onClick={toggleExpiryMenu}
              >
                <MoreVert />
              </IconButton>
            </Hidden>
            <ExpiryOptionsContainer
              showExpiryOptions={showExpiryOptions}
              showMobileExpiryOptions={showMobileExpiryOptions}
              toggleMessageActions={toggleExpiryMenu}
              toggleMobileExpiryMenu={toggleMobileExpiryMenu}
              anchorEl={anchorEl}
              encryptedMessageGuid={encryptedMessage.guid}
              encryptedMessageSubject={encryptedMessage.subject}
              activeRecipients={activeRecipients}
              revokedRecipients={revokedRecipients}
              currentExpiry={encryptedMessage.expireTime}
              isRevoked={!encryptedMessage.active}
              handleViewAnimation={handleViewAnimation}
            />
          </>
        )}
      </section>
      <AppBar className={messageOptionsTabsClass} position="static">
        <Hidden lgUp>
          <Tabs
            variant="standard"
            value={activeTabIndex}
            onChange={handleTabChange}
            classes={{ indicator: classes.primaryBackgroundColor }}
          >
            <Tab
              classes={{ selected: 'message-tab--selected' }}
              label={renderString('encryption.message.tab.recipients')}
            />
            <Tab
              classes={{ selected: 'message-tab--selected' }}
              label={renderString('encryption.message.tab.activity')}
            />
          </Tabs>
        </Hidden>
      </AppBar>
      <div className="message-options__main">
        {activeTabIndex === 0 && (
          <section className="message-options__main__recipients">
            <Hidden xsDown>
              <AccessList
                guid={encryptedMessage.guid}
                isReady={isReady}
                title="encryption.message.tab.recipients"
                data={encryptedMessage.recipients}
                showSingleMessageActions={showSingleMessageActions}
              />
            </Hidden>
            <Hidden smUp>
              <AccessList
                guid={encryptedMessage.guid}
                isReady={isReady}
                title="encryption.message.tab.recipients"
                data={encryptedMessage.recipients}
                showSingleMessageActions
              />
            </Hidden>
          </section>
        )}
        {activeTabIndex === 1 && (
          <>
            <Hidden lgUp>
              <section className="message-options__main__timeline">
                <MessageActivity isReady={isReady} currentGuid={encryptedMessage.guid} />
              </section>
            </Hidden>
          </>
        )}
      </div>
    </main>
  );
}

MessageOptions.defaultProps = {
  anchorEl: null
};

MessageOptions.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  viewAnimateDirection: PropTypes.string.isRequired,
  handleViewAnimation: PropTypes.func.isRequired,
  toggleExpiryMenu: PropTypes.func.isRequired,
  toggleMobileExpiryMenu: PropTypes.func.isRequired,
  encryptedMessage: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  isReady: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  showExpiryOptions: PropTypes.bool.isRequired,
  showMobileExpiryOptions: PropTypes.bool.isRequired,
  encryptedMessageCount: PropTypes.number.isRequired,
  showSingleMessageActions: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(MessageOptions);
