export const lang = 'en-gb';
export const messages = {
  'app.header.title': 'Welcome to Proofpoint',
  'app.switcher.applications.header': 'Apps',
  'not.available': 'N/A',
  // Top level views
  'app.quarantine': 'Quarantine',
  'app.smc': 'Secure Message Center',
  'app.smc.label': 'SMC',
  'app.settings': 'Settings',
  'app.landingPage': 'Home',
  'app.logo': 'The company logo',
  // action buttons
  'action.button.release': 'Release',
  'action.button.allow': 'Allow Sender',
  'action.button.block': 'Block Sender',
  'action.button.block.domain': 'Block Domain',
  'action.button.block.and.delete': 'Block and Delete',
  'action.button.delete': 'Delete Message',
  'action.button.back': 'Back',
  'action.button.menu': 'Menu',
  'action.button.add': 'Add',
  'action.button.cancel': 'Cancel',
  'action.button.ok': 'Ok',
  'action.button.more': 'More...',
  'selection.counter': '{counter} selected',
  'selection.counter.items': '{counter} item(s) selected',
  'action.button.more.attachments': '{count} more',
  'action.button.release.approve': 'Release & Approve',
  'action.button.view': 'View',
  'action.button.delete.all': 'Delete All',
  'action.button.mark.phish': 'Mark as Phish',
  'action.button.mark.spam': 'Mark as Spam',
  'action.button.mark.not.spam': 'Mark as Not Spam',
  'action.button.recommend.release': 'Recommend Sender for Approval',
  'action.disabled': 'This action is disabled.',
  // Alerts
  'alert.no.images': 'Images and links have been removed.',
  // Tooltips
  'tooltip.nav.help': 'Help Document',
  'tooltip.button.add': 'Add an email address to your Allow list.',
  'tooltip.button.block.add': 'Add an email address to your Block list.',
  'tooltip.button.release': 'Release the selected message(s) to your inbox.',
  'tooltip.button.release.individual': 'Release the message to your inbox.',
  'tooltip.button.block': 'Add sender email address of the selected message to your Block list.',
  'tooltip.button.block.delete':
    'Add sender email address of the selected message to your Block list and delete the message.',
  'tooltip.button.block.domain': 'Add sender domain of the selected message to your Block list.',
  'tooltip.button.block.individual': 'Add sender email address of the message to your Block list.',
  'tooltip.button.allow': 'Add sender email address of the selected message to your Safe list.',
  'tooltip.button.allow.individual': 'Add sender email address of the message to your Safe list.',
  'tooltip.button.delete': 'Delete the selected message(s).',
  'tooltip.button.delete.individual': 'Delete the message.',
  'tooltip.button.delete.item': 'Delete the selected item(s).',
  'tooltip.button.back': 'Back',
  'tooltip.button.more': 'More...',
  'tooltip.button.actions': 'Message Actions...',
  'tooltip.button.user.profile': 'User profile',
  'tooltip.button.close': 'Close',
  'tooltip.button.undo': 'Undo last action',
  'tooltip.button.cancel': 'Discard changes',
  'tooltip.button.save': 'Save changes',
  'tooltip.button.back.to.folders': 'Go back to Folders list',
  'tooltip.label.score': 'Spam Score',
  'tooltip.attachment.access.denied': 'Download not permitted',
  // action button confirmation messages
  'action.multiselect.subtitle': 'Message Actions',
  'action.button.add.senderlist.message': '{emailCount} email(s) has been added.',
  'action.button.edit.senderlist.message': '{emailCount} email(s) has been updated.',
  'action.button.add.senderlist.failed.message': '{emailCount} email(s) were unable to be added.',
  'action.button.delete.senderlist.message': 'Deleting {emailCount} email(s)',
  'action.button.delete.senderlist.failed.message': 'Deleting {emailCount} email(s) were unable to be deleted.',
  'action.button.default.message': 'This operation is scheduled for execution.',
  'action.button.release.message': 'Release command is scheduled for execution. {counter} message(s) will be affected.',
  'action.button.delete.message': 'Delete command is scheduled for execution. {counter} message(s) will be affected.',
  'action.button.add.message': 'Add command is scheduled for execution. {counter} message(s) will be affected.',
  'action.button.block.message':
    'Block command is scheduled for execution. {counter} sender address(es) will be affected.',
  'action.button.block.delete.message':
    'Block and Delete command is scheduled for execution. {counter} sender address(es) will be affected.',
  'action.button.allow.message':
    'Allow command is scheduled for execution. {counter} sender address(es) will be affected.',
  'action.button.release.approve.message':
    'Release and Approve command is scheduled for execution. {counter} sender address(es) will be affected.',
  'action.button.mark.phish.message':
    'Mark as Phish command is scheduled for execution. {counter} sender address(es) will be affected.',
  'action.button.mark.spam.message':
    'Mark as Spam command is scheduled for execution. {counter} sender address(es) will be affected.',
  'action.button.mark.not.spam.message':
    'Mark as Not Spam command is scheduled for execution. {counter} sender address(es) will be affected.',
  'action.button.recommend.release.message':
    'Your administrator has been notified of your request to add {sender} sender address(es) to your organizations safe list.',
  'command.execution.error':
    'An error occurred while trying to execute your command. ' +
    '{counter} item(s) were affected. Please try again later. ' +
    'If the problem persists, please contact your systems administrator.',
  'command.execution.error.without.module':
    'An error occurred while trying to execute your command. ' +
    '{counter} item(s) were affected. Please try again later. ' +
    'You can locate the message and repeat the desired action. ' +
    'If the problem persists, please contact your systems administrator.',
  'command.execution.error.with.reasons':
    'An error occurred while trying to execute your command. ' +
    '{counter} item(s) were affected with the following errors reported: "{reasons}". ' +
    'If the problem persists, please contact your systems administrator.',
  // digest actions
  'digest.opperation.success': 'Action applied.',
  'digest.opperation.error': 'Action could not be applied.',
  'digest.release.success': 'Message successfully released.',
  'digest.release.error': 'Message could not be released.',
  'digest.delete.success': 'Message successfully deleted.',
  'digest.delete.error': 'Message could not be deleted.',
  'digest.msgsafelist.success': 'Message sender safelisted.',
  'digest.msgsafelist.error': 'Message sender could not be safelisted.',
  'digest.msgblocklist.success': 'Message sender added to blocked list.',
  'digest.msgblocklist.error': 'Message sender could not be added to blocked list.',
  'digest.releasewhitelist.success': 'Message released and sender is now safelisted.',
  'digest.releasewhitelist.error': 'Could not release and allow sender.',
  'digest.reportfalsepositive.success': 'Message reported as not spam.',
  'digest.reportfalsepositive.error': 'Could not report as not spam.',
  'digest.reportfalsenegative.success': 'Message reported as spam.',
  'digest.reportfalsenegative.error': 'Could not report as spam.',
  'digest.reportfnphishing.success': 'Message reported as phishing.',
  'digest.reportfnphishing.error': 'Could not report as phishing.',
  'digest.send.success': 'Message has been sent.',
  'digest.send.error': 'Could not send message.',
  'digest.sendenc.success': 'Message has been encrypted and sent.',
  'digest.sendenc.error': 'Message could not be encrypted and sent.',
  'digest.block.success': 'Message has been blocked.',
  'digest.block.error': 'Message could not be blocked.',
  'digest.recommendwhitelist.success': 'Sender has been added to the allowed sender list.',
  'digest.recommendwhitelist.error': 'Sender could not be added to the allowed sender list.',
  'digest.gendigest.success':
    'Generating digest is currently unavailable. Contact your administrator and request they generate a new digest for you.',
  // notification buttons
  'notification.button.undo': 'Undo',
  'notification.button.undo.message': 'Your action has been undone.',
  'notification.updated.message': 'Your settings have been updated.',
  // folder names
  'folder.title': 'Folders',
  'folder.not.found': 'There was a problem fetching your Quarantine folders',
  'folder.Quarantine': 'Quarantine',
  'folder.Spam.Quarantined': 'Spam - Quarantined',
  'folder.LowPriorityMail.Delivered': 'Low Priority Mail - Delivered',
  'folder.LowPriorityMail.Quarantined': 'Low Priority Mail - Quarantined',
  'folder.AuditMessages': 'Audit Messages',
  'folder.BounceManagement': 'Bounce Management',
  'folder.ReputationService': 'Reputation Service',
  'folder.DKIM': 'DKIM',
  'folder.DKIMVerification': 'DKIM Verification',
  'folder.SMTPServer': 'SMTP Server',
  'folder.EmailFirewall': 'Email Firewall',
  'folder.SenderPolicyFramework.SPF': 'Sender Policy Framework (SPF)',
  'folder.AttachmentDefense': 'Attachment Defense',
  'folder.URLDefense': 'URL Defense',
  'folder.Virus': 'Virus',
  'folder.ZeroHour': 'Zero Hour',
  'folder.Encryption': 'Encryption',
  'folder.DigitalAssets': 'Digital Assets',
  'folder.RegulatoryCompliance': 'Regulatory Compliance',
  'folder.Dmarc': 'Dmarc',
  'folder.UntrustedSenders': 'Untrusted Senders',
  'folder.SecureMessageCenter': 'Sent Messages',
  'sender.blocklist': 'Block List',
  'sender.safelist': 'Safe List',
  'folder.banner': 'Banner',
  'folder.journal': 'Journal',

  // profile side-bar items
  'profile.GeneralSettings': 'General Settings',
  'profile.Profile': 'Profile',
  'profile.EmailSettings': 'Email Settings',
  'profile.Name': 'Name',
  'profile.Email': 'Email',
  'profile.Aliases': 'Aliases',
  'profile.SpamPolicy': 'Spam Policy',
  'profile.Save': 'Save',
  'profile.Back': 'Back',
  'profile.Cancel': 'Cancel',
  'profile.Settings': 'Settings',  
  'profile.Logout': 'Sign Out',
  'profile.manage.profile': 'Manage Profile',

  // Settings
  'settings.settings': 'Settings',
  'settings.quarantine': 'Quarantine',
  'settings.safeList': 'Allow List',
  'settings.allowList.addAddress': 'ADD ADDRESS',
  'settings.safeList.add': 'Add',
  'settings.safeList.emailAddress': 'Email Address',
  'settings.safeList.add.title': 'New Allow List Entry',
  'settings.safeList.add.description': 'Enter an email address you would like to add to your allow list',
  'settings.allowList.options': 'Options',
  'settings.allowList.options.long': 'What would you like to do?',
  'settings.allowList.edit': 'Edit Address',
  'settings.allowList.remove': 'Remove from Allow List',
  'settings.blockList': 'Block List',
  'settings.blockList.remove': 'Remove from Block List',
  'settings.general': 'General',
  'settings.generalSettings': 'General Settings',
  'settings.general.profile': 'Profile',
  'settings.general.displayName': 'Name',
  'settings.general.email': 'Email',
  'settings.general.aliases': 'Aliases',
  'settings.general.aliases.none': 'No Aliases Added',

  'settings.blockList.add': 'Add',
  'settings.blockList.add.title': 'New Block List Entry',
  'settings.blockList.add.description': 'Enter an email address or domain you would like to add to your block list.',
  'settings.blockList.entry': 'Email Address or Domain',

  // messages
  'message.not.found': 'Message not found',
  'messages.not.found.folder': 'There are no messages in this folder.',
  'messages.loading': 'Waiting for message(s) to load',
  'messages.loading.error': `There was a problem retrieving your messages.
    If this happens again contact your administrator. `,
  'message.loading.error.title': 'Message content unavailable',
  'message.loading.error':
    'There was a problem retrieving your message.{lineBreak}If this happens again contact your administrator.',

  // Sender
  'sender.dialog.add.to': 'Add to {displayName}',
  'sender.no.email.addresses': 'There are no email addresses.',
  'no.search.result.found': 'No search result found.',

  // Recipient
  'recipient.to': 'to me',

  // form validation messages
  'form.invalid.email.address': 'Invalid email address',
  'form.invalid.email.address.or.domain': 'Invalid email address or domain name',
  'form.email.required': 'Email address is required!',
  'form.email.or.domain.required': 'Email or domain is required!',
  'form.email.notChanged': 'Email not changed.',
  'form.email.or.domain.notChanged': 'Value has not changed.',
  'form.email.already.exists': 'Email already exists.',
  'form.email.or.domain.already.exists': 'Value already exists.',
  'form.email.wildcards.not.allowed': 'Wildcards are not allowed.',

  // Signin page messages
  'signin.welcome.header': 'Welcome to Proofpoint Email Protection',
  'signin.button.continue': 'Continue',
  'signin.label.EmailAddress': 'Email Address',
  'signin.footer.PrivacyPolicy': 'Privacy Policy',
  'signin.footer.TermsOfService': 'Terms of Service',
  'signin.footer.AllRightsReserved': 'All Rights Reserved',
  'sigin.email.address': 'Email Address',
  'signin.session.expired': 'Your session has expired. Please log in again',
  'signin.noFeaturesEnabled': 'Access is not enabled. Please contact your administrator.',
  // Error Message
  'error.poor.network': 'Poor network connection',
  'error.nav.help': "The service of help document is not available right now. Please try again later.",


  // Helper Text:
  'load.more.button': 'Load more',

  // Dates:
  'dates.today': 'Today',
  'dates.yesterday': 'Yesterday',
  'dates.thisweek': 'This Week',
  'dates.lastWeek': 'Last 7 Days',
  'dates.twoWeeksAgo': 'Last 14 Days',
  'dates.threeWeeksAgo': 'Last 21 Days',
  'dates.lastMonth': 'Last Month',
  'dates.thisYear': 'This Year',
  'dates.overAYear': 'Over a Year Old',
  'dates.second': '{seconds} Second',
  'dates.seconds': '{seconds} Seconds',
  'dates.minute': '{minutes} Minute',
  'dates.minutes': '{minutes} Minutes',
  'dates.hour': '{hours} Hour',
  'dates.hours': '{hours} Hours',
  'dates.day': '{days} day',
  'dates.days': '{days} days',
  'dates.week': '{weeks} Week',
  'dates.weeks': '{weeks} Weeks',
  'dates.month': '{months} Month',
  'dates.months': '{months} Months',
  'dates.year': '{years} Year',
  'dates.years': '{years} Years',

  // Search
  'search.placeholder': 'Search...',
  'search.suggestion': 'Enter a search above',
  'search.by.date.default': 'All',
  'search.by.date.lastHour': 'Last hour',
  'search.by.date.last4hours': 'Last 4 hours',
  'search.by.date.last12hours': 'Last 12 hours',
  'search.by.date.last24hours': 'Last 24 hours',
  'search.by.date.lastWeek': 'Last week',
  'search.by.date.lastMonth': 'Last month',

  'search.by.date.default.selected': 'Showing all',
  'search.by.date.lastHour.selected': 'Showing last hour',
  'search.by.date.last4hours.selected': 'Showing last 4 hours',
  'search.by.date.last12hours.selected': 'Showing last 12 hours',
  'search.by.date.last24hours.selected': 'Showing last 24 hours',
  'search.by.date.lastWeek.selected': 'Showing last week',
  'search.by.date.lastMonth.selected': 'Showing last month',

  // Tabs
  'tabs.content': 'Content',
  'tabs.details': 'Details',

  // Quarantine
  'quarantine.welcome': 'Welcome to your Quarantine',
  'quarantine.welcome.subTitle': 'You currently have no quarantined messages in this folder.',
  'quarantine.title.actions': 'What would you like to do?',
  'quarantine.title.details': 'Quarantine Details',
  'quarantine.title.options': 'Message Options',
  'quarantine.search.placeholder': 'Search Messages',
  'quarantine.search.placeholder.suggestion': 'Search for an email address or subject',

  // Encryption
  'encryption.welcome.title': 'Hi, welcome to Secure Message Center!',
  'encryption.welcome.subtitle': 'You currently have no secure sent messages to manage.',

  'encryption.message.activity.title': 'Message Event Activity',
  'encryption.message.activity.sent': 'Message sent',
  'encryption.message.activity.lastViewed': 'last viewed the message',
  'encryption.message.activity.firstViewed': 'Viewed the message for the 1st time',
  'encryption.message.options.expires': 'Expires in ',
  'encryption.message.options.expiresIn': 'in ',
  'encryption.message.options.expiry.andHours': 'and ',
  'encryption.message.options.expired': 'Expired',
  'encryption.message.options.neverExpires': 'Never Expires',
  'encryption.message.options.access.settings': 'Access Settings',
  'encryption.message.search.error': 'No results found',
  'encryption.message.error': 'You have no sent messages to manage',
  'encryption.message.filter.error': 'There was a problem fetching your encrypted messages',
  'encryption.message.limit.error':
    'We recommend that no more than {counter} messages are selected when performing a bulk action.',

  'encryption.status.all': 'All',
  'encryption.status.active': 'Active',
  'encryption.status.expired': 'Expired',
  'encryption.status.revoked': 'Revoked',

  'encryption.search.suggestion': 'Search for recipient email address',
  'encryption.search.placeholder': 'Search Recipients',
  'encryption.search.results': 'Results',

  'encryption.message.tab.recipients': 'Recipient Access',
  'encryption.message.tab.activity': 'Activity',
  'encryption.message.active.title': 'Recipient With Access',
  'encryption.message.revoked.title': 'Revoked Recipients',
  'encryption.message.revoked.processed': '{totalCount} message(s) are being scheduled for a status change to revoked',
  'encryption.message.restored.processed':
    '{totalCount} message(s) are being scheduled for a status change to restored',
  'encryption.message.restored.error': '1 or more messages could not be restored',
  'encryption.message.revoked.error': '1 or more messages could not be revoked',

  'encryption.recipients.table.recipients': 'Recipients',
  'encryption.recipients.table.viewed': 'Viewed',
  'encryption.recipients.table.unread': 'Unread',
  'encryption.recipients.table.selected': '{count} selected',
  'encryption.recipients.revoked.success': '{recipientCount} recipient(s) revoked',
  'encryption.recipients.restored.success': '{recipientCount} recipient(s) restored',
  'encryption.messages.revoked.success': '{messageCount} messages(s) revoked',
  'encryption.messages.restored.success': '{messageCount} messages(s) restored',
  'encryption.revoke.action': 'Revoke',
  'encryption.restore.action': 'Restore',

  'encryption.expiry.update.success': 'Message expiry updated',
  'encryption.expiry.update.error': 'There was a problem updating the message expiry',
  'encryption.expiry.options.header': 'Expiry Options',
  'encryption.mobile.expiry.options.header': 'Expiry Settings',
  'encryption.expiry.option.never.expire': 'Never Expire',
  'encryption.expiry.option.change.expiry': 'Change Expiry Date',
  'encryption.expiry.option.expire.message': 'Expire Message Now',
  'encryption.expiry.option.revoke.all': 'Revoke All Access',
  'encryption.expiry.message.expires': 'Message Expires',
  'encryption.expiry.option.custom.dateTime': 'Specific Date/Time',
  'encryption.expiry.option.date.input.label': 'Date',
  'encryption.expiry.option.time.input.label': 'Time ({timezone})',
  'encryption.expiry.option.restore.all': 'Restore All Access',
  'encryption.expiry.option.set.new.expiry': 'Set New Expiry Date',
  'encryption.message.options.expired.on': 'Expired on {expiryDateTime}',
  'encryption.message.options.expires.on': 'Expires on {expiryDateTime}',

  // Landing Page
  'landing.page.greeting.message': 'Welcome to your Personal Security Hub',
  'landing.page.greeting.morning': 'Morning, {displayName}',
  'landing.page.greeting.afternoon': 'Afternoon, {displayName}',
  'landing.page.greeting.evening': 'Evening, {displayName}',
  'landing.page.smc.card.title': 'Recent Secure Messages',
  'landing.page.smc.card.action.button.text': 'View All Secure Messages',
  'landing.page.smc.card.empty': 'No Secure Messages',
  'landing.page.quarantine.card.title': 'Quarantine Summary (Unread)',
  'landing.page.quarantine.card.empty': 'No unread Quarantine mail',

  // Dialogs
  'dialog.options.title': 'Choose an action',

  // Messages
  'aria.label.message': '{date}, sender: {sender}, subject: {subject}',
  'aria.label.message.checked': 'checked, {date}, sender: {sender}, subject: {subject}',
  'aria.label.message.content': 'Message content',
  'aria.label.message.body': 'Message body',

  // Delegates
  'manage.users': 'Manage Users',
  'stop.managing': 'Stop Managing',
  'managing': 'Managing',
  'stop.managing.user': 'Stop managing {username}',
  'stop.managing.confirm.dialog.content':'Are you sure you want to stop managing {username}?',
  'stop.managing.confirm.dialog.no':'No - Continue Managing',
  'stop.managing.confirm.dialog.yes':'Yes',
};
