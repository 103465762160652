// safelist and blocklist values are very important.
// They are used for defining route urls, PUT_CONFG API key names, show/hide reducer, and INTL id/values.
// PLEASE DO NOT CHANGE THEM UNLESS YOU KNOW WHAT YOU ARE DOING.
// Look at senderlistview component for more information.

export const senderListConstants = Object.freeze({
  SAFELIST: 'safeList',
  BLOCKLIST: 'blockList'
});

export default senderListConstants;
