import React, { Component } from 'react';
import { Hidden } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import './SenderListItem.scss';
import '../../../../common/Menu/EllipsisMenu/EllipsisMenu.scss';
import { RootContext } from '../../../../RootContext';
import { buttonType } from '../../../../constants/uiConstants';
import { moduleType } from '../../../../ducks/appTypes';
import { qaTags } from '../../../../constants/qaConstants';
import { setSettingsDialogFocus } from '../../../../utils/accessibilityUtils';
import ActionButton from '../../../../common/ActionButton';
import MobileDrawer from '../../../../common/MobileDrawer';
import OptionSelectSenderListItem from '../OptionSelectSenderListItem';
import RowItem from '../../../../common/RowItem';
import withPrimaryStyles from '../../../../common/withPrimaryStyles';

class SenderListItem extends Component {
  constructor(props) {
    super(props);
    this.iconBtn = React.createRef();
  }

  getActionItems() {
    const { configProperty, classes, openPopupMenu, id, intl, handlePopoverOpen, handlePopoverClose } = this.props;
    return [
      <RootContext.Consumer>
        {(value) => {
          return (
            <React.Fragment key="deleteMenu">
              <Hidden xsDown>
                <div ref={this.iconBtn}>
                  <ActionButton
                    ariaExpanded={openPopupMenu}
                    actionBtnDescription={`More menu ${
                      openPopupMenu ? 'expanded' : 'collapsed'
                    }. Press period key to open dropdown.`}
                    key="action-delete"
                    type={buttonType.BUTTON_MORE}
                    tooltip="settings.allowList.options"
                    isIconButton
                    onClick={(e) => {
                      handlePopoverOpen(e);
                    }}
                    ariaLabel={`More actions for ${id}`}
                    variant="text"
                  />
                </div>
                <Menu
                  className=""
                  classes={{ paper: 'ellipsis-menu' }}
                  autoFocus
                  role="menu"
                  anchorEl={this.iconBtn.current}
                  open={openPopupMenu}
                  PaperProps={{
                    style: {
                      transform: 'translateY(45px)'
                    },
                    role: 'menuitem'
                  }}
                  onClose={() => {
                    handlePopoverClose();
                  }}
                >
                  <MenuItem
                    className={`senderList-menu__list ${classes.darkSecondaryColor10}`}
                    onClick={this.openEditClick}
                  >
                    {intl.formatMessage({ id: 'settings.allowList.edit' })}
                  </MenuItem>
                  <MenuItem
                    className={`senderList-menu__list ${classes.errorColor}`}
                    onClick={() => {
                      this.deleteItemClick(value.appRef);
                    }}
                  >
                    {intl.formatMessage({
                      id: configProperty === 'safeList' ? 'settings.allowList.remove' : 'settings.blockList.remove'
                    })}
                  </MenuItem>
                </Menu>
              </Hidden>
            </React.Fragment>
          );
        }}
      </RootContext.Consumer>
    ];
  }

  deleteItemClick = (appRef) => {
    const { id, configProperty, selectItemFunc, clearAllSelectionFunc, deleteConfigFunc } = this.props;
    setSettingsDialogFocus(appRef);
    // Clear the selection and delete
    clearAllSelectionFunc();
    selectItemFunc(id, true, moduleType.SENDER_LIST);
    deleteConfigFunc(configProperty);
  };

  openDeleteClick = () => {
    const { id, addSettingsPathFunc, selectItemFunc, clearAllSelectionFunc } = this.props;

    clearAllSelectionFunc();
    selectItemFunc(id, true, moduleType.SENDER_LIST);
    addSettingsPathFunc(`options[${id}]`);
  };

  openEditClick = () => {
    const { id, addSettingsPathFunc, selectItemFunc, clearAllSelectionFunc } = this.props;

    clearAllSelectionFunc();
    selectItemFunc(id, true, moduleType.SENDER_LIST);
    addSettingsPathFunc(`edit[${id}]`);
  };

  render() {
    const {
      id,
      shouldShowDeleteUi,
      setCurrentKeyboardFocus,
      configProperty,
      index,
      currentFocus,
      handleOnBlur,
      openPopupMenu,
      handleOnFocus
    } = this.props;

    return (
      <>
        <Hidden xsDown>
          <RowItem
            key={id}
            rowItemQaTag={qaTags.SIDEBAR_LIST_ITEM}
            leftText={id}
            handleOnFocus={handleOnFocus}
            handleOnBlur={handleOnBlur}
            index={index}
            isFocussed={currentFocus === index}
            setCurrentKeyboardFocus={setCurrentKeyboardFocus}
            showCheckboxes={false}
            hoverCommandActionItems={this.getActionItems()}
            alwaysShowCommandActionItems={Boolean(openPopupMenu)}
          />
        </Hidden>

        <Hidden smUp>
          <RowItem
            key={id}
            rowItemQaTag={qaTags.SENDER_LIST_ITEM}
            leftText={id}
            setCurrentKeyboardFocus={setCurrentKeyboardFocus}
            handleOnFocus={handleOnFocus}
            handleOnBlur={handleOnBlur}
            index={index}
            isFocussed={currentFocus === index}
            onClickRowItem={this.openDeleteClick}
            showCheckboxes={false}
            hoverCommandActionItems={<ChevronRight key="chevron" />}
            alwaysShowCommandActionItems
          />

          <MobileDrawer location="left" shouldShow={shouldShowDeleteUi}>
            <OptionSelectSenderListItem id={id} configProperty={configProperty} />
          </MobileDrawer>
        </Hidden>
      </>
    );
  }
}

SenderListItem.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  configProperty: PropTypes.string.isRequired,
  shouldShowDeleteUi: PropTypes.bool.isRequired,
  selectItemFunc: PropTypes.func.isRequired,
  deleteConfigFunc: PropTypes.func.isRequired,
  clearAllSelectionFunc: PropTypes.func.isRequired,
  addSettingsPathFunc: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  currentFocus: PropTypes.number.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  openPopupMenu: PropTypes.bool.isRequired,
  setCurrentKeyboardFocus: PropTypes.func.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired
};

export default injectIntl(withPrimaryStyles(SenderListItem));
