import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getAppOptionsForDisplay } from '../../../ducks/featureReducer';
import { getCurrentApp } from '../../../utils/featureUtils';
import { getDefaultQuarantineFolder } from '../../../SideBar/ducks/folderReducer';
import { getEncryptedTotalCheckedGuids } from '../../../Encryption/MessageList/ducks/MessageListReducer';
import { getQuarantineFolderFromPath, isMessageBodyPath } from '../../../utils/routeUtils';
import { getTotalCheckedGuids } from '../../../Quarantine/ducks/messageReducer';
import { getSelectedSettingsOption, getSettingsPath, isQuarantineApp } from '../../../ducks/uiStatusReducer';
import { renderViewTitle } from '../../../utils/renderUtils';
import { resetCheckedEncryptedMessages } from '../../../Encryption/MessageList/ducks/MessageListActions';
import { resetCheckedMessages } from '../../../Quarantine/ducks/messageActions';
import { setComponentDisable } from '../../../ducks/uiStatusActions';
import BasicNav from './BasicNav';
import { fetchProfile, resetManagingDelegate } from '../../../Settings/ducks/profileActions';
import { getCurrentEmailAddressFromState, getManagingDelegate } from '../../../Settings/ducks/profileReducer';
import { isEmpty } from '../../../utils/strUtils';
import { loadSenderList } from '../../../Settings/Quarantine/SenderListView/ducks/senderListActions';


class BasicNavContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false
    };
  }

  componentDidUpdate(prevProps){
    const { managedDelegate,  settingsPath, loadSenderListDispatch, selectedSettingsOption, } = this.props;
   if(managedDelegate.email !== prevProps.managedDelegate.email){
      if (!isEmpty(settingsPath) && selectedSettingsOption === 'settings.quarantine.allowList') {               
        loadSenderListDispatch('safeList');
       }
      if (!isEmpty(settingsPath) && selectedSettingsOption === 'settings.quarantine.blockList') {               
        loadSenderListDispatch('blockList');
      }
    }
  }

  openStopManagementConfirmationDialog = () => {    
    this.setState({openDialog:true});
  }

  handleDialogClose = () => {
    this.setState({openDialog: false});
  }
  
  handleMultiSelectCancel = () => {
    const {
      handleResetQuarantineMessagesDispatch,
      handleResetEncryptedMessagesDispatch,
      setComponentDisableDispatch,
      isQuarantine
    } = this.props;

    if (isQuarantine) {
      handleResetQuarantineMessagesDispatch();
      setComponentDisableDispatch('ActionButton', false);
    } else {
      handleResetEncryptedMessagesDispatch();
      setComponentDisableDispatch('SingleEncryptionActions', false);
    }
  };

  render() {
    const {
      totalCheckedMessages,
      buttonLeft,
      buttonCenter,
      buttonRight,
      subTitle,
      mainTitle,
      mainClass,
      intl,
      innerClass,
      stopManagingDispatch,
      isReady,
      logout,
      isPrimaryNav,
      toggleShowSettings,
      managedDelegate,
      userEmailAddress,
      fetchProfileDispatch
    } = this.props;
    const {openDialog} = this.state;
    const {
      location: { pathname }
    } = window;
    const folderId = getQuarantineFolderFromPath(pathname);
    return (
      <BasicNav
        totalCheckedMessages={totalCheckedMessages}
        handleMultiSelectCancel={this.handleMultiSelectCancel}
        buttonLeft={buttonLeft}
        buttonCenter={buttonCenter}
        buttonRight={buttonRight}
        mainTitle={mainTitle || renderViewTitle(folderId)}
        subTitle={subTitle}
        mainClass={mainClass}
        innerClass={innerClass}
        logout={logout}
        intl={intl}
        stopManagingDispatch={stopManagingDispatch}
        isReady={isReady}
        toggleShowSettings={toggleShowSettings}
        managedDelegate={managedDelegate}
        userEmailAddress={userEmailAddress}
        fetchProfileDispatch={fetchProfileDispatch}
        openDialog={openDialog}
        isPrimaryNav={isPrimaryNav}
        openStopManagementConfirmationDialog={this.openStopManagementConfirmationDialog}
        handleDialogClose={this.handleDialogClose}
      />
    );
  }
}

BasicNavContainer.defaultProps = {
  subTitle: null,
  buttonLeft: null,
  buttonCenter: null,
  buttonRight: null,
  mainClass: '',
  mainTitle: null,
  innerClass: '',
  isReady: true,
  totalCheckedMessages: 0,
  isPrimaryNav: true,
  managedDelegate: {},
  userEmailAddress:'',
  settingsPath: null,
  selectedSettingsOption: ''
};

BasicNavContainer.propTypes = {
  buttonLeft: PropTypes.shape({
    icon: PropTypes.string,
    handleAction: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object])
  }),
  buttonCenter: PropTypes.shape({
    handleAction: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  buttonRight: PropTypes.shape({
    icon: PropTypes.string,
    handleAction: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  totalCheckedMessages: PropTypes.number,
  handleResetQuarantineMessagesDispatch: PropTypes.func.isRequired,
  handleResetEncryptedMessagesDispatch: PropTypes.func.isRequired,
  setComponentDisableDispatch: PropTypes.func.isRequired,
  isQuarantine: PropTypes.bool.isRequired,
  subTitle: PropTypes.string,
  mainClass: PropTypes.string,
  mainTitle: PropTypes.object,
  innerClass: PropTypes.string,
  isReady: PropTypes.bool,
  isPrimaryNav: PropTypes.bool,
  toggleShowSettings: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  stopManagingDispatch: PropTypes.func.isRequired,
  fetchProfileDispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object,
  userEmailAddress: PropTypes.string,

  
  settingsPath: PropTypes.string,
  selectedSettingsOption: PropTypes.string,
  loadSenderListDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.isQuarantine = isQuarantineApp(state);
  if (props.isQuarantine) {
    props.totalCheckedMessages = getTotalCheckedGuids(state);
  } else {
    props.totalCheckedMessages = getEncryptedTotalCheckedGuids(state);
  }
  const settingsPath = getSettingsPath(state);
  const settingsPathMatch = settingsPath.match(/\/[^/]*\/([^/]*).*/);
  props.settingsPath = settingsPathMatch == null ? null : settingsPathMatch[1];
  props.selectedSettingsOption = getSelectedSettingsOption(state);
  
  props.folderName = getDefaultQuarantineFolder(state);
  props.isMessageBodyPath = isMessageBodyPath(window.location.pathname) || false;
  props.appsForDisplay = getAppOptionsForDisplay(state);
  props.currentApp = getCurrentApp(state);
  props.userEmailAddress= getCurrentEmailAddressFromState(state);
  props.managedDelegate = getManagingDelegate(state);
  return props;
};

export default connect(
  mapStateToProps,
  {
    fetchProfileDispatch: fetchProfile,
    stopManagingDispatch: resetManagingDelegate,
    handleResetQuarantineMessagesDispatch: resetCheckedMessages,
    handleResetEncryptedMessagesDispatch: resetCheckedEncryptedMessages,
    setComponentDisableDispatch: setComponentDisable,
    loadSenderListDispatch: loadSenderList
  }
)(injectIntl(BasicNavContainer));
