import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const SigninFooter = ({intl}) => (
  <div className="footer-panel">
    <span className="copyRightText">
      {`\u00A9 Proofpoint, Inc. ${moment().format('YYYY')} `}
      <FormattedMessage id="signin.footer.AllRightsReserved" />.
    </span>
    <div className="footer-link-panel">
      <a className="footer-link" aria-label={intl.formatMessage({id:'signin.footer.TermsOfService'})} href="https://www.proofpoint.com/us/license" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="signin.footer.TermsOfService" />
      </a>
      <a
        className="footer-link"
        href="http://www.proofpoint.com/us/privacy-policy"
        target="_blank"
        aria-label={intl.formatMessage({id:'signin.footer.PrivacyPolicy'})}
        rel="noopener noreferrer"
      >
        <FormattedMessage id="signin.footer.PrivacyPolicy" />
      </a>
    </div>
  </div>
);

SigninFooter.propTypes = {  
  intl: PropTypes.object.isRequired
};
export default injectIntl(SigninFooter);
