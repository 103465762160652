import { isQuarantinePath, isSecureMessageCenterPath } from './routeUtils';
import { renderString } from './renderUtils';

export function parseSenderList(data) {
  const senderType = Object.keys(data.properties).length > 0 && Object.keys(data.properties)[0];
  return data.properties[senderType].reduce(
    (acc, currItem) => acc.concat({ guid: currItem, email: currItem, type: senderType }),
    []
  );
}

export function getApplicationTitle(secureMessageCenter = false, quarantine = false) {
  const {
    location: { pathname }
  } = window;

  const secureMessageCenterTitle = secureMessageCenter || 'app.smc';
  const quarantineTitle = quarantine || 'app.quarantine';

  if (isSecureMessageCenterPath(pathname)) {
    return renderString(secureMessageCenterTitle);
  }

  if (isQuarantinePath(pathname)) {
    // Todo: replace with dynamic quar folders
    return renderString(quarantineTitle);
  }

  return null;
}
