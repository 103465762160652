import { createSelector } from 'reselect';
import { replace, startsWith, trimStart } from 'lodash';

import { APP_NAMES } from './appConstants';
import { apiActionState } from './apiConstants';
import { createReducer } from '../utils/reducerUtils';
import { defaultActions, uiStatusActions } from './appTypes';
import { messageTypes } from '../Quarantine/ducks/messageTypes';
import { sideBarTypes } from '../SideBar/ducks/sideBarTypes';

export const moduleName = 'uiStatus';

const INITIAL_STATE = {
  loadMoreCount: 0,
  contentUpdateCount: 0,
  contentUpdate: {},
  reason: {},
  disabledComponents: {
    blockList: false,
    safeList: false
  },
  showMobileViewSideBar: false,
  settingsPath: '',
  selectedSettingsOption: '',
  currentFolderId: undefined,
  showMobileSearch: false,
  keyboardFocus: {
    folderList: 0,
    messageList: 1,
    senderList: 0
  }
};

function setContentUpdate(state, action) {
  const contentUpdate = { ...state.contentUpdate };
  contentUpdate[action.contentType] = action.isUpdating;
  const reason = { ...state.reason };
  reason[action.contentType] = action.reason;
  return { ...state, contentUpdate, reason };
}

function setFolderListKeyboardFocus(state, action) {
  return { ...state, keyboardFocus: { ...state.keyboardFocus, folderList: action.focus } };
}

function setMessageListKeyboardFocus(state, action) {
  return { ...state, keyboardFocus: { ...state.keyboardFocus, messageList: action.focus } };
}

function setSenderListKeyboardFocus(state, action) {
  return { ...state, keyboardFocus: { ...state.keyboardFocus, senderList: action.focus } };
}

function setComponentDisabled(state, action) {
  const disabledComponents = { ...state.disabledComponents };
  disabledComponents[action.componentType] = action.isDisabled;
  return { ...state, disabledComponents };
}

function updateStatusReducer(state, action) {
  let { loadMoreCount, contentUpdateCount } = state;
  const { params, data } = action.requestParams || {};
  if (action.type.indexOf(apiActionState.API_ATTEMPT) > 0) {
    if ((params && params.scrollId) || (data && (data.scrollId || data.searchCursor))) {
      loadMoreCount += 1;
    }
    contentUpdateCount += 1;
    return { ...state, contentUpdateCount, loadMoreCount };
  }
  if (action.type.indexOf(apiActionState.API_SUCCESS) > 0 || action.type.indexOf(apiActionState.API_ERROR) > 0) {
    if ((params && params.scrollId) || (data && (data.scrollId || data.searchCursor))) {
      loadMoreCount -= 1;
    }
    contentUpdateCount -= 1;
    contentUpdateCount = contentUpdateCount < 0 ? 0 : contentUpdateCount;
    loadMoreCount = loadMoreCount < 0 ? 0 : loadMoreCount;
    return { ...state, contentUpdateCount, loadMoreCount };
  }
  return state;
}

function setMobileViewSideBar(state) {
  return { ...state, showMobileViewSideBar: !state.showMobileViewSideBar };
}

function setMobileSearchVisibility(state) {
  return { ...state, showMobileSearch: !state.showMobileSearch };
}

function addSettingsPath(state, action) {
  return {
    ...state,
    settingsPath: `${state.settingsPath}${startsWith(action.settingsPath, '/') ? '' : '/'}${action.settingsPath}`
  };
}

function addSelectedSettingsOption(state, action) {
  return {
    ...state,
    selectedSettingsOption: action.selectedSettingsOption
  };
}

function removeSettingsPath(state, action) {
  let path = state.settingsPath;
  if (action.settingsPath) {
    const { shouldEscape } = action;
    const actionPath = shouldEscape ? action.settingsPath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : action.settingsPath;
    path = replace(path, new RegExp(`/${trimStart(actionPath, '/')}(/.*)?$`), '');
  } else {
    path = '';
  }
  return { ...state, settingsPath: `${path}` };
}

function closeMobileViewSideBar(state) {
  return { ...state, showMobileViewSideBar: false };
}

function setEncryptionAnimationDirection(state, action) {
  return { ...state, encryptionAnimationDirection: action.encryptionAnimationDirection };
}

function updateFolderId(state, action) {
  return { ...state, currentFolderId: action.folderId };
}

function updateCurrentApp(state, action) {
  return { ...state, currentApp: action.currentApp };
}

function toggleSingleMessageActionsDisplay(state) {
  return { ...state, showSingleMessageActions: !state.showSingleMessageActions };
}

export default createReducer(INITIAL_STATE, {
  [uiStatusActions.SET_CONTENT_UPDATE]: setContentUpdate,
  [uiStatusActions.SET_COMPONENT_DISABLE]: setComponentDisabled,
  [defaultActions.CATCH_ALL]: updateStatusReducer,
  [uiStatusActions.TOGGLE_MOBILE_SIDEBAR]: setMobileViewSideBar,
  [uiStatusActions.TOGGLE_MOBILE_SEARCH]: setMobileSearchVisibility,
  [uiStatusActions.ADD_SETTINGS_PATH]: addSettingsPath,
  [uiStatusActions.ADD_SELECTED_SETTINGS_OPTION]: addSelectedSettingsOption,
  [uiStatusActions.REMOVE_SETTINGS_PATH]: removeSettingsPath,
  [sideBarTypes.NAVIGATE_TO_FOLDER]: updateFolderId,
  [uiStatusActions.SET_FOLDER_LIST_KEYBOARD_FOCUS]: setFolderListKeyboardFocus,
  [uiStatusActions.SET_MESSAGE_LIST_KEYBOARD_FOCUS]: setMessageListKeyboardFocus,
  [uiStatusActions.SET_SENDER_LIST_KEYBOARD_FOCUS]: setSenderListKeyboardFocus,
  [messageTypes.FETCH_MESSAGES]: updateFolderId,
  [uiStatusActions.SET_CURRENT_APP_TITLE]: updateCurrentApp,
  [uiStatusActions.SET_ENCRYPTION_ANIMATION_DIRECTION]: setEncryptionAnimationDirection,
  [uiStatusActions.CLOSE_MOBILE_VIEW_SIDEBAR]: closeMobileViewSideBar,
  [uiStatusActions.TOGGLE_SINGLE_MESSAGE_ACTIONS]: toggleSingleMessageActionsDisplay
});

const getUiStatus = (state) => state[moduleName];

export const isContentUpdatingSelector = (state, contentType) => {
  if (!contentType) {
    return getUiStatus(state).contentUpdateCount > 0;
  }
  if (!Array.isArray(contentType)) {
    return getUiStatus(state).contentUpdate[contentType];
  }
  return contentType.reduce((acc, type) => acc || getUiStatus(state).contentUpdate[type], false);
};
export const getContentUpdateReason = (state, contentType) => getUiStatus(state).reason[contentType];
export const isComponentDisabled = (state, componentType) => getUiStatus(state).disabledComponents[componentType];

export const getMobileViewSidebarStatus = (state) => getUiStatus(state).showMobileViewSideBar;
export const getMobileSearchStatus = (state) => getUiStatus(state).showMobileSearch;
export const getSettingsPath = (state) => getUiStatus(state) && getUiStatus(state).settingsPath;
export const getSelectedSettingsOption = (state) => getUiStatus(state) && getUiStatus(state).selectedSettingsOption;

export const isLoadingMore = (state) => getUiStatus(state).loadMoreCount > 0;

export const getCurrentFolderId = (state) => getUiStatus(state).currentFolderId;

export const getCurrentMessageListFocus = (state) => getUiStatus(state).keyboardFocus.messageList;

export const getCurrentSenderListFocus = (state) => getUiStatus(state).keyboardFocus.senderList;

export const getCurrentFolderListFocus = (state) => getUiStatus(state).keyboardFocus.folderList;

export const getCurrentApp = createSelector(getUiStatus, (uiStatus) => uiStatus.currentApp);

export const isQuarantineApp = createSelector(getCurrentApp, (appName) => appName === APP_NAMES.quarantine);

export const isSecureMessageCenterApp = createSelector(getCurrentApp, (appName) => appName === APP_NAMES.keyManagement);

export const getEncryptionAnimationDirection = createSelector(
  getUiStatus,
  (uiStatus) => uiStatus.encryptionAnimationDirection
);

export const getShowSingleMessageActionsStatus = createSelector(
  getUiStatus,
  (uiStatus) => uiStatus.showSingleMessageActions
);
