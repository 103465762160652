import React, { Component, createRef } from 'react';
import { injectIntl } from 'react-intl';
import AppsIcon from '@material-ui/icons/Apps';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import RootRef from '@material-ui/core/RootRef';
import cx from 'classnames';

import './AppSwitcher.scss';
import { renderString } from '../../utils/renderUtils';
import withPrimaryStyles from '../withPrimaryStyles';

class AppSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAppSwitcherOpen: false
    };
    this.openedSwitcherRef = createRef();
    this.closedSwitcherRef = createRef();
  }

  componentDidUpdate() {
    const { isAppSwitcherOpen } = this.state;
    setTimeout(() => {
      if (isAppSwitcherOpen && this.openedSwitcherRef.current) {
        this.openedSwitcherRef.current.focus();
      }
    }, 100);
  }

  handleOnClick(onClickAction) {
    onClickAction();
    this.onClickAppSwitcher();
  }

  onClickAppSwitcher() {
    const { isAppSwitcherOpen } = this.state;
    this.setState({ isAppSwitcherOpen: !isAppSwitcherOpen });
    const { handleRequestOpen } = this.props;
    handleRequestOpen();
  }

  render() {
    const { appOptions, classes, intl } = this.props;
    const { isAppSwitcherOpen } = this.state;
    return (
      <>
        <RootRef rootRef={this.closedSwitcherRef}>
          <IconButton
            color="inherit"
            aria-label="Apps Switcher"
            className={`app-switcher ${classes.darkBackgroundColor}`}
            onClick={() => this.onClickAppSwitcher()}
            button="true"
          >
            <AppsIcon />
          </IconButton>
        </RootRef>
        <Drawer
          open={isAppSwitcherOpen}
          onClose={() => this.onClickAppSwitcher()}
          PaperProps={{
            'aria-label': intl.formatMessage({ id: 'app.switcher.applications.header' }),
            style: {
              width: 320
            }
          }}
          className="app-switcher-container"
        >
          <RootRef rootRef={this.openedSwitcherRef}>
            <IconButton
              color="inherit"
              aria-label="Apps Switcher"
              className={`app-switcher ${classes.secondaryColor} ${classes.focusedState}`}
              onClick={() => this.onClickAppSwitcher()}
              button="true"
            >
              <AppsIcon />
            </IconButton>
          </RootRef>
          {appOptions[0].header && (
            <h1 className="app-switcher-content__header app-switcher-content__item">{appOptions[0].header}</h1>
          )}
          <List className="app-switcher-content">
            {appOptions.map((app) => (
              <div className="app-switcher-content__section" key={`app-switcher-content__section-${app.header}`}>
                {app.options.map((option) => {
                  const appliedClasses = cx('app-switcher-content__item', {
                    'app-switcher-content__item--selected': option.selected
                  });
                  return (
                    <React.Fragment key={`app-switcher-content__item-${option.text}`}>
                      {option.selected && (
                        <ListItem
                          className={`${appliedClasses} ${classes.selectedState}`}
                          onClick={() => this.handleOnClick(option.handleOnClick)}
                          aria-label={option.text}
                          aria-current
                          button
                        >
                          <ListItemIcon className={`app-switcher-content__icon ${classes.secondaryColor}`}>
                            {option.icon}
                          </ListItemIcon>
                          <ListItemText className="app-switcher-content__text" primary={renderString(option.textId)} />
                        </ListItem>
                      )}
                      {!option.selected && (
                        <ListItem
                          className={appliedClasses}
                          onClick={() => this.handleOnClick(option.handleOnClick)}
                          aria-label={option.text}
                          button
                        >
                          <ListItemIcon className="app-switcher-content__icon">{option.icon}</ListItemIcon>
                          <ListItemText className="app-switcher-content__text" primary={renderString(option.textId)} />
                        </ListItem>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            ))}
          </List>
        </Drawer>
      </>
    );
  }
}

AppSwitcher.defaultProps = {
  handleRequestOpen: null
};

AppSwitcher.propTypes = {
  handleRequestOpen: PropTypes.func,
  appOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        icon: PropTypes.object,
        text: PropTypes.string,
        handleOnClick: PropTypes.func,
        selected: PropTypes.bool
      })
    ])
  ).isRequired,
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default injectIntl(withPrimaryStyles(AppSwitcher));
