import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCurrentPathLocation, getCurrentPathLocationWithoutMsgId } from '../../ducks/routerReducer';
import { getTotalMessagesFromState } from '../../Encryption/MessageList/ducks/MessageListReducer';
import { isQuarantineApp } from '../../ducks/uiStatusReducer';
import { navigateToFolder, navigateToSecureMessageCenter } from '../ducks/sideBarActions';
import SideBarFolderListItem from './SideBarFolderListItem';

class SideBarFolderListItemContainer extends PureComponent {
  render() {
    const { path, folderId, totalMessageCount, folderOnClick, secureMessageCenterOnClick } = this.props;
    return (
      <SideBarFolderListItem
        path={path}
        folderId={folderId}
        totalMessageCount={totalMessageCount}
        folderOnClick={folderOnClick}
        secureMessageCenterOnClick={secureMessageCenterOnClick}
      />
    );
  }
}

SideBarFolderListItemContainer.defaultProps = {
  folderId: null,
  totalMessageCount: 0
};

SideBarFolderListItemContainer.propTypes = {
  path: PropTypes.string.isRequired,
  folderId: PropTypes.string,
  totalMessageCount: PropTypes.number,
  folderOnClick: PropTypes.func.isRequired,
  secureMessageCenterOnClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const isQuarantine = isQuarantineApp(state);
  if (isQuarantine) {
    return {
      path: getCurrentPathLocationWithoutMsgId(state)
    };
  }
  return {
    path: getCurrentPathLocation(state),
    totalMessageCount: getTotalMessagesFromState(state)
  };
};

const mapDispatchToProps = () => ({
  folderOnClick: navigateToFolder,
  secureMessageCenterOnClick: navigateToSecureMessageCenter
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(SideBarFolderListItemContainer);
