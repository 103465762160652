import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './SideBarListItem.scss';
import { qaTags } from '../../constants/qaConstants';

const SideBarListItem = ({ leftIcon, leftText, rightText, onClick, isSelected }) => {
  const classnames = cx('sidebarlistitem', {
    'sidebarlistitem--selected': isSelected
  });
  return (
    <ListItem button name={qaTags.SIDEBAR_LIST_ITEM} onClick={onClick} className={classnames} disableRipple>
      {leftIcon}
      {leftText}
      {rightText}
    </ListItem>
  );
};

SideBarListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  leftIcon: PropTypes.node.isRequired,
  leftText: PropTypes.node,
  rightText: PropTypes.node
};

SideBarListItem.defaultProps = {
  leftText: undefined,
  rightText: undefined,
  isSelected: false
};

export default SideBarListItem;
