import { sideBarTypes } from './sideBarTypes';

export function navigateToFolder(folderId) {
  return {
    type: sideBarTypes.NAVIGATE_TO_FOLDER,
    folderId
  };
}

export function navigateToSecureMessageCenter() {
  return {
    type: sideBarTypes.NAVIGATE_TO_SECURE_MESSAGE_CENTER
  };
}

export function navigateToLandingPage() {
  return {
    type: sideBarTypes.NAVIGATE_TO_LANDING_PAGE
  };
}

export const increaseFolderCount = (count, folderId) => ({
  type: sideBarTypes.INCREASE_FOLDER_COUNT,
  count,
  folderId
});

export const decreaseFolderCount = (count, folderId) => ({
  type: sideBarTypes.DECREASE_FOLDER_COUNT,
  count,
  folderId
});

export const decreaseFolderUnreadCount = (folderId, count) => ({
  type: sideBarTypes.DECREASE_FOLDER_READ_COUNT,
  folderId,
  count
});

export function logout() {
  return {
    type: sideBarTypes.LOGOUT
  };
}
