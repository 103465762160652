export const sideBarTypes = Object.freeze({
  NAVIGATE_TO_FOLDER: 'NAVIGATE_TO_FOLDER',
  NAVIGATE_TO_SECURE_MESSAGE_CENTER: 'NAVIGATE_TO_SECURE_MESSAGE_CENTER',
  NAVIGATE_TO_LANDING_PAGE: 'NAVIGATE_TO_LANDING_PAGE',
  INCREASE_FOLDER_COUNT: 'INCREASE_FOLDER_COUNT',
  DECREASE_FOLDER_COUNT: 'DECREASE_FOLDER_COUNT',
  DECREASE_FOLDER_READ_COUNT: 'DECREASE_FOLDER_READ_COUNT',
  LOGOUT: 'LOGOUT'
});

export default sideBarTypes;
