import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './SummaryCard.scss';
import { renderString } from '../../utils/renderUtils';
import ActionButton from '../ActionButton';
import HelperText from '../HelperText';

const SummaryCard = ({ actionButton, content, cardTitleId, greyCardTitle }) => {
  const summaryCardTitleClass = cx('summary-card__title', {
    'summary-card__title--grey': greyCardTitle
  });

  return (
    <Paper className="summary-card">
      <div className={summaryCardTitleClass}>
        <HelperText title={renderString(cardTitleId)} />
      </div>
      <div className="summary-card__content">{content}</div>
      {actionButton && (
        <ActionButton
          name={actionButton.buttonText}
          allowKeyboardTab={false}
          onClick={actionButton.handleOnClick}
          tooltip={actionButton.buttonText}
          tooltipPosition="bottom"
          type="summary-card-button"
          variant="text"
        />
      )}
    </Paper>
  );
};

SummaryCard.defaultProps = {
  actionButton: null,
  cardTitleId: '',
  greyCardTitle: false
};

SummaryCard.propTypes = {
  content: PropTypes.object.isRequired,
  actionButton: PropTypes.shape({
    buttonText: PropTypes.string,
    handleOnClick: PropTypes.func
  }),
  cardTitleId: PropTypes.string,
  greyCardTitle: PropTypes.bool
};

export default SummaryCard;
