import groupBy from 'lodash/groupBy';
import moment from 'moment';

const TODAY = moment().startOf('day');
export const LAST_HOUR = moment().subtract(1, 'hour');
export const LAST_4_HOURS = moment().subtract(4, 'hour');
export const LAST_12_HOURS = moment().subtract(12, 'hour');
export const LAST_DAY = moment().subtract(1, 'days');
export const YESTERDAY = moment()
  .subtract(1, 'days')
  .startOf('day');
export const A_WEEK_OLD = moment()
  .subtract(6, 'days')
  .startOf('day');
const TWO_WEEK_OLD = moment()
  .subtract(13, 'days')
  .startOf('day');
const THREE_WEEK_OLD = moment()
  .subtract(20, 'days')
  .startOf('day');
const FOUR_WEEK_OLD = moment()
  .subtract(27, 'days')
  .startOf('day');
const START_OF_LAST_MONTH = moment()
  .subtract(1, 'months')
  .startOf('month');
const IS_THIS_YEAR = moment().startOf('year');
export const THIRTY_DAYS_OLD = moment()
  .subtract(30, 'days')
  .startOf('day');

export function isToday(date) {
  const momentDate = moment(date);
  return date && momentDate.isSame(TODAY, 'd');
}
export function isYesterday(date) {
  const momentDate = moment(date);
  return date && momentDate.isSame(YESTERDAY, 'd');
}
export function isLessThanAWeekOld(date) {
  const momentDate = moment(date);
  return date && momentDate.isSameOrAfter(A_WEEK_OLD);
}
export function isLessThan2WeeksOld(date) {
  const momentDate = moment(date);
  return date && momentDate.isSameOrAfter(TWO_WEEK_OLD);
}
export function isLessThan3WeeksOld(date) {
  const momentDate = moment(date);
  return date && momentDate.isSameOrAfter(THREE_WEEK_OLD);
}
export function isLessThan4WeeksOld(date) {
  const momentDate = moment(date);
  return date && momentDate.isSameOrAfter(FOUR_WEEK_OLD);
}
export function isLessThanAMonthOld(date) {
  const momentDate = moment(date);
  return date && momentDate.isSameOrAfter(START_OF_LAST_MONTH);
}
export function isThisYear(date) {
  const momentDate = moment(date);
  return date && momentDate.isSameOrAfter(IS_THIS_YEAR);
}

export function getRelativeString(date) {
  if (isToday(date)) {
    return 'dates.today';
  }
  if (isYesterday(date)) {
    return 'dates.yesterday';
  }
  if (isLessThanAWeekOld(date)) {
    return 'dates.lastWeek';
  }
  if (isLessThan2WeeksOld(date)) {
    return 'dates.twoWeeksAgo';
  }
  if (isLessThan3WeeksOld(date)) {
    return 'dates.threeWeeksAgo';
  }
  if (isLessThanAMonthOld(date)) {
    return 'dates.lastMonth';
  }
  if (isThisYear(date)) {
    return 'dates.thisYear';
  }
  return 'dates.overAYear';
}

export function getNewExpiryTime(isHours, value) {
  const dateTime = moment();
  if (isHours) {
    return dateTime.add(value, 'hour').unix();
  }
  return dateTime.add(value, 'day').unix();
}

export function isFutureExpiry(expiryDate) {
  return expiryDate > 0 && expiryDate > moment().unix();
}

export function getTotalQuarantineDateGroups(messages) {
  const messageGroups = groupBy(messages, (message) => getRelativeString(message.date));
  return Object.keys(messageGroups).length;
}

export function getTotalDateGroups(messages) {
  const messageGroups = groupBy(messages, (message) => getRelativeString(message.createTime * 1000));
  return Object.keys(messageGroups).length;
}

export function flattenMsgsByDate(messages) {
  const flattenedArray = [];
  Object.keys(messages).forEach((date) => {
    // Dont append date if there are no messages associated with it
    if (Object.keys(messages[date]).length === 0) {
      return;
    }
    // Add group header
    flattenedArray[date] = { group: date };
    // Add child messages associated with that date
    Object.keys(messages[date]).forEach((key) => {
      const messageObject = messages[date][key];
      const { guid } = messageObject;
      if (messageObject === undefined) {
        return;
      }
      flattenedArray[guid] = messageObject;
    });
  });

  return { ...flattenedArray };
}

export function groupQuarantineMsgsByDate(messages) {
  return flattenMsgsByDate(groupBy(messages, (message) => getRelativeString(message.date)));
}

export function groupEncryptedMsgsByDate(messages) {
  return flattenMsgsByDate(groupBy(messages, (message) => getRelativeString(message.createTime * 1000)));
}
