import { FormattedMessage } from 'react-intl';
import Button from '@proofpoint/fuse-ui/components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@proofpoint/fuse-ui/components/Typography';

import './OptionSelectSenderListItem.scss';
import ProfileTile from '../../../ProfileTile';
import withPrimaryStyles from '../../../../common/withPrimaryStyles';

function OptionSelectSenderListItem({ id, configProperty, closeFunc, editConfigFunc, deleteConfigFunc, classes }) {
  return (
    <ProfileTile header={id} backFunc={closeFunc}>
      <Typography variant="button" color="inherit" className="senderListItemOptions">
        <FormattedMessage id="settings.allowList.options.long" />
      </Typography>
      <Button name="edit" variant="text" className="optionsSenderListItemButton" fullWidth onClick={editConfigFunc}>
        <FormattedMessage id="settings.allowList.edit" />
      </Button>
      <Button
        name="delete"
        variant="text"
        className={`optionsSenderListItemButton ${classes.errorColor}`}
        fullWidth
        onClick={deleteConfigFunc}
      >
        <FormattedMessage
          id={configProperty === 'safeList' ? 'settings.allowList.remove' : 'settings.blockList.remove'}
        />
      </Button>
    </ProfileTile>
  );
}

OptionSelectSenderListItem.propTypes = {
  id: PropTypes.string.isRequired,
  configProperty: PropTypes.string.isRequired,
  editConfigFunc: PropTypes.func.isRequired,
  deleteConfigFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(OptionSelectSenderListItem);
