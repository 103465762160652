import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isFutureExpiry } from '../../../utils/dateUtils';
import { restoreUser, revokeUser, updateExpiry } from '../ducks/MessageOptionsActions';
import ExpiryOptions from './ExpiryOptions';
import ExpiryOptionsMobile from './Mobile/ExpiryOptionsMobile';

class ExpiryOptionsContainer extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.closeExpiryOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.closeExpiryOnResize);
  }

  handleExpirySelection = (expiryTime) => {
    const { updateExpiryDispatch, encryptedMessageGuid, toggleMessageActions, currentExpiry } = this.props;
    const params = {
      guid: encryptedMessageGuid,
      newExpireTime: expiryTime,
      currentExpireTime: currentExpiry
    };
    updateExpiryDispatch(params);
    toggleMessageActions();
  };

  handleMobileExpirySelection = (expiryTime) => {
    const {
      updateExpiryDispatch,
      encryptedMessageGuid,
      currentExpiry,
      toggleMobileExpiryMenu,
      handleViewAnimation
    } = this.props;
    // Close dialog and return to the message list view
    handleViewAnimation();
    const params = {
      guid: encryptedMessageGuid,
      newExpireTime: expiryTime,
      currentExpireTime: currentExpiry
    };
    updateExpiryDispatch(params);
    toggleMobileExpiryMenu();
  };

  handleMessageStatus = () => {
    const {
      activeRecipients,
      revokedRecipients,
      revokeUserDispatch,
      restoreUserDispatch,
      encryptedMessageGuid,
      toggleMessageActions,
      isRevoked
    } = this.props;

    const recipsToUpdate = [];
    toggleMessageActions();

    if (isRevoked) {
      revokedRecipients.forEach((recipient) => {
        recipsToUpdate[recipient.email] = recipient.email;
      });
      return restoreUserDispatch({
        guid: encryptedMessageGuid,
        selectedEmails: recipsToUpdate,
        showNotification: true,
        updateMessageStatus: true
      });
    }

    activeRecipients.forEach((recipient) => {
      recipsToUpdate[recipient.email] = recipient.email;
    });

    return revokeUserDispatch({
      guid: encryptedMessageGuid,
      selectedEmails: recipsToUpdate,
      showNotification: true,
      updateMessageStatus: true
    });
  };

  closeExpiryOnResize = () => {
    const { toggleMessageActions, showExpiryOptions } = this.props;
    if (window.innerWidth < 600 && showExpiryOptions) {
      toggleMessageActions();
    }
  };

  render() {
    const {
      showExpiryOptions,
      toggleMessageActions,
      toggleMobileExpiryMenu,
      anchorEl,
      currentExpiry,
      encryptedMessageSubject,
      showMobileExpiryOptions,
      isRevoked
    } = this.props;
    const isExpired = !isFutureExpiry(currentExpiry) && currentExpiry !== 0;
    return (
      <>
        <Hidden xsDown>
          <ExpiryOptions
            isOpen={showExpiryOptions}
            toggleExpiryDropdown={toggleMessageActions}
            anchorEl={anchorEl}
            updateExpiryDispatch={this.handleExpirySelection}
            handleMessageStatus={this.handleMessageStatus}
            isExpired={isExpired}
            isRevoked={isRevoked}
          />
        </Hidden>
        <Hidden smUp>
          <ExpiryOptionsMobile
            isOpen={showMobileExpiryOptions}
            toggleMessageActions={toggleMobileExpiryMenu}
            updateExpiryDispatch={this.handleMobileExpirySelection}
            handleMessageStatus={this.handleMessageStatus}
            currentExpiry={currentExpiry}
            encryptedMessageSubject={encryptedMessageSubject}
            isRevoked={isRevoked}
          />
        </Hidden>
      </>
    );
  }
}

ExpiryOptionsContainer.defaultProps = {
  encryptedMessageGuid: '',
  anchorEl: null,
  activeRecipients: [],
  revokedRecipients: []
};

ExpiryOptionsContainer.propTypes = {
  showExpiryOptions: PropTypes.bool.isRequired,
  showMobileExpiryOptions: PropTypes.bool.isRequired,
  toggleMessageActions: PropTypes.func.isRequired,
  toggleMobileExpiryMenu: PropTypes.func.isRequired,
  revokeUserDispatch: PropTypes.func.isRequired,
  restoreUserDispatch: PropTypes.func.isRequired,
  encryptedMessageGuid: PropTypes.string,
  anchorEl: PropTypes.object,
  updateExpiryDispatch: PropTypes.func.isRequired,
  activeRecipients: PropTypes.arrayOf(PropTypes.object),
  revokedRecipients: PropTypes.arrayOf(PropTypes.object),
  currentExpiry: PropTypes.number.isRequired,
  encryptedMessageSubject: PropTypes.string.isRequired,
  isRevoked: PropTypes.bool.isRequired,
  handleViewAnimation: PropTypes.func.isRequired
};

export default withRouter(
  connect(
    null,
    {
      updateExpiryDispatch: updateExpiry,
      revokeUserDispatch: revokeUser,
      restoreUserDispatch: restoreUser
    }
  )(ExpiryOptionsContainer)
);
