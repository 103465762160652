import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import Send from '@material-ui/icons/Send';
import Visibility from '@material-ui/icons/Visibility';

import './MessageActivity.scss';
import { getRelativeString, isToday, isYesterday } from '../../utils/dateUtils';
import { renderDateShort, renderString, renderTime } from '../../utils/renderUtils';
import HelperText from '../../common/HelperText';
import withPrimaryStyles from '../../common/withPrimaryStyles';

function renderActivityTimes(messageActivity, isReady, primaryColor) {
  return (
    <div className="message-activity--left">
      {Object.keys(messageActivity).map((key) => (
        <div className="message-activity--left__item" key={`message-activity--left__item--${key}`}>
          {(isToday(messageActivity[key].activityTime) || isYesterday(messageActivity[key].activityTime)) && (
            <ReactPlaceholder type="text" rows={2} ready={isReady} className="placeholder placeholder--timeline-date">
              <HelperText
                title={renderString(getRelativeString(messageActivity[key].activityTime))}
                subtitle={renderTime(messageActivity[key].activityTime)}
              />
            </ReactPlaceholder>
          )}
          {!isToday(messageActivity[key].activityTime) && !isYesterday(messageActivity[key].activityTime) && (
            <ReactPlaceholder type="text" rows={2} ready={isReady} className="placeholder placeholder--timeline-date">
              <HelperText
                title={renderDateShort(messageActivity[key].activityTime)}
                subtitle={renderTime(messageActivity[key].activityTime)}
              />
            </ReactPlaceholder>
          )}
          {messageActivity.length - 1 === parseInt(key, 10) && (
            <div className="message-activity-icon message-activity-icon--sent">
              <ReactPlaceholder type="round" ready={isReady} className="placeholder placeholder--timeline-avatar">
                <Send className={primaryColor} />
              </ReactPlaceholder>
            </div>
          )}
          {messageActivity.length - 1 !== parseInt(key, 10) && (
            <div className="message-activity-icon">
              <ReactPlaceholder type="round" ready={isReady} className="placeholder placeholder--timeline-avatar">
                <Visibility className={primaryColor} />
              </ReactPlaceholder>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function renderActivityDescription(messageActivity, isReady) {
  return (
    <div className="message-activity--right">
      {Object.keys(messageActivity).map((key) => (
        <div className="message-activity--right__item" key={`message-activity--right__item--${key}`}>
          <Paper className="message-activity--right__item__container">
            {!messageActivity[key].activitySubTitle && (
              <ReactPlaceholder type="text" rows={1} ready={isReady} className="placeholder placeholder--timeline-row">
                <HelperText title={renderString(messageActivity[key].activityTitle)} />
              </ReactPlaceholder>
            )}
            {messageActivity[key].activitySubTitle && (
              <ReactPlaceholder type="text" rows={2} ready={isReady} className="placeholder placeholder--timeline-row">
                <HelperText
                  title={messageActivity[key].activityTitle}
                  subtitle={renderString(messageActivity[key].activitySubTitle)}
                />
              </ReactPlaceholder>
            )}
          </Paper>
        </div>
      ))}
    </div>
  );
}

const MessageActivity = ({ isReady, messageActivity, classes }) => (
  <>
    <h3 className="desktop-heading">{renderString('encryption.message.activity.title')}</h3>
    <div className="message-activity">
      {renderActivityTimes(messageActivity, isReady, classes.secondaryColor)}
      {renderActivityDescription(messageActivity, isReady)}
    </div>
  </>
);

MessageActivity.propTypes = {
  isReady: PropTypes.bool.isRequired,
  messageActivity: PropTypes.arrayOf(
    PropTypes.shape({
      activityTitle: PropTypes.string,
      activitySubTitle: PropTypes.string,
      activityTime: PropTypes.number
    })
  ).isRequired,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(MessageActivity);
