import Cookies from 'js-cookie';

import { cookiesConstant } from '../ducks/appConstants';

export function setPPRememberMeCookie(email) {
  if (process.env.NODE_ENV !== 'production') {
    Cookies.set(cookiesConstant.PP_REMEMBER_EMAIL, email, {
      path: '/',
      expires: 30,
      domains: window.location.host.toString()
    });
  } else {
    Cookies.set(cookiesConstant.PP_REMEMBER_EMAIL, email, {
      path: '/',
      expires: 30,
      domains: window.location.host.toString(),
      Secure: true
    });
  }
}

export function getPPRememberMeCookie() {
  return Cookies.get(cookiesConstant.PP_REMEMBER_EMAIL);
}

export function clearAllCookies() {
  Cookies.remove(cookiesConstant.PP_REMEMBER_EMAIL, { path: '/' });
}
