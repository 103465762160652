
import MenuItem from '@material-ui/core/MenuItem';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withPrimaryStyles from '../withPrimaryStyles';
import { isEmpty } from '../../utils/strUtils';
import './DelegateMenu.scss';


class DelegateMenu extends Component {  
  render() {
    const { delegatedUsers, handleClose, managedDelegate, setManagingDelegateDispatch, userEmailAddress, fetchProfileDispatch } = this.props; 
    const isManagingDelegate = !isEmpty(managedDelegate);
    return ( delegatedUsers.map((delegatedUser) => {
        // Visually split actions into groups              
          return <MenuItem
            key={`action-${delegatedUser}`}
            onClick={() => {
              // Dispatch setManagingDelegate only when clicked on a different delegate than the one being managed.
              if(!isManagingDelegate || (isManagingDelegate && (managedDelegate.email!== delegatedUser.email)) ){
                setManagingDelegateDispatch(delegatedUser);
              }
              fetchProfileDispatch(userEmailAddress);
              handleClose();
            }}         
            className='delegate-menu'        
            role="option"
          >
            {delegatedUser.displayName}
          </MenuItem>        
      }))    
  }
}

DelegateMenu.defaultProps = {
  userEmailAddress: '',  
  delegatedUsers: [],
  managedDelegate: {}
};

DelegateMenu.propTypes = {
  userEmailAddress: PropTypes.string,
  fetchProfileDispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired, 
  delegatedUsers: PropTypes.arrayOf(PropTypes.string),
  managedDelegate: PropTypes.object,
  setManagingDelegateDispatch: PropTypes.func.isRequired,
};

export default withPrimaryStyles(DelegateMenu);