import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import cx from 'classnames';

import './MessageList.scss';
import { apiActions, apiConstants } from '../../ducks/apiConstants';
import {
  getCurrentGuid,
  getEncryptedMessagesByDate,
  getSelectedMessageGuids,
  getTotalRows
} from './ducks/MessageListReducer';
import { getRecipientsEmail } from '../../utils/encryptionUtils';
import { messageFilterStatus } from '../../constants/encryptionConstants';
import { renderString } from '../../utils/renderUtils';
import { restoreMessages, revokeMessages } from '../MessageOptions/ducks/MessageOptionsActions';
import { retrieveEncryptedMessages, setMessageReadyStatus, setSelectedGuids } from './ducks/MessageListActions';
import { toggleMobileSearch } from '../../ducks/uiStatusActions';
import MessageActions from '../../common/Inbox/MessageActions';
import MessageFilter from '../../common/Inbox/MessageFilter';
import MessageList from './MessageList';
import MessageSearch from '../../common/Inbox/MessageSearch/MessageSearch';

const STATUS_LOADING = 1;
const STATUS_LOADED = 2;
const WAIT_INTERVAL = 500;

class MessageListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedRowCount: 0,
      loadedRowsMap: {},
      loadingRowCount: 0,
      searchString: '',
      isSearching: false,
      messageType: apiActions.FETCH_ALL_ENCRYPTED_MESSAGES,
      messageStatus: messageFilterStatus.ALL
    };
    this.timeoutIdMap = {};
    this.searchTimeout = null;
  }

  componentDidUpdate(prevProps) {
    const { handleOnMessageClick, isReady, history, encryptedMessages } = this.props;

    // Determine the first GUID from state
    const nextFirstGuid = Object.keys(encryptedMessages)[1];
    const urlHash = history.location.hash;
    if (!urlHash || isReady !== prevProps.isReady) {
      // Open first message on page load and post search
      handleOnMessageClick(nextFirstGuid, false);
    }
  }

  static getDerivedStateFromProps(props) {
    const { isReady } = props;

    // Messages exist in state so filter or search has results
    if (isReady) {
      return {
        isSearching: false
      };
    }
    return null;
  }

  componentWillUnmount() {
    Object.keys(this.timeoutIdMap).forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
  }

  handleMessageFilterChange = (event, status) => {
    const { retrieveEncryptedMessagesDispatch, setMessageReadyStatusDispatch } = this.props;
    const { searchString, loadedRowsMap } = this.state;
    const actionType = `FETCH_${status}_ENCRYPTED_MESSAGES`;

    // Display placeholders
    setMessageReadyStatusDispatch();

    // Update state
    this.setState({
      messageStatus: status,
      messageType: actionType,
      isSearching: true
    });

    if (Object.keys(loadedRowsMap).length > apiConstants.pageSize) {
      this.setState({
        loadedRowsMap: {}
      });
    }

    // Fetch messages
    retrieveEncryptedMessagesDispatch({
      offset: 0,
      type: actionType,
      status,
      searchString
    });
  };

  handleListSearch = (searchString) => {
    // Prevent search if the user has no sent messages
    const { totalSearchResults } = this.props;
    if (totalSearchResults === 0) {
      return;
    }
    // Clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than WAIT_INTERVAL
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    // Set new timeout that waits for user to stop typing before sending request
    this.searchTimeout = setTimeout(() => {
      this.handleSearchResults(searchString);
    }, WAIT_INTERVAL);
  };

  handleClearSearch = () => {
    this.setState({ searchString: '' });
    this.handleSearchResults('');
  };

  handleCancelSearch = () => {
    const { toggleMobileSearchDispatch } = this.props;
    this.handleClearSearch();
    return toggleMobileSearchDispatch();
  };

  handleSearchValue = (searchString) => {
    if (searchString === '') {
      this.handleClearSearch();
    }
    this.setState({ searchString });
  };

  handleCancelSelected = () => {
    const { setSelectedGuidsDispatch, toggleSingleMessageActions } = this.props;
    setSelectedGuidsDispatch({});
    toggleSingleMessageActions(true);
  };

  handleRevokeMessage = () => {
    const { revokeMessageDispatch, encryptedMessages, selectedMessages } = this.props;
    // Validation
    if (Object.keys(selectedMessages).length === 0) {
      return;
    }

    // Build a map of selected guids and their recipients
    const guidsMap = {};
    Object.keys(selectedMessages).forEach((key) => {
      const { guid, active, recipients } = encryptedMessages[key];
      if (active) {
        guidsMap[guid] = getRecipientsEmail(recipients);
      }
    });

    if (Object.keys(guidsMap).length > 0) {
      revokeMessageDispatch({
        guids: guidsMap
      });
    }

    // Clear selected keys
    this.handleCancelSelected();
  };

  handleRestoreMessage = () => {
    const { restoreMessageDispatch, encryptedMessages, selectedMessages } = this.props;

    // Validation
    if (Object.keys(selectedMessages).length === 0) {
      return;
    }

    // Build a map of selected guids and their recipients
    const guidsMap = {};
    Object.keys(selectedMessages).forEach((key) => {
      const { guid, active, recipients } = encryptedMessages[key];
      if (!active) {
        guidsMap[guid] = getRecipientsEmail(recipients);
      }
    });

    if (Object.keys(guidsMap).length > 0) {
      restoreMessageDispatch({
        guids: guidsMap
      });
    }

    // Clear selected keys
    this.handleCancelSelected();
  };

  handleSearchResults = (searchString) => {
    // Prevent lazy search by only allowing strings over 3 chars to be searchable
    if (searchString.length > 0 && searchString.length < 3) {
      return;
    }

    const { retrieveEncryptedMessagesDispatch, setMessageReadyStatusDispatch } = this.props;
    const { messageType, messageStatus, loadedRowsMap } = this.state;

    // Display placeholders
    setMessageReadyStatusDispatch();

    this.setState({
      searchString,
      isSearching: true
    });

    if (Object.keys(loadedRowsMap).length > apiConstants.pageSize) {
      this.setState({
        loadedRowsMap: {}
      });
    }

    retrieveEncryptedMessagesDispatch({
      offset: 0,
      type: messageType,
      searchString,
      status: messageStatus
    });
  };

  isRowLoaded = ({ index }) => {
    const { loadedRowsMap } = this.state;
    return Boolean(loadedRowsMap[index]); // STATUS_LOADING or STATUS_LOADED
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    const { retrieveEncryptedMessagesDispatch } = this.props;
    const { loadedRowsMap, loadingRowCount, searchString, messageType, messageStatus } = this.state;
    const increment = stopIndex - startIndex + 1;

    // Fetch more messages
    if (startIndex !== 0) {
      retrieveEncryptedMessagesDispatch({
        offset: startIndex,
        type: messageType,
        searchString,
        status: messageStatus
      });
    }

    // Mark next batch of messages as loading
    for (let i = startIndex; i <= stopIndex; i += 1) {
      loadedRowsMap[i] = STATUS_LOADING;
    }

    this.setState({
      loadingRowCount: loadingRowCount + increment
    });

    let promiseResolver;

    const timeoutId = setTimeout(() => {
      const { loadedRowCount } = this.state;

      delete this.timeoutIdMap[timeoutId];

      // Mark messages as loaded
      for (let i = startIndex; i <= stopIndex; i += 1) {
        loadedRowsMap[i] = STATUS_LOADED;
      }

      this.setState({
        loadingRowCount: loadingRowCount - increment,
        loadedRowCount: loadedRowCount + increment,
        loadedRowsMap
      });

      promiseResolver();
    }, WAIT_INTERVAL);

    this.timeoutIdMap[timeoutId] = true;

    return new Promise((resolve) => {
      promiseResolver = resolve;
    });
  };

  render() {
    const {
      totalRows,
      encryptedMessages,
      viewAnimateDirection,
      isEmpty,
      isReady,
      currentGuid,
      showMobileSearch,
      selectedMessages,
      totalSearchResults,
      encryptedMessageCount,
      toggleSingleMessageActions,
      handleOnMessageClick,
      setSelectedGuidsDispatch
    } = this.props;
    const { messageStatus, searchString, isSearching } = this.state;

    const messageListClass = cx('message-list', {
      'message-list--slideLeft': viewAnimateDirection === 'left',
      'message-list--fadeOut': viewAnimateDirection === 'right'
    });

    return (
      <aside role="list" className={messageListClass}>
        {/* Search */}
        <MessageSearch
          handleListSearch={this.handleListSearch}
          handleClearSearch={this.handleClearSearch}
          handleSearchValue={this.handleSearchValue}
          handleCancelSearch={this.handleCancelSearch}
          searchString={searchString}
          showMobileSearch={showMobileSearch}
          placeholderString="encryption.search.placeholder"
          placeholderSuggestion="encryption.search.suggestion"
        />
        <div className="list-row">
          {/* Message Filter */}
          {encryptedMessageCount > 0 && (
            <MessageFilter messageStatus={messageStatus} handleMessageFilterChange={this.handleMessageFilterChange} />
          )}
          {/* Message Actions */}
          <Hidden xsDown>
            <MessageActions
              wrapperClass="list__selectedRows"
              commands={[
                messageStatus !== messageFilterStatus.REVOKED
                  ? {
                      command: 'revoke',
                      intlId: 'encryption.revoke.action'
                    }
                  : {},
                messageStatus !== messageFilterStatus.ACTIVE
                  ? {
                      command: 'restore',
                      intlId: 'encryption.restore.action'
                    }
                  : {}
              ]}
            />
          </Hidden>
        </div>
        {/* Empty State */}
        {isEmpty && isReady && (
          <>
            <section className="list__noResults">
              <WarningIcon />
              {encryptedMessageCount > totalSearchResults || isSearching
                ? renderString('encryption.message.search.error')
                : renderString('encryption.message.error')}
            </section>
          </>
        )}
        <div className="message-list__infinite">
          {/* Message List */}
          <MessageList
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={!isReady && isEmpty ? encryptedMessageCount : totalRows}
            currentGuid={currentGuid}
            isSearching={!isReady}
            selectedMessages={selectedMessages}
            isReady={isReady}
            encryptedMessages={encryptedMessages}
            toggleSingleMessageActions={toggleSingleMessageActions}
            handleOnMessageClick={handleOnMessageClick}
            setSelectedGuids={setSelectedGuidsDispatch}
          />
        </div>
      </aside>
    );
  }
}

MessageListContainer.defaultProps = {
  showMobileSearch: false,
  selectedMessages: {}
};

MessageListContainer.propTypes = {
  handleOnMessageClick: PropTypes.func.isRequired,
  encryptedMessages: PropTypes.object.isRequired,
  currentGuid: PropTypes.string.isRequired,
  viewAnimateDirection: PropTypes.string.isRequired,
  retrieveEncryptedMessagesDispatch: PropTypes.func.isRequired,
  totalSearchResults: PropTypes.number.isRequired,
  isReady: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  showMobileSearch: PropTypes.bool,
  revokeMessageDispatch: PropTypes.func.isRequired,
  restoreMessageDispatch: PropTypes.func.isRequired,
  toggleMobileSearchDispatch: PropTypes.func.isRequired,
  toggleSingleMessageActions: PropTypes.func.isRequired,
  setSelectedGuidsDispatch: PropTypes.func.isRequired,
  selectedMessages: PropTypes.object,
  setMessageReadyStatusDispatch: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  encryptedMessageCount: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.encryptedMessages = getEncryptedMessagesByDate(state);
  props.selectedMessages = getSelectedMessageGuids(state);
  props.totalRows = getTotalRows(state);
  props.currentGuid = getCurrentGuid(state);
  return props;
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      retrieveEncryptedMessagesDispatch: retrieveEncryptedMessages,
      revokeMessageDispatch: revokeMessages,
      restoreMessageDispatch: restoreMessages,
      toggleMobileSearchDispatch: toggleMobileSearch,
      setSelectedGuidsDispatch: setSelectedGuids,
      setMessageReadyStatusDispatch: setMessageReadyStatus
    }
  )(MessageListContainer)
);
