import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { contentTypes } from '../../../constants/uiConstants';
import { folderList } from '../../../constants/folderConstants';
import { getCurrentFolderId } from '../../../ducks/uiStatusReducer';
import { getFolderUnreadCountFromState } from '../../../SideBar/ducks/folderReducer';
import { isEmpty } from '../../../utils/strUtils';
import { navigateToFolder } from '../../../SideBar/ducks/sideBarActions';
import SidebarList from './SidebarList';
import roveFocusHOC from '../../Hoc/roveFocusHOC';

class SidebarListContainer extends Component {  
  handleOnClick = () => {
    const {
      navigateToFolderDispatch,
      folderId,
      currentFolderId,
      currentFocus,
      index,
      setCurrentKeyboardFocus
    } = this.props;
    /* Setting the focussed folder and selected folder same for keyboard accessibilty */
    if (currentFocus !== index) setCurrentKeyboardFocus(index);
    if (currentFolderId === folderId) return;
    navigateToFolderDispatch(folderId);
  };

  render() {
    const { folderId, currentFolderId, handleOnFocus, handleOnBlur, totalCount, currentFocus, index } = this.props;
    const { intlId, icon } = folderList[folderId] || {};
    return (
      !isEmpty(intlId) && (
        <SidebarList
          handleOnFocus={handleOnFocus}
          handleOnBlur={handleOnBlur}
          name={intlId}
          icon={icon}
          handleOnClick={this.handleOnClick}
          isFocussed={currentFocus === index}
          totalCount={totalCount}
          showCount={totalCount > 0}
          isSelected={currentFolderId === folderId}
        />
      )
    );
  }
}

SidebarListContainer.defaultProps = {
  totalCount: 0,
  currentFolderId: null
};

SidebarListContainer.propTypes = {
  totalCount: PropTypes.number,
  folderId: PropTypes.string.isRequired,
  currentFolderId: PropTypes.string,
  navigateToFolderDispatch: PropTypes.func.isRequired,
  currentFocus: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  setCurrentKeyboardFocus: PropTypes.func.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  totalCount: getFolderUnreadCountFromState(state, ownProps.folderId),
  currentFolderId: getCurrentFolderId(state),
  listType: contentTypes.FOLDER_LIST
});

export default connect(mapStateToProps, {
  navigateToFolderDispatch: navigateToFolder
})(roveFocusHOC(SidebarListContainer));
