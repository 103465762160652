import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import CellMeasurer from 'react-virtualized/dist/es/CellMeasurer/CellMeasurer';
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader';
import List from 'react-virtualized/dist/es/List';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';
import cx from 'classnames';
import './MessageList.scss';
import { apiConstants } from '../../ducks/apiConstants';
import { renderDateShortStr, renderString } from '../../utils/renderUtils';
import EmptyState from '../../common/EmptyState';
import MessageGroup from '../../common/Inbox/MessageGroup';
import SingleMessageRow from '../../common/Inbox/SingleMessageRow';
import withPrimaryStyles from '../../common/withPrimaryStyles';
import { isEmpty } from '../../utils/strUtils';

class MessageList extends Component {
  constructor() {
    super();
    this.isResized = false;
    this.isScrolled = false;
    this.isFolderChanged = false;
    this.lastVisibleIndex = 0;
    this.firstVisibleIndex = 0;
    this.scrollStop = false;
  }

  componentDidUpdate() {
    const { rowCache } = this.props;
    rowCache.clearAll();

    if (this.list) {
      this.list.forceUpdateGrid();
    }
  }

  componentResized = () => {
    this.isResized = true;
  };

  bindListRef = (ref) => {
    this.list = ref;
  };

  toggleScrollStop = () => {
    this.scrollStop = !this.scrollStop;
  };

  onScroll = ({ scrollTop }) => {
    // Avoid scrollToTop in List renderer when the list is scrolled.
    if (scrollTop !== 0) {
      this.isScrolled = true;
      this.isFolderChanged = false;
    }
  };

  /**
   * Render a single row by index
   *
   * @param {string} key - Unique key within array of rendered rows
   * @param {number} index - Index of row
   * @param {object} parent - Reference to the parent List (instance)
   * @param {object} style - Style object to be applied to row (to position it)
   * @param {bool} isVisible - This row is visible within the List (eg it is not an overscanned row)
   */
  renderRowItem = ({ key, index, parent, style, isVisible, isScrolling }) => {
    const {
      rowCache,
      handleOnMessageClick,
      handleMessageAction,
      quarantineMessages,
      isReady,
      currentFocus,
      setCurrentKeyboardFocus,
      loadMoreRows,
      checkingIfMessageIsHeader,
      intl
    } = this.props;
    this.lastVisibleIndex = parent['_renderedRowStopIndex']; // eslint-disable-line dot-notation
    this.firstVisibleIndex = parent['_renderedRowStartIndex']; // eslint-disable-line dot-notation

    if (isScrolling && this.scrollStop) {
      // Toggle scrollStop variable , should be called only on the first message scroll
      // Scroll start
      this.toggleScrollStop();
    }

    if (!isScrolling && !this.scrollStop) {
      // if currently Focussed message is not in visible range focus on tthe first visible message
      if (currentFocus > this.lastVisibleIndex || currentFocus < this.firstVisibleIndex) {
        setCurrentKeyboardFocus(this.firstVisibleIndex);
      }
      // Toggle scrollStop variable on the first message scroll to indicate scrolling action stopped
      // Scroll stop
      this.toggleScrollStop();
    }
    const messagesInStateMap = Object.keys(quarantineMessages);
    const messageKey = messagesInStateMap[index];
    const message = quarantineMessages[messageKey];
    if (Object.keys(quarantineMessages).length - 1 === index) {
      loadMoreRows({ stopIndex: index });
    }
    // Show the initial visible messages
    // and the rest when the map status is ready
    const messageReady = isReady && isVisible;

    // Cannot find message in array map
    if (message === undefined) {
      return (
        <li role="row" style={style} key={key} parent={parent}>
          <div>
            <SingleMessageRow index={index} isEncrypted={false} isReady={false} />
          </div>
        </li>
      );
    }

    // Group Header (by Date)
    if (index === 0 || (message && message.group)) {
      return (
        <>
          <CellMeasurer key={key} cache={rowCache} columnIndex={0} parent={parent} rowIndex={index}>
            {({ measure }) => (
              <div role="row" style={style} onLoad={measure}>
                <MessageGroup
                  title={message && message.group ? message.group : ''}
                  isReady={messageReady}
                  className={index === 0 ? 'message-group--first' : ''}
                />
              </div>
            )}
          </CellMeasurer>
        </>
      );
    }
    const deliveryDate = new Date(message.date).getTime();
    const areaLabelParams = {
      date: renderDateShortStr(deliveryDate, intl),
      sender: message.headerSender || message.envelopeSender,
      subject: message.subject
    };
    const ariaLabel = intl.formatMessage({ id: 'aria.label.message' }, areaLabelParams);
    const ariaLabelChecked = intl.formatMessage({ id: 'aria.label.message.checked' }, areaLabelParams);
    // Message item
    return (
      <CellMeasurer key={key} cache={rowCache} columnIndex={0} parent={parent} rowIndex={index}>
        {({ measure }) => (
          <div key={key} style={style} onLoad={measure} role="row">
            <SingleMessageRow
              isEncrypted={false}
              messages={quarantineMessages}
              lenOfListToDisplay={messagesInStateMap.length}
              firstFocussableElemInList={checkingIfMessageIsHeader(quarantineMessages, 0) ? 1 : 0}
              setCurrentKeyboardFocus={setCurrentKeyboardFocus}
              index={index}
              lastVisibleIndex={this.lastVisibleIndex}
              firstVisibleIndex={this.firstVisibleIndex}
              currentFocus={currentFocus}
              handleOnMessageClick={handleOnMessageClick}
              guid={message.guid}
              active={message.active}
              senderAddress={message.headerSender || message.envelopeSender}
              subject={message.subject}
              deliveryDate={deliveryDate}
              attachmentCount={message.attachmentCount}
              isReady={messageReady}
              showCheckboxOnHover
              handleMessageAction={handleMessageAction}
              isRead={message.read}
              ariaLabel={ariaLabel}
              ariaLabelChecked={ariaLabelChecked}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  render() {
    const {
      isRowLoaded,
      rowCount,
      loadMoreRows,
      quarantineMessages,
      rowCache,
      totalMessages,
      isMessageListEmpty,
      isFolderError,
      currentFocus,
      managedDelegate,
      searchString
    } = this.props;
    const initialFocus = 1;
    let bodyMessage =
      totalMessages > 0 || (totalMessages === 0 && !isEmpty(searchString))
        ? renderString('no.search.result.found')
        : renderString('messages.not.found.folder');
    if (isFolderError) {
      bodyMessage = renderString('messages.loading.error');
    }
    if (isMessageListEmpty || isFolderError) {
      return (
        <EmptyState className="empty-state--list" body={bodyMessage}>
          <WarningIcon />
        </EmptyState>
      );
    }

    const quarantineMessageListClass = cx('quarantine-message-list', {
      'quarantine-message-list-mobile-view': !isEmpty(managedDelegate)
    });

    return (
      <section className={quarantineMessageListClass}>
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={rowCount}
          minimumBatchSize={apiConstants.pageSize}
          threshold={50}
        >
          {({ onRowsRendered }) => {
            const { isFolderChanged, setCurrentKeyboardFocus } = this.props;
            this.isFolderChanged = isFolderChanged;
            return (
              <AutoSizer onResize={(x) => this.componentResized(x)}>
                {({ width, height }) => {
                  // To avoid list from scrolling up when the window is resized or scrolledDown
                  if (!this.isFolderChanged && (this.isResized || this.isScrolled)) {
                    this.isResized = false;
                  } else if (this.isFolderChanged) {
                    // Shift focus to the first message on the list when folder is changed
                    setCurrentKeyboardFocus(initialFocus);
                  }
                  return (
                    <>
                      <List
                        id="virtualized-list"
                        width={width}
                        height={height}
                        tabIndex={-1}
                        onRowsRendered={onRowsRendered}
                        overscanRowCount={5}
                        ref={this.bindListRef}
                        rowCount={Object.keys(quarantineMessages).length}
                        rowHeight={rowCache.rowHeight}
                        deferredMeasurementCache={rowCache}
                        scrollToIndex={currentFocus}
                        onScroll={this.onScroll}
                        rowRenderer={({ index, key, parent, style, isVisible, isScrolling }) =>
                          this.renderRowItem({ index, key, parent, style, isVisible, isScrolling })
                        }
                      />
                    </>
                  );
                }}
              </AutoSizer>
            );
          }}
        </InfiniteLoader>
      </section>
    );
  }
}

MessageList.defaultProps = {
  handleOnMessageClick: false,
  handleMessageAction: false
};

MessageList.propTypes = {
  isRowLoaded: PropTypes.func.isRequired,
  quarantineMessages: PropTypes.object.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  totalMessages: PropTypes.number.isRequired,
  rowCache: PropTypes.object.isRequired,
  isMessageListEmpty: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  handleOnMessageClick: PropTypes.func,
  handleMessageAction: PropTypes.func,
  currentFocus: PropTypes.number.isRequired,
  checkingIfMessageIsHeader: PropTypes.func.isRequired,
  isFolderChanged: PropTypes.number.isRequired,
  isFolderError: PropTypes.bool.isRequired,
  setCurrentKeyboardFocus: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  managedDelegate: PropTypes.bool.isRequired,
  searchString: PropTypes.string.isRequired
};

export default injectIntl(withPrimaryStyles(MessageList));
