import React, { useEffect, useRef } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import cx from 'classnames';

import './SingleMessageRow.scss';
import { buttonElementRetriever } from '../../../utils/buttonUtils';
import { keyCodes } from '../../../constants/uiConstants';
import { renderDateShort, renderMessageStatus, renderRecipientsAsString } from '../../../utils/renderUtils';
import ActionButton from '../../ActionButton';
import Attachment from '../../Attachment';
import RecipientAvatars from '../RecipientAvatars';
import withPrimaryStyles from '../../withPrimaryStyles';

function SingleMessageRow({
  guid,
  active,
  senderAddress,
  recipients,
  subject,
  deliveryDate,
  expiryDate,
  isSelected,
  brandingColorPrimary,
  isReady,
  showCheckboxOnHover,
  isChecked,
  isHovering,
  handleToggleCheckbox,
  handleOnTouchStart,
  handleOnTouchEnd,
  handleOnMessageClick,
  classes,
  showCheckbox,
  attachmentCount,
  isEncrypted,
  isRead,
  handleRowHover,
  commands,
  handleMessageAction,
  areActionsDisabled,
  hasMessageActions,
  handleQuickActionsHover,
  areActionsVisible,
  isFocussed,
  handleOnFocus,
  handleOnBlur,
  ariaLabel,
  notificationCount
}) {
  const ref = useRef();
  const listRef = useRef();
  useEffect(() => {
    // Note: Focus on Message only when there are no snackbar notifications
    if (isFocussed && notificationCount === 0) {
      ref.current.firstChild.focus();
    }
  }, [isFocussed]);

  const wrapperClass = cx('singleMessage disable-select', {
    'singleMessage--selected': isSelected || isChecked,
    // primaryBackgroundColor should be present only when a singlemessagerow is selected but multiple elements in the list are not checked. 
    [classes.primaryBackgroundColor10]: isMobileOnly ? isChecked : (isChecked || (isSelected && !showCheckbox)),
    [classes.hoverState]: (!isSelected && isHovering) ,
    [classes.primarySelectedBar]: (isSelected && !showCheckbox),
    [`singleMessage--selected::before ${classes.primaryBackgroundColor}`]: isMobile ? false : (isChecked || (isSelected && !showCheckbox)),
    [classes.darkColor]: isSelected,
    'singleMessage--checked': isChecked || showCheckbox,
    'singleMessage--quarantine': !isEncrypted,
    'singleMessage--encryption': isEncrypted,
    'singleMessage--unRead': !isRead,
    [classes.primaryBorderColor]: true
  });

  const hoverEnabled = !areActionsDisabled && !isEncrypted && isHovering;

  const checkboxClass = cx('singleMessage__checkbox', {
    'singleMessage__checkbox--visible': (showCheckboxOnHover && isHovering) || showCheckbox || isChecked,
    'singleMessage__checkbox--checked': isChecked
  });

  const messageActionsClass = cx('singleMessage__actions', {
    'singleMessage__actions--visible': hoverEnabled && hasMessageActions,
    [`${classes.secondaryColor}`]: classes.secondaryColor
  });

  const messageRecipientsClass = cx('singleMessage__recipients disable-select', {
    'singleMessage__recipients--hover': hoverEnabled && hasMessageActions
  });

  const messageSenderClass = cx('singleMessage__sender disable-select', {
    'singleMessage__sender--hover': hoverEnabled && hasMessageActions
  });

  const messageSubjectClass = cx('singleMessage__subject disable-select', {
    'singleMessage__subject--hover': hoverEnabled && hasMessageActions
  });

  const messageDateClass = cx('singleMessage__date disable-select', {
    'singleMessage__date--hidden': hoverEnabled && hasMessageActions
  });

  const onClick = () => (!areActionsVisible ? handleOnMessageClick(guid) : null);

  const onKeyUp = (event) => {
    const { keyCode, key } = event;
    if (
      keyCode === keyCodes.KEY_ENTER ||
      key === keyCodes.KEYNAME_ENTER ||
      keyCode === keyCodes.KEY_SPACE ||
      key === keyCodes.KEYNAME_SPACE
    ) {
      onClick();
    }
  };

  const deliveryTime = isEncrypted ? deliveryDate * 1000 : deliveryDate;
  return (
    <div
      role="cell"
      ref={ref}
      onFocus={(e) => {
        handleOnFocus(e);
      }}
      onBlur={(e) => {
        handleOnBlur(e);
      }}
    >
      <div
        role="textbox"
        className={wrapperClass}
        tabIndex={isFocussed ? 0 : -1}
        ref={listRef}
        aria-label={ariaLabel}
        onClick={onClick}
        onKeyUp={onKeyUp}
        onMouseEnter={() => handleRowHover(true)}
        onMouseLeave={() => handleRowHover(false)}
        onTouchStart={handleOnTouchStart ? () => handleOnTouchStart(guid) : null}
        onTouchEnd={handleOnTouchEnd ? () => handleOnTouchEnd() : null}
      >
        {/* Dont show checkboxes if the message has no actions available */}
        {isReady && hasMessageActions && (
          <>
            <Hidden xsDown>
              <Checkbox
                aria-checked={isChecked}
                role="checkbox"
                inputProps={{ 'aria-hidden': true }}
                checked={isChecked}
                aria-hidden
                tabIndex={-1}
                classes={{ root: checkboxClass, checked: 'singleMessage__checkbox--selected' }}
                onClick={!showCheckbox ? () => handleToggleCheckbox() : null}
              />
            </Hidden>
            <Hidden smUp>
              {showCheckbox && (
                <Checkbox
                  aria-checked={isChecked}
                  role="checkbox"
                  aria-hidden
                  tabIndex={-1}
                  inputProps={{ 'aria-hidden': true }}
                  checked={isChecked}
                  classes={{ root: checkboxClass, checked: 'singleMessage__checkbox--selected' }}
                />
              )}
            </Hidden>
          </>
        )}

        {/* Recipient / 1st Row */}
        <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--recipient">
          {isEncrypted && (
            <p className={messageRecipientsClass}>
              <span>to:</span> {renderRecipientsAsString(recipients)}
            </p>
          )}
          {senderAddress && <p className={messageSenderClass}>{senderAddress}</p>}
        </ReactPlaceholder>

        {/* Subject / 2nd Row */}
        <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--subject">
          <p className={messageSubjectClass}>{subject}</p>
        </ReactPlaceholder>

        {/* Expiry Date / 3rd Row */}
        {isEncrypted && (
          <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--expiry">
            {renderMessageStatus(active, expiryDate)}
          </ReactPlaceholder>
        )}

        {/* Delivery Date */}
        <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--date">
          <p className={messageDateClass}>{renderDateShort(deliveryTime)}</p>
        </ReactPlaceholder>

        {/* Hover Actions */}
        {!isEncrypted && hasMessageActions && (
          <ul
            className={messageActionsClass}
            onMouseEnter={() => handleQuickActionsHover(true)}
            onMouseLeave={() => handleQuickActionsHover(false)}
          >
            {commands.map((command) => {
              const buttonElement = buttonElementRetriever(command, true);
              return (
                buttonElement && (
                  <li key={`single-message-action-${command}`}>
                    <ActionButton
                      tooltip={buttonElement.tooltipId}
                      tooltipPosition="top"
                      type={command}
                      onClick={() => {
                        handleOnMessageClick();
                        handleMessageAction(command);
                      }}
                      showIcon
                    />
                  </li>
                )
              );
            })}
          </ul>
        )}

        {/* User Profiles */}
        {isEncrypted && (
          <ReactPlaceholder type="round" ready={isReady} className="placeholder placeholder--avatar">
            <RecipientAvatars brandingColorPrimary={brandingColorPrimary} recipients={recipients} />
          </ReactPlaceholder>
        )}

        {!hoverEnabled && isReady && attachmentCount > 0 && <Attachment totalCount={attachmentCount} />}
      </div>
    </div>
  );
}

SingleMessageRow.defaultProps = {
  isSelected: false,
  handleOnMessageClick: null,
  guid: null,
  active: false,
  senderAddress: null,
  recipients: [],
  subject: null,
  deliveryDate: null,
  expiryDate: null,
  brandingColorPrimary: null,
  isReady: false,
  isChecked: false,
  isHovering: false,
  showCheckboxOnHover: false,
  handleToggleCheckbox: null,
  handleOnTouchStart: null,
  handleOnTouchEnd: null,
  showCheckbox: true,
  attachmentCount: 0,
  isEncrypted: true,
  isRead: true,
  handleRowHover: null,
  commands: [],
  handleMessageAction: null,
  areActionsDisabled: false,
  handleQuickActionsHover: null,
  areActionsVisible: false,
  hasMessageActions: true,
  notificationCount: 0
};

SingleMessageRow.propTypes = {
  handleOnMessageClick: PropTypes.func,
  guid: PropTypes.string,
  active: PropTypes.bool,
  recipients: PropTypes.arrayOf(PropTypes.object),
  subject: PropTypes.string,
  deliveryDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  expiryDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  brandingColorPrimary: PropTypes.string,
  isReady: PropTypes.bool,
  isSelected: PropTypes.bool,
  showCheckboxOnHover: PropTypes.bool,
  isChecked: PropTypes.bool,
  isHovering: PropTypes.bool,
  handleToggleCheckbox: PropTypes.func,
  handleOnTouchStart: PropTypes.func,
  handleOnTouchEnd: PropTypes.func,
  classes: PropTypes.object.isRequired,
  showCheckbox: PropTypes.bool,
  senderAddress: PropTypes.string,
  attachmentCount: PropTypes.number,
  isEncrypted: PropTypes.bool,
  isRead: PropTypes.bool,
  handleRowHover: PropTypes.func,
  commands: PropTypes.arrayOf(PropTypes.string),
  handleMessageAction: PropTypes.func,
  areActionsDisabled: PropTypes.bool,
  handleQuickActionsHover: PropTypes.func,
  areActionsVisible: PropTypes.bool,
  hasMessageActions: PropTypes.bool,
  isFocussed: PropTypes.bool.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  notificationCount: PropTypes.number
};

export default withPrimaryStyles(SingleMessageRow);
