import { FormattedMessage } from 'react-intl';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';

import './MessageFolders.scss';
import { renderString } from '../../utils/renderUtils';
import EmptyState from '../../common/EmptyState';
import SidebarList from '../../common/List/SidebarList';

const MessageFolders = ({ folderIds, currentFocus, isError, setCurrentKeyboardFocus }) => {
  // If Config Center is down
  if (isError) {
    return (
      <EmptyState className="empty-state--list" body={renderString('folder.not.found')}>
        <WarningIcon />
      </EmptyState>
    );
  }

  return (
    <>
      <List
        name="sidebar-folder-list"
        onFocus={() => {}}
        className="c-message-folder"
        aria-label="main mailbox folders"
        role="tablist"
      >
        <div className="c-message-folder__list">
          <span role="heading" aria-level={2}>
            <FormattedMessage id="folder.title" />
          </span>
        </div>
        {folderIds.map((folder, index) => (
          <SidebarList
            setCurrentKeyboardFocus={setCurrentKeyboardFocus}
            index={index}
            lenOfListToDisplay={folderIds.length}
            currentFocus={currentFocus}
            key={folder}
            folderId={folder}
          />
        ))}
      </List>
    </>
  );
};

MessageFolders.defaultProps = {
  isError: false
};

MessageFolders.propTypes = {
  isError: PropTypes.bool,
  folderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentFocus: PropTypes.number.isRequired,
  setCurrentKeyboardFocus: PropTypes.func.isRequired
};

export default MessageFolders;
