import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import Slide from '@material-ui/core/Slide';

import './MobileDrawer.scss';

const dialogStyles = {
  root: 'mobileDialogContainer',
  paper: 'mobileDialog'
};

function Transition(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} />;
}

const MobileDrawer = ({ shouldShow, location, closeFunc, children }) => (
  <>
    {/* Mobile only */}
    <Hidden smUp>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={shouldShow}
        TransitionProps={{ direction: location }}
        onClose={closeFunc}
        classes={dialogStyles}
      >
        {children}
      </Dialog>
    </Hidden>
    {/* Full Screen */}
    {shouldShow && <Hidden xsDown>{children}</Hidden>}
  </>
);

MobileDrawer.defaultProps = { closeFunc: undefined, location: 'down' };

MobileDrawer.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
  location: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  closeFunc: PropTypes.func,
  children: PropTypes.element.isRequired
};

export default MobileDrawer;
