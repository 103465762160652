import React, { useEffect, useRef } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import cx from 'classnames';

import './SidebarList.scss';
import { handleKeyboardBtnSelect } from '../../../utils/accessibilityUtils';
import { renderString } from '../../../utils/renderUtils';
import withPrimaryStyles from '../../withPrimaryStyles';

function SidebarList({
  name,
  icon,
  isSelected,
  totalCount,
  showCount,
  handleOnClick,
  classes,
  isFocussed,
  handleOnFocus,
  handleOnBlur
}) {
  const sideBarItemRef = useRef();
  useEffect(() => {
    if (isFocussed) {
      // Move element into view when it is focused
      sideBarItemRef.current.focus();
    }
  }, [isFocussed]);

  const wrapperClass = cx('o-list o-list--sidebar c-sidebar-list', {
    [classes.primarySelectedBar]: isSelected,
    [`c-sidebar-list--selected`]: isSelected,
    [classes.darkColor]: isSelected,
    [classes.primaryBorderColor]: true
  });

  const listItemClass = cx({'list-item': !isSelected,
  [classes.primaryBackgroundColor10]: isSelected });

  const iconClass = cx('o-list__icon', {
    [classes.primaryBackgroundColor10]: isSelected,
    [classes.primarySelectedBar]: isSelected,
    [classes.darkColor]: isSelected
  });

  return (
    <li
      ref={sideBarItemRef}
      role="tab"
      className={listItemClass}
      onFocus={(e) => {
        handleOnFocus(e);
      }}
      onBlur={(e) => {
        handleOnBlur(e);
      }}
      onClick={() => handleOnClick()}
      onKeyUp={(e) => {
        handleKeyboardBtnSelect(e, handleOnClick);
      }}
      tabIndex={isFocussed ? 0 : -1}
      aria-current={isSelected}
    >
      <div className={wrapperClass}>
        <ListItemIcon classes={{ root: 'c-sidebar-list-item-icon' }} className={iconClass}>
          {icon}
        </ListItemIcon>
        <ReactPlaceholder type="textRow" ready={Boolean(name)} className="placeholder placeholder--recipient">
          <ListItemText
            classes={{ root: 'sidebar-folder-list__root' }}
            className="o-list__item o-list__item--sidebar"
            primary={renderString(name)}
          />
          {showCount && <span className="c-sidebar-list__count">{totalCount}</span>}
        </ReactPlaceholder>
      </div>
    </li>
  );
}

SidebarList.defaultProps = {
  name: '',
  icon: null,
  totalCount: 0,
  showCount: false,
  isSelected: false,
  handleOnClick: null
};

SidebarList.propTypes = {
  name: PropTypes.string,
  totalCount: PropTypes.number,
  showCount: PropTypes.bool,
  icon: PropTypes.object,
  isSelected: PropTypes.bool,
  handleOnClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  isFocussed: PropTypes.bool.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired
};

export default withPrimaryStyles(SidebarList);
