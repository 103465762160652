import { all, call, put, race, select, spawn, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { addFilterString } from '../app/common/ActionBar/Search/ducks/searchActions';
import { callConfig, getConfig } from '../app/ducks/configApi';
import { configActions, moduleType } from '../app/ducks/appTypes';
import { getCurrentEmailAddressFromState, getLoggedInUserEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import { hideListItems, revealListItems } from '../app/common/RowItem/ducks/rowItemActions';
import {
  notificationOnTimeout,
  notificationOnUndoAction,
  pushNotification
} from '../app/common/Notifier/ducks/notifierActions';
import { notifierTypes } from '../app/common/Notifier/ducks/notifierTypes';
import { senderListAdd, senderListRemove } from '../app/Settings/Quarantine/SenderListView/ducks/senderListActions';
import { senderListFilterUtils } from '../app/utils/filterUtils';
import apiHandler from '../app/ducks/utils/apiHandler';
import logger from '../tools/logger';

const log = logger.child({ childName: 'configSaga' });
let notificationId = 0;

export function* submitConfig(action) {
  const { formData } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  const loggedInUserEmail = yield select(getLoggedInUserEmailAddressFromState);
  const property = Object.keys(formData.properties)[0];
  let emails = formData.properties[property];
  if (emails) {
    emails = emails.map((email) => email.toLowerCase());
  }
  notificationId += 1;
  const id = notificationId;
  try {
    yield put(revealListItems(emails, username, moduleType.SENDER_LIST));
    yield put(senderListAdd(emails, property));
    yield put(addFilterString(moduleType.SENDER_LIST));

    yield put(
      pushNotification({
        intlId: 'action.button.add.senderlist.message',
        values: { emailCount: emails.length },
        onAction: notificationOnUndoAction(id),
        actionIntlId: 'notification.button.undo',
        onTimeout: notificationOnTimeout(id)
      })
    );

    const { timeout, undo } = yield race({
      timeout: take(notifierTypes.NOTIFICATION_ON_TIME_OUT),
      undo: take(notifierTypes.NOTIFICATION_ON_UNDO_ACTION)
    });
    if (timeout) {
      yield call(apiHandler, callConfig('put', { loggedInUserEmail }, formData));
    }
    if (undo) {
      // undo the changes
      yield put(senderListRemove(emails));
      yield put(addFilterString(moduleType.SENDER_LIST));
    }
  } catch (err) {
    log.error(err, action);
    yield put(senderListRemove(emails));
    yield put(addFilterString(moduleType.SENDER_LIST));
  }
}

export function* deleteConfig(action) {
  const { property } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  const loggedInUserEmail = yield select(getLoggedInUserEmailAddressFromState);
  const filterStr = yield select(senderListFilterUtils.getFilterStringFromState);
  // need to convert to array to use in reducer
  const selectedEmails = yield select(senderListFilterUtils.getSelectedItemIdsFromState);
  notificationId += 1;
  const id = notificationId;

  try {
    yield put(hideListItems(selectedEmails, username, moduleType.SENDER_LIST, true));
    // remove email from senderList
    yield put(senderListRemove(selectedEmails));
    // refilter filteredList after removing from senderList
    yield put(addFilterString(moduleType.SENDER_LIST, filterStr));
    yield put(
      pushNotification({
        intlId: 'action.button.delete.senderlist.message',
        values: { emailCount: selectedEmails.length },
        onAction: notificationOnUndoAction(id),
        actionIntlId: 'notification.button.undo',
        onTimeout: notificationOnTimeout(id)
      })
    );

    const { timeout, undo } = yield race({
      timeout: take(notifierTypes.NOTIFICATION_ON_TIME_OUT),
      undo: take(notifierTypes.NOTIFICATION_ON_UNDO_ACTION)
    });
    if (timeout) {
      const formData = {
        properties: {
          [property]: selectedEmails
        }
      };
      yield put(revealListItems(selectedEmails, username, moduleType.SENDER_LIST));
      yield call(apiHandler, callConfig('delete', { loggedInUserEmail }, formData));
    }
    if (undo) {
      yield put(revealListItems(selectedEmails, username, moduleType.SENDER_LIST));
      // add email back to senderList
      yield put(senderListAdd(selectedEmails, property));
      // refilters filteredList on senderList update
      yield put(addFilterString(moduleType.SENDER_LIST, filterStr));
    }
  } catch (err) {
    log.error(err, action);
    yield put(
      pushNotification({
        intlId: 'action.button.delete.senderlist.failed.message',
        values: { emailCount: selectedEmails.length }
      })
    );
    yield put(revealListItems(selectedEmails, username, moduleType.SENDER_LIST));
    // add email back to senderList and refilter filteredList
    yield put(senderListAdd(selectedEmails, property));
    yield put(addFilterString(moduleType.SENDER_LIST, filterStr));
  }
}

export function* editConfig(action) {
  const { formData } = action;

  const property = Object.keys(formData.properties)[0];
  const emails = formData.properties[property];
  const username = yield select(getCurrentEmailAddressFromState);
  const loggedInUserEmail = yield select(getLoggedInUserEmailAddressFromState);
  const filterStr = yield select(senderListFilterUtils.getFilterStringFromState);
  // need to convert to array to use in reducer
  const selectedEmails = yield select(senderListFilterUtils.getSelectedItemIdsFromState);
  notificationId += 1;
  const id = notificationId;

  try {
    yield put(hideListItems(selectedEmails, username, moduleType.SENDER_LIST, true));
    // remove email from senderList
    yield put(senderListRemove(selectedEmails));
    yield put(senderListAdd(emails, property));
    // refilter filteredList after removing from senderList
    yield put(addFilterString(moduleType.SENDER_LIST, filterStr));
    yield put(
      pushNotification({
        intlId: 'action.button.edit.senderlist.message',
        values: { emailCount: selectedEmails.length },
        onAction: notificationOnUndoAction(id),
        actionIntlId: 'notification.button.undo',
        onTimeout: notificationOnTimeout(id)
      })
    );

    const { timeout, undo } = yield race({
      timeout: take(notifierTypes.NOTIFICATION_ON_TIME_OUT),
      undo: take(notifierTypes.NOTIFICATION_ON_UNDO_ACTION)
    });
    if (timeout) {
      const deleteFormData = {
        properties: {
          [property]: selectedEmails
        }
      };
      yield call(apiHandler, callConfig('delete', { loggedInUserEmail }, deleteFormData));

      // add new value
      yield call(apiHandler, callConfig('put', { loggedInUserEmail }, formData));
      yield put(senderListAdd(emails, property));
      // refilter filteredItems after senderList update
      yield put(addFilterString(moduleType.SENDER_LIST, filterStr));
      yield put(revealListItems(emails, username, moduleType.SENDER_LIST));
    }
    if (undo) {
      yield put(revealListItems(selectedEmails, username, moduleType.SENDER_LIST));
      // add email back to senderList
      yield put(senderListAdd(selectedEmails, property));
      // Removes the email from the list
      yield put(senderListRemove(emails));
      // refilters filteredList on senderList update
      yield put(addFilterString(moduleType.SENDER_LIST, filterStr));
    }
  } catch (err) {
    log.error(err, action);
    yield put(
      pushNotification({
        intlId: 'action.button.delete.senderlist.failed.message',
        values: { emailCount: selectedEmails.length }
      })
    );
    yield put(revealListItems(selectedEmails, username, moduleType.SENDER_LIST));
    // add email back to senderList and refilter filteredList
    yield put(senderListAdd(selectedEmails, property));
    yield put(senderListRemove(emails));
    yield put(addFilterString(moduleType.SENDER_LIST, filterStr));
  }
}

export function* retrieveConfig(action) {
  const username = yield select(getCurrentEmailAddressFromState);
  const property = [action.property];

  try {
    yield call(apiHandler, getConfig({ username, property }));
  } catch (err) {
    log.error(`fetching sender error: ${username}: ${err}`);
  }
}

export function* watchAddConfig() {
  yield takeLatest(configActions.SUBMIT_CONFIG, submitConfig);
}

export function* watchDeleteConfig() {
  yield takeEvery(configActions.DELETE_CONFIG, deleteConfig);
}

export function* watchEditConfig() {
  yield takeLatest(configActions.EDIT_CONFIG, editConfig);
}

export function* watchRetrieveConfig() {
  yield takeLatest(configActions.RETRIEVE_CONFIG, retrieveConfig);
}

export default function* configSaga() {
  yield all([spawn(watchAddConfig), spawn(watchDeleteConfig), spawn(watchRetrieveConfig), spawn(watchEditConfig)]);
}
