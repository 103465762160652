import PropTypes from 'prop-types';
import React from 'react';

import './HelperText.scss';

const HelperText = ({ title, subtitle }) => (
  <div className="helper-text">
    {title && <h1 className="helper-text-title">{title}</h1>}
    {subtitle && <h2 className="helper-text-subtitle">{subtitle}</h2>}
  </div>
);

HelperText.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node
};

HelperText.defaultProps = {
  title: undefined,
  subtitle: undefined
};

export default HelperText;
