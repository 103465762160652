import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import Button from '@proofpoint/fuse-ui/components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@proofpoint/fuse-ui/components/Tooltip';

import './AddSenderListItem.scss';
import { isValidDomain, isValidEmail } from '../../../../utils/strUtils';
import { renderString } from '../../../../utils/renderUtils';
import ProfileTile from '../../../ProfileTile';
import listConstants from '../ducks/senderlistConstants';

const AddSenderlistItem = ({
  configProperty,
  closeFunc,
  backFunc,
  addConfigFunc,
  intl,
  addressList,
  initialAddress
}) => {
  const isBlockList = configProperty === listConstants.BLOCKLIST;
  return (
    <>
      <ProfileTile
        header={`settings.${configProperty}.add.title`}
        closeFunc={closeFunc}
        backFunc={backFunc}
        showFullScreenBackButton
      >
        <div className="profile-view">
          <div className="add-sender__subtitle">{renderString(`settings.${configProperty}.add.description`)}</div>
          <>
            <Formik
              initialValues={{
                email: initialAddress,
                valuesChanged: Boolean(initialAddress)
              }}
              validationSchema={yupObject().shape({
                email: yupString()
                  .required(
                    intl.formatMessage({ id: isBlockList ? 'form.email.or.domain.required' : 'form.email.required' })
                  )
                  .test(
                    'shouldBeEmailOrDomain',
                    intl.formatMessage({
                      id: isBlockList ? 'form.invalid.email.address.or.domain' : 'form.invalid.email.address'
                    }),
                    (value) => {
                      if (value === undefined) {
                        return true;
                      }
                      if (isBlockList) {
                        return isValidDomain(value) || isValidEmail(value);
                      }
                      return isValidEmail(value);
                    }
                  )
                  .test(
                    'wildcardsForbidden',
                    intl.formatMessage({ id: 'form.email.wildcards.not.allowed' }),
                    (value) => value && value.indexOf('*') < 0
                  )
                  .test(
                    'emailExists',
                    intl.formatMessage({
                      id: isBlockList ? 'form.email.or.domain.already.exists' : 'form.email.already.exists'
                    }),
                    (value) => value && !addressList[value.toLowerCase()]
                  )
              })}
              enableReinitialize
              onSubmit={(values, { setValues }) => {
                const formData = {
                  properties: {
                    [configProperty]: [values.email]
                  }
                };
                addConfigFunc(formData);
                backFunc();
                setValues({ valuesChanged: false });
              }}
              render={(formik) => {
                const { handleSubmit, values, errors, touched } = formik;

                const selectChanged = (event) => {
                  const newText = event.target.value;
                  const { setValues } = formik;
                  setValues({ ...values, email: newText, valuesChanged: true });
                };
                const placeholder = intl.formatMessage({
                  id: isBlockList ? 'settings.blockList.entry' : 'settings.safeList.emailAddress'
                });
                const saveTooltip = intl.formatMessage({ id: 'tooltip.button.save' });
                return (
                  <form className="profile-form" onSubmit={handleSubmit}>
                    <div className="add-sender__container">
                      <TextField
                        fullWidth
                        autoFocus
                        defaultValue={values.email}
                        onChange={selectChanged}
                        className="add-sender__container__text"
                        error={touched.email && errors.email}
                        helperText={touched.email && errors.email}
                        placeholder={placeholder}
                        InputProps={{
                          inputProps: { 'aria-label': placeholder }
                        }}
                      />
                      <div className="add-sender__container__submit-buttons">
                        <Tooltip id="add-address-tooltip" enterDelay={500} title={saveTooltip} placement="bottom-start">
                          <div
                            className={`appContainer-tooltip--button profile-submit-button${
                              values.valuesChanged ? '--enabled' : ''
                            }`}
                          >
                            <Button
                              name="save"
                              aria-label={saveTooltip}
                              disabled={
                                !values.valuesChanged || (Boolean(touched.email) && Object.keys(errors).length !== 0)
                              }
                              type="submit"
                            >
                              <FormattedMessage id={isBlockList ? 'settings.blockList.add' : 'settings.safeList.add'} />
                            </Button>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </form>
                );
              }}
            />
          </>
        </div>
      </ProfileTile>
    </>
  );
};

AddSenderlistItem.propTypes = {
  configProperty: PropTypes.string.isRequired,
  closeFunc: PropTypes.func.isRequired,
  backFunc: PropTypes.func.isRequired,
  addConfigFunc: PropTypes.func.isRequired,
  addressList: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  initialAddress: PropTypes.string.isRequired
};

export default injectIntl(AddSenderlistItem);
