import { FormattedDate, FormattedMessage, FormattedTimeParts } from 'react-intl';
import React from 'react';
import moment from 'moment';

import { getFolderIntlId } from './intlUtils';
import { getLanguageId } from './jsUtils';
import { isEmpty } from './strUtils';
import { isFolderPath, isMessageBodyPath, isSecureMessageCenterPath } from './routeUtils';
import { isThisYear, isToday, isYesterday } from './dateUtils';
import StatusBadge from '../common/StatusBadge/StatusBadge';

export function renderPartsStr(parts = []) {
  let formattedTimeStr = '';

  if (!parts || isEmpty(parts)) {
    return '';
  }
  //     making sure 12 am/pm is not displayed as 0 am/pm by default in FormattedTimeParts
  //     The parts returned from FormattedTimeParts are as follows:
  //        parts= [  {"type": "hour","value": "xx"},
  //                  {"type": "literal","value": ":"},
  //                  {"type": "minute","value": "xx"},
  //                  {"type": "literal","value": " "},
  //                  {"type": "dayPeriod","value": "AM/PM"}]

  // Example time parts for en

  // [
  //   { "type": "hour","value": "0"},
  //   {"type": "literal", "value": ":"},
  //   {"type": "minute", "value": "24"},
  //   {"type": "literal", "value": " "},
  //   {"type": "dayPeriod", "value": "am"}
  // ]

  // Example time parts for zh-cn (chinese)
  // [
  //     {"type": "dayPeriod","value": "上午"},
  //     {"type": "hour","value": "12"},
  //     {"type": "literal","value": ":"},
  //     {"type": "minute","value": "24"}
  // ]
  const hourIndex = parts.findIndex((x) => x.type === 'hour');

  // eslint-disable-next-line no-param-reassign
  parts[hourIndex].value =
    parts[hourIndex].value === '0' || parts[hourIndex].value === '00' ? '12' : parts[hourIndex].value; // eslint-disable-line no-param-reassign

  parts.forEach((_part) => {
    formattedTimeStr = formattedTimeStr.concat(_part.value);
  });

  return formattedTimeStr;
}

export function renderParts(parts) {
  // converting to react node
  return <>{renderPartsStr(parts)}</>;
}

export function renderFormattedTimeParts(date) {
  return (
    <FormattedTimeParts value={date} hour="numeric" minute="numeric" hour12>
      {(parts) => renderParts(parts)}
    </FormattedTimeParts>
  );
}

export function renderFormattedTimePartsStr(date, intl) {
  const parts = intl.formatTimeToParts(date, { hour: 'numeric', minute: 'numeric', hour12: true });
  return renderPartsStr(parts);
}

export function renderDateShort(dateStr) {
  if (!dateStr) {
    return <FormattedMessage id="not.available" />;
  }
  const date = new Date(dateStr);

  if (isToday(date) || isYesterday(date)) {
    return renderFormattedTimeParts(date);
  }
  if (isThisYear(date)) {
    return <FormattedDate value={date} day="numeric" month="short" />;
  }

  return <FormattedDate value={date} day="2-digit" month="2-digit" year="numeric" />;
}

export function renderDateShortStr(dateStr, intl) {
  if (!dateStr) {
    return intl.formatMessage({ id: 'not.available' });
  }
  const date = new Date(dateStr);

  if (isToday(date) || isYesterday(date)) {
    return renderFormattedTimePartsStr(date, intl);
  }
  if (isThisYear(date)) {
    return intl.formatDate(date, { day: 'numeric', month: 'short' });
  }

  return intl.formatDate(date, { day: '2-digit', month: '2-digit', year: 'numeric' });
}

export function renderString(strId, values, defaultStr = '') {
  return strId ? (
    <span>
      <FormattedMessage id={strId} values={values} />
    </span>
  ) : (
    defaultStr
  );
}

export function renderLocalDateStr(dateStr, defaultStr = '') {
  return dateStr ? new Date(dateStr).toLocaleDateString() : defaultStr;
}

export function renderTime(dateStr) {
  const date = new Date(dateStr);
  if (!dateStr) {
    return <FormattedMessage id="not.available" />;
  }
  return renderFormattedTimeParts(date);
}

export function renderFolderName(folderId) {
  return <FormattedMessage id={getFolderIntlId(folderId)} />;
}

export function renderInitialsFromString(string) {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function renderRecipientsAsString(recipients) {
  let string = '';
  if (!recipients) {
    return string;
  }
  recipients.forEach((recipient) => {
    string += `${recipient.email}, `;
  });
  return string.slice(0, -2);
}

export function renderExpiryTime(expiryDate, label) {
  const dateNow = moment(new Date());
  const dateEnd = moment(new Date(expiryDate * 1000));

  const years = dateEnd.diff(dateNow, 'years');
  const months = dateEnd.diff(dateNow, 'months');
  const weeks = dateEnd.diff(dateNow, 'weeks');
  const days = dateEnd.diff(dateNow, 'days');
  const hours = dateEnd.diff(dateNow, 'hours');
  const minutes = dateEnd.diff(dateNow, 'minutes');
  const seconds = dateEnd.diff(dateNow, 'seconds');

  let expiredInLabel = 'encryption.message.options.expires';
  if (label) {
    expiredInLabel = label;
  }

  if (years >= 1) {
    const intlDate = years > 1 ? 'dates.years' : 'dates.year';
    return (
      <>
        <FormattedMessage id={expiredInLabel} />
        <FormattedMessage id={intlDate} values={{ years }} />
      </>
    );
  }

  if (months >= 1) {
    const intlDate = months > 1 ? 'dates.months' : 'dates.month';
    return (
      <>
        <FormattedMessage id={expiredInLabel} />
        <FormattedMessage id={intlDate} values={{ months }} />
      </>
    );
  }

  if (weeks >= 1) {
    const intlDate = weeks > 1 ? 'dates.weeks' : 'dates.week';
    return (
      <>
        <FormattedMessage id={expiredInLabel} />
        <FormattedMessage id={intlDate} values={{ weeks }} />
      </>
    );
  }

  if (days >= 1) {
    const intlDate = days > 1 ? 'dates.days' : 'dates.day';
    return (
      <>
        <FormattedMessage id={expiredInLabel} />
        <FormattedMessage id={intlDate} values={{ days }} />
      </>
    );
  }

  if (hours >= 1) {
    const intlDate = hours > 1 ? 'dates.hours' : 'dates.hour';
    return (
      <>
        <FormattedMessage id={expiredInLabel} />
        <FormattedMessage id={intlDate} values={{ hours }} />
      </>
    );
  }

  if (minutes >= 1) {
    const intlDate = minutes > 1 ? 'dates.minutes' : 'dates.minute';
    return (
      <>
        <FormattedMessage id={expiredInLabel} />
        <FormattedMessage id={intlDate} values={{ minutes }} />
      </>
    );
  }

  if (seconds >= 1) {
    const intlDate = seconds > 1 ? 'dates.seconds' : 'dates.second';
    return (
      <>
        <FormattedMessage id={expiredInLabel} />
        <FormattedMessage id={intlDate} values={{ seconds }} />
      </>
    );
  }

  return renderString('encryption.message.options.expired');
}

export function renderMessageStatus(active, expiryDate, largeBadge) {
  // Expired
  if (expiryDate > 0 && moment(new Date()).unix() > expiryDate) {
    return (
      <StatusBadge content={renderString('encryption.status.expired')} largeBadge={largeBadge} showIcon={largeBadge} />
    );
  }
  // Revoked
  if (!active) {
    return <StatusBadge content={renderString('encryption.status.revoked')} largeBadge={largeBadge} />;
  }
  // Never Expires
  if (expiryDate === 0) {
    return (
      <StatusBadge content={renderString('encryption.message.options.neverExpires')} solid largeBadge={largeBadge} />
    );
  }
  // Active
  return <StatusBadge content={renderExpiryTime(expiryDate)} solid largeBadge={largeBadge} showIcon={largeBadge} />;
}

export function renderExpiryDays(expiryDate) {
  const dateNow = moment(new Date());
  const dateEnd = moment(new Date(expiryDate * 1000));

  const days = Math.trunc(moment.duration(dateEnd.diff(dateNow)).asDays());

  const intlDate = days > 1 && days !== 0 ? 'dates.days' : 'dates.day';
  const expiredInLabel = 'encryption.message.options.expiresIn';
  return (
    <>
      <FormattedMessage id={expiredInLabel} />
      <FormattedMessage id={intlDate} values={{ days }} />
    </>
  );
}

export function renderExpiryHours(expiryDate) {
  const dateNow = moment(new Date());
  const dateEnd = moment(new Date(expiryDate * 1000));

  const days = Math.trunc(moment.duration(dateEnd.diff(dateNow)).asDays());
  const expiryDaysEnd = dateEnd.subtract(days, 'days');
  const hours = Math.trunc(moment.duration(expiryDaysEnd.diff(dateNow)).asHours());

  const intlDate = hours > 1 && hours !== 0 ? 'dates.hours' : 'dates.hour';
  const expiredInLabel = 'encryption.message.options.expiry.andHours';
  return (
    <>
      <FormattedMessage id={expiredInLabel} />
      <FormattedMessage id={intlDate} values={{ hours }} />
    </>
  );
}

export function renderExpiryDate(expiryDate, isExpired) {
  const expiryDateTime = moment(expiryDate * 1000)
    .locale(getLanguageId())
    .format('MMM, Do YYYY hh:mm A');

  const content = isExpired ? 'encryption.message.options.expired.on' : 'encryption.message.options.expires.on';
  const hasSolidBg = !isExpired;

  return <StatusBadge content={renderString(content, { expiryDateTime })} solid={hasSolidBg} largeBadge showIcon />;
}

export function renderUserGreeting(displayName) {
  const splitAfternoon = 12;
  const splitEvening = 17;
  const currentHour = parseFloat(moment().format('HH'));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    return <FormattedMessage id="landing.page.greeting.afternoon" values={{ displayName }} />;
  }

  if (currentHour >= splitEvening) {
    return <FormattedMessage id="landing.page.greeting.evening" values={{ displayName }} />;
  }

  return <FormattedMessage id="landing.page.greeting.morning" values={{ displayName }} />;
}

export function renderViewTitle(folderId = null) {
  const {
    location: { pathname }
  } = window;

  if (folderId) {
    return (renderString(getFolderIntlId(folderId)));
  }

  if (isFolderPath(pathname) || isMessageBodyPath(pathname)) {
    return renderString('folder.quarantine');
  }

  if (isSecureMessageCenterPath(pathname)) {
    return renderString('folder.SecureMessageCenter');
  }

  return null;
}
