import { createSelector } from 'reselect';

import { actionAttempt, actionError, actionSuccess, apiActions, apiConstants } from '../../ducks/apiConstants';
import { createReducer } from '../../utils/reducerUtils';
import { getTotalQuarantineDateGroups } from '../../utils/dateUtils';
import { isMisCqsEnabled } from '../../ducks/featureReducer';
import { messageTypes } from './messageTypes';
import { sideBarTypes } from '../../SideBar/ducks/sideBarTypes';

export const moduleName = 'messages';

const INITIAL_STATE = {
  totalMessages: 0, // Overall total messages
  totalResults: 0, // Total count of resultset
  totalRows: apiConstants.pageSize, // Number of messages and dates
  checkedGuids: {}, // Multi-selected messages
  isLoading: false, // true if is in process of loading messages
  isReady: false, // Successful api response
  isEmpty: false, // No resultset
  firstGuid: null, // First message from resultset
  nextGuid: null, // Next message to select upon an action
  currentGuid: null, // Current Message Id
  currentFolder: null, // Current folder name
  keys: {}, // Message object resultset
  meta: {}, // Meta data that relates to the message guid but not returned from the API,
  searchCursor: null,
  showMessageActions: false // ellipses
};

function onFetchMessagesAttempt(state) {
  return {
    ...state,
    isLoading: true,
    isReady: false
  };
}

function onFetchMessagesSuccess(state, action) {
  const {
    requestParams: {
      data: { offset }
    },
    response: {
      entities: { messages = {} },
      result: { totalCount, scrollId: searchCursor }
    }
  } = action;

  // Determine if resultset should be appended to existing state
  let messageKeys;
  // If Mis CQS is enabled for the account there will be no offset for scrolling
  if ((offset && offset > 0) || isMisCqsEnabled) {
    messageKeys = { ...state.keys, ...messages };
  } else {
    messageKeys = messages;
  }

  const currentFolder = Object.keys(messages).length > 0 ? messages[Object.keys(messages)[0]].folder : null;
  const totalResults = messages ? Object.keys(messages).length : 0;
  return !state.currentFolder || state.currentFolder === currentFolder
    ? {
        ...state,
        totalMessages: totalCount || 0,
        isLoading: false,
        isReady: true,
        isEmpty: totalCount === 0,
        firstGuid: (messageKeys && Object.keys(messageKeys)[0]) || null,
        totalRows: totalResults > 0 ? getTotalQuarantineDateGroups(messageKeys) + totalCount : 0,
        keys: messageKeys || {},
        searchCursor
      }
    : {
        ...state,
        isReady: true,
        isLoading: false,
        totalMessages: totalCount || 0,
        isEmpty: totalCount === 0,
        searchCursor: null
      };
}

function onFetchMessageBodySuccess(state, action) {
  const { message, msgId } = action;
  const hasErrors = !message || message.messageBodyText === '' || false;
  return {
    ...state,
    meta: {
      ...state.meta,
      ...{
        [msgId]: {
          ...state.meta[msgId],
          ...{ hasErrors },
          ...message
        }
      }
    }
  };
}

function onUpdateCurrentFolder(state, action) {
  const { folderId } = action;
  return {
    ...state,
    currentFolder: folderId
  };
}

function onUpdateCurrentGuid(state, action) {
  const { nextGuid: currentGuid, filteredMessages } = action;
  const { currentGuid: prevGuid } = state;

  if (currentGuid === prevGuid) {
    return state;
  }

  const keys = Object.keys(filteredMessages);
  const prevIndex = keys.indexOf(currentGuid) - 1;
  const nextIndex = keys.indexOf(currentGuid) + 1;

  // Locate next key
  let nextItem = keys[nextIndex];

  // If last key, use previous key
  if (nextItem === undefined) {
    nextItem = keys[prevIndex];
  }

  // Fallback to the first available key
  if (nextItem === undefined) {
    [nextItem] = keys;
  }

  return {
    ...state,
    currentGuid,
    nextGuid: nextItem,
    meta: {
      ...state.meta,
      ...{
        [currentGuid]: {
          ...state.meta[currentGuid],
          ...{ isSelected: true, isRead: true }
        },
        [prevGuid]: {
          ...state.meta[prevGuid],
          ...{ isSelected: false }
        }
      }
    }
  };
}

function onFetchMessagesError(state) {
  return {
    ...state,
    isLoading: false,
    isReady: false,
    isEmpty: true
  };
}

function onFetchMessageBodyError(state) {
  const { currentGuid } = state;
  return {
    ...state,
    meta: {
      ...state.meta,
      ...{
        [currentGuid]: {
          ...state.meta[currentGuid],
          ...{ hasErrors: true }
        }
      }
    }
  };
}

function onFetchMessageBodyTextError(state) {
  const { currentGuid } = state;
  return {
    ...state,
    meta: {
      ...state.meta,
      ...{
        [currentGuid]: {
          ...state.meta[currentGuid],
          ...{ hasErrors: true }
        }
      }
    }
  };
}

function onResetMessages(state) {
  const { meta, currentFolder = null } = state;
  return {
    ...INITIAL_STATE,
    meta,
    currentFolder
  };
}

function onResetMessagesAndMeta() {
  return {
    ...INITIAL_STATE    
  };
}

function resetMessageEmpty(state) {
  return {
    ...state,        
    isReady: true,
    isEmpty: false
  };
}

function setMessageReadyStatus(state) {
  return {
    ...state,
    isReady: !state.isReady
  };
}

function onMarkMessageAsChecked(state, action) {
  const { guid, isChecked } = action;
  const { currentGuid, checkedGuids } = state;

  // Update or remove guid
  const newCheckedGuids = { ...checkedGuids };
  if (isChecked) {
    newCheckedGuids[guid] = guid;
  } else {
    delete newCheckedGuids[guid];
  }

  // Mark message as selected (theme primary colour as background)
  const isSelected = guid === currentGuid ? true : isChecked;

  return {
    ...state,
    checkedGuids: newCheckedGuids,
    meta: {
      ...state.meta,
      ...{
        [guid]: {
          ...state.meta[guid],
          ...{ isChecked, isSelected }
        }
      }
    }
  };
}

function onResetCheckedMessages(state) {
  const { currentGuid, checkedGuids } = state;
  const updatedMeta = [];

  Object.keys(checkedGuids).map((guid) => {
    updatedMeta[guid] = {
      ...state.meta[guid],
      ...{ isChecked: false, isSelected: currentGuid === guid }
    };
    return updatedMeta;
  });

  return {
    ...state,
    checkedGuids: {},
    meta: {
      ...state.meta,
      ...updatedMeta
    }
  };
}

function onIncreaseMessageCount(state, action) {
  const { count } = action;
  const { totalMessages, totalResults, totalRows } = state;
  return {
    ...state,
    totalMessages: totalMessages + count,
    totalResults: totalResults + count,
    totalRows: totalRows + count
  };
}

function onDecreaseMessageCount(state, action) {
  const { count } = action;
  const { totalMessages, totalResults, totalRows } = state;
  return {
    ...state,
    totalMessages: totalMessages - count,
    totalResults: totalResults - count,
    totalRows: totalRows - count
  };
}

function onUpdateMessageCount(state, action) {
  const { filteredMessages, getTotalHiddenMessages } = action;
  const { totalMessages } = state;
  const totalFilteredResults = Object.keys(filteredMessages).length;
  return {
    ...state,
    totalMessages: Math.round(totalMessages - getTotalHiddenMessages),
    totalResults: totalFilteredResults
  };
}

function onShowMessageActions(state, { showMessageActions }) {
  return {
    ...state,
    showMessageActions
  };
}

export default createReducer(INITIAL_STATE, {
  [actionAttempt(apiActions.FETCH_MESSAGES)]: onFetchMessagesAttempt,
  [actionSuccess(apiActions.FETCH_MESSAGES)]: onFetchMessagesSuccess,
  [actionError(apiActions.FETCH_MESSAGES)]: onFetchMessagesError,
  [actionError(apiActions.FETCH_MESSAGE_BODY)]: onFetchMessageBodyError,
  [actionError(apiActions.FETCH_MESSAGE_BODY_TEXT)]: onFetchMessageBodyTextError,
  [messageTypes.RESET_MESSAGES]: onResetMessages,
  [messageTypes.RESET_MESSAGES_AND_META] : onResetMessagesAndMeta,
  [messageTypes.UPDATE_MESSAGE_BODY]: onFetchMessageBodySuccess,
  [sideBarTypes.NAVIGATE_TO_FOLDER]: onUpdateCurrentFolder,
  [messageTypes.UPDATE_CURRENT_GUID]: onUpdateCurrentGuid,
  [messageTypes.SET_MESSAGE_READY]: setMessageReadyStatus,
  [messageTypes.MARK_MESSAGE_AS_CHECKED]: onMarkMessageAsChecked,
  [messageTypes.RESET_CHECKED_MESSAGES]: onResetCheckedMessages,
  [messageTypes.SET_MESSAGE_EMPTY]: onFetchMessagesError,
  [messageTypes.RESET_MESSAGE_EMPTY]: resetMessageEmpty,
  [sideBarTypes.INCREASE_FOLDER_COUNT]: onIncreaseMessageCount,
  [sideBarTypes.DECREASE_FOLDER_COUNT]: onDecreaseMessageCount,
  [messageTypes.UPDATE_FILTER_COUNT]: onUpdateMessageCount,
  [messageTypes.SHOW_MESSAGE_ACTIONS]: onShowMessageActions
});

/*
  Messages Selectors
 */
export function getMessagesFromState(state) {
  return state[moduleName];
}

export const getMessages = createSelector(getMessagesFromState, (messages) => messages.keys);

export const getSearchCursor = createSelector(getMessagesFromState, (messages) => messages.searchCursor);

export const getTotalMessages = createSelector(getMessagesFromState, (messages) => messages.totalMessages);

export const getFirstGuid = createSelector(getMessagesFromState, (messages) => messages.firstGuid);

export const getNextGuid = createSelector(getMessagesFromState, (messages) => messages.nextGuid);

export const getCurrentGuid = createSelector(getMessagesFromState, (messages) => messages.currentGuid);

export const getCurrentFolder = createSelector(getMessagesFromState, (messages) => messages.currentFolder);

export const getTotalRows = createSelector(getMessagesFromState, (messages) => messages.totalRows);

export const getLoadingStatus = createSelector(getMessagesFromState, (messages) => messages.isLoading);

export const getReadyStatus = createSelector(getMessagesFromState, (messages) => messages.isReady);

export const getEmptyStatus = createSelector(getMessagesFromState, (messages) => messages.isEmpty);

export const getMessageMeta = createSelector(getMessagesFromState, (messages) => messages.meta);

export const getCheckedGuids = createSelector(getMessagesFromState, (messages) => messages.checkedGuids);

export const getTotalCheckedGuids = createSelector(getCheckedGuids, (guids) => Object.keys(guids).length || 0);

export const getShowMessageActions = (state) => state[moduleName].showMessageActions;

const selectCheckedMessageByGuid = (state, guid) => getCheckedGuids(state)[guid];
const selectMessageByGuid = (state, guid) => getMessages(state)[guid];
const selectMessageMetaByGuid = (state, guid) => getMessageMeta(state)[guid];
const selectMetaKey = (state, guid, key) => key;

// message body text
export const getMessageBodyTextFromMessage = createSelector(
  selectMessageMetaByGuid,
  (message) => message && message.messageBodyText !== undefined
);

export const getMessageFromMessages = createSelector(
  selectMessageByGuid,
  selectMessageMetaByGuid,
  (message, messageMeta) => ({ ...message, ...messageMeta })
);

export const getMetaKeyByGuid = createSelector(selectMessageMetaByGuid, selectMetaKey, (messages, key) => {
  if (messages && messages[key] !== undefined) {
    return messages[key];
  }
  return false;
});

export const getCheckedMessageByGuid = createSelector(selectCheckedMessageByGuid, (guid) => guid);

export function getPageCount(state) {
  const messages = getMessages(state);
  return Math.ceil(Object.keys(messages).length / apiConstants.pageSize);
}
