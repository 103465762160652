import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { buttonElements } from '../../constants/uiConstants';
import { handleKeyboardBtnSelect } from '../../utils/accessibilityUtils';
import Tooltip from '../Tooltip';

export const ActionButton = ({
  allowKeyboardTab,
  name,
  color,
  variant,
  disabled,
  onClick,
  type,
  isIconButton,
  tooltip,
  isMobile,
  showIcon,
  tooltipPosition,
  classes,
  intl,
  ariaLabel,
  ariaExpanded,
  actionBtnDescription
}) => {
  const classnames = cx('action-button', {
    'action-button--icon': isIconButton,
    'action-button-more': isMobile,
    [classes]: classes
  });

  const tooltipStr = tooltip ? intl.formatMessage({ id: tooltip }) : undefined;
  return (
    <Tooltip title={tooltipStr} placement={tooltipPosition}>
      <div>
        <div
          name={`qaTags.ACTIONBAR_BUTTON-${type}`}
          className={classnames}
          color={color}
          role="button"
          tabIndex={allowKeyboardTab ? 0 : -1}
          disabled={disabled}
          aria-expanded={ariaExpanded}
          aria-describedby="actionBtnInfo"
          aria-label={ariaLabel || `Action Button ${type}`}
          classes={{ disabled: 'action-button--disabled' }}
          onClick={() => {
            if (!disabled) {
              onClick();
            }
          }}
          variant={variant}
          onKeyUp={(e) => {
            if (!disabled) {
              handleKeyboardBtnSelect(e, onClick);
            }
          }}
        >
          {buttonElements[type] && showIcon && (
            <div className={!isIconButton ? 'action-icon' : ''}>{buttonElements[type].icon}</div>
          )}
          {name && <FormattedMessage id={name} />}
        </div>
        <p className="sr-only">{actionBtnDescription}</p>
      </div>
    </Tooltip>
  );
};

ActionButton.defaultProps = {
  color: 'primary',
  isIconButton: false,
  disabled: false,
  name: undefined,
  isMobile: false,
  tooltipPosition: 'bottom-start',
  variant: 'contained',
  showIcon: true,
  classes: '',
  tooltip: undefined,
  ariaLabel: '',
  allowKeyboardTab: false,
  actionBtnDescription: ''
};

ActionButton.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  isIconButton: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  showIcon: PropTypes.bool,
  classes: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaExpanded: PropTypes.bool.isRequired,
  actionBtnDescription: PropTypes.string,
  allowKeyboardTab: PropTypes.bool,
  intl: PropTypes.object.isRequired
};

export default injectIntl(ActionButton);
