import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const Release = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <g transform="matrix(0.014993, 0, 0, -0.014993, -4.472033, 26.983429)">
      <path d="M1262 1473 l-464 -186 103 -103 c57 -57 107 -104 112 -104 7 0 263
      192 525 393 6 5 12 7 12 4 0 -3 -90 -123 -200 -267 -110 -143 -200 -264 -200
      -268 0 -4 47 -54 105 -112 101 -100 104 -103 113 -79 6 13 89 222 186 463 96
      242 175 441 173 442 -1 1 -211 -81 -465 -183z" 
      />
      <path d="M988 944 l-75 -76 -64 30 c-102 47 -138 56 -234 57 -62 0 -106 -6
      -142 -18 -29 -10 -53 -20 -53 -22 0 -2 5 -16 12 -30 12 -28 20 -30 49 -14 11
      5 46 14 77 20 73 12 156 -3 244 -43 l67 -30 -55 -72 c-149 -194 -164 -329 -44
      -389 39 -19 61 -22 165 -22 103 0 125 3 155 21 55 32 80 77 80 143 0 81 -41
      161 -131 256 l-72 76 72 73 72 72 -24 22 -23 21 -76 -75z m12 -241 c132 -141
      146 -271 32 -303 -48 -13 -147 -13 -197 1 -89 24 -105 75 -55 175 37 73 127
      194 145 194 7 0 41 -30 75 -67z"
      />
    </g>
  </SvgIcon>
);

Release.defaultProps = {
  className: ''
};

Release.propTypes = {
  className: PropTypes.string
};

export default Release;