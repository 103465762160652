import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import DatePicker from './DatePicker';

class DatePickerContainer extends Component {
  handleDateChange = (date) => {
    const { updateDateActionDispatch } = this.props;
    updateDateActionDispatch(date.unix());
  };

  render() {
    const {
      hasButtonInput,
      buttonInputContent,
      buttonInputClass,
      dateOnly,
      timeOnly,
      selectedDate,
      showCustomActionButton,
      customActionButton
    } = this.props;

    return (
      <DatePicker
        selectedDate={selectedDate}
        hasButtonInput={hasButtonInput}
        buttonInputContent={buttonInputContent}
        buttonInputClass={buttonInputClass}
        handleDateChange={this.handleDateChange}
        dateOnly={dateOnly}
        timeOnly={timeOnly}
        showCustomActionButton={showCustomActionButton}
        customActionButton={customActionButton}
      />
    );
  }
}

DatePickerContainer.defaultProps = {
  hasButtonInput: false,
  buttonInputContent: {},
  buttonInputClass: '',
  dateOnly: false,
  timeOnly: false,
  selectedDate: moment(),
  showCustomActionButton: false,
  customActionButton: {}
};

DatePickerContainer.propTypes = {
  hasButtonInput: PropTypes.bool,
  buttonInputContent: PropTypes.object,
  buttonInputClass: PropTypes.string,
  updateDateActionDispatch: PropTypes.func.isRequired,
  dateOnly: PropTypes.bool,
  timeOnly: PropTypes.bool,
  selectedDate: PropTypes.object,
  showCustomActionButton: PropTypes.bool,
  customActionButton: PropTypes.object
};

export default DatePickerContainer;
