import PropTypes from 'prop-types';
import React from 'react';

import BottomNav from '../../../common/Nav/Bottom';
import OptionsDialog from '../../../common/Dialogs/Options';

const BottomActions = ({ isOpen, selectedOption, commands, toggleDialog, handleOption, handleConfirmAndClose }) => (
  <>
    <BottomNav
      position="bottom"
      buttonCenter={{
        text: 'Manage',
        handleAction: toggleDialog
      }}
    />
    <OptionsDialog
      isOpen={isOpen}
      handleClose={toggleDialog}
      handleOption={handleOption}
      handleConfirmAndClose={handleConfirmAndClose}
      options={commands}
      selectedOption={selectedOption}
    />
  </>
);

BottomActions.defaultProps = {
  commands: [],
  selectedOption: null
};

BottomActions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  handleOption: PropTypes.func.isRequired,
  handleConfirmAndClose: PropTypes.func.isRequired,
  commands: PropTypes.arrayOf(PropTypes.string),
  selectedOption: PropTypes.string
};

export default BottomActions;
