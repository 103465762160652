import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getCurrentGuid, getEncryptedMessageByGuid } from '../MessageList/ducks/MessageListReducer';
import MessageActivity from './MessageActivity';

class MessageActivityContainer extends Component {
  extractActivityData() {
    const { encryptedMessage } = this.props;
    const activityData = [];
    if (encryptedMessage.recipients) {
      encryptedMessage.recipients.forEach((recipient) => {
        if (recipient.firstRetrievalTime && recipient.firstRetrievalTime !== 0) {
          activityData.push({
            activityTitle: recipient.email.toLowerCase(),
            activitySubTitle: 'encryption.message.activity.firstViewed',
            activityTime: recipient.firstRetrievalTime * 1000
          });
          if (recipient.firstRetrievalTime !== recipient.lastRetrievalTime && recipient.lastRetrievalTime !== 0) {
            activityData.push({
              activityTitle: recipient.email.toLowerCase(),
              activitySubTitle: 'encryption.message.activity.lastViewed',
              activityTime: recipient.lastRetrievalTime * 1000
            });
          }
        }
      });
    }
    activityData.sort((a, b) => b.activityTime - a.activityTime);
    // Set default createTime if one is not found
    const createTime = encryptedMessage.createTime ? encryptedMessage.createTime * 1000 : new Date().getTime();
    activityData.push({
      activityTitle: 'encryption.message.activity.sent',
      activityTime: createTime
    });
    return activityData;
  }

  render() {
    const { isReady } = this.props;
    const activityData = this.extractActivityData();
    return <MessageActivity isReady={isReady} messageActivity={activityData} />;
  }
}

MessageActivityContainer.defaultProps = {
  encryptedMessage: {}
};

MessageActivityContainer.propTypes = {
  isReady: PropTypes.bool.isRequired,
  encryptedMessage: PropTypes.object
};

const mapStateToProps = (state) => {
  const props = {};
  props.encryptedMessage = getEncryptedMessageByGuid(state, getCurrentGuid(state));
  return props;
};

export default connect(mapStateToProps)(MessageActivityContainer);
