import { createReducer } from '../utils/reducerUtils';
import { intlActions } from './appTypes';

export const moduleName = 'intl';

const INITIAL_STATE = {
  lang: 'en',
  key: 'en',
  messages: {},
  defaultMessages: {},
  isReady: false
};

function setDefaultLanguage(state, action) {
  return {
    lang: action.payload.lang,
    key: action.payload.lang,
    messages: action.payload.messages,
    defaultMessages: action.payload.messages,
    isReady: true
  };
}

function setLocale(state, action) {
  return {
    lang: action.payload.lang,
    key: action.payload.lang,
    messages: { ...state.defaultMessages, ...action.payload.messages },
    defaultMessages: state.defaultMessages,
    isReady: true
  };
}

export default createReducer(INITIAL_STATE, {
  [intlActions.SET_DEFAULT_LANGUAGE]: setDefaultLanguage,
  [intlActions.SET_LOCALE]: setLocale
});

const allIntl = (state) => state[moduleName];

export const intlLanguage = (state) => allIntl(state).lang;

export const intlMessages = (state) => allIntl(state).messages;

export const isIntlReadySelector = (state) => allIntl(state) && allIntl(state).isReady;
