// This file contains a list of all the locales supported by the application
// We will load and add these locales in /src/index.js before we start the application
export const defaultLangId = 'en';
export const localeList = [
  'en',
  'de',
  'fr',
  'it',
  'es',
  'pl',  
  'ar',
  'bg',
  'cz',
  'da',
  'de',
  'el',
  'es',
  'fi',
  'fr',
  'he',
  'hi',
  'hu',
  'id',
  'it',
  'ja',
  'ko',
  'kz',
  'ms',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'si',
  'sk',
  'sl',
  'sv',
  'sw',
  'ta',
  'th',
  'tr',
  'uk',
  'ur',
  'vi',
  'zh-cn',
  'zh-tw'
];
