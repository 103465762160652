import { buttonElements } from '../constants/uiConstants';
import { isEmpty } from './strUtils';

export const sortButtonsByGroup = (leftCommand, rightCommand) => {
  if (isEmpty(buttonElements[leftCommand])) {
    return -1;
  }
  if (isEmpty(buttonElements[rightCommand])) {
    return 1;
  }
  return (buttonElements[leftCommand].groupIndex || 0) - (buttonElements[rightCommand].groupIndex || 0);
};

export const buttonElementRetriever = (command, forIndividualView = false) => {
  let buttonCommand;

  const commandLocal = `${command}_individual`;
  if (forIndividualView === true && commandLocal in buttonElements) {
    buttonCommand = commandLocal;
  } else {
    buttonCommand = command;
  }
  return buttonElements[buttonCommand];
};

export const getbuttonNameFromArray = (commands) =>
  commands.map((command) => {
    const buttonElement = buttonElementRetriever(command, true);
    return {
      command,
      label: buttonElement.intlId
    };
  });
