import { all, call, put, select, spawn, takeLatest } from 'redux-saga/effects';

import { actionAttempt, actionSuccess, apiActions, apiConstants } from '../app/ducks/apiConstants';
import { contentTypes } from '../app/constants/uiConstants';
import { decreaseFolderUnreadCount } from '../app/SideBar/ducks/sideBarActions';
import {
  fetchMessageBodyText,
  fetchMessagesCqsDisabled,
  fetchMessagesCqsEnabled,
  scrollSearchMessagesCqsEnabled
} from '../app/Quarantine/ducks/folderApi';
import {
  getCurrentGuid,
  getMessageBodyTextFromMessage,
  getMessageFromMessages,
  getMessageMeta,
  getMessages
} from '../app/Quarantine/ducks/messageReducer';
import { getCurrentEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import { getMessageBodyPath } from '../app/utils/routeUtils';
import { getPPRememberMeCookie } from '../app/utils/cookieUtils';
import { getStore } from '../store/storeUtil';
import { isEmpty } from '../app/utils/strUtils';
import { isMaxPages } from '../app/SideBar/ducks/folderReducer';
import { isMisCqsEnabled } from '../app/ducks/featureReducer';
import { messageListFilterUtils } from '../app/utils/filterUtils';
import { messageTypes } from '../app/Quarantine/ducks/messageTypes';
import { pushNotification } from '../app/common/Notifier/ducks/notifierActions';
import {
  resetMessages,
  setMessageEmptyStatus,
  showMessageBody,
  updateCurrentGuid,
  updateMessageBody,
  updateMessageCount
} from '../app/Quarantine/ducks/messageActions';
import { setCurrentKeyboardFocus } from '../app/ducks/uiStatusActions';
import apiHandler from '../app/ducks/utils/apiHandler';
import history from '../app/utils/historyUtils';
import logger from '../tools/logger/logger';

const log = logger.child({ childName: 'messageSaga' });

export function* handleGoToMessage(action) {
  const { guid, folderId } = action;
  if (!guid || !folderId) {
    log.error('No guid or folderId', action);
    return;
  }

  // Validate message
  const message = yield select(getMessageFromMessages, guid);
  if (!message) {
    log.error('Message not available', action);
    return;
  }

  // Decrease folder unread total count
  const messageMeta = (yield select(getMessageMeta))[guid];
  if (!message.read && (!messageMeta || !messageMeta.isRead)) {
    yield put(decreaseFolderUnreadCount(folderId));
  }

  const visibleMessages = yield select(messageListFilterUtils.getVisibleMessages);

  // No messages available for selection
  // Note: API may still return messages that have not been released or deleted due to
  // PPS event queues (which is actually a cron) running slow or down.
  if (Object.keys(visibleMessages).length === 0) {
    yield put(setMessageEmptyStatus());
    return;
  }

  // Mark as read and update current Guid
  yield put(updateCurrentGuid(guid, visibleMessages));

  // Fetch message body
  yield put(showMessageBody(guid, folderId));

  // Redirect to message
  yield call(history.push, getMessageBodyPath(folderId, guid));
}

export function* getMessageBodyText({ msgId, folderId }) {
  if (isEmpty(msgId) || isEmpty(folderId)) {
    return;
  }
  const misCqsEnabled = yield select(isMisCqsEnabled);
  const messages = yield select(getMessages);
  const username = yield select(getCurrentEmailAddressFromState);

  if (Object.keys(messages).length < 1) {
    yield call(
      apiHandler,
      misCqsEnabled ? fetchMessagesCqsEnabled({ folderId }) : fetchMessagesCqsDisabled({ username, folder: folderId })
    );
  }

  // if the message text already fetched
  if (yield select(getMessageBodyTextFromMessage, msgId)) {
    return;
  }
  // calling fetch message sync way
  const rawMesageBody = yield call(apiHandler, fetchMessageBodyText({ username, guid: msgId, folder: folderId }));
  if (rawMesageBody) {
    // update message in store
    yield put(updateMessageBody(msgId, rawMesageBody));
  }
}

export function* showMessageBodyHandler(action) {
  const { msgId, folderId } = action;
  try {
    yield call(getMessageBodyText, { msgId, folderId });
  } catch (err) {
    log.error(err, action);
  }
}

export function* executeMessageFolderCommandHandler(action) {
  const msgId = getCurrentGuid(getStore().getState());
  const folderId = action.requestParams.data.folder;
  try {
    yield call(getMessageBodyText, { msgId, folderId });
  } catch (err) {
    log.error(err, action);
  }
}

export function* executeMessageFolderCommandAttempt(action) {
  const { folder, guids, operation } = action.requestParams.data;
  const actionsReduceUnreadMsgCount = ['delete', 'release', 'releasewhitelist', 'msgblock&delete'];
  if (!actionsReduceUnreadMsgCount.includes(operation)) {
    return;
  }
  let i = 0;
  let count = 0;
  while (i < guids.length) {
    const message = yield select(getMessageFromMessages, guids[i]);
    const messageMeta = (yield select(getMessageMeta))[guids[i]];
    i++; // eslint-disable-line no-plusplus
    if (!message.read && (!messageMeta || !messageMeta.isRead)) {
      count++; // eslint-disable-line no-plusplus
    }
  }
  if (count > 0) {
    yield put(decreaseFolderUnreadCount(folder, count));
  }
}

export function* fetchApiMessagesHandler(action) {
  const { params, data } = action.requestParams || {};
  // ScrollId is passed when CQS v1 api calls are enabled
  // searchCursor is passed for scrolling for CQS v2 api calls
  if ((params && params.scrollId) || (data && (data.scrollId || data.searchCursor))) {
    return;
  }
  // Unset current guid and isSelected state
  const visibleMessages = yield select(messageListFilterUtils.getVisibleMessages);
  yield put(setCurrentKeyboardFocus(0, contentTypes.MESSAGE_LIST));
  yield put(updateCurrentGuid(null, visibleMessages));
  // Reset/Clear message
  yield put(resetMessages());
}

/**
 * Fetch all messages by folder and search string or offset
 * @param {Object} action
 */
export function* fetchMessagesHandler(action) {
  const misCqsEnabled = yield select(isMisCqsEnabled);

  if (!getPPRememberMeCookie()) {
    yield put(
      pushNotification({
        intlId: 'signin.session.expired',
        isError: true
      })
    );
  }

  const { offset, folderId, filterString, scrollId, forceRefresh, searchCursor } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  let searchString = filterString;

  if (!searchString) {
    searchString = yield select(messageListFilterUtils.getFilterStringFromState);
  }

  if (!folderId) {
    return;
  }

  let params = {};

  // Data to be passed to API when MIS CQS is enabled
  if (misCqsEnabled) {
    params = { ...params, count: apiConstants.pageSize, module: folderId };
    if (searchCursor) params.searchCursor = searchCursor;

    // Search data to be passed to API when filter search data is entered in the search field on messagelist
    // q replaces orFields, subject and sender in MIS CQS is disabled user accounts
    if (!isEmpty(searchString)) params.q = searchString;
  } else {
    // Data to be passed to API when MIS CQS feature is disabled
    params = { ...params, username, limit: apiConstants.pageSize, folder: folderId };
    if (!isEmpty(searchString)) {
      // Search data to be passed to API when filter search data is entered in the search field on messagelist
      params = { ...params, subject: searchString, sender: searchString, orFields: 'sender,subject' };
    }
    if (offset > 0) params.offset = offset;
    if (scrollId) params.scrollId = scrollId;
  }

  const isMaximumPages = yield select(isMaxPages, folderId);
  if (isMaximumPages && !forceRefresh) return;

  if (misCqsEnabled) {
    if (searchCursor) yield put(scrollSearchMessagesCqsEnabled(params));
    else if (!searchCursor) yield put(fetchMessagesCqsEnabled(params));
  }

  // Fetch and store api messages
  else yield put(fetchMessagesCqsDisabled(params));

  const visibleMessages = yield select(messageListFilterUtils.getVisibleMessages);

  // Determine how many messages are hidden to subtract from the total returned from the API.
  const getTotalHiddenMessages = yield select(messageListFilterUtils.getTotalHiddenMessages);

  yield put(updateMessageCount(visibleMessages, getTotalHiddenMessages));
}

export function* watchShowMessageBody() {
  yield takeLatest(messageTypes.SHOW_MESSAGE_BODY, showMessageBodyHandler);
}

export function* watchExecuteMessageFolderCommand() {
  yield takeLatest(actionSuccess(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND), executeMessageFolderCommandHandler);
  yield takeLatest(actionAttempt(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND), executeMessageFolderCommandAttempt);
}

export function* watchFetchMessages() {
  yield takeLatest(messageTypes.FETCH_MESSAGES, fetchMessagesHandler);
}

export function* watchApiFetchMessages() {
  yield takeLatest(actionAttempt(apiActions.FETCH_MESSAGES), fetchApiMessagesHandler);
}

export function* watchGoToMessage() {
  yield takeLatest(messageTypes.GO_TO_MESSAGE, handleGoToMessage);
}

/* root message Saga */
export default function* messageSaga() {
  yield all([
    spawn(watchShowMessageBody),
    spawn(watchExecuteMessageFolderCommand),
    spawn(watchApiFetchMessages),
    spawn(watchFetchMessages),
    spawn(watchGoToMessage)
  ]);
}
