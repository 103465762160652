import { appActions, appSettings } from './appConstants';
import { createReducer } from '../utils/reducerUtils';

export const moduleName = 'application';

const INITIAL_STATE = {
  [appSettings.SERVICE_ERROR]: 0,
  [appSettings.APPLICATION_INTIALIZED]: false
};

function serviceError(state) {
  return { ...state, ...{ [appSettings.SERVICE_ERROR]: state[appSettings.SERVICE_ERROR] + 1 } };
}

function serviceOk(state) {
  return { ...state, ...{ [appSettings.SERVICE_ERROR]: 0 } };
}

function applicationInitializeComplete(state) {
  return { ...state, ...{ [appSettings.APPLICATION_INTIALIZED]: true } };
}

export default createReducer(INITIAL_STATE, {
  [appActions.SERVICE_ERROR]: serviceError,
  [appActions.SERVICE_OK]: serviceOk,
  [appActions.APPLICATION_INITIALIZE_COMPLETE]: applicationInitializeComplete
});
