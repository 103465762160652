import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactPlaceholder from 'react-placeholder';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Visibility from '@material-ui/icons/Visibility';
import cx from 'classnames';

import './AccessList.scss';
import { getActiveRecipients, getFilteredEmails, getRevokedRecipients } from '../../utils/encryptionUtils';
import { renderString } from '../../utils/renderUtils';
import { restoreUser, revokeUser } from '../../Encryption/MessageOptions/ducks/MessageOptionsActions';
import AppSwitcherContainer from '../AppSwitcher/AppSwitcherContainer';
import Tooltip from '../Tooltip';
import UserBadge from '../UserBadge';
import withPrimaryStyles from '../withPrimaryStyles';

class AccessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: []
    };
    this.recipientListRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const { guid, showSingleMessageActions } = props;
    const { prevGuid, prevShowSingleMessageActions } = state;
    if (prevGuid !== guid || prevShowSingleMessageActions !== showSingleMessageActions) {
      return {
        selected: [],
        prevGuid: guid,
        prevShowSingleMessageActions: showSingleMessageActions
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { guid } = this.props;
    if (prevProps.guid !== guid) {
      this.recipientListRef.current.scrollTop = 0;
    }
  }

  handleClearSelected = () => {
    this.setState({
      selected: []
    });
  };

  handlePrimaryAction = (newStatus, selected, updateMessageStatus) => {
    const { guid, restoreUserDispatch, revokeUserDispatch } = this.props;
    // Ensure the same email isnt selected twice
    let selectedEmails = [];
    if (Array.isArray(selected)) {
      selectedEmails = selected;
    } else {
      selectedEmails[selected] = selected;
    }
    this.handleClearSelected();
    // newStatus:true = active
    // newStatus:false = revoked
    if (newStatus) {
      return revokeUserDispatch({
        guid,
        selectedEmails: this.validateRecipients(selectedEmails, newStatus),
        showNotification: true,
        updateMessageStatus
      });
    }
    return restoreUserDispatch({
      guid,
      selectedEmails: this.validateRecipients(selectedEmails, newStatus),
      showNotification: true,
      updateMessageStatus
    });
  };

  validateRecipients = (selectedRecipients, newStatus) => {
    const { data } = this.props;
    // newStatus:true = active
    // newStatus:false = revoked
    if (newStatus) {
      return getFilteredEmails(getActiveRecipients(data), selectedRecipients);
    }
    return getFilteredEmails(getRevokedRecipients(data), selectedRecipients);
  };

  handleMultiSelect = (actionType) => {
    const { data } = this.props;
    const { selected } = this.state;
    const status = actionType === 'revoke';
    const updateMessageStatus = data.length === Object.keys(selected).length;
    this.handlePrimaryAction(status, selected, updateMessageStatus);
  };

  handleCheckbox = (email) => {
    const { selected } = this.state;
    const newSelectedList = selected;
    if (this.isSelected(email)) {
      delete newSelectedList[email];
    } else {
      newSelectedList[email] = email;
    }
    this.setState({
      selected: newSelectedList
    });
  };

  isSelected = (email) => {
    const { selected } = this.state;
    return Boolean(selected[email]);
  };

  render() {
    const { isReady, data, title, showSingleMessageActions, classes } = this.props;
    const { selected } = this.state;

    const totalActiveRecipients = Object.keys(getActiveRecipients(data)).length;
    const totalRevokedRecipients = Object.keys(getRevokedRecipients(data)).length;

    return (
      <>
        <h3 className="desktop-heading">{renderString(title)}</h3>
        {Object.keys(selected).length > 0 && (
          <div className="multi-select-actions">
            <h4>
              {renderString(`encryption.recipients.table.selected`, { count: Object.keys(selected).length })}
              <Button disableRipple className="desktop-heading__button" onClick={() => this.handleClearSelected()}>
                {renderString('action.button.cancel')}
              </Button>
              <div className="multi-select-actions__primary">
                {totalActiveRecipients > 0 && (
                  <Button
                    disableRipple
                    className="desktop-heading__button--main"
                    onClick={() => this.handleMultiSelect('revoke')}
                  >
                    {renderString(`encryption.revoke.action`)}
                  </Button>
                )}
                {totalRevokedRecipients > 0 && (
                  <Button
                    disableRipple
                    className="desktop-heading__button--main"
                    onClick={() => this.handleMultiSelect('restore')}
                  >
                    {renderString(`encryption.restore.action`)}
                  </Button>
                )}
              </div>
            </h4>
          </div>
        )}
        {Object.keys(selected).length < 1 && <AppSwitcherContainer mobileSwitcher bottomNav />}
        <Paper className="list-container">
          <Table className="list-table">
            <TableHead>
              <TableRow className="list-row list-row--header">
                <TableCell className="list-row__cell list-row__cell--header" align="left" colSpan="2">
                  {renderString('encryption.recipients.table.recipients')}
                </TableCell>
                <TableCell
                  classes={{ root: 'list-row__cell list-row__cell--header list-row__cell--header--viewed' }}
                  align="right"
                  colSpan="2"
                >
                  {renderString('encryption.recipients.table.viewed')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="list-table__body" ref={this.recipientListRef}>
              {data.map((row) => {
                const isSelected = this.isSelected(row.email);
                const isActive = row.active;
                const actionType = isActive ? 'revoke' : 'restore';
                const tableCellClass = cx('list-row__cell', {
                  'list-row__cell--isRevoked': !isActive
                });
                const listButtonClass = cx('list-button', {
                  'list-button--hidden': Object.keys(selected).length > 0 || !showSingleMessageActions
                });
                const listCheckboxClass = cx('list-checkbox', {
                  'list-checkbox--hidden': !showSingleMessageActions
                });
                const recipientAvatarViewedClass = cx('recipient-avatar--viewed', {
                  [`${classes.secondaryBackgroundColor}`]: classes.secondaryBackgroundColor
                });
                const recipientAvatarClass = cx('recipient-avatar', {
                  [`${classes.secondaryColor}`]: classes.secondaryColor,
                  [`${classes.secondaryBorderColor}`]: classes.secondaryBorderColor
                });
                return (
                  <TableRow classes={{ root: 'list-row' }} key={row.email} hover role="row" tabIndex={-1}>
                    <TableCell className="list-row__cell">
                      <Checkbox
                        disableRipple
                        classes={{ checked: 'list-checkbox--selected' }}
                        className={listCheckboxClass}
                        checked={isSelected}
                        aria-checked={isSelected}
                        inputProps={{ 'aria-label': `${row.email} checkbox` }}
                        onClick={() => this.handleCheckbox(row.email)}
                      />
                    </TableCell>
                    <TableCell
                      classes={{ root: tableCellClass }}
                      className="list-row__cell--email"
                      component="th"
                      scope="row"
                    >
                      <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--tableRow">
                        <UserBadge
                          className={row.firstRetrievalTime > 0 ? recipientAvatarViewedClass : recipientAvatarClass}
                          name={row.email}
                        />{' '}
                        <span className="recipient-email">{row.email}</span>
                      </ReactPlaceholder>
                    </TableCell>
                    <TableCell classes={{ root: tableCellClass }} className="list-row__cell--viewed" align="right">
                      <Tooltip
                        title={
                          row.firstRetrievalTime > 0
                            ? renderString('encryption.recipients.table.viewed')
                            : renderString('encryption.recipients.table.unread')
                        }
                      >
                        <Visibility
                          color={row.firstRetrievalTime > 0 ? 'primary' : 'inherit'}
                          classes={{
                            root: row.firstRetrievalTime === 0 ? 'receiptIcon' : '',
                            colorPrimary: classes.secondaryColor
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell classes={{ root: 'list-row__cell list-row__cell--actions' }} align="right">
                      <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--tableAction">
                        <Button
                          className={listButtonClass}
                          type="button"
                          onClick={() => this.handlePrimaryAction(row.active, row.email, false)}
                        >
                          {renderString(`encryption.${actionType}.action`)}
                        </Button>
                      </ReactPlaceholder>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }
}

AccessList.defaultProps = {
  guid: null,
  data: [
    {
      firstRetrievalTime: 0,
      lastRetrievalTime: 0,
      email: '',
      active: true
    }
  ]
};

AccessList.propTypes = {
  isReady: PropTypes.bool.isRequired,
  guid: PropTypes.string,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  revokeUserDispatch: PropTypes.func.isRequired,
  restoreUserDispatch: PropTypes.func.isRequired,
  showSingleMessageActions: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(null, {
  revokeUserDispatch: revokeUser,
  restoreUserDispatch: restoreUser
})(withPrimaryStyles(AccessList));
