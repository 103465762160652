import AttachmentIcon from '@material-ui/icons/Attachment';
import BounceManagementIcon from '@material-ui/icons/AddCircle';
import CustomIcon from '@material-ui/icons/FolderOutlined';
import QuarantineIcon from '@material-ui/icons/EmailOutlined';
import React from 'react';
import RegulatoryComplianceIcon from '@material-ui/icons/AccountBalance';
import UntrustedSendersIcon from '@material-ui/icons/Person';
import UrlDefenseIcon from '@material-ui/icons/Language';
import VirusIcon from '@material-ui/icons/BugReport';
import ZeroHourIcon from '@material-ui/icons/HourglassEmpty';

import LowPriorityDeliveredIcon from '../common/Icons/LowPriorityDelivered';
import LowPriorityQuarantinedIcon from '../common/Icons/LowPriorityQuarantined';
import Spf from '../common/Icons/Spf';

// folderIntlId maps backend folder IDS to react-intl string IDs (as in /resources/en.js)
export const folderIntlId = Object.freeze({
  spam: 'folder.Spam.Quarantined',
  bulk_continue: 'folder.LowPriorityMail.Delivered',
  bulk_discard: 'folder.LowPriorityMail.Quarantined',
  audit: 'folder.AuditMessages',
  batv: 'folder.BounceManagement',
  pdr: 'folder.ReputationService',
  dkim: 'folder.DKIM',
  dkimv: 'folder.DKIMVerification',
  smtpsrv: 'folder.SMTPServer',
  access: 'folder.EmailFirewall',
  spf: 'folder.SenderPolicyFramework.SPF',
  sandbox: 'folder.AttachmentDefense',
  urldefense: 'folder.URLDefense',
  av: 'folder.Virus',
  zerohour: 'folder.ZeroHour',
  encrypt: 'folder.Encryption',
  asset: 'folder.DigitalAssets',
  regulation: 'folder.RegulatoryCompliance',
  dmarc: 'folder.Dmarc',
  circle_of_trust: 'folder.UntrustedSenders',
  safeList: 'sender.safelist',
  blockList: 'sender.blocklist',
  journal: 'folder.journal',
  banner: 'folder.banner'
});

export const folderType = Object.freeze({
  EMPTY: '',
  SPAM: 'spam',
  LOW_PRIORITY_DELIVERED: 'bulk_continue',
  LOW_PRIORITY_QUARANTINE: 'bulk_discard',
  AUDIT: 'audit',
  BOUNCE_MANAGEMENT: 'batv',
  REPUTATION: 'pdr',
  DKIM: 'dkim',
  DKIMV: 'dkimv',
  SMTP_SERVER: 'smtpsrv',
  EMAIL_FIREWALL: 'access',
  SENDER_POLICY_FRAMEWORK: 'spf',
  SANDBOX: 'sandbox',
  URL_DEFENSE: 'urldefense',
  VIRUS: 'av',
  ZERO_HOUR: 'zerohour',
  ENCRYPTION: 'encrypt',
  DIGITAL_ASSETS: 'asset',
  REGULATORY_COMPLIANCE: 'regulation',
  DMARC: 'dmarc',
  COT: 'circle_of_trust',
  INTERNAL_MAIL_DEFENCE: 'journal',
  EMAIL_WARNING_TAG: 'banner'
});

/* eslint-disable react/jsx-filename-extension */
export const folderList = {
  [folderType.EMPTY]: {
    icon: <CustomIcon />,
    intlId: null
  },
  [folderType.SPAM]: {
    icon: <QuarantineIcon />,
    intlId: folderIntlId.spam
  },
  [folderType.LOW_PRIORITY_DELIVERED]: {
    icon: <LowPriorityDeliveredIcon />,
    intlId: folderIntlId.bulk_continue
  },
  [folderType.LOW_PRIORITY_QUARANTINE]: {
    icon: <LowPriorityQuarantinedIcon />,
    intlId: folderIntlId.bulk_discard
  },
  [folderType.AUDIT]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.audit
  },
  [folderType.BOUNCE_MANAGEMENT]: {
    icon: <BounceManagementIcon className="u-rotate45" />,
    intlId: folderIntlId.batv
  },
  [folderType.REPUTATION]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.pdr
  },
  [folderType.DKIM]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.dkim
  },
  [folderType.DKIMV]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.dkimv
  },
  [folderType.SMTP_SERVER]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.smtpsrv
  },
  [folderType.EMAIL_FIREWALL]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.access
  },
  [folderType.SENDER_POLICY_FRAMEWORK]: {
    icon: <Spf />,
    intlId: folderIntlId.spf
  },
  [folderType.SANDBOX]: {
    icon: <AttachmentIcon className="u-rotate-90" />,
    intlId: folderIntlId.sandbox
  },
  [folderType.URL_DEFENSE]: {
    icon: <UrlDefenseIcon />,
    intlId: folderIntlId.urldefense
  },
  [folderType.VIRUS]: {
    icon: <VirusIcon />,
    intlId: folderIntlId.av
  },
  [folderType.ZERO_HOUR]: {
    icon: <ZeroHourIcon />,
    intlId: folderIntlId.zerohour
  },
  [folderType.ENCRYPTION]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.encrypt
  },
  [folderType.DIGITAL_ASSETS]: {
    icon: <AttachmentIcon />,
    intlId: folderIntlId.asset
  },
  [folderType.REGULATORY_COMPLIANCE]: {
    icon: <RegulatoryComplianceIcon />,
    intlId: folderIntlId.regulation
  },
  [folderType.DMARC]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.dmarc
  },
  [folderType.COT]: {
    icon: <UntrustedSendersIcon />,
    intlId: folderIntlId.circle_of_trust
  },
  [folderType.INTERNAL_MAIL_DEFENCE]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.journal
  },
  [folderType.EMAIL_WARNING_TAG]: {
    icon: <CustomIcon />,
    intlId: folderIntlId.banner
  }
};
