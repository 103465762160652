import PropTypes from 'prop-types';
import React from 'react';

import { getRedirectPath, getRedirectSearch } from '../../../utils/routeUtils';

/**
 * Redirects CEUWEB digest and bookmark URLS
 *
 * @param {Object} ComposedComponent
 * @return {(Object|null)}
 */
export default function(ComposedComponent) {
  const WithRedirect = (props) => {
    const { history } = props;
    const redirectPath = getRedirectPath();
    const redirectQuery = getRedirectSearch();

    // Redirect to original URL
    if (redirectPath) {
      history.push(`${redirectPath}${redirectQuery}`);
      return null;
    }

    // Otherwise continue and render the composed component
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ComposedComponent {...props} />;
  };

  WithRedirect.propTypes = {
    history: PropTypes.object.isRequired
  };

  return WithRedirect;
}
