import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react';

import './OptionsDialog.scss';
import { buttonElementRetriever } from '../../../utils/buttonUtils';
import { renderString } from '../../../utils/renderUtils';
import withPrimaryStyles from '../../withPrimaryStyles';

function OptionsDialog({ isOpen, selectedOption, options, handleClose, handleConfirmAndClose, handleOption, classes }) {
  return (
    <Dialog
      classes={{ root: 'c-options-dialog', paper: 'c-options-dialog__paper' }}
      fullWidth="sm"
      maxWidth="sm"
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="options-dialog"
      open={isOpen}
    >
      <DialogTitle className="c-options-dialog__title">{renderString('dialog.options.title')}</DialogTitle>
      <DialogContent className="c-options-dialog__content">
        <List className="o-list">
          {options.map((option) => {
            const buttonElement = buttonElementRetriever(option, true);
            return (
              buttonElement && (
                <ListItem className="o-list__item" key={option} button onClick={() => handleOption(option)}>
                  <ListItemIcon className="o-list__icon">
                    <Radio
                      checked={selectedOption === option}
                      classes={{ checked: classes.checked }}
                      value={option}
                      name="dialog-checkbox"
                      color="primary"
                      disableRipple
                      inputProps={{ 'aria-label': option }}
                      icon={<RadioButtonUncheckedIcon fontSize="small" />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                    />
                  </ListItemIcon>
                  <ListItemText primary={renderString(buttonElement.intlId)} />
                </ListItem>
              )
            );
          })}
        </List>
      </DialogContent>
      <DialogActions className="c-options-dialog__actions">
        <Button onClick={handleClose} color="primary">
          {renderString('action.button.cancel')}
        </Button>
        <Button onClick={handleConfirmAndClose} color="primary">
          {renderString('action.button.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

OptionsDialog.defaultProps = {
  isOpen: false,
  options: [],
  selectedOption: null
};

OptionsDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleOption: PropTypes.func.isRequired,
  handleConfirmAndClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  selectedOption: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(OptionsDialog);
