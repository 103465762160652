import { commandActions } from './appTypes';

export const scheduleFolderCommand = ({ command, folderId, guids = [] }) => ({
  type: commandActions.SCHEDULE_FOLDER_COMMAND,
  command,
  folderId,
  guids
});

export const scheduleDigestFolderCommand = ({ command, folderId, guids = [] }) => ({
  type: commandActions.SCHEDULE_DIGEST_FOLDER_COMMAND,
  command,
  folderId,
  guids
});

export const scheduleSenderListCommand = (command, configType, guids = []) => ({
  type: commandActions.SCHEDULE_CONFIG_COMMAND,
  command,
  configType,
  guids
});
