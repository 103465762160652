import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchMessages, goToMessage, updateCurrentGuid } from './ducks/messageActions';
import { getCurrentFolderId, getMobileSearchStatus } from '../ducks/uiStatusReducer';
import {
  getDefaultQuarantineFolder,
  getFolderErrorStatus,
  getFolderIdsFromState
} from '../SideBar/ducks/folderReducer';
import { getEmptyStatus, getReadyStatus } from './ducks/messageReducer';
import { getQuarantineFolderFromPath, isQuarantinePath } from '../utils/routeUtils';
import { isValidFolder } from '../utils/folderUtils';
import { navigateToFolder } from '../SideBar/ducks/sideBarActions';
import { scheduleFolderCommand } from '../ducks/commandActions';
import { setComponentDisable } from '../ducks/uiStatusActions';
import Quarantine from './Quarantine';
import { getManagingDelegate } from '../Settings/ducks/profileReducer';

class QuarantineContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAnimateDirection: ''
    };
  }

  /**
   * Fetch messages on initial load / hard refresh
   */
  componentDidMount() {
    const { history, fetchMessagesDispatch, folderIds } = this.props;
    const { pathname } = history.location;

    let folderId = getQuarantineFolderFromPath(pathname);

    // If folderId is corrupt use the first folder from state
    if (!isValidFolder(folderId)) {
      [folderId] = folderIds;
    }

    if (isQuarantinePath(pathname) && isValidFolder(folderId)) {
      fetchMessagesDispatch({
        folderId,
        offset: 0,
        forceRefresh: true
      });
    }
  }

  /**
   * Fetch messages on folder change
   * @param {object} prevProps
   */
  componentDidUpdate(prevProps) {
    const {
      currentFolderId,
      pathname,
      folderIds,
      navigateToFolderDispatch,
      fetchMessagesDispatch,
      isFolderError,
      managedDelegate
    } = this.props;
    const prevPathname = prevProps.pathname;
    const prevFolderId = getQuarantineFolderFromPath(prevPathname);

    // folderId in the pathname
    const folderIdInPathName = getQuarantineFolderFromPath(pathname);
    const validFolderIdInPathName = isValidFolder(folderIdInPathName);

    // Determine the folder Id based on the route.
    let nextFolderId = currentFolderId;

    // If folderId is corrupt use the first folder from state

    if (!isValidFolder(currentFolderId)) {
      [nextFolderId] = folderIds;
    }

    // Redirect from folder/ to folder/{nextFolderId}
    // if there is no currentFolderId in pathname (happens in accounts when there is no landing page)

    if (!validFolderIdInPathName && !isFolderError) {
      navigateToFolderDispatch(nextFolderId);
    }

    // Only fetch new messages if the folder has changed
    // or if there is no current folderId in the pathname (happens when there is no landing page)

    if (
      (!validFolderIdInPathName && !isFolderError) || (managedDelegate.email !== prevProps.managedDelegate.email) || 
      (isValidFolder(prevFolderId) && isValidFolder(nextFolderId) && nextFolderId !== prevFolderId)
    ) {
      fetchMessagesDispatch({
        folderId: nextFolderId,
        offset: 0,
        forceRefresh: true
      });
      return true;
    }

    return false;
  }

  handleViewAnimation = () => {
    const { viewAnimateDirection } = this.state;
    let getDirection = '';
    if (viewAnimateDirection === '' || viewAnimateDirection === 'right') {
      getDirection = 'left';
    } else {
      getDirection = 'right';
    }
    this.setState({ viewAnimateDirection: getDirection });
  };

  handleOnMessageClick = (nextGuid, animate = true) => {
    if (nextGuid) {
      const { fetchSingleMessageDispatch, currentFolderId } = this.props;
      fetchSingleMessageDispatch({
        guid: nextGuid,
        folderId: currentFolderId
      });
      if (animate) {
        this.handleViewAnimation();
      }
    }
  };

  handleMessageAction = (command, guids) => {
    const { scheduleFolderCommandDispatch, setComponentDisableDispatch, currentFolderId } = this.props;

    setComponentDisableDispatch('ActionButton', true);

    scheduleFolderCommandDispatch({
      command,
      folderId: currentFolderId,
      guids
    });
  };

  render() {
    const { isReady, isEmpty, managedDelegate, showMobileSearch, fetchMessagesDispatch, isFolderError } = this.props;
    const { viewAnimateDirection } = this.state;
    return (
      <Quarantine
        isReady={isReady}
        isFolderError={isFolderError}
        isEmpty={isEmpty}
        managedDelegate={managedDelegate}
        showMobileSearch={showMobileSearch}
        viewAnimateDirection={viewAnimateDirection}
        handleOnMessageClick={this.handleOnMessageClick}
        handleViewAnimation={this.handleViewAnimation}
        handleMessageAction={this.handleMessageAction}
        fetchMessagesDispatch={fetchMessagesDispatch}
      />
    );
  }
}

QuarantineContainer.defaultProps = {
  currentFolderId: '',
  showMobileSearch: false
};

QuarantineContainer.propTypes = {
  history: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  folderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentFolderId: PropTypes.string,
  isReady: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  showMobileSearch: PropTypes.bool,
  navigateToFolderDispatch: PropTypes.func.isRequired,
  fetchMessagesDispatch: PropTypes.func.isRequired,
  fetchSingleMessageDispatch: PropTypes.func.isRequired,
  scheduleFolderCommandDispatch: PropTypes.func.isRequired,
  setComponentDisableDispatch: PropTypes.func.isRequired,
  isFolderError: PropTypes.bool.isRequired,
  managedDelegate: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const props = {};
  props.pathname = ownProps.location.pathname;
  props.folderIds = getFolderIdsFromState(state);
  props.currentFolderId = getCurrentFolderId(state);
  props.defaultQuarantineFolder = getDefaultQuarantineFolder(state);
  props.isReady = getReadyStatus(state);
  props.isEmpty = getEmptyStatus(state);
  props.showMobileSearch = getMobileSearchStatus(state);
  props.isFolderError = getFolderErrorStatus(state);
  props.managedDelegate = getManagingDelegate(state)

  return props;
};

export default withRouter(
  connect(mapStateToProps, {
    navigateToFolderDispatch: navigateToFolder,
    fetchMessagesDispatch: fetchMessages,
    fetchSingleMessageDispatch: goToMessage,
    updateCurrentGuidDispatch: updateCurrentGuid,
    scheduleFolderCommandDispatch: scheduleFolderCommand,
    setComponentDisableDispatch: setComponentDisable,
    

  })(QuarantineContainer)
);
