import { apiActions } from '../../../ducks/apiConstants';
import { messageListTypes } from './MessageListTypes';

export const retrieveEncryptedMessages = (query) => query;

export const retrieveEncryptedMessagesCount = () => ({
  type: apiActions.FETCH_TOTAL_SENT_MESSAGES
});

export const setSelectedGuids = (guids) => ({
  type: apiActions.UPDATE_SELECTED_GUIDS,
  guids
});

export const setMessageReadyStatus = () => ({
  type: apiActions.SET_MESSAGE_READY
});

export const updateCurrentGuid = (nextGuid) => ({
  type: messageListTypes.UPDATE_CURRENT_ENCRYPTED_GUID,
  nextGuid
});

export const markEncryptedMessageAsChecked = (guid, isChecked = false) => ({
  type: messageListTypes.MARK_ENCRYPTED_MESSAGE_AS_CHECKED,
  guid,
  isChecked
});

export const resetCheckedEncryptedMessages = () => ({
  type: messageListTypes.RESET_CHECKED_ENCRYPTED_MESSAGES
});

export const retrieveRecentEncryptedMessages = () => ({
  type: messageListTypes.FETCH_RECENT_ENCRYPTED_MESSAGES
});
