import { Form, withFormik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { object as yupObject, string as yupString } from 'yup';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@proofpoint/fuse-ui/components/TextField';

import './SignIn.scss';
import { getPPRememberMeCookie, setPPRememberMeCookie } from '../utils/cookieUtils';
import { qaTags } from '../constants/qaConstants';
import CompanyLogo from './CompanyLogo';
import SignInFooter from './SignInFooter';

function SignIn({
  handleSubmit,
  handleBlur,
  handleChange,
  values,
  wasLoggedIn,
  errors,
  touched,
  isSubmitting,
  noFeatureEnabled,
  intl
}) {
  const moveCaretAtEnd = (e) => {
    const tempValue = e.target.value;
    e.target.value = '';
    e.target.value = tempValue;
  };

  const renderError = () => (
    <>
      {wasLoggedIn && !noFeatureEnabled && (
        <p className="session-error ">
          <FormattedMessage id="signin.session.expired" />
        </p>
      )}
      {noFeatureEnabled && (
        <p className="session-error ">
          <FormattedMessage id="signin.noFeaturesEnabled" />
        </p>
      )}
    </>
  );

  return (
    <div className="loginMainPanel">
      <div className="signinLogoPanel">
        <CompanyLogo url="https://www.proofpoint.com/" />
      </div>

      <div className="mainComponentContainerPanel">
        <div className="componentTopPanel">
          <h1 className="loginTitle">
            <FormattedMessage id="signin.welcome.header" />
          </h1>
          {renderError()}
          <Form onSubmit={handleSubmit}>
            <div>
              <TextField
                label={intl.formatMessage({ id: 'signin.label.EmailAddress' })}
                className="login-input-container input-login-password"
                name="email"
                inputProps={{
                  'aria-label': intl.formatMessage({ id: 'signin.label.EmailAddress' }),
                  'aria-describedby': 'emailError',
                  onFocus: moveCaretAtEnd,
                  onChange: handleChange,
                  type: 'email',
                  value: values.email,
                  onBlur: handleBlur
                }}
                id="emailId"
                error={touched.email && errors.email}
              />
              {touched.email && errors.email && (
                <div id="emailError" name={qaTags.SINGIN_EMAIL_ERROR_TEXT} className="input-signin-email--error">
                  {errors.email}
                </div>
              )}
            </div>
            <div>
              <button
                name={qaTags.LOGIN_SIGNIN_BUTTON}
                id="resetPasswordSubmitButton"
                type="submit"
                aria-label={intl.formatMessage({id:'signin.button.continue'})}
                disabled={isSubmitting}
                className="login-signin-button"
              >
                <FormattedMessage id="signin.button.continue" />
              </button>
            </div>
          </Form>
        </div>
      </div>
      <SignInFooter />
    </div>
  );
}

SignIn.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  wasLoggedIn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  noFeatureEnabled: PropTypes.bool.isRequired
};

export default withFormik({
  mapPropsToValues() {
    return {
      email: (atob(window.redirect.notThisUser) === 'false' && getPPRememberMeCookie()) || ''
    };
  },
  validationSchema: yupObject().shape({
    email: yupString()
      .email(<FormattedMessage id="form.invalid.email.address" />)
      .required(<FormattedMessage id="form.email.required" />)
  }),
  handleSubmit(values) {
    const emailAddress = values.email;
    setPPRememberMeCookie(emailAddress);
    window.location.replace(
      `${atob(window.redirect.url)}/v1/token?client_id=${atob(window.redirect.client)}&user_email=${encodeURIComponent(
        emailAddress
      )}`
    );
  }
})(injectIntl(SignIn));
