import React, { useCallback, useRef } from 'react';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
import Button from '@material-ui/core/Button';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

import './DatePicker.scss';
import { getLanguageId, getTimezone } from '../../utils/jsUtils';
import { renderString } from '../../utils/renderUtils';

const locale = getLanguageId();
if (!locale.includes('en')) {
  // eslint-disable-next-line no-unused-expressions
  import(`moment/locale/${locale}`);
}

const CustomDateTimePicker = ({
  hasButtonInput,
  buttonInputContent,
  buttonInputClass,
  selectedDate,
  handleDateChange,
  dateOnly,
  timeOnly,
  showCustomActionButton,
  customActionButton
}) => {
  const pickerRef = useRef(null);

  const openPicker = useCallback(
    (e) => {
      if (pickerRef.current) {
        pickerRef.current.open(e);
      }
    },
    [pickerRef.current]
  );

  moment.locale(locale);
  const timezone = getTimezone();

  let dateTime = false;
  if ((!dateOnly && !timeOnly) || (dateOnly && timeOnly)) {
    // render DateTimePicker if both dateOnly and timeOnly are true
    dateTime = true;
  }

  const renderHiddenTextField = () => <TextField className="date-picker-input date-picker-input--hidden" />;

  return (
    <>
      {hasButtonInput && dateTime && (
        <>
          <Button className={buttonInputClass} onClick={openPicker}>
            {buttonInputContent}
          </Button>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} moment={moment}>
            <DateTimePicker
              value={moment(selectedDate).locale(locale)}
              onChange={(date) => handleDateChange(date)}
              disablePast
              ref={pickerRef}
              TextFieldComponent={renderHiddenTextField}
              cancelLabel={renderString('action.button.cancel')}
              okLabel={renderString('action.button.ok')}
              showTodayButton={showCustomActionButton}
              todayLabel={customActionButton}
            />
          </MuiPickersUtilsProvider>
        </>
      )}
      {!hasButtonInput && dateTime && (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} moment={moment}>
          <DateTimePicker
            value={moment(selectedDate).locale(locale)}
            onChange={(date) => handleDateChange(date)}
            disablePast
            cancelLabel={renderString('action.button.cancel')}
            okLabel={renderString('action.button.ok')}
          />
        </MuiPickersUtilsProvider>
      )}
      {dateOnly && !dateTime && (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} moment={moment}>
          <DatePicker
            margin="normal"
            value={moment(selectedDate).locale(locale)}
            onChange={(date) => handleDateChange(date)}
            disablePast
            TextFieldComponent={() => (
              <TextField
                className="date-picker-input-date"
                label={renderString('encryption.expiry.option.date.input.label')}
                value={moment(selectedDate).format('ddd DD MMM')}
                onClick={openPicker}
                InputLabelProps={{ shrink: false }}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            )}
            ref={pickerRef}
            cancelLabel={renderString('action.button.cancel')}
            okLabel={renderString('action.button.ok')}
          />
        </MuiPickersUtilsProvider>
      )}
      {timeOnly && !dateTime && (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} moment={moment}>
          <TimePicker
            margin="normal"
            value={moment(selectedDate).locale(locale)}
            onChange={(date) => handleDateChange(date)}
            TextFieldComponent={() => (
              <TextField
                className="date-picker-input-time"
                label={renderString('encryption.expiry.option.time.input.label', { timezone })}
                value={moment(selectedDate).format('HH:mm')}
                onClick={openPicker}
                InputLabelProps={{ shrink: false }}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            )}
            ref={pickerRef}
            cancelLabel={renderString('action.button.cancel')}
            okLabel={renderString('action.button.ok')}
          />
        </MuiPickersUtilsProvider>
      )}
    </>
  );
};

CustomDateTimePicker.defaultProps = {
  hasButtonInput: false,
  buttonInputContent: {},
  buttonInputClass: '',
  selectedDate: moment(),
  dateOnly: false,
  timeOnly: false,
  showCustomActionButton: false,
  customActionButton: {}
};

CustomDateTimePicker.propTypes = {
  hasButtonInput: PropTypes.bool,
  buttonInputContent: PropTypes.object,
  buttonInputClass: PropTypes.string,
  selectedDate: PropTypes.object,
  handleDateChange: PropTypes.func.isRequired,
  dateOnly: PropTypes.bool,
  timeOnly: PropTypes.bool,
  showCustomActionButton: PropTypes.bool,
  customActionButton: PropTypes.object
};

export default CustomDateTimePicker;
