import { getStore } from '../../../../store/storeUtil';
import { API_CALL, apiActions, apiEndpoints } from '../../../ducks/apiConstants';
import { getManagingDelegate } from '../../../Settings/ducks/profileReducer';
import { isEmpty } from '../../../utils/strUtils';

// Set onBehalfOf in the payload of message related API calls only when managing delegates

export const getTotalMessages = (payload) => {
  const managingDelegate = getManagingDelegate(getStore().getState());

  const { username, ...data } = payload;
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.USERKEY_URL,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.FETCH_TOTAL_SENT_MESSAGES
    }
  };
};

export const getMessagesByType = (payload, actionType) => {
  const { username, ...data } = payload;
  const managingDelegate = getManagingDelegate(getStore().getState());

  return {
    [API_CALL]: {
      endpoint: apiEndpoints.USERKEY_URL,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType
    }
  };
};
