import PropTypes from 'prop-types';
import React from 'react';

import { renderString } from '../../../utils/renderUtils';
import BottomNav from '../../../common/Nav/Bottom';

const BottomActions = ({ handleRevokeMessage, handleRestoreMessage }) => (
  <>
    <BottomNav
      position="bottom"
      buttonLeft={{
        text: renderString('encryption.revoke.action'),
        handleAction: handleRevokeMessage
      }}
      buttonRight={{
        text: renderString('encryption.restore.action'),
        handleAction: handleRestoreMessage
      }}
    />
  </>
);

BottomActions.propTypes = {
  handleRestoreMessage: PropTypes.func.isRequired,
  handleRevokeMessage: PropTypes.func.isRequired
};

export default BottomActions;
