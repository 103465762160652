export const profileTypes = Object.freeze({
  FETCH_PROFILE: 'FETCH_PROFILE',
  FETCH_PROFILE_AND_SPAM: 'FETCH_PROFILE_AND_SPAM',
  SUBMIT_PROFILE: 'SUBMIT_PROFILE',
  SET_PROFILE: 'SET_PROFILE',  
  SUBMIT_MANAGE_DELEGATE_CHANGE:'SUBMIT_MANAGE_DELEGATE_CHANGE',
  SET_SPAM_POLICY_OPTION_DEFAULT: 'SET_SPAM_POLICY_OPTION_DEFAULT',
  SET_USER_PROFILE: 'SET_USER_PROFILE'
});

export default profileTypes;
