/**
 * @module middleware
 */
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import immutableStateInvariant from 'redux-immutable-state-invariant'; // eslint-disable-line import/no-extraneous-dependencies

import apiMiddleware from './apiMiddleware';
import history from '../app/utils/historyUtils';

export default function() {
  // Build the middleware for intercepting and dispatching navigation actions
  const reduxRouterMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    reduxRouterMiddleware,
    apiMiddleware, // custome api middleware using axios plugin
    sagaMiddleware
  ];

  if (process.env.NODE_ENV !== 'production') {
    middleware.unshift(immutableStateInvariant());
  }

  return {
    middleware,
    sagaMiddleware
  };
}
