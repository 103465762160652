import { all, call, put, select, spawn, takeLatest } from 'redux-saga/effects';

import { APP_NAMES } from '../app/ducks/appConstants';
import { actionSuccess, apiActions } from '../app/ducks/apiConstants';
import {
  addFilterString,
  updateFilteredRowItems,
  updateSelectedRowItems
} from '../app/common/ActionBar/Search/ducks/searchActions';
import { contentTypes, storageKeys } from '../app/constants/uiConstants';
import { fetchFolderCount } from '../app/Quarantine/ducks/folderApi';
import {  getCurrentEmailAddressFromState, getLoggedInUserEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import { getFolderPath, getHomePath, getSecureMessageCenterPath } from '../app/utils/routeUtils';
import { getNotificationCountInQueue } from '../app/common/Notifier/ducks/notifierReducer';
import { loadHiddenRowItems } from '../app/common/RowItem/ducks/rowItemActions';
import { messageTypes } from '../app/Quarantine/ducks/messageTypes';
import { moduleType, uiStatusActions } from '../app/ducks/appTypes';
import {
  resetCheckedEncryptedMessages,
  updateCurrentGuid
} from '../app/Encryption/MessageList/ducks/MessageListActions';
import { resetCheckedMessages } from '../app/Quarantine/ducks/messageActions';
import {
  setComponentDisable,
  setCurrentApp,
  setFolderListKeyboardFocus,
  setMessageListKeyboardFocus,
  setSenderListKeyboardFocus,
  toggleMobileViewSideBar
} from '../app/ducks/uiStatusActions';
import { sideBarTypes } from '../app/SideBar/ducks/sideBarTypes';
import apiHandler from '../app/ducks/utils/apiHandler';
import history from '../app/utils/historyUtils';
import logger from '../tools/logger';
import { removeFromStorage } from '../app/utils/strUtils';

const log = logger.child({ childName: 'sideBarSaga' });

export function* navigateToFolder(action) {
  const { folderId } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  try {
    yield put(loadHiddenRowItems(username, moduleType.MESSAGE_LIST));
    yield put(updateSelectedRowItems(moduleType.MESSAGE_LIST));
    yield put(updateFilteredRowItems(moduleType.MESSAGE_LIST));
    yield put(addFilterString(moduleType.MESSAGE_LIST));
    yield put(setCurrentApp('quarantine'));
    yield put(setComponentDisable('ActionButton', false));
    yield put(resetCheckedMessages());
    yield call(history.push, getFolderPath(folderId));
    yield call(
      apiHandler,
      fetchFolderCount({
        username,
        folderId,
        includeTotalUnreadMessageCount: true
      })
    );
  } catch (err) {
    log.error(`fetching folder error: ${username}: ${err}`);
  }
}

export function* navigationToEncryption() {
  try {
    yield call(history.push, getSecureMessageCenterPath());
    yield put(setCurrentApp('keyManagement'));
  } catch (err) {
    log.error('Could not navigate to encryption');
  }
}

export function* navigateToLandingPage() {
  try {
    yield call(history.push, getHomePath());
    yield put(setCurrentApp(APP_NAMES.landingPage));
    yield put(updateCurrentGuid(''));
  } catch (err) {
    log.error('Could not navigate to landing page');
  }
}

export function* setCurrentKeyboardFocus(action) {
  switch (action.componentName) {
    case contentTypes.FOLDER_LIST: {
      yield put(setFolderListKeyboardFocus(action.focus));
      break;
    }
    case contentTypes.MESSAGE_LIST: {
      yield put(setMessageListKeyboardFocus(action.focus));
      break;
    }
    case contentTypes.SENDER_LIST: {
      yield put(setSenderListKeyboardFocus(action.focus));
      break;
    }
    default:
      break;
  }
}
export function* setMessageListFocus() {
  yield setTimeout(() => {
    const section = document.querySelector('div#virtualized-list');
    if (section) {
      section.focus();
      const message = document.querySelector("div.singleMessage--quarantine[tabindex='0']");
      if (message) message.focus();
    }
  }, 100);
}

export function* setMoreActionsFocus({ showMessageActions }) {
  const notificationCount = yield select(getNotificationCountInQueue);
  if (!showMessageActions && notificationCount === 0) {
    yield setMessageListFocus();
  }
}

export function* showMobileSidebar() {
  try {
    yield put(toggleMobileViewSideBar());
    // Clear any checked messages
    yield put(resetCheckedMessages());
    yield put(resetCheckedEncryptedMessages());
    // Enable single message actions
    yield put(setComponentDisable('ActionButton', false));
    yield put(setComponentDisable('SingleEncryptionActions', false));
  } catch (err) {
    log.error(`showMobileSidebar error: ${err}`);
  }
}

export function* showDesktopAppSwitcher() {
  try {
    // Clear any checked messages
    yield put(resetCheckedMessages());
    yield put(resetCheckedEncryptedMessages());
    // Enable single message actions
    yield put(setComponentDisable('ActionButton', false));
    yield put(setComponentDisable('SingleEncryptionActions', false));
  } catch (err) {
    log.error(`showDesktopAppSwitcher error: ${err}`);
  }
}

export function* logout() {
  try {
    const username = yield select(getLoggedInUserEmailAddressFromState);
    yield call(removeFromStorage,sessionStorage, storageKeys.STORAGE_KEY_FOR_DELEGATE);
    const url = `${atob(window.redirect.url)}/v1/token/logout?client_id=${atob(
      window.redirect.client
    )}&logout=true&user_email=${username}`;
    window.location.replace(url);
  } catch (err) {
    log.error('Could not logout');
  }
}

export function* watchsideBarNavigation() {
  yield takeLatest(sideBarTypes.NAVIGATE_TO_FOLDER, navigateToFolder);
  yield takeLatest(uiStatusActions.SET_CURRENT_KEYBOARD_FOCUS, setCurrentKeyboardFocus);
  yield takeLatest(messageTypes.SHOW_MESSAGE_ACTIONS, setMoreActionsFocus);
  yield takeLatest(messageTypes.RESET_CHECKED_MESSAGES, setMessageListFocus);
  yield takeLatest(actionSuccess(apiActions.FETCH_MESSAGES), setMessageListFocus);
  yield takeLatest(actionSuccess(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND), setMessageListFocus);
  yield takeLatest(sideBarTypes.NAVIGATE_TO_SECURE_MESSAGE_CENTER, navigationToEncryption);
  yield takeLatest(uiStatusActions.SHOW_MOBILE_VIEW_SIDEBAR, showMobileSidebar);
  yield takeLatest(uiStatusActions.SHOW_APP_SWITCHER, showDesktopAppSwitcher);
  yield takeLatest(sideBarTypes.NAVIGATE_TO_LANDING_PAGE, navigateToLandingPage);
  yield takeLatest(sideBarTypes.LOGOUT, logout);
}

/* root sideBar Saga */
export default function* sideBarSaga() {
  yield all([spawn(watchsideBarNavigation)]);
}
