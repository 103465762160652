import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const MarkAsSpam = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <g transform="matrix(0.014117, 0, 0, -0.014117, -3.105815, 25.506723)">
      <path d="M1480 1555 c-215 -59 -320 -298 -221 -500 36 -74 91 -125 171 -164
      46 -21 69 -26 140 -26 77 0 92 3 152 33 82 40 149 111 178 190 30 79 26 205
      -10 277 -73 150 -252 233 -410 190z m191 -70 c24 -9 53 -22 64 -31 18 -14 10
      -24 -180 -214 -110 -110 -204 -199 -210 -197 -5 2 -21 25 -35 52 -32 66 -34
      169 -4 236 62 135 224 203 365 154z m160 -147 c92 -180 -47 -408 -248 -409
      -69 -1 -128 15 -164 42 l-24 18 199 200 c110 110 203 198 208 195 5 -3 18 -24
      29 -46z" 
      />
      <path d="M490 1377 c-14 -7 -33 -28 -43 -47 -16 -32 -17 -67 -15 -383 l3 -349
      33 -29 32 -29 458 0 c345 0 463 3 482 13 40 20 60 72 60 159 l0 76 -32 7 c-97
      21 -199 94 -258 186 l-36 57 -95 -60 c-52 -33 -99 -62 -106 -65 -7 -3 -109 55
      -227 129 l-215 133 -1 58 0 58 28 -15 c15 -9 112 -69 216 -134 l190 -120 80
      50 c45 28 85 54 90 59 5 5 8 37 7 71 -1 35 5 89 14 121 8 32 15 60 15 62 0 3
      -147 5 -327 5 -242 0 -335 -4 -353 -13z"
      />
      <path d="M220 779 l0 -391 29 -29 29 -29 501 0 501 0 0 45 0 45 -480 0 -480 0
      0 375 0 375 -50 0 -50 0 0 -391z"
      />
    </g>
  </SvgIcon>
);

MarkAsSpam.defaultProps = {
  className: ''
};

MarkAsSpam.propTypes = {
  className: PropTypes.string
};

export default MarkAsSpam;
