import { all, call, put, spawn, take } from 'redux-saga/effects';
import isUndefined from 'lodash/isUndefined';

import { pushNotification } from '../app/common/Notifier/ducks/notifierActions';
import { serviceError } from '../app/ducks/appActions';
import { unAuthenticatedError } from '../app/SignIn/ducks/authActions';
import ApiError from '../app/utils/error/ApiError';
import logger from '../tools/logger';

const log = logger.child({ childName: 'errorSaga' });

export const NETWORK_ERROR = 'Network Error';

function* pushErrorMessage(intlId) {
  yield put(
    pushNotification({
      intlId,
      isError: true
    })
  );
}

function* handleAuthExpired() {
  yield put(unAuthenticatedError());
}

/**
 * http://www.restapitutorial.com/httpstatuscodes.html
 * @description handle all action with suffix _ERROR or _FAIL
 * @export
 * @param {any} action action end with suffix _ERROR or _FAIL
 */
export function* failureOrErrorHandler(action) {
  const { error, requestParams } = action;
  if (isUndefined(error.response) && error.message === NETWORK_ERROR) {
    yield call(pushErrorMessage, 'error.poor.network');
    return;
  }
  if (error && error instanceof ApiError) {
    log.error(
      `Unhandled service error: ${action.type} : ${error && error.status} - ${error &&
        error.data &&
        error.data.errorMessage}`
    );

    // 401 is special case, it does not return error.data = null, so no need to parse error.data object
    if (error.status === 401) {
      yield call(handleAuthExpired);
      return;
    }
    const errMsg = error && error.data && error.data.errorMessage;
    if (error.status >= 500 && error.status <= 599) {
      yield put(serviceError(error, requestParams));
      yield call(pushErrorMessage, errMsg);
      return;
    }
    if (error.status === 400 /* &&   check for auth specific api response */) {
      yield call(pushErrorMessage, errMsg);
    }
  } else {
    log.error(`Unhandled service error: ${action.type} : ${error}`);
  }
}

export function* watchFailureOrErrorAction() {
  while (true) {
    const action = yield take((currAction) => currAction.type.endsWith('_ERROR') || currAction.type.endsWith('_FAIL'));
    try {
      yield call(failureOrErrorHandler, action);
    } catch (err) {
      log.error(err, action);
    }
  }
}

/* root api error Saga */
export default function* errorHandlereSaga() {
  yield all([spawn(watchFailureOrErrorAction)]);
}
