import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';

const DefaultMessage = ({ filteredItems, isBeingFiltered, folderId, name, className, square, elevation }) => (
  <Paper name={name} className={className} square={square} elevation={elevation}>
    <FormattedMessage id={filteredItems < 1 && isBeingFiltered ? 'no.search.result.found' : folderId} />
  </Paper>
);

DefaultMessage.propTypes = {
  filteredItems: PropTypes.number.isRequired,
  isBeingFiltered: PropTypes.bool.isRequired,
  folderId: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string.isRequired,
  square: PropTypes.bool,
  elevation: PropTypes.number
};

DefaultMessage.defaultProps = {
  name: null,
  square: false,
  elevation: 1
};

export default DefaultMessage;
