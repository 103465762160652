import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { intlLanguage, intlMessages } from '../../../ducks/intlReducer';

/**
 * This function will map the current redux state to the props for the component that it is "connected" to.
 * When the state of the redux store changes, this function will be called, if the props that come out of
 * this function are different, then the component that is wrapped is re-rendered.
 *
 * Source: https://stackoverflow.com/questions/35776663/react-intl-multi-language-app-changing-languages-and-translations-storage
 * @param {*} state
 */
function mapStateToProps(state) {
  const lang = intlLanguage(state);
  const messages = intlMessages(state);
  return { locale: lang, key: lang, messages };
}
export default connect(mapStateToProps)(IntlProvider);
