import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { addSelectedSettingsOption, addSettingsPath, removeSettingsPath } from '../ducks/uiStatusActions';
import { getSelectedSettingsOption, getSettingsPath } from '../ducks/uiStatusReducer';
import { isSettingsPath } from '../utils/uiStatusUtils';
import Settings from './Settings';
import { getAlternateEmailAddresses, getDisplayName, getProfileLoadingStatus } from './ducks/profileReducer';


const SettingsContainer = ({
  showSettings,
  settingsPath,
  selectedSettingsOption,
  addSettingsPathDispatch,
  removeSettingsPathDispatch,
  addSelectedSettingsOptionDispatch,
  displayName, 
  primaryEmailAddress, 
  aliases,
  isProfileUpdating,
  managedDelegate
}) => {
  return (
    <>
      <Settings
        showSettings={showSettings}
        closeFunc={removeSettingsPathDispatch}
        settingsPath={settingsPath}
        displayName={displayName}
        primaryEmailAddress={primaryEmailAddress}
        aliases={aliases}
        managedDelegate={managedDelegate}
        isProfileUpdating={isProfileUpdating}
        selectedSettingsOption={selectedSettingsOption}
        addSelectedSettingsOption={addSelectedSettingsOptionDispatch}
        addSettingsPath={addSettingsPathDispatch}
        removeSettingsPath={removeSettingsPathDispatch}
      />
    </>
  );
};

SettingsContainer.defaultProps = {
  settingsPath: null,
  selectedSettingsOption: ''
};

SettingsContainer.propTypes = {
  addSettingsPathDispatch: PropTypes.func.isRequired,
  addSelectedSettingsOptionDispatch: PropTypes.func.isRequired,
  removeSettingsPathDispatch: PropTypes.func.isRequired,
  settingsPath: PropTypes.string,
  selectedSettingsOption: PropTypes.string,
  showSettings: PropTypes.bool.isRequired,
  primaryEmailAddress: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  aliases: PropTypes.arrayOf(PropTypes.string).isRequired,
  isProfileUpdating: PropTypes.bool.isRequired,
  managedDelegate: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const props = {};
  const { profile } = state;
  props.primaryEmailAddress = profile.primaryEmailAddress;
  props.displayName = getDisplayName(state);
  props.aliases = getAlternateEmailAddresses(state);
  const settingsPath = getSettingsPath(state);
  props.selectedSettingsOption = getSelectedSettingsOption(state);
  props.showSettings = isSettingsPath(settingsPath, '/settings');

  const settingsPathMatch = settingsPath.match(/\/[^/]*\/([^/]*).*/);
  props.settingsPath = settingsPathMatch == null ? null : settingsPathMatch[1];
  props.isProfileUpdating = getProfileLoadingStatus(state);  

  return props;
};

const mapDispatchToProps = () => ({
  addSettingsPathDispatch: addSettingsPath,
  removeSettingsPathDispatch: removeSettingsPath,
  addSelectedSettingsOptionDispatch: addSelectedSettingsOption
});

export default connect(mapStateToProps, mapDispatchToProps())(SettingsContainer);
