import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getStringFromStorage } from './utils/strUtils';
import { fetchQuarantineFolders } from './Quarantine/ducks/folderActions';
import { getBrandingColorPrimaryFromState } from './ducks/configReducer';
import { getCurrentApp } from './utils/featureUtils';
import { getCurrentEmailAddressFromState, getManagingDelegate } from './Settings/ducks/profileReducer';
import { getEnabledFeaturesCount, getFeature, showLandingPage } from './ducks/featureReducer';
import { getMobileSearchStatus } from './ducks/uiStatusReducer';
import { isBrowserSupportedSelector } from './utils/uiUtils';
import { isFirstTimeLoginSelector, isUserAuthenticated } from './utils/loginUtils';
import { isHomePath } from './utils/routeUtils';
import { isIntlReadySelector } from './ducks/intlReducer';
import { retrieveConfig } from './ducks/configActions';
import { setCurrentApp } from './ducks/uiStatusActions';
import App from './App';
import { fetchProfile, setManagingDelegate } from './Settings/ducks/profileActions';
import { storageKeys } from './constants/uiConstants';

class AppContainer extends Component {
  componentDidMount() {
    const {
      retrieveConfigDispatch,
      isLoggedIn,
      isQuarantineFeatureOn,
      fetchFoldersDispatch,
      setCurrentAppDispatch,
      isLandingPageFeatureOn,
      setManagingDelegateDispatch,
      userEmailAddress,
      fetchProfileDispatch
    } = this.props;
    
    if (isLoggedIn) {
      // Store branding in state
      retrieveConfigDispatch('brandingLogoSmall');
      retrieveConfigDispatch('brandingColorPrimary');
      const urlParams = new URLSearchParams(window.location.search);
      // Getting email from the digest url
      // if there is account in url params, delegate must be set. 
      const delegateAccountEmailfromDigest = urlParams.get('account');
      // Getting userId from the sessionStorage if the page was refreshed while managing a delegate
      const delegateAccountUserIdFromSession =  getStringFromStorage(sessionStorage, storageKeys.STORAGE_KEY_FOR_DELEGATE);      
      if(delegateAccountEmailfromDigest){
        setManagingDelegateDispatch({email: delegateAccountEmailfromDigest});
        fetchProfileDispatch(userEmailAddress);
      }else if(delegateAccountUserIdFromSession){
        setManagingDelegateDispatch({userId: delegateAccountUserIdFromSession});
        fetchProfileDispatch(userEmailAddress);
      }
  
      if (isQuarantineFeatureOn) {
        // Store Quarantine folders in state
        fetchFoldersDispatch();
      }
      setCurrentAppDispatch(getCurrentApp(window.location.pathname, isLandingPageFeatureOn, true));
    }
  }

  shouldComponentUpdate(nextProps){  
    let update = true;
    Object.entries(this.props).forEach(([key, val]) => {
      if((JSON.stringify(nextProps[key]) !== JSON.stringify(val) )){
        // Do not re render the component when randomly generated location 'key' in 'location' object changes.
        if(key==='location') update = false;  
      }    
    })    
    return update;  
  }

  componentDidUpdate(prevProps) {
    const {
      isQuarantineFeatureOn,
      isLandingPageFeatureOn,
      fetchFoldersDispatch,
      setCurrentAppDispatch,
      managedDelegate
      
    } = this.props;


    if ((isQuarantineFeatureOn !== prevProps.isQuarantineFeatureOn) || 
    ((managedDelegate.primaryEmailAddress !== prevProps.managedDelegate.primaryEmailAddress))) {
      fetchFoldersDispatch();
    }

    if (isLandingPageFeatureOn !== prevProps.isLandingPageFeatureOn) {
      setCurrentAppDispatch(getCurrentApp(window.location.pathname, isLandingPageFeatureOn, true));
    }
  }

  render() {
    const {
      isIntlReady,
      isBrowserSupported,
      isFirstTimeLogin,
      brandingColorPrimary,
      isLandingPageFeatureOn,
      isEncryptionFeatureOn,
      featuresEnabledCount,
      showMobileSearch,
      userEmailAddress,
      managedDelegate
    } = this.props;

    if (!isIntlReady) {
      return null;
    }

    if (!isBrowserSupported) {
      // TODO: redirect to route Not-supported browser page
    }
    if (!isFirstTimeLogin) {
      // TODO: redirect to landing page
    }
    return (
      // JssProvider allows customizing the JSS styling solution.
      <App
        toggleMobileViewSearch={this.toggleMobileViewSearch}
        showMobileSearch={showMobileSearch}
        colorPrimary={brandingColorPrimary}
        showLandingPage={isLandingPageFeatureOn}
        featuresEnabledCount={featuresEnabledCount}
        isEncryptionFeatureOn={isEncryptionFeatureOn}
        isLandingPage={isHomePath(window.location.pathname)}
        userEmailAddress={userEmailAddress}
        managedDelegate={managedDelegate}
      />
    );
  }
}

AppContainer.defaultProps = {
  brandingColorPrimary: null,
  showMobileSearch: false
};

AppContainer.propTypes = {
  isBrowserSupported: PropTypes.bool.isRequired,
  isFirstTimeLogin: PropTypes.bool.isRequired,
  isIntlReady: PropTypes.bool.isRequired,
  retrieveConfigDispatch: PropTypes.func.isRequired,
  brandingColorPrimary: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  isEncryptionFeatureOn: PropTypes.bool.isRequired,
  isQuarantineFeatureOn: PropTypes.bool.isRequired,
  isLandingPageFeatureOn: PropTypes.bool.isRequired,
  fetchFoldersDispatch: PropTypes.func.isRequired,
  setCurrentAppDispatch: PropTypes.func.isRequired,
  fetchProfileDispatch: PropTypes.func.isRequired,
  setManagingDelegateDispatch: PropTypes.func.isRequired,
  featuresEnabledCount: PropTypes.number.isRequired,
  showMobileSearch: PropTypes.bool,
  userEmailAddress: PropTypes.string.isRequired,
  managedDelegate: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const props = {};
  // pass props from store
  props.isBrowserSupported = isBrowserSupportedSelector(state);
  props.isFirstTimeLogin = isFirstTimeLoginSelector(state);
  props.isIntlReady = isIntlReadySelector(state);
  props.brandingColorPrimary = getBrandingColorPrimaryFromState(state);
  props.isLoggedIn = isUserAuthenticated(state);
  props.isEncryptionFeatureOn = getFeature(state, 'keyManagement');
  props.isQuarantineFeatureOn = getFeature(state, 'quarantine');
  props.isLandingPageFeatureOn = showLandingPage(state);
  props.featuresEnabledCount = getEnabledFeaturesCount(state);
  props.showMobileSearch = getMobileSearchStatus(state);
  props.userEmailAddress = getCurrentEmailAddressFromState(state);
  props.managedDelegate = getManagingDelegate(state)
  return props;
};

export default 
    connect(mapStateToProps, {
      fetchFoldersDispatch: fetchQuarantineFolders,
      retrieveConfigDispatch: retrieveConfig,
      setCurrentAppDispatch: setCurrentApp,
      fetchProfileDispatch: fetchProfile,
      setManagingDelegateDispatch: setManagingDelegate
    })(AppContainer)
;
