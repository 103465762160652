/**
 * Error class for an Api that does not conform to the Api definition
 *
 * @class InvalidApi
 * @access public
 * @param {array} validationErrors - an array of validation errors
 */
function InvalidApi(message) {
  this.name = 'InvalidApi';
  this.message = message;
}

InvalidApi.prototype = new Error();
InvalidApi.prototype.constructor = InvalidApi;

export default InvalidApi;
