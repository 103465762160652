import { authActions } from './authConstants';
import { createReducer } from '../../utils/reducerUtils';

export const moduleName = 'auth';

const INITIAL_STATE = {
  authenticated: false,
  wasLoggedIn: false,
  authenticatedError: ''
};

function autheticated(state) {
  return { ...state, authenticated: true };
}

function unAutheticated(state) {
  return { ...state, authenticated: false };
}

function toggleWasLoggedIn(state) {
  return { ...state, wasLoggedIn: !this.wasLoggedIn };
}

function autheticatedError(state, action) {
  return { authenticated: false, authenticatedError: action.error };
}

export default createReducer(INITIAL_STATE, {
  [authActions.AUTHENTICATED]: autheticated,
  [authActions.UNAUTHENTICATED]: unAutheticated,
  [authActions.TOGGLEWASLOGGEDIN]: toggleWasLoggedIn,
  [authActions.AUTHENTICATION_ERROR]: autheticatedError
});

const allAuth = (state) => state[moduleName];

export const wasLoggedInSelector = (state) => allAuth(state).wasLoggedIn;

export const isAuthenticatedSelector = (state) => allAuth(state).authenticated;
