import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const ReleaseAndApprove = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <g transform="matrix(0.012841, 0, 0, -0.012841, -0.847961, 25.485519)">
      <path d="M905 1507 c-247 -99 -452 -182 -455 -184 -2 -2 44 -51 102 -110 l106
  -106 263 201 c145 111 265 199 267 197 2 -2 -87 -121 -198 -266 l-201 -263
  105 -105 c58 -58 107 -103 110 -101 5 6 366 908 366 915 0 9 -11 5 -465 -178z"
      />
      <path d="M629 974 l-76 -76 -61 31 c-138 70 -275 80 -404 29 -26 -10 -27 -11
  -13 -39 15 -28 15 -28 57 -13 100 35 220 24 329 -31 l51 -26 -26 -32 c-226
  -281 -203 -441 65 -455 156 -8 247 40 264 137 14 86 -55 226 -158 316 l-52 45
  73 73 73 73 -24 22 -23 21 -75 -75z m31 -252 c84 -98 113 -200 70 -254 -48
  -62 -256 -63 -318 -1 -20 20 -23 30 -17 61 10 53 50 127 112 209 l55 73 29
  -20 c15 -12 46 -42 69 -68z"
      />
      <path d="M1730 675 c-74 -74 -139 -135 -145 -135 -5 0 -42 33 -82 72 l-73 73
  -35 -35 -35 -35 113 -113 112 -112 175 175 175 175 -35 35 -35 35 -135 -135z"
      />
    </g>
    <path d="M 19.576 4.783 C 18.507 5.13 17.989 6.219 18.418 7.22 C 18.902 8.35 20.42 8.636 21.291 7.772 C 21.665 7.391 21.788 7.077 21.788 6.492 C 21.781 5.763 21.468 5.232 20.835 4.913 C 20.542 4.763 19.855 4.688 19.576 4.783 Z" />
    <path d="M 18.793 9.957 C 17.724 10.188 17.037 10.481 16.573 10.896 C 16.138 11.291 16.104 11.407 16.104 12.448 L 16.104 13.367 L 20.052 13.367 L 24 13.367 L 24 12.448 C 24 11.407 23.966 11.291 23.531 10.896 C 22.591 10.045 20.386 9.61 18.793 9.957 Z" />
  </SvgIcon>
);

ReleaseAndApprove.defaultProps = {
  className: ''
};

ReleaseAndApprove.propTypes = {
  className: PropTypes.string
};

export default ReleaseAndApprove;