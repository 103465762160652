import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const Quarantine = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <path d="M10,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,8 C22,6.9 21.1,6 20,6 L12,6 L10,4 Z" />
    <g transform="translate(12.000000, 10.000000)" fill="#FFFFFF" fillRule="nonzero">
      <path d="M4,0 C1.8,0 0,1.8 0,4 C0,6.2 1.8,8 4,8 C6.2,8 8,6.2 8,4 C8,1.8 6.2,0 4,0 Z M0.8,4 C0.8,2.24 2.24,0.8 4,0.8 C4.72,0.8 5.4,1.04 5.96,1.48 L1.48,5.96 C1.04,5.4 0.8,4.72 0.8,4 Z M4,7.2 C3.28,7.2 2.6,6.96 2.04,6.52 L6.52,2.04 C6.96,2.6 7.2,3.28 7.2,4 C7.2,5.76 5.76,7.2 4,7.2 Z" />
    </g>
  </SvgIcon>
);

Quarantine.defaultProps = {
  className: ''
};

Quarantine.propTypes = {
  className: PropTypes.string
};

export default Quarantine;
