import FolderIcon from '@material-ui/icons/FolderOutlined';
import React from 'react';

import './Icons.scss';

const Spf = () => (
  <div className="c-spf-icon">
    <FolderIcon />
  </div>
);

export default Spf;
