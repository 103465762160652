import React from 'react';

import LandingPageSpinner from '../Quarantine/LandingPageSpinner/LandingPageSpinner';

const DigestPage = () => (
  <>
    <div className="region-contentView">
      <LandingPageSpinner showSpinner />
    </div>
  </>
);

export default DigestPage;
