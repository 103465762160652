import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Proptypes from 'prop-types';
import React from 'react';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import cx from 'classnames';

import './Alert.scss';

function renderIcon(type) {
  switch (type) {
    case 'error':
      return <ErrorIcon />;
    case 'success':
      return <SuccessIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'info':
    default:
      return <InfoIcon />;
  }
}

function Alert({ message, type }) {
  const wrapperClass = cx('alert', {
    [`alert--${type}`]: type
  });

  return (
    <section className={wrapperClass}>
      <Grid container direction="row" alignItems="center">
        <Grid item>{renderIcon(type)}</Grid>
        <Grid item>{message}</Grid>
      </Grid>
    </section>
  );
}

Alert.defaultProps = {
  type: 'info'
};

Alert.propTypes = {
  type: Proptypes.oneOf(['warning', 'info', 'success', 'error']),
  message: Proptypes.oneOfType([Proptypes.string, Proptypes.object]).isRequired
};

export default Alert;
