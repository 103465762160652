import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const BlockDomain = ({ className }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={`icon-container ${className}`}>
    <path
      fillRule="evenodd"
      d="M16.983 20.333C26.2 20.333 33.667 27.8 33.667 
      37c0 9.2-7.467 16.667-16.684 16.667C7.783 53.667.333 46.2.333 37c0-9.2 7.45-16.667 
      16.65-16.667Zm3.2 23.334h-6.366c.716 2.383 1.8 4.6 3.183 6.6 1.383-2 2.467-4.217 
      3.183-6.6Zm-9.8 0H5.467a13.311 13.311 0 0 0 7.216 5.933c-1-1.85-1.766-3.85-2.3-5.933Zm18.15 
      0h-4.916a26.082 26.082 0 0 1-2.3 5.933 13.383 13.383 0 0 0 7.216-5.933Zm-18.8-10H4.1A13.736 
      13.736 0 0 0 3.667 37c0 1.15.166 2.267.433 3.333h5.633C9.6 39.233 9.5 38.133 9.5 
      37s.1-2.233.233-3.333Zm11.167 0h-7.8c-.15 1.083-.267 2.2-.267 3.333s.117 2.233.267 
      3.333h7.8c.15-1.1.267-2.2.267-3.333s-.117-2.25-.267-3.333Zm9 0h-5.633c.133 1.1.233 
      2.2.233 3.333s-.1 2.233-.233 3.333H29.9c.267-1.066.433-2.183.433-3.333 
      0-1.15-.166-2.267-.433-3.333ZM49.5 12.75c7.59 0 13.75 6.16 13.75 13.75S57.09 40.25 49.5 
      40.25 35.75 34.09 35.75 26.5s6.16-13.75 13.75-13.75Zm8.676 7.012L42.763 35.176A10.866 
      10.866 0 0 0 49.5 37.5c6.078 0 11-4.922 11-11 0-2.544-.866-4.881-2.324-6.738ZM49.5 
      15.5c-6.078 0-11 4.922-11 11 0 2.544.866 4.881 2.324 6.737l15.413-15.413A10.866 
      10.866 0 0 0 49.5 15.5Zm-28.183 8.9c1 1.85 1.766 3.85 2.3 5.933h4.916a13.383 
      13.383 0 0 0-7.216-5.933ZM17 23.733a23.478 23.478 0 0 0-3.183 
      6.6h6.366c-.716-2.383-1.8-4.6-3.183-6.6Zm-4.317.667a13.311 13.311 0 0 0-7.216 
      5.933h4.916a26.082 26.082 0 0 1 2.3-5.933Z"
    />
  </SvgIcon>
);

BlockDomain.defaultProps = {
  className: ''
};

BlockDomain.propTypes = {
  className: PropTypes.string
};

export default BlockDomain;
