import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getCurrentGuid, getEncryptedMessageByGuid } from '../MessageList/ducks/MessageListReducer';
import MessageOptions from './MessageOptions';

class MessageOptionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      showExpiryOptions: false,
      showMobileExpiryOptions: false,
      anchorEl: null
    };
  }

  handleTabChange = (event, activeTabIndex) => {
    this.setState({ activeTabIndex });
  };

  toggleExpiryMenu = (event) => {
    const { showExpiryOptions } = this.state;

    this.setState({ showExpiryOptions: !showExpiryOptions });
    this.setState({ showMobileExpiryOptions: false });
    if (!showExpiryOptions) {
      this.setState({ anchorEl: null });
      if (event) {
        this.setState({ anchorEl: event.currentTarget });
      }
    }
  };

  toggleMobileExpiryMenu = () => {
    const { showMobileExpiryOptions } = this.state;
    this.setState({ showMobileExpiryOptions: !showMobileExpiryOptions });
    this.setState({ showExpiryOptions: false });
  };

  render() {
    const { activeTabIndex, showExpiryOptions, anchorEl, showMobileExpiryOptions } = this.state;
    const {
      viewAnimateDirection,
      handleViewAnimation,
      encryptedMessage,
      isReady,
      isEmpty,
      brandingColorPrimary,
      encryptedMessageCount,
      showSingleMessageActions
    } = this.props;
    return (
      <MessageOptions
        activeTabIndex={activeTabIndex}
        handleTabChange={this.handleTabChange}
        viewAnimateDirection={viewAnimateDirection}
        handleViewAnimation={handleViewAnimation}
        encryptedMessage={encryptedMessage}
        isReady={isReady}
        isEmpty={isEmpty}
        brandingColorPrimary={brandingColorPrimary}
        showExpiryOptions={showExpiryOptions}
        showMobileExpiryOptions={showMobileExpiryOptions}
        toggleExpiryMenu={this.toggleExpiryMenu}
        toggleMobileExpiryMenu={this.toggleMobileExpiryMenu}
        anchorEl={anchorEl}
        encryptedMessageCount={encryptedMessageCount}
        showSingleMessageActions={showSingleMessageActions}
      />
    );
  }
}

MessageOptionsContainer.defaultProps = {
  encryptedMessage: {}
};

MessageOptionsContainer.propTypes = {
  viewAnimateDirection: PropTypes.string.isRequired,
  handleViewAnimation: PropTypes.func.isRequired,
  encryptedMessage: PropTypes.object,
  isReady: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  brandingColorPrimary: PropTypes.string.isRequired,
  encryptedMessageCount: PropTypes.number.isRequired,
  showSingleMessageActions: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.currentGuid = getCurrentGuid(state);
  props.encryptedMessage = getEncryptedMessageByGuid(state, props.currentGuid);
  return props;
};

export default connect(mapStateToProps)(MessageOptionsContainer);
