import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const MarkAsPhish = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <circle cx="7.732" cy="6.603" r="3.384" />
    <circle fill="white" cx="7.653" cy="6.589" r="1.392" />
    <path d="M 6.647 9.387 L 6.647 16.96 C 6.561 17.119 6.474 17.296 6.388 17.494 C 6.289 17.717 6.153 17.92 5.975 18.101 C 5.692 18.391 5.338 18.59 4.915 18.698 C 4.591 18.78 4.256 18.792 3.911 18.731 C 3.707 18.695 3.514 18.619 3.332 18.505 C 3.106 18.361 2.89 18.202 2.686 18.027 C 2.523 17.888 2.377 17.721 2.33 17.486 C 2.304 17.355 2.244 17.225 2.231 17.086 C 2.216 16.938 2.105 16.809 2.197 16.614 L 4.497 16.614 L 0.003 12.2 L 0 17.313 C 0.031 17.484 0.057 17.667 0.078 17.863 C 0.102 18.074 0.188 18.269 0.335 18.448 C 0.427 18.558 0.514 18.67 0.596 18.787 C 0.695 18.926 0.796 19.061 0.901 19.193 C 1.019 19.343 1.151 19.481 1.296 19.608 C 1.406 19.703 1.517 19.799 1.627 19.893 C 1.798 20.038 1.983 20.165 2.181 20.274 C 2.384 20.386 2.598 20.479 2.82 20.552 C 3.026 20.62 3.236 20.669 3.452 20.7 C 3.593 20.72 3.738 20.748 3.886 20.784 C 4.229 20.87 4.583 20.868 4.947 20.781 C 5.284 20.699 5.617 20.6 5.945 20.486 C 6.148 20.415 6.343 20.328 6.532 20.222 C 6.735 20.107 6.94 19.993 7.145 19.882 C 7.337 19.777 7.503 19.637 7.646 19.458 C 7.788 19.281 7.937 19.114 8.092 18.955 C 8.272 18.772 8.407 18.555 8.497 18.3 C 8.571 18.089 8.651 17.88 8.739 17.674 C 8.809 17.507 8.837 17.335 8.823 17.155 L 8.823 9.391 L 6.647 9.387 Z" />
    <path d="M 17.449 4.473 C 14.248 5.351 12.686 8.909 14.159 11.916 C 14.695 13.018 15.514 13.778 16.704 14.358 C 17.39 14.671 17.732 14.745 18.789 14.745 C 19.935 14.745 20.158 14.701 21.051 14.254 C 22.273 13.658 23.27 12.602 23.702 11.425 C 24.148 10.249 24.089 8.374 23.553 7.301 C 22.466 5.068 19.801 3.832 17.449 4.473 Z M 20.292 5.515 C 20.65 5.648 21.082 5.842 21.245 5.977 C 21.513 6.185 21.394 6.334 18.565 9.162 C 16.928 10.8 15.529 12.125 15.439 12.095 C 15.365 12.065 15.126 11.723 14.918 11.321 C 14.441 10.339 14.412 8.804 14.858 7.808 C 15.781 5.797 18.193 4.786 20.292 5.515 Z M 22.674 7.703 C 24.044 10.383 21.975 13.778 18.983 13.792 C 17.955 13.807 17.077 13.569 16.54 13.167 L 16.184 12.899 L 19.147 9.922 C 20.784 8.284 22.169 6.974 22.242 7.019 C 22.317 7.063 22.511 7.376 22.674 7.703 Z" />
  </SvgIcon>
);

MarkAsPhish.defaultProps = {
  className: ''
};

MarkAsPhish.propTypes = {
  className: PropTypes.string
};

export default MarkAsPhish;
