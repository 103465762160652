import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getMobileViewSidebarStatus } from '../../ducks/uiStatusReducer';
import { showMobileViewSideBar } from '../../ducks/uiStatusActions';
import SideBar from './SideBar';
import SideBarContentContainer from '../../SideBar/Content/SideBarContentContainer';

class SideBarContainer extends PureComponent {
  render() {
    const { shouldShowMobileViewSideBar, showMobileViewSideBarDispatch } = this.props;
    return (
      <SideBar toggleMobileView={showMobileViewSideBarDispatch} shouldShowMobileView={shouldShowMobileViewSideBar}>
        <SideBarContentContainer />
      </SideBar>
    );
  }
}

SideBarContainer.propTypes = {
  shouldShowMobileViewSideBar: PropTypes.bool.isRequired,
  showMobileViewSideBarDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  shouldShowMobileViewSideBar: getMobileViewSidebarStatus(state)
});

const mapDispatchToProps = () => ({
  showMobileViewSideBarDispatch: showMobileViewSideBar
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(SideBarContainer);
