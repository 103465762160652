import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  darkBackgroundColor: {
    'background-color': theme.palette.primary.dark
  },
  darkColor: {
    color: theme.palette.secondary.dark
  },
  lightBackgroundColor: {
    'background-color': theme.palette.primary.light
  },
  selectedText: {
    color: theme.palette.secondary.main
  },
  lightColor: {
    color: theme.palette.primary.light
  },
  selectedState: {
    'background-color': lighten(theme.palette.secondary.main, 0.92),
    'border-left': `4px solid ${theme.palette.secondary.main}`
  },
  focusedState: {
    '&:hover,&:focus': {
      backgroundColor: lighten(theme.palette.primary.main, 0.92)
    }
  },
  hoverState: {
    'background-color': theme.palette.action.hover,
    'border-left': 'none',
    outline: 'none'
  },
  primaryColor: {
    color: theme.palette.primary.main,
    '& > p': {
      color: theme.palette.primary.main
    }
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
    '& > p': {
      color: theme.palette.secondary.main
    }
  },
  darkSecondaryColor10: {
    color: darken(theme.palette.primary.main, 0.1),
    '& > p': {
      color: darken(theme.palette.primary.main, 0.1)
    }
  },
  errorColor: {
    color: theme.palette.error.main,
    '& > p': {
      color: theme.palette.error.main
    }
  },
  checked: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    }
  },
  primaryBorderColor: {
    'border-color': theme.palette.primary.main
  },
  secondaryBorderColor: {
    'border-color': theme.palette.secondary.main
  },
  primarySelectedBar: {
    '&:before': {
      background: theme.palette.secondary.main
    }
  },
  primaryBackgroundColor: {
    'background-color': theme.palette.primary.main
  },
  primaryBackgroundColor10: {
    'background-color': lighten(theme.palette.secondary.main, 0.9),
    '&:hover': {
      'background-color': lighten(theme.palette.secondary.main, 0.9)
    },
    '&:focus': {
      'background-color': lighten(theme.palette.secondary.main, 0.9)
    },
    '&:checked': {
      'background-color': lighten(theme.palette.secondary.main, 0.9)
    }
  },
  primaryBackgroundColor25: {
    'background-color': lighten(theme.palette.primary.main, 0.75)
  },
  primaryBorderColor10: {
    'border-color': lighten(theme.palette.primary.main, 0.9)
  },
  primaryBorderColor25: {
    'border-color': lighten(theme.palette.primary.main, 0.75)
  },
  secondaryBackgroundColor: {
    'background-color': theme.palette.secondary.main
  },
  lightColorLeftBorder: {
    'border-left': `3px solid ${theme.palette.primary.light}`
  }
});

export default withStyles(styles);
