import React, { useState } from 'react';
import StopIcon from '@material-ui/icons/Stop';
import HelpIcon from '@material-ui/icons/Help';

import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Hidden } from '@material-ui/core';

import { useSelector } from 'react-redux';
import AccountCircle from '@material-ui/icons/AccountCircle';
import './NavRightContainer.scss';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import { qaTags } from '../../../constants/qaConstants';
import { renderString } from '../../../utils/renderUtils';
import { isEmpty } from '../../../utils/strUtils';
import UserSettingsMenu from '../UserSettingsMenu/UserSettingsMenu';
import Tooltip from '../../Tooltip';

import { getLoggedInUserEmailAddressFromState, getLoggedInUserName, getDelegatedUsers } from '../../../Settings/ducks/profileReducer';
import { navConstant } from '../../../constants/uiConstants';


const NavRightContainer = (props) => {
  const {toggleShowSettings, redirectToHelpPage, navType, managedDelegate, intl, logout, openStopManagementConfirmationDialog} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const loggedInUserName  = useSelector((state)=>getLoggedInUserName(state))
  const loggedInUserEmail = useSelector((state)=>getLoggedInUserEmailAddressFromState(state))
  const delegatedUsers = useSelector((state)=>getDelegatedUsers(state))
  const isMobileView = (navType === navConstant.BASIC_NAV);
  const isManagingDelegate = !isEmpty(managedDelegate);
  const hasDelegatedUsers = !isEmpty(delegatedUsers);
  const stopManagingTooltip = intl.formatMessage( {id: 'stop.managing.user'}, { username: managedDelegate.displayName });
  const managingDelegateClass = cx('nav-account-btn', {'nav-account-btn__managing': isManagingDelegate && !isMobileView});
  const mobileViewClass = cx({'mng-delegate-mobile-view': isMobileView && isManagingDelegate});

  const onAccountDropdownClicked = (evt) => {
    setAnchorEl(evt.currentTarget)
  };

  const handleClose = () => {    
    setAnchorEl(null);
  };
  
  return (<div className="nav-right-container">              
      {!isMobileView && 
        <Tooltip title={renderString('profile.Settings')} placement="bottom-start">
          <IconButton
            className="nav-account-btn"
            aria-label="Settings"
            aria-haspopup="true"
            color="inherit"
            onClick={toggleShowSettings}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>}

        {!isMobileView && 
        <Tooltip title={renderString('tooltip.nav.help')} placement="bottom-start">
          <IconButton
            className="nav-account-btn"            
            aria-label={intl.formatMessage({ id: 'tooltip.nav.help' })}            
            aria-haspopup="true"
            color="inherit"
            onClick={redirectToHelpPage}
          >
            <HelpIcon />
          </IconButton>
        </Tooltip>}

      {/* Display User icon in the nav bar only when in desktop mode or in mobile view only when there is are delegates assigned */}
      <Hidden smUp>
        {hasDelegatedUsers && 
          <IconButton
            className={managingDelegateClass}
            aria-label="User Account"
            aria-haspopup="true"
            color="inherit"
            onClick={onAccountDropdownClicked}
          >            
            <div>
              <AccountCircle name={qaTags.NAV_USER_ACCOUNT} />
            </div>                                 
          </IconButton>}  
      </Hidden>

      {/* Show Managing delegate header in mobile vire only when the managedelegate settings dropdown is not open (when anchorEl is null) */}
       <span className={mobileViewClass}>
       {isManagingDelegate && 
          <Tooltip title={stopManagingTooltip} placement="bottom-start">
            <IconButton
              className={managingDelegateClass}
              aria-label={intl.formatMessage({ id: 'stop.managing' })}
              aria-haspopup="true"
              color="inherit"
              onClick={()=>{
                openStopManagementConfirmationDialog(); 
                if(navType === navConstant.BASIC_NAV){
                  handleClose();
                }
              }}
            >
              <div className='stop-circle-wrapper'>
                <StopIcon name={qaTags.NAV_USER_ACCOUNT} /> 
              </div>             
            </IconButton>                  
          </Tooltip>  }

      {(!isMobileView || (isMobileView && isManagingDelegate) ) && 
        <Tooltip title="User Account" placement="bottom-start">
          <IconButton
            className={managingDelegateClass}
            aria-label="User Account"
            aria-haspopup="true"
            color="inherit"
            onClick={onAccountDropdownClicked}
          >            
            <div>
              {!isMobileView && <AccountCircle name={qaTags.NAV_USER_ACCOUNT} /> }
              {isManagingDelegate && <span className='managing-label'>{renderString('managing')}<span> :</span></span>}
              {(!isMobileView || (isMobileView && isManagingDelegate) )&& <span className="display-name"> {isManagingDelegate? (managedDelegate.displayName || managedDelegate.email ) : loggedInUserName } </span>                    }
            </div>                                                                                     
          </IconButton>                  
        </Tooltip>}
      </span>
               
      {/* UserSettingsMenu is the manage delegate dropdown in NavBar */}
      <UserSettingsMenu 
        logout={logout} 
        loggedInUserName={loggedInUserName}
        navType={navType}
        loggedInUserEmail={loggedInUserEmail}
        delegatedUsers={delegatedUsers}
        managedDelegate={managedDelegate}
        openStopManagementConfirmationDialog={openStopManagementConfirmationDialog}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    </div>
  );
}

NavRightContainer.defaultProps = {
  managedDelegate: {}
};

NavRightContainer.propTypes = {
  toggleShowSettings: PropTypes.func.isRequired,
  redirectToHelpPage: PropTypes.func.isRequired,
  managedDelegate: PropTypes.object,
  logout: PropTypes.func.isRequired,
  navType: PropTypes.string.isRequired,
  openStopManagementConfirmationDialog: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};


export default (NavRightContainer);
