import { createSelector } from 'reselect';
import filter from 'lodash/filter';

import { actionError, actionSuccess, apiActions } from '../../../ducks/apiConstants';
import { createReducer } from '../../../utils/reducerUtils';
import { getTotalDateGroups, groupEncryptedMsgsByDate } from '../../../utils/dateUtils';
import { messageListTypes } from './MessageListTypes';

export const moduleName = 'encryptedMessages';

const INITIAL_STATE = {
  totalMessages: 0, // Total sent messages
  totalResults: 0, // Total filtered messages in resultset
  isReady: false, // Successful API response
  isEmpty: false, // No resultset
  firstGuid: null, // First messages guid
  currentGuid: null, // Current Message Id
  searchString: '', // Stored search string
  checkedGuids: {}, // Multi-Selected guids
  totalRows: 0, // Total date groups
  keys: {}, // Message object resultset
  meta: {} // Meta data that relates to the message guid but not returned from the API
};

function onFetchTotalMessagesSuccess(state, action) {
  const {
    response: {
      result: { totalResults }
    }
  } = action;
  return { ...state, totalMessages: totalResults || 0, totalResults: totalResults || 0, totalRows: totalResults || 0 };
}

function setMessageReadyStatus(state) {
  return {
    ...state,
    isReady: !state.isReady
  };
}

function onFetchMessagesSuccess(state, action) {
  const { searchString } = state;

  const {
    response: {
      entities: { keys },
      result: { totalResults }
    },
    requestParams: {
      data: { toUser }
    }
  } = action;

  // Remove the search wildcard appended to either side of string
  const prevSearch = toUser ? toUser.slice(1, -1) : '';

  const filterChanged = action.type.replace('_SUCCESS', '') !== state.filterBy;
  const searchChanged = !filterChanged && searchString !== prevSearch;

  // Determine if resultset should be appended to existing state
  let messageKeys;
  if (filterChanged || searchChanged) {
    messageKeys = keys;
  } else {
    messageKeys = { ...state.keys, ...keys };
  }

  const isEmpty = !messageKeys || (messageKeys && Object.entries(messageKeys).length === 0);

  return {
    ...state,
    totalMessages: state.totalMessages || 0,
    totalResults: totalResults || 0,
    isReady: true,
    isEmpty,
    firstGuid: messageKeys && Object.keys(messageKeys)[0],
    filterBy: action.type.replace('_SUCCESS', ''),
    searchString: prevSearch,
    totalRows: getTotalDateGroups(messageKeys) + totalResults,
    keys: messageKeys || {}
  };
}

function onFetchMessagesError(state, action) {
  const {
    requestParams: {
      data: { toUser }
    }
  } = action;

  const prevSearch = toUser ? toUser.slice(1, -1) : '';

  return { ...state, totalResults: 0, isReady: true, isEmpty: true, searchString: prevSearch };
}

function onUpdateMessageSuccess(state, action) {
  const { response } = action;
  const { guid } = response;
  // Update current guid in state by response
  return { ...state, keys: { ...state.keys, [guid]: response } };
}

const onUpdateMessageError = (state) => state;

function onUpdateExpirySuccess(state, action) {
  const {
    requestParams: {
      guid,
      data: { newExpireTime }
    }
  } = action;
  return {
    ...state,
    keys: {
      ...state.keys,
      ...{
        [guid]: {
          ...state.keys[guid],
          ...{ expireTime: newExpireTime }
        }
      }
    }
  };
}

function onUpdateExpiryError(state, action) {
  const {
    requestParams: { guid, currentExpireTime }
  } = action;
  return {
    ...state,
    keys: {
      ...state.keys,
      ...{
        [guid]: {
          ...state.keys[guid],
          ...{ expireTime: currentExpireTime }
        }
      }
    }
  };
}

function onRestoreMessage(state, action) {
  const { guid } = action;
  const {
    keys: { [guid]: active }
  } = state;
  const messageStatus = action.updateMessageStatus ? true : active;
  return {
    ...state,
    keys: {
      ...state.keys,
      ...{
        [guid]: {
          ...state.keys[guid],
          ...{ active: messageStatus }
        }
      }
    }
  };
}

function onRevokeMessage(state, action) {
  const { guid } = action;
  const {
    keys: { [guid]: active }
  } = state;
  const messageStatus = action.updateMessageStatus ? false : active;
  return {
    ...state,
    keys: {
      ...state.keys,
      ...{
        [guid]: {
          ...state.keys[guid],
          ...{ active: messageStatus }
        }
      }
    }
  };
}

function onUpdateCurrentGuid(state, action) {
  const { nextGuid } = action;
  const { currentGuid: prevGuid } = state;

  if (nextGuid === prevGuid) {
    return state;
  }

  return {
    ...state,
    currentGuid: nextGuid,
    meta: {
      ...state.meta,
      ...{
        [nextGuid]: {
          ...state.meta[nextGuid],
          ...{ isSelected: true, isRead: true }
        },
        [prevGuid]: {
          ...state.meta[prevGuid],
          ...{ isSelected: false }
        }
      }
    }
  };
}

function onMarkMessageAsChecked(state, action) {
  const { guid, isChecked } = action;
  const { currentGuid, checkedGuids } = state;

  // Update or remove guid
  const newCheckedGuids = { ...checkedGuids };
  if (isChecked) {
    newCheckedGuids[guid] = guid;
  } else {
    delete newCheckedGuids[guid];
  }

  // Mark message as selected (theme primary colour as background)
  const isSelected = guid === currentGuid ? true : isChecked;

  return {
    ...state,
    checkedGuids: newCheckedGuids,
    meta: {
      ...state.meta,
      ...{
        [guid]: {
          ...state.meta[guid],
          isChecked,
          isSelected
        }
      }
    }
  };
}

function onResetCheckedMessages(state) {
  const { currentGuid, checkedGuids } = state;
  const updatedMeta = [];

  Object.keys(checkedGuids).map((guid) => {
    updatedMeta[guid] = {
      ...state.meta[guid],
      isChecked: false,
      isSelected: currentGuid === guid
    };
    return updatedMeta;
  });

  return {
    ...state,
    checkedGuids: {},
    meta: {
      ...state.meta,
      ...updatedMeta
    }
  };
}

export default createReducer(INITIAL_STATE, {
  // Success
  [actionSuccess(apiActions.FETCH_TOTAL_SENT_MESSAGES)]: onFetchTotalMessagesSuccess,
  [actionSuccess(apiActions.FETCH_ALL_ENCRYPTED_MESSAGES)]: onFetchMessagesSuccess,
  [actionSuccess(apiActions.FETCH_ACTIVE_ENCRYPTED_MESSAGES)]: onFetchMessagesSuccess,
  [actionSuccess(apiActions.FETCH_EXPIRED_ENCRYPTED_MESSAGES)]: onFetchMessagesSuccess,
  [actionSuccess(apiActions.FETCH_REVOKED_ENCRYPTED_MESSAGES)]: onFetchMessagesSuccess,
  [actionSuccess(apiActions.FETCH_ENCRYPTED_MESSAGE_BY_GUID)]: onUpdateMessageSuccess,
  [actionSuccess(apiActions.UPDATE_EXPIRY)]: onUpdateExpirySuccess,
  // Error
  [actionError(apiActions.FETCH_TOTAL_SENT_MESSAGES)]: onFetchMessagesError,
  [actionError(apiActions.FETCH_ALL_ENCRYPTED_MESSAGES)]: onFetchMessagesError,
  [actionError(apiActions.FETCH_ACTIVE_ENCRYPTED_MESSAGES)]: onFetchMessagesError,
  [actionError(apiActions.FETCH_EXPIRED_ENCRYPTED_MESSAGES)]: onFetchMessagesError,
  [actionError(apiActions.FETCH_REVOKED_ENCRYPTED_MESSAGES)]: onFetchMessagesError,
  [actionError(apiActions.FETCH_ENCRYPTED_MESSAGE_BY_GUID)]: onUpdateMessageError,
  [actionError(apiActions.UPDATE_EXPIRY)]: onUpdateExpiryError,
  // Catch All Actions
  [apiActions.SET_MESSAGE_READY]: setMessageReadyStatus,
  [apiActions.RESTORE_ENCRYPTED_RECIPIENT]: onRestoreMessage,
  [apiActions.REVOKE_ENCRYPTED_RECIPIENT]: onRevokeMessage,
  [messageListTypes.UPDATE_CURRENT_ENCRYPTED_GUID]: onUpdateCurrentGuid,
  [messageListTypes.MARK_ENCRYPTED_MESSAGE_AS_CHECKED]: onMarkMessageAsChecked,
  [messageListTypes.RESET_CHECKED_ENCRYPTED_MESSAGES]: onResetCheckedMessages
});

export const getEncryptedMessagesFromState = (state) => state.encryptedMessages;

export const getReadyFromState = createSelector(getEncryptedMessagesFromState, (messages) => messages.isReady);

export const getEmptyFromState = createSelector(getEncryptedMessagesFromState, (messages) => messages.isEmpty);

export const getFirstGuidFromState = createSelector(getEncryptedMessagesFromState, (messages) => messages.firstGuid);

export const getTotalMessagesFromState = createSelector(
  getEncryptedMessagesFromState,
  (messages) => messages.totalMessages
);

export const getTotalResultsFromState = createSelector(
  getEncryptedMessagesFromState,
  (messages) => messages.totalResults
);

export const getSearchStringFromState = createSelector(
  getEncryptedMessagesFromState,
  (messages) => messages.searchString
);

export const getEncryptedMessages = createSelector(getEncryptedMessagesFromState, (messages) => messages.keys);

export const getCurrentGuid = createSelector(getEncryptedMessagesFromState, (messages) => messages.currentGuid);

export const getEncryptedMessagesByDate = createSelector(getEncryptedMessages, (messages) =>
  groupEncryptedMsgsByDate(messages)
);

export const getTotalRows = createSelector(getEncryptedMessagesFromState, (messages) => messages.totalRows);

export const getEncryptedCheckedGuids = createSelector(
  getEncryptedMessagesFromState,
  (messages) => messages.checkedGuids
);

export const getEncryptedTotalCheckedGuids = createSelector(getEncryptedCheckedGuids, (guids) =>
  guids ? Object.keys(guids).length : 0
);

export const getMessageMeta = createSelector(getEncryptedMessagesFromState, (messages) => messages.meta);

const selectMessageMetaByGuid = (state, guid) => getMessageMeta(state)[guid];
const selectMetaKey = (state, guid, key) => key;

export const getEncryptionMetaByGuid = createSelector(selectMessageMetaByGuid, selectMetaKey, (messages, key) => {
  if (messages && messages[key] !== undefined) {
    return messages[key];
  }
  return false;
});

export const getFilterByFromState = createSelector(getEncryptedMessagesFromState, (messages) => messages.filterBy);

export const getSelectedMessageGuids = (state) => getEncryptedMessagesFromState(state).selectedGuids;

export const getEncryptedMessageByGuid = (state, targetGuid) => {
  const allMessages = getEncryptedMessages(state);
  const result = filter(allMessages, (message) => message.guid === targetGuid);
  return result[0];
};

export const getMostRecentEncryptedMessages = createSelector(getEncryptedMessages, (encryptedMessages) => {
  const count = 3;
  const messageCount = Math.min(Object.keys(encryptedMessages).length, count);

  return Object.entries(encryptedMessages)
    .slice(0, messageCount)
    .map((encryptedMessage) => encryptedMessage[1]);
});
