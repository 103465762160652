import React from 'react';
import ViewAgenda from '@material-ui/icons/ViewAgenda';

import QuarantineIcon from '../common/Icons/Quarantine';
import SecureMessageCenterIcon from '../common/Icons/SecureMessageCenter';

export const featureName = Object.freeze({
  quarantine: 'Quarantine',
  keyManagement: 'Secure Message Center',
  landingPage: 'Home'
});

/* eslint-disable react/jsx-filename-extension */
export const featuresForDisplay = Object.freeze({
  landingPage: {
    text: featureName.landingPage,
    textId: 'app.landingPage',
    icon: <ViewAgenda />,
    selector: 'switcher-option__landing-page'
  },
  quarantine: {
    text: featureName.quarantine,
    textId: 'app.quarantine',
    icon: <QuarantineIcon />,
    selector: 'switcher-option__quarantine'
  },
  keyManagement: {
    text: featureName.keyManagement,
    textId: 'app.smc',
    icon: <SecureMessageCenterIcon />,
    label: 'app.smc.label',
    selector: 'switcher-option__smc'
  }
});
