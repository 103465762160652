import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

const LandingPageSpinner = ({ showSpinner }) =>
  showSpinner && <CircularProgress className="appContainer-spinner" size={50} thickness={4} />;

LandingPageSpinner.propTypes = {
  showSpinner: PropTypes.bool.isRequired
};

export default LandingPageSpinner;
