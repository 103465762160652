/**
 * @description Error class for an API error for backend response outside the 200 range
 * (https://github.com/axios/axios#handling-errors)
 * @export public
 * @class ApiError
 * @extends {Error}
 */
/**
 * Creates an instance of ApiError.(https://github.com/axios/axios#response-schema)
 * @param {object} config - the config that was provided to `axios` for the request
 * @param {object} headers - the headers that the server responded with (All header names are lower cased)
 * @param {number} status - the HTTP status code from the server response
 * @param {string} statusText - the HTTP status message from the server response
 * @param {object} data - the response that was provided by the server
 * @param {object} action - action object
 * @memberof ApiError - created ApiError error
 */
function ApiError({ config = {}, headers = {}, status = 400, statusText = '', data = {} }, action = null) {
  this.name = 'ApiError';

  // Axios specific error fields
  this.config = config;
  this.headers = headers;
  this.status = status;
  this.statusText = statusText;
  this.data = data;

  if (action) {
    this.action = action;
  }
  // additional fields
  this.errorTimeStamp = Date.now();
  // the action that resulted in the
}

ApiError.prototype = new Error();
ApiError.prototype.constructor = ApiError;

export default ApiError;
