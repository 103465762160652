import React, { createRef, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import Button from '@proofpoint/fuse-ui/components/Button';
import PropTypes from 'prop-types';
import Select from '@proofpoint/fuse-ui/components/Select';
import cx from 'classnames';
import './GeneralSettings.scss';
import { qaTags } from '../../../constants/qaConstants';
import { renderString } from '../../../utils/renderUtils';
import ProfileTile from '../../ProfileTile';
import Tooltip from '../../../common/Tooltip';
import { isEmpty } from '../../../utils/strUtils';

const GeneralSettings = ({
  primaryEmailAddress,
  spamPolicyOptionDefault,
  spamPolicies,
  profileOnSubmit,
  managedDelegate,
  closeFunc,
  backFunc,
  intl
}) => {
  const inputRef = createRef();
  const SpamSelectRef = createRef();
  useEffect(() => {
    if (SpamSelectRef && SpamSelectRef.current) {
      const selectEl = SpamSelectRef.current.select;
      selectEl.focus();
      // aria-label label to read out the selected value. Fuse UI selected element does not get read  in NVDA
      SpamSelectRef.current.select.inputRef.ariaLabel = `${intl.formatMessage({
        id: 'profile.SpamPolicy'
      })} dropdown menu ${selectEl.getValue()[0].label} selected`;
    }
  }, [SpamSelectRef]);
  const focusOnSelect = () => {
    inputRef.current.firstChild.childNodes[0].focus();
  };
  const selectSpamPolicies = spamPolicies.map((item) => ({
    label: item.description ? item.description : item.id,
    value: item.id
  }));

  const initialSelectedValue = selectSpamPolicies[spamPolicyOptionDefault];
   
  const generalSettingsContainer = cx('general-settings-container', {
    'managed-delegate': !isEmpty(managedDelegate),
  });   

  return (
    <div ref={inputRef} className="profile-view">
      <ProfileTile header="settings.quarantine" subHeader="settings.general" closeFunc={closeFunc} backFunc={backFunc}>
        {initialSelectedValue && (
          <Formik
            isInitialValid
            initialValues={{
              email: primaryEmailAddress || '',
              selection: initialSelectedValue,
              valuesChanged: false
            }}
            onSubmit={(values, { setValues }) => {
              const formData = {
                properties: {
                  currentSpamPolicy: values.selection.value
                }
              };
              profileOnSubmit(
                primaryEmailAddress,
                formData,
                selectSpamPolicies.findIndex(function(obj) {
                  return obj.label === values.selection.label && obj.value === values.selection.value;
                })
              );
              setValues({ valuesChanged: false });
            }}
            onReset={() => {}}
            render={(formik) => {
              const { handleSubmit, values } = formik;

              const selectChanged = (selected) => {
                const { setValues } = formik;
                setValues({ ...values, selection: selected, valuesChanged: true });
              };
              const saveTooltip = intl.formatMessage({ id: 'tooltip.button.save' });
              return (
                <form className="profile-form" onSubmit={handleSubmit}>
                  {spamPolicies.length > 0 && (
                    <div className={generalSettingsContainer}>
                      <Select
                        label={renderString('profile.SpamPolicy')}
                        isSearchable={false}
                        InputProps={{
                          tabIndex: '-1',
                          inputProps: {
                            tabIndex: '-1',
                            'aria-hidden': true
                          }
                        }}
                        selectRef={SpamSelectRef}
                        onChange={selectChanged}
                        value={values.selection}
                        options={selectSpamPolicies}
                      />
                      <div className="submit-buttons">
                        <Tooltip enterDelay={500} title={saveTooltip} placement="bottom-start">
                          <div
                            className={`appContainer-tooltip--button profile-submit-button${
                              values.valuesChanged ? '--enabled' : ''
                            }`}
                          >
                            <Button
                              aria-label={saveTooltip}
                              name={qaTags.PROFILE_SUBMIT_BUTTON}
                              disabled={!values.valuesChanged}
                              type="submit"
                              onClick={() => {
                                focusOnSelect();
                              }}
                            >
                              <FormattedMessage id="profile.Save" />
                            </Button>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </form>
              );
            }}
          />
        )}
      </ProfileTile>
    </div>
  );
};
GeneralSettings.propTypes = {
  primaryEmailAddress: PropTypes.string.isRequired,
  spamPolicyOptionDefault: PropTypes.number.isRequired,
  spamPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
  profileOnSubmit: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired,
  backFunc: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object.isRequired
};

export default injectIntl(GeneralSettings);
