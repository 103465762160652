import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import CellMeasurer from 'react-virtualized/dist/es/CellMeasurer/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/es/CellMeasurer/CellMeasurerCache';
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader';
import List from 'react-virtualized/dist/es/List';
import PropTypes from 'prop-types';
import React from 'react';

import { apiConstants } from '../../ducks/apiConstants';
import MessageGroup from '../../common/Inbox/MessageGroup';
import SingleMessageRow from '../../common/Inbox/SingleMessageRow';

const MESSAGE_GROUP_HEIGHT = 42;
const MESSAGE_ROW_HEIGHT = 100;
const CHECKBOX_LIMIT = 50;

const rowCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: MESSAGE_ROW_HEIGHT,
  minHeight: MESSAGE_GROUP_HEIGHT
});

const height100Style = { height: MESSAGE_ROW_HEIGHT };

class MessageList extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { isSearching } = this.props;
    const previousIsSearching = prevProps.isSearching;

    // If the underlying data changes clear the cache
    if (isSearching !== previousIsSearching) {
      rowCache.clearAll();
    }
  }

  onMessageClick = (guid) => {
    const { handleOnMessageClick } = this.props;
    return handleOnMessageClick(guid);
  };

  rowRenderer = ({ key, index, parent, style }) => {
    const { encryptedMessages, isReady, selectedMessages, currentGuid, isSearching, isRowLoaded } = this.props;

    const messagesInStateMap = Object.keys(encryptedMessages);
    const messageKey = messagesInStateMap[index];
    const message = encryptedMessages[messageKey];

    // Show the initial visible messages
    // and the rest when the map status is ready
    const messageReady = isReady && isRowLoaded({ index });
    const isCheckboxDisabled = Object.keys(selectedMessages).length === CHECKBOX_LIMIT;

    let cellContents;
    if (index === 0 || (message && message.group)) {
      cellContents = (
        <MessageGroup
          title={message && message.group ? message.group : ''}
          isReady={!isSearching && isReady}
          className={index === 0 ? 'message-group--first' : ''}
        />
      );
    } else if (message === undefined) {
      cellContents = (
        <div style={height100Style}>
          <SingleMessageRow isReady={false} />
        </div>
      );
    } else {
      cellContents = (
        <div style={height100Style}>
          <SingleMessageRow
            tabIndex={index}
            handleOnMessageClick={this.onMessageClick}
            guid={message.guid}
            active={message.active}
            recipients={message.recipients}
            subject={message.subject}
            deliveryDate={message.createTime}
            expiryDate={message.expireTime}
            isSelected={isReady && currentGuid === message.guid}
            isReady={messageReady}
            showCheckboxOnHover={!isCheckboxDisabled}
            isChecked={Boolean(selectedMessages[message.guid])}
          />
        </div>
      );
    }

    return (
      <CellMeasurer cache={rowCache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <li style={style}>{cellContents}</li>
      </CellMeasurer>
    );
  };

  render() {
    const {
      isRowLoaded,
      loadMoreRows,
      rowCount,
      isSearching,
      selectedMessages,
      currentGuid,
      isReady,
      encryptedMessages
    } = this.props;

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
        minimumBatchSize={apiConstants.pageSize}
        threshold={50}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                id="virtualized-list"
                width={width}
                height={height}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                rowCount={rowCount}
                rowHeight={rowCache.rowHeight}
                deferredMeasurementCache={rowCache}
                scrollToIndex={0}
                rowRenderer={this.rowRenderer}
                // The next properties are to cause a rerender as the react-virtualised is a PureComponent
                selectedMessages={selectedMessages}
                currentGuid={currentGuid}
                isSearching={isSearching}
                isReady={isReady}
                encryptedMessages={encryptedMessages}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  }
}

MessageList.propTypes = {
  isRowLoaded: PropTypes.func.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  encryptedMessages: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
  isReady: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  selectedMessages: PropTypes.object.isRequired,
  currentGuid: PropTypes.string.isRequired,
  handleOnMessageClick: PropTypes.func.isRequired
};

export default MessageList;
