import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const AllowSender = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <g transform="matrix(0.012713, 0, 0, -0.012713, -1.01704, 25.043291)">
      <path d="M601 1629 c-116 -35 -194 -139 -194 -259 0 -77 17 -122 70 -180 165
    -184 463 -69 463 180 0 106 -53 193 -145 239 -53 26 -143 36 -194 20z"
      />
      <path d="M1650 1225 l-195 -195 -107 107 -108 108 -50 -50 -50 -50 160 -160
    160 -160 247 247 248 248 -50 50 c-27 27 -52 50 -55 50 -3 0 -93 -88 -200
    -195z" 
      />
      <path d="M522 855 c-143 -26 -292 -82 -362 -137 -14 -11 -37 -38 -53 -60 -26
    -40 -27 -45 -27 -185 l0 -143 600 0 600 0 0 118 c0 124 -10 180 -41 224 -31
    43 -128 100 -229 135 -176 60 -335 76 -488 48z"
      />
    </g>
  </SvgIcon>
);

AllowSender.defaultProps = {
  className: ''
};

AllowSender.propTypes = {
  className: PropTypes.string
};

export default AllowSender;
