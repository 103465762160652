import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Delete from '@material-ui/icons/Delete';
import MoreVert from '@material-ui/icons/MoreVert';
import React from 'react';

import AllowSender from '../common/Icons/AllowSender';
import BlockAndDelete from '../common/Icons/BlockAndDelete';
import BlockDomain from '../common/Icons/BlockDomain';
import BlockSender from '../common/Icons/BlockSender';
import MarkAsPhish from '../common/Icons/MarkAsPhish';
import MarkAsSpam from '../common/Icons/MarkAsSpam';
import MarkNotSpam from '../common/Icons/MarkNotSpam';
import Release from '../common/Icons/Release';
import ReleaseAndApprove from '../common/Icons/ReleaseAndApprove';

export const mindtouchAppPath = Object.freeze({
  CEUWEB_PORTAL: 'End_User_Web_Portal',
});
 
export const keyCodes = Object.freeze({
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  KEY_X: 88,
  KEY_PERIOD: 190,
  KEY_ENTER: 13,
  KEY_SPACE: 32,
  KEYNAME_ENTER: 'Enter',
  KEYNAME_SPACE: 'Space',
  KEYNAME_TAB: 'Tab',
  KEY_TAB: 9
});

/* eslint-disable react/jsx-filename-extension */
export const buttonType = Object.freeze({
  // Quarantine
  BUTTON_RELEASE: 'release',
  BUTTON_RELEASE_INDIVIDUAL: 'release_individual',
  BUTTON_ALLOW: 'msgsafelist',
  BUTTON_ALLOW_INDIVIDUAL: 'msgsafelist_individual',
  BUTTON_BLOCK: 'msgblocklist',
  BUTTON_BLOCK_INDIVIDUAL: 'msgblocklist_individual',
  BUTTON_BLOCK_DOMAIN: 'msgblockdomain',
  BUTTON_BLOCK_AND_DELETE: 'msgblock&delete',
  BUTTON_DELETE: 'delete',
  BUTTON_DELETE_INDIVIDUAL: 'delete_individual',
  BUTTON_BACK: 'BUTTON_BACK',
  BUTTON_MORE: 'BUTTON_MORE',
  BUTTON_ADD: 'add',
  BUTTON_ALLOW_AND_RELEASE: 'releasewhitelist',
  BUTTON_RECOMMEND_ALLOW_AND_RELEASE: 'recommendwhitelist',
  VIEW: 'view',
  DELETE_ALL: 'deleteall',
  MARK_AS_SPAM: 'reportfalsenegative',
  VIEW_ATTACHMENT: 'viewattachment',
  MARK_AS_NOT_SPAM: 'reportfalsepositive',
  MARK_AS_PHISH: 'reportfnphishing',
  DOWNLOAD_ATTACHMENT: 'downloadattachment',
  DOWNLOAD_MESSAGE: 'downloadmessage',
  // Encryption
  REVOKE_MESSAGE: 'revoke',
  RESTORE_MESSAGE: 'restore'
});

export const storageKeys = Object.freeze({
  STORAGE_KEY_FOR_DELEGATE: 'managedDelegate'
})

export const navConstant = Object.freeze({
  BASIC_NAV: 'basicnav',
  FULL_NAV: 'fullnav'
})
/**
 * ------
 * Icons
 * ------
 *
 * Actions that do not have an icon will not render as a quick action in the UI
 *
 * ------
 * Groups
 * ------
 *
 * Feature: Actions that appear in an ellipsis dropdown do not
 * honour the ordering set by PPS admin.
 *
 * Using the groupIndex property the groups are broken down
 * as follows:
 *
 * 1. Block Sender
 * 2. Allow Sender
 *
 * 3. Release
 * 4. Release & Approve
 *
 * 5. Mark as Spam
 * 6. Mark as Not Spam
 * 7. Mark as Phish
 *
 * 8. ~~ Unrelated actions go here ~~
 *
 * 9. Delete Message
 *
 * ---------
 * Props
 * ---------
 *
 * quickActionsLowPriority : Set when the icons have lower priority to be displayed as quick actions on single message hover.
 *
 */
export const buttonElements = {
  [buttonType.BUTTON_RELEASE]: {
    icon: <Release className="action-icon" />,
    intlId: 'action.button.release',
    tooltipId: 'tooltip.button.release',
    message: 'action.button.release.message',
    hideAffectedElements: true,
    groupIndex: 2
  },
  [buttonType.BUTTON_RELEASE_INDIVIDUAL]: {
    icon: <Release className="action-icon" />,
    intlId: 'action.button.release',
    tooltipId: 'tooltip.button.release.individual',
    message: 'action.button.release.message',
    hideAffectedElements: true
  },
  [buttonType.BUTTON_DELETE]: {
    icon: <Delete className="action-icon" />,
    intlId: 'action.button.delete',
    tooltipId: 'tooltip.button.delete',
    message: 'action.button.delete.message',
    hideAffectedElements: true,
    groupIndex: 4
  },
  [buttonType.BUTTON_DELETE_INDIVIDUAL]: {
    icon: <Delete className="action-icon" />,
    intlId: 'action.button.delete',
    tooltipId: 'tooltip.button.delete.individual',
    message: 'action.button.delete.message',
    hideAffectedElements: true
  },
  [buttonType.BUTTON_ALLOW]: {
    icon: <AllowSender className="action-icon" />,
    intlId: 'action.button.allow',
    tooltipId: 'tooltip.button.allow',
    message: 'action.button.allow.message',
    groupIndex: 1
  },
  [buttonType.BUTTON_ALLOW_INDIVIDUAL]: {
    icon: <AllowSender className="action-icon" />,
    intlId: 'action.button.allow',
    tooltipId: 'tooltip.button.allow.individual',
    message: 'action.button.allow.message'
  },
  [buttonType.BUTTON_BLOCK]: {
    icon: <BlockSender className="action-icon" />,
    intlId: 'action.button.block',
    tooltipId: 'tooltip.button.block',
    message: 'action.button.block.message',
    groupIndex: 1
  },
  [buttonType.BUTTON_BLOCK_INDIVIDUAL]: {
    icon: <BlockSender className="action-icon" />,
    intlId: 'action.button.block',
    tooltipId: 'tooltip.button.block.individual',
    message: 'action.button.block.message'
  },
  [buttonType.BUTTON_BLOCK_DOMAIN]: {
    icon: <BlockDomain className="action-icon" />,
    intlId: 'action.button.block.domain',
    tooltipId: 'tooltip.button.block.domain',
    message: 'action.button.block.message',
    quickActionsLowPriority: true,
    groupIndex: 1
  },
  [buttonType.BUTTON_BLOCK_AND_DELETE]: {
    icon: <BlockAndDelete className="action-icon" />,
    intlId: 'action.button.block.and.delete',
    tooltipId: 'tooltip.button.block.delete',
    message: 'action.button.block.delete.message',
    hideAffectedElements: true,
    quickActionsLowPriority: true,
    groupIndex: 1
  },
  [buttonType.BUTTON_ALLOW_AND_RELEASE]: {
    icon: <ReleaseAndApprove className="action-icon" />,
    intlId: 'action.button.release.approve',
    tooltipId: 'action.button.release.approve',
    message: 'action.button.release.approve.message',
    hideAffectedElements: true,
    groupIndex: 2
  },
  [buttonType.BUTTON_RECOMMEND_ALLOW_AND_RELEASE]: {
    icon: <ReleaseAndApprove className="action-icon" />,
    intlId: 'action.button.recommend.release',
    tooltipId: 'action.button.recommend.release',
    message: 'action.button.recommend.release.message',
    hideAffectedElements: true,
    groupIndex: 3
  },
  [buttonType.MARK_AS_PHISH]: {
    icon: <MarkAsPhish className="action-icon" />,
    intlId: 'action.button.mark.phish',
    tooltipId: 'action.button.mark.phish',
    message: 'action.button.mark.phish.message',
    groupIndex: 3
  },
  [buttonType.MARK_AS_SPAM]: {
    icon: <MarkAsSpam className="action-icon" />,
    intlId: 'action.button.mark.spam',
    tooltipId: 'action.button.mark.spam',
    message: 'action.button.mark.spam.message',
    groupIndex: 3
  },
  [buttonType.MARK_AS_NOT_SPAM]: {
    icon: <MarkNotSpam className="action-icon" />,
    intlId: 'action.button.mark.not.spam',
    tooltipId: 'action.button.mark.not.spam',
    message: 'action.button.mark.not.spam.message',
    groupIndex: 3
  },
  [buttonType.BUTTON_ADD]: {
    icon: <AddCircleOutline className="action-icon" />,
    intlId: 'action.button.add',
    tooltipId: 'tooltip.button.add',
    message: 'action.button.add.message',
    hideAffectedElements: true
  },
  [buttonType.BUTTON_BACK]: {
    icon: <ArrowBack />,
    intlId: 'action.button.back',
    tooltipId: 'tooltip.button.back'
  },
  [buttonType.BUTTON_MORE]: {
    icon: <MoreVert />,
    intlId: 'action.button.menu',
    tooltipId: 'tooltip.button.more'
  },
  [buttonType.REVOKE_MESSAGE]: {
    intlId: 'encryption.revoke.action',
    groupIndex: -1
  },
  [buttonType.RESTORE_MESSAGE]: {
    intlId: 'encryption.restore.action',
    groupIndex: -1
  },
  defaults: {
    message: 'action.button.default.message'
  }
};

export const contentTypes = {
  MESSAGE_LIST: 'MESSAGE_LIST',
  SENDER_LIST: 'SENDER_LIST',
  MESSAGE_BODY: 'MESSAGE_BODY',
  FOLDER_LIST: 'FOLDER_LIST',
  GENERIC_CONTENT: 'GENERIC_CONTENT'
};

export const elementSizes = {
  MESSAGE_ITEM: 47,
  GROUP_HEADER: 47,
  PAGE_HEADER: 56,
  ACTION_BAR: 100,
  MESSAGE_PAGE_HEADER_COMBINED: 245,
  SENDER_PAGE_PADDING: 25,
  LOAD_MORE_OFFSET: 100,
  BOTTOM_NAV_HEIGHT: 91
};

export const measures = Object.freeze({
  DEBOUNCE_SHORT: 100,
  DEBOUNCE_MEDIUM: 300,
  DEBOUNCE_LONG: 500,
  DEBOUNCE_XL: 1000
});

export const commonIds = Object.freeze({
  moreActions: 'more_actions'
});
