import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const BlockSender = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <g transform="matrix(0.01304, 0, 0, -0.01304, -0.749829, 25.281601)">
      <path d="M590 1601 c-157 -51 -233 -211 -170 -358 71 -166 294 -208 422 -81
      55 56 73 102 73 188 -1 107 -47 185 -140 232 -43 22 -144 33 -185 19z"
      />
      <path d="M1383 1596 c-188 -61 -310 -259 -275 -447 24 -129 111 -242 229 -297
      52 -24 70 -27 163 -27 93 0 111 3 163 27 119 56 207 171 230 301 28 167 -64
      342 -220 419 -57 28 -78 33 -157 35 -57 2 -107 -2 -133 -11z m215 -72 c27 -9
      61 -26 76 -38 l27 -21 -225 -224 c-249 -249 -236 -242 -276 -149 -16 37 -22
      73 -23 123 -1 220 212 376 421 309z m189 -159 c92 -164 7 -387 -176 -456 -91
      -34 -219 -17 -301 40 -12 9 27 52 210 236 124 124 229 224 235 222 5 -2 20
      -21 32 -42z"
      />
      <path d="M475 841 c-157 -34 -258 -77 -326 -138 -64 -58 -69 -75 -69 -228 l0
      -135 580 0 580 0 0 135 c0 153 -5 170 -69 228 -138 125 -462 189 -696 138z"
      />
    </g>
  </SvgIcon>
);

BlockSender.defaultProps = {
  className: ''
};

BlockSender.propTypes = {
  className: PropTypes.string
};

export default BlockSender;
