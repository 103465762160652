import { schema } from 'normalizr';

// Define a folder schema
const messagesSchema = new schema.Entity('messages', {}, { idAttribute: 'guid' });
const emailSchema = new schema.Entity('emails', {}, { idAttribute: 'guid' });
const encryptedMessgageSchema = new schema.Entity('keys', {}, { idAttribute: 'guid' });

// noEntity is not used to normalize data
const noEntity = new schema.Entity('noEntity');

const messageListSchema = { messages: [messagesSchema] };
const emailListSchema = new schema.Array(emailSchema);
const encryptedMessageListSchema = { keys: [encryptedMessgageSchema] };

export const Schemas = {
  MESSAGES: messageListSchema,
  EMAILS: emailListSchema,
  ENCRYPTEDMESSAGES: encryptedMessageListSchema,
  NO_ENTITY: noEntity
};

export default Schemas;
