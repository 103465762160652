import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addSettingsPath } from '../../../ducks/uiStatusActions';
import { getCurrentEmailAddressFromState, getDisplayName } from '../../../Settings/ducks/profileReducer';
import { getFolderIdsFromState } from '../../../SideBar/ducks/folderReducer';
import { logout } from '../../../SideBar/ducks/sideBarActions';
import SideBarAppDrawer from './SideBarAppDrawer';
import { redirectToMindtouchPage } from '../../Nav/helpApi';
import { getHelpPageRequestingStatus } from '../../Nav/ducks/helpReducer';

class SideBarAppDrawerContainer extends PureComponent {
  settingsOnClick = () => {
    const { addSettingsPathDispatch, toggleMobileView } = this.props;
    addSettingsPathDispatch('settings');
    toggleMobileView();
  };

  redirectToHelpPage = (helpPath) => {
    const { isRequestingHelpPage, redirectToMindtouchPageDispatch } = this.props;
    if (!isRequestingHelpPage) {
      redirectToMindtouchPageDispatch(helpPath);
    }
  };

  render() {
    const { shouldShowMobileView, logoutDispatch, isSingleTier, displayName, username } = this.props;

    return (
      <SideBarAppDrawer
        shouldShowMobileView={shouldShowMobileView}
        settingsOnClick={this.settingsOnClick}
        logoutOnClick={logoutDispatch}
        isSingleTier={isSingleTier}
        displayName={displayName}
        username={username}
        redirectToHelpPage={this.redirectToHelpPage}
      />
    );
  }
}

SideBarAppDrawerContainer.defaultProps = {
  isSingleTier: false,
  isRequestingHelpPage: false
};

SideBarAppDrawerContainer.propTypes = {
  shouldShowMobileView: PropTypes.bool.isRequired,
  addSettingsPathDispatch: PropTypes.func.isRequired,
  logoutDispatch: PropTypes.func.isRequired,
  toggleMobileView: PropTypes.func.isRequired,
  isSingleTier: PropTypes.bool,
  isRequestingHelpPage: PropTypes.bool,
  displayName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  redirectToMindtouchPageDispatch: PropTypes.func.isRequired

};

const mapStateToProps = (state) => {
  const props = {};
  props.folderIds = getFolderIdsFromState(state);
  props.username = getCurrentEmailAddressFromState(state);
  props.displayName = getDisplayName(state);
  props.isRequestingHelpPage = getHelpPageRequestingStatus(state);
  return props;
};

const mapDispatchToProps = () => ({
  logoutDispatch: logout,
  addSettingsPathDispatch: addSettingsPath,
  redirectToMindtouchPageDispatch: redirectToMindtouchPage,

});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(SideBarAppDrawerContainer);
