import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getFolderErrorStatus, getFolderIdsFromState } from '../ducks/folderReducer';
import SideBarContent from './SideBarContent';

class SideBarContentContainer extends Component {
  componentDidUpdate(prevProps) {
    const { folderIds } = this.props;
    if (folderIds === prevProps.folderIds) {
      return false;
    }
    return true;
  }

  render() {
    const { folderIds, isError } = this.props;
    return <SideBarContent isError={isError} folderIds={folderIds} />;
  }
}

SideBarContentContainer.defaultProps = {
  isError: false
};

SideBarContentContainer.propTypes = {
  isError: PropTypes.bool,
  folderIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const mapStateToProps = (state) => ({
  isError: getFolderErrorStatus(state),
  folderIds: getFolderIdsFromState(state)
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(SideBarContentContainer);
