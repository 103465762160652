import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getEncryptedCheckedGuids, getEncryptedMessages } from '../../MessageList/ducks/MessageListReducer';
import { getRecipientsEmail } from '../../../utils/encryptionUtils';
import { resetCheckedEncryptedMessages } from '../../MessageList/ducks/MessageListActions';
import { restoreMessages, revokeMessages } from '../../MessageOptions/ducks/MessageOptionsActions';
import BottomActions from './BottomActions';

class BottomActionsContainer extends PureComponent {
  handleRevokeMessage = () => {
    const { revokeMessageDispatch, handleResetEncryptedMessagesDispatch, encryptedMessages, checkedGuids } = this.props;
    // Validation
    if (Object.keys(checkedGuids).length === 0) {
      return;
    }

    // Build a map of selected guids and their recipients
    const guidsMap = {};
    Object.keys(checkedGuids).forEach((key) => {
      const { guid, active, recipients } = encryptedMessages[key];
      if (active) {
        guidsMap[guid] = getRecipientsEmail(recipients);
      }
    });

    if (Object.keys(guidsMap).length > 0) {
      revokeMessageDispatch({
        guids: guidsMap
      });
    }

    // Clear selected keys
    handleResetEncryptedMessagesDispatch();
  };

  handleRestoreMessage = () => {
    const {
      restoreMessageDispatch,
      handleResetEncryptedMessagesDispatch,
      encryptedMessages,
      checkedGuids
    } = this.props;

    // Validation
    if (Object.keys(checkedGuids).length === 0) {
      return;
    }

    // Build a map of selected guids and their recipients
    const guidsMap = {};
    Object.keys(checkedGuids).forEach((key) => {
      const { guid, active, recipients } = encryptedMessages[key];
      if (!active) {
        guidsMap[guid] = getRecipientsEmail(recipients);
      }
    });

    if (Object.keys(guidsMap).length > 0) {
      restoreMessageDispatch({
        guids: guidsMap
      });
    }

    // Clear selected keys
    handleResetEncryptedMessagesDispatch();
  };

  render() {
    const { checkedGuids } = this.props;

    if (Object.keys(checkedGuids).length === 0) {
      return null;
    }

    return (
      <BottomActions handleRestoreMessage={this.handleRestoreMessage} handleRevokeMessage={this.handleRevokeMessage} />
    );
  }
}

BottomActionsContainer.defaultProps = {
  checkedGuids: {}
};

BottomActionsContainer.propTypes = {
  encryptedMessages: PropTypes.object.isRequired,
  revokeMessageDispatch: PropTypes.func.isRequired,
  restoreMessageDispatch: PropTypes.func.isRequired,
  checkedGuids: PropTypes.object,
  handleResetEncryptedMessagesDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.encryptedMessages = getEncryptedMessages(state);
  props.checkedGuids = getEncryptedCheckedGuids(state);
  return props;
};

export default connect(
  mapStateToProps,
  {
    revokeMessageDispatch: revokeMessages,
    restoreMessageDispatch: restoreMessages,
    handleResetEncryptedMessagesDispatch: resetCheckedEncryptedMessages
  }
)(BottomActionsContainer);
