import { mindtouchAppPath } from "../../constants/uiConstants";
import { apiActions, apiEndpoints, API_CALL } from "../../ducks/apiConstants";

export function redirectToMindtouchPage () {
    return {    
        [API_CALL]: {
            endpoint: apiEndpoints.MINDTOUCH_HELP_URL,
            method: 'get',
            actionType: apiActions.REDIRECT_HELP_PAGE,
            params: { redirectPage: `${mindtouchAppPath.CEUWEB_PORTAL}` },

        }
    }
};

export default redirectToMindtouchPage;