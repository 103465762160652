import { all, call, put, select, spawn, takeLatest } from 'redux-saga/effects';

import { addFilterString } from '../app/common/ActionBar/Search/ducks/searchActions';
import { fetchSenderList } from '../app/Settings/Quarantine/SenderListView/ducks/senderListApi';
import {  getCurrentEmailAddressFromState, getLoggedInUserEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import { moduleType } from '../app/ducks/appTypes';
import { senderListTypes } from '../app/Settings/Quarantine/SenderListView/ducks/senderListTypes';
import apiHandler from '../app/ducks/utils/apiHandler';
import logger from '../tools/logger';

const log = logger.child({ childName: 'sideBarSaga' });

export function* loadSenderList(action) {
  const { senderId } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  const loggedInUserEmail = yield select(getLoggedInUserEmailAddressFromState);
  try {
    yield call(apiHandler, fetchSenderList({ loggedInUserEmail, property: senderId }));
    yield put(addFilterString(moduleType.SENDER_LIST));
  } catch (err) {
    log.error(`fetching sender error: ${username}: ${err}`);
  }
}

export function* watchSenderListNavigation() {
  yield takeLatest(senderListTypes.LOAD_SENDERLIST, loadSenderList);
}

/* root senderList Saga */
export default function* senderListSaga() {
  yield all([spawn(watchSenderListNavigation)]);
}
