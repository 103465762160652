import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const MarkNotSpam = ({ className }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`icon-container ${className}`}>
    <g transform="matrix(0.014423, 0, 0, -0.014423, -3.172935, 26.617201)">
      <path d="M1663 1348 l-143 -143 -80 80 -80 80 -37 -38 -38 -37 118 -118 117
      -117 182 182 182 182 -34 36 c-19 19 -36 35 -40 35 -3 0 -69 -64 -147 -142z"
      />
      <path d="M482 1444 c-50 -35 -52 -48 -52 -411 0 -320 1 -340 20 -371 11 -18
      32 -37 46 -42 15 -6 205 -10 470 -10 495 0 492 0 520 65 8 19 14 67 14 108 l0
      74 -42 13 c-105 31 -192 94 -248 181 l-37 57 -103 -65 c-89 -55 -106 -63 -124
      -53 -12 6 -109 66 -216 133 l-195 123 -3 57 c-2 31 -1 57 2 57 2 0 100 -60
      217 -133 l213 -134 90 57 89 56 -2 65 c-1 35 5 90 14 122 8 32 15 60 15 62 0
      3 -150 5 -333 5 -285 0 -336 -2 -355 -16z"
      />
      <path d="M220 854 l0 -396 29 -29 29 -29 501 0 501 0 0 50 0 50 -480 0 -480 0
      0 375 0 375 -50 0 -50 0 0 -396z"
      />
    </g>
  </SvgIcon>
);

MarkNotSpam.defaultProps = {
  className: ''
};

MarkNotSpam.propTypes = {
  className: PropTypes.string
};

export default MarkNotSpam;
