import { createSelector } from 'reselect';

import { actionSuccess, apiActions } from './apiConstants';
import { createReducer } from '../utils/reducerUtils';

export const moduleName = 'config';

const INITIAL_STATE = {}; // {key1: {}, key2:{}, key3:{}}

function onFetchConfigSuccess(state, action) {
  const config = Object.assign(action.response.properties, state);
  return config;
}

export default createReducer(INITIAL_STATE, {
  [actionSuccess(apiActions.GET_CONFIG)]: onFetchConfigSuccess
});

// Config
export const getConfigFromState = (state) => state[moduleName];

// Get banding logo
export const getBrandingLogoFromState = createSelector(
  getConfigFromState,
  (config) => config !== undefined && config.brandingLogo
);

// Get banding logo
export const getBrandingLogoSmallFromState = createSelector(
  getConfigFromState,
  (config) => config !== undefined && config.brandingLogoSmall
);

// Get banding color
export const getBrandingColorPrimaryFromState = createSelector(
  getConfigFromState,
  (config) => config !== undefined && config.brandingColorPrimary
);
