import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';

import './EmptyState.scss';

function EmptyState({ children, title, subTitle, body, className }) {
  const wrapperClass = cx('empty-state', {
    [`${className}`]: className
  });

  return (
    <div className={wrapperClass}>
      <div className="empty-state__inner">
        {children}
        {title && (
          <Typography className="empty-state__title" gutterBottom variant="h5" component="h5">
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography className="empty-state__subtitle" gutterBottom variant="h6" component="h6">
            {subTitle}
          </Typography>
        )}
        {body && (
          <Typography className="empty-state__body" variant="body2" color="textSecondary" component="p">
            {body}
          </Typography>
        )}
      </div>
    </div>
  );
}

EmptyState.defaultProps = {
  children: null,
  title: '',
  subTitle: '',
  body: '',
  className: ''
};

EmptyState.propTypes = {
  children: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string
};

export default EmptyState;
