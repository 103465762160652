import { actionError, actionSuccess, apiActions } from '../../ducks/apiConstants';
import { createReducer } from '../../utils/reducerUtils';
import { digestActions } from './digestActions';

export const moduleName = 'digest';

const INITIAL_STATE = {};

function updateDigestMessageSuccess(state, action) {
  let data = { ...state };
  if (action.requestParams.actionType === apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND) {
    data = Object.assign(action.requestParams.data, data);
    data.success = !action.response.result.hasError;
  }
  return data;
}

function updateDigestMessageError(state, action) {
  let data = { ...state };
  if (action.requestParams.actionType === apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND) {
    data = Object.assign(action.requestParams.data, data);
    data.success = false;
  }
  return data;
}

function resetDigest() {
  return {};
}

export default createReducer(INITIAL_STATE, {
  [actionError(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND)]: updateDigestMessageError,
  [actionSuccess(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND)]: updateDigestMessageSuccess,
  [digestActions.RESET_DIGEST]: resetDigest
});

const allDigest = (state) => state[moduleName];

export const digestFolderSelector = (state) => allDigest(state).folder;

export const digestOperationSelector = (state) => allDigest(state).operation;

export const digestSuccessSelector = (state) => allDigest(state).success;
