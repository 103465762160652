export const messageTypes = Object.freeze({
  SHOW_MESSAGE_BODY: 'SHOW_MESSAGE_BODY',
  UPDATE_MESSAGE_BODY: 'UPDATE_MESSAGE_BODY',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
  RESET_MESSAGES: 'RESET_MESSAGES',
  RESET_MESSAGES_AND_META: 'RESET_MESSAGES_AND_META',
  UPDATE_CURRENT_FOLDER_ID: 'UPDATE_CURRENT_FOLDER_ID',
  UPDATE_CURRENT_GUID: 'UPDATE_CURRENT_GUID',
  SET_MESSAGE_READY: 'SET_MESSAGE_READY',
  MARK_MESSAGE_AS_CHECKED: 'MARK_MESSAGE_AS_CHECKED',
  RESET_CHECKED_MESSAGES: 'RESET_CHECKED_MESSAGES',
  GO_TO_MESSAGE: 'GO_TO_MESSAGE',
  SET_MESSAGE_EMPTY: 'SET_MESSAGE_EMPTY',
  RESET_MESSAGE_EMPTY: 'RESET_MESSAGE_EMPTY',
  UPDATE_FILTER_COUNT: 'UPDATE_FILTER_COUNT',
  SHOW_MESSAGE_ACTIONS: 'SHOW_MESSAGE_ACTIONS'
});

export default messageTypes;
