/**
 * @module sagas/index.js
 *
 * This file is the application root saga from which all other sagas are spawned
 */

import { all, spawn } from 'redux-saga/effects';

import applicationSaga from './applicationSaga';
import configSaga from './configSaga';
import encryptionSaga from './encryptionSaga';
import errorHanderSaga from './errorHandlerSaga';
import folderSaga from './folderSaga';
import logger from '../tools/logger';
import messageSaga from './messageSaga';
import profileSaga from './profileSaga';
import scheduleFolderCommandSaga from './commandSaga';
import searchSaga from './searchSaga';
import senderListSaga from './senderListSaga';
import sideBarSaga from './sideBarSaga';

const log = logger.child({ childName: 'rootSaga' });
/**
 * Root Application Saga
 */
const sagas = [
  spawn(errorHanderSaga),
  spawn(applicationSaga),
  spawn(folderSaga),
  spawn(searchSaga),
  spawn(messageSaga),
  spawn(profileSaga),
  spawn(sideBarSaga),
  spawn(senderListSaga),
  spawn(configSaga),
  spawn(scheduleFolderCommandSaga),
  spawn(encryptionSaga)
];

export default function* rootSaga() {
  log.info('inside rootSaga!');
  yield all([...sagas]);
}
