import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { addFilterString } from '../../ActionBar/Search/ducks/searchActions';
import { getCurrentFolderId, isQuarantineApp } from '../../../ducks/uiStatusReducer';
import { getFolderPath, getSecureMessageCenterPath } from '../../../utils/routeUtils';
import { getTotalRows } from '../../../Quarantine/ducks/messageReducer';
import { messageListFilterUtils } from '../../../utils/filterUtils';
import { toggleMobileSearch } from '../../../ducks/uiStatusActions';
import MessageSearch from './MessageSearch';

const WAIT_INTERVAL = 500;

class MessageSearchContainer extends Component {
  handleListSearch = (searchString) => {
    // Prevent search if the user has no sent messages
    const { totalRows } = this.props;
    if (totalRows === 0) {
      return;
    }
    // Clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than WAIT_INTERVAL
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    // Set new timeout that waits for user to stop typing before sending request
    this.searchTimeout = setTimeout(() => {
      this.handleSearchResults(searchString);
    }, WAIT_INTERVAL);
  };

  handleClearSearch = () => {
    this.handleSearchValue('');
    this.handleSearchResults('', true);
  };

  handleSearchValue = (searchString) => {
    const { currentFolderId, addFilterStringDispatch } = this.props;
    addFilterStringDispatch('MESSAGE_LIST', searchString, currentFolderId);
  };

  handleCancelSearch = () => {
    const { toggleMobileSearchDispatch, history, isQuarantine, currentFolderId } = this.props;
    this.handleClearSearch();
    toggleMobileSearchDispatch();
    if (isQuarantine) {
      history.push(getFolderPath(currentFolderId));
    } else {
      history.push(getSecureMessageCenterPath());
    }
  };

  handleSearchResults = (searchString, clearSearch = false) => {
    // Prevent lazy search by only allowing strings over 3 chars to be searchable
    if (!clearSearch && searchString.length > 0 && searchString.length < 3) {
      return null;
    }
    const { history, currentFolderId, isQuarantine } = this.props;

    if (isQuarantine) {
      return history.push(getFolderPath(currentFolderId));
    }

    return history.push(getSecureMessageCenterPath());
  };

  render() {
    const { showMobileSearch, searchString, placeholderString, isQuarantine, placeholderSuggestion } = this.props;
    return (
      <MessageSearch
        handleListSearch={this.handleListSearch}
        handleClearSearch={this.handleClearSearch}
        handleSearchValue={this.handleSearchValue}
        handleCancelSearch={this.handleCancelSearch}
        searchString={searchString}
        showMobileSearch={showMobileSearch}
        placeholderString={placeholderString}
        placeholderSuggestion={placeholderSuggestion}
        isQuarantine={isQuarantine}
      />
    );
  }
}

MessageSearchContainer.defaultProps = {
  searchString: '',
  showMobileSearch: false,
  placeholderString: 'search.placeholder',
  placeholderSuggestion: 'search.suggestion',
  totalRows: 0,
  currentFolderId: '',
  isQuarantine: false
};

MessageSearchContainer.propTypes = {
  history: PropTypes.object.isRequired,
  searchString: PropTypes.string,
  showMobileSearch: PropTypes.bool,
  placeholderString: PropTypes.string,
  placeholderSuggestion: PropTypes.string,
  currentFolderId: PropTypes.string,
  totalRows: PropTypes.number,
  toggleMobileSearchDispatch: PropTypes.func.isRequired,
  addFilterStringDispatch: PropTypes.func.isRequired,
  isQuarantine: PropTypes.bool
};

const mapStateToProps = (state) => {
  const props = {};
  props.currentFolderId = getCurrentFolderId(state);
  props.searchString = messageListFilterUtils.getFilterStringFromState(state);
  props.totalRows = getTotalRows(state);
  props.isQuarantine = isQuarantineApp(state);
  return props;
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      toggleMobileSearchDispatch: toggleMobileSearch,
      addFilterStringDispatch: addFilterString
    }
  )(MessageSearchContainer)
);
