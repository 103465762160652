import { appActions } from './appConstants';
/**
 * Action to signal a service error
 *
 * @param {Object} error - the error object from the service call
 * @param {Object} originalAction - the action that triggered this error
 * @returns {Object} - action
 */
export function serviceError(error, originalAction) {
  return {
    type: appActions.SERVICE_ERROR,
    error,
    originalAction
  };
}

/**
 * Action to signal healthy service response
 *
 * @returns {Object} - action
 */
export function serviceOk() {
  return {
    type: appActions.SERVICE_OK
  };
}

/**
 * Application init action
 * @param {Object} store - redux store
 * @returns {Object} application init
 */
export function init() {
  return {
    type: appActions.APPLICATION_INITIALIZE
  };
}

/**
 * Complete application initialization
 * @returns {Object} application initialization complete action
 */
export function initComplete() {
  return {
    type: appActions.APPLICATION_INITIALIZE_COMPLETE
  };
}
