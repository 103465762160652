import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import CellMeasurer from 'react-virtualized/dist/es/CellMeasurer/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/es/CellMeasurer/CellMeasurerCache';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from 'react-virtualized/dist/es/List';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import windowDimensions from 'react-window-dimensions';
import cx from 'classnames';

import './SenderListView.scss';
import { moduleType } from '../../../ducks/appTypes';
import ActionBarSearch from '../../../common/ActionBar/Search';
import AddSenderListItem from './AddSenderListItem';
import DefaultMessage from '../../../common/DefaultMessage';
import EditSenderListItem from './EditSenderListItem';
import MobileDrawer from '../../../common/MobileDrawer';
import ProfileTile from '../../ProfileTile';
import SenderListItem from './SenderListItem';
import { isEmpty } from '../../../utils/strUtils';

const MIN_SENDER_ROW_HEIGHT = 42;
const SENDER_ROW_HEIGHT = 100;

const SenderListView = ({
  isBeingFiltered,
  totalItemCount,
  configProperty,
  senderList,
  isContentUpdating,
  closeFunc,
  backFunc,
  managedDelegate,
  addFunc,
  shouldShowAddUi,
  shouldShowEditUi,
  setCurrentKeyboardFocus,
  currentFocus,
  intl,
  clearSenderListDispatch,
  hideAddEditUiClick
}) => {
  const sendersArray = Object.keys(senderList);
  const filteredItemsLength = sendersArray.length || 0;
  const rowCache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: SENDER_ROW_HEIGHT,
      minHeight: MIN_SENDER_ROW_HEIGHT
    })
  );

  useEffect(() => {
    rowCache.current.clearAll();
  }, [senderList]);

  useEffect(() => {
    return () => {
      rowCache.current.clearAll();
      clearSenderListDispatch();
    };
  }, []);

  const rowRenderer = ({ key, index, parent, style }) => {
    const currentSender = sendersArray[index];
    return (
      <CellMeasurer cache={rowCache.current} key={key} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style} role="row">
          <SenderListItem
            key={currentSender}
            currentFocus={currentFocus}
            lenOfListToDisplay={filteredItemsLength}
            id={currentSender}
            setCurrentKeyboardFocus={setCurrentKeyboardFocus}
            index={index}
            configProperty={configProperty}
          />
        </div>
      </CellMeasurer>
    );
  };

  const senderListSearch = cx('senderlist_search', {
    'managed-delegate': !isEmpty(managedDelegate),
  });   


  return (
    <>
      {!shouldShowAddUi && !shouldShowEditUi && (
        <ProfileTile
          header={`settings.${configProperty}`}
          managedDelegate={managedDelegate}
          closeFunc={closeFunc}
          backFunc={backFunc}
          hideAddEditUiClick={hideAddEditUiClick}
          buttonRight={{
            text: intl.formatMessage({ id: 'settings.safeList.add' }),
            icon: 'add',
            handleAction: addFunc
          }}          
          profileTileQaTag="senderListTitle"
        >
          <div className={senderListSearch}>
            <ActionBarSearch filterModuleType={moduleType.SENDER_LIST} fullScreen />
          </div>

          {isContentUpdating && (
            <CircularProgress
              variant="indeterminate"
              classes={{ indeterminate: 'senderListView-spinner-indeterminate' }}
              className="senderListView-spinner"
              size={50}
              thickness={4}
            />
          )}

          {!isContentUpdating && totalItemCount > 0 && (filteredItemsLength > 0 || !isBeingFiltered) ? (
            <Paper className="senderList--marginTop" square elevation={0}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    height={height-1}
                    tabIndex={-1}
                    rowHeight={rowCache.current.rowHeight}
                    deferredMeasurementCache={rowCache.current}
                    rowCount={filteredItemsLength}
                    rowRenderer={rowRenderer}
                    scrollToIndex={0}
                  />
                )}
              </AutoSizer>
            </Paper>
          ) : (
            !isContentUpdating && (
              <DefaultMessage
                filteredItems={filteredItemsLength}
                isBeingFiltered={isBeingFiltered}
                folderId="sender.no.email.addresses"
                className="senderList--empty"
                square
                elevation={0}
              />
            )
          )}
        </ProfileTile>
      )}
      <MobileDrawer location="up" shouldShow={shouldShowAddUi}>
        <AddSenderListItem
          hideAddEditUiClick={hideAddEditUiClick}
          backFunc={backFunc}
          senderListType={configProperty}
        />
      </MobileDrawer>

      <MobileDrawer location="left" shouldShow={shouldShowEditUi}>
        <EditSenderListItem
          hideAddEditUiClick={hideAddEditUiClick}
          backFunc={backFunc}
          configProperty={configProperty}
        />
      </MobileDrawer>
    </>
  );
};

SenderListView.propTypes = {
  totalItemCount: PropTypes.number.isRequired,
  configProperty: PropTypes.string.isRequired,
  senderList: PropTypes.object.isRequired,
  isContentUpdating: PropTypes.bool.isRequired,
  isBeingFiltered: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  backFunc: PropTypes.func.isRequired,
  addFunc: PropTypes.func.isRequired,
  shouldShowAddUi: PropTypes.bool.isRequired,
  shouldShowEditUi: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object.isRequired,
  clearSenderListDispatch: PropTypes.func.isRequired,
  hideAddEditUiClick: PropTypes.func.isRequired,
  currentFocus: PropTypes.number.isRequired,  
  setCurrentKeyboardFocus: PropTypes.func.isRequired
};

export default windowDimensions({
  debounce: (onResize) => debounce(onResize, 100)
})(injectIntl(SenderListView));
