import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import appReducer, { moduleName as appModuleName } from './appReducer';
import authReducer, { moduleName as authModuleName } from '../SignIn/ducks/authReducer';
import configReducer, { moduleName as configModuleName } from './configReducer';
import digestReducer, { moduleName as digestModuleName } from '../Digest/ducks/digestReducer';
import encryptedMessageReducer, {
  moduleName as messageListModuleName
} from '../Encryption/MessageList/ducks/MessageListReducer';
import featureReducer, { moduleName as featuresModuleName } from './featureReducer';
import folderReducer, { moduleName as folderModuleName } from '../SideBar/ducks/folderReducer';
import helpReducer, { moduleName as helpModuleName } from '../common/Nav/ducks/helpReducer';
import intlReducer, { moduleName as intlModuleName } from './intlReducer';
import messageReducer, { moduleName as messageModuleName } from '../Quarantine/ducks/messageReducer';
import notifierReducer, { moduleName as notifierModuleName } from '../common/Notifier/ducks/notifierReducer';
import profileReducer, { moduleName as profileModuleName } from '../Settings/ducks/profileReducer';
import senderListReducer, {
  moduleName as senderListModuleName
} from '../Settings/Quarantine/SenderListView/ducks/senderListReducer';
import uiStatusReducer, { moduleName as uiStatusModuleName } from './uiStatusReducer';
import searchReducer from '../common/ActionBar/Search/ducks/searchReducer';

/* build reducer dictionary */
// Please keep these in alphabetical order so they're displayed in order in
// redux devtools
const reducerDict = {
  [appModuleName]: appReducer,
  [authModuleName]: authReducer,
  [configModuleName]: configReducer,
  [digestModuleName]: digestReducer,
  [messageListModuleName]: encryptedMessageReducer,
  [featuresModuleName]: featureReducer,
  filter: searchReducer,
  [folderModuleName]: folderReducer,
  [helpModuleName]:helpReducer,
  [intlModuleName]: intlReducer,
  [messageModuleName]: messageReducer,
  [notifierModuleName]: notifierReducer,
  [profileModuleName]: profileReducer,
  [senderListModuleName]: senderListReducer,
  [uiStatusModuleName]: uiStatusReducer
};

export default (history) => {
  return combineReducers({
    router: connectRouter(history),
    ...reducerDict
  });
};
