import { authActions } from './authConstants';
import { authTypes } from './authTypes';
import { profileTypes } from '../../Settings/ducks/profileTypes';

export function unAuthenticatedError() {
  return {
    type: authActions.UNAUTHENTICATED
  };
}

export const updateUserProfile = (user) => ({
  type: profileTypes.SET_USER_PROFILE,
  user
});

export const authenticateUser = () => ({
  type: authActions.AUTHENTICATED
});

export const validateExistenceToken = () => ({
  type: authTypes.VALIDATE_EXISTENCE_TOKEN
});
