import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addOrRemoveRowItem, selectAllRowItem } from '../../../../common/RowItem/ducks/rowItemActions';
import { addSettingsPath } from '../../../../ducks/uiStatusActions';
import { contentTypes } from '../../../../constants/uiConstants';
import { deleteConfig } from '../../../../ducks/configActions';
import { getSettingsPath } from '../../../../ducks/uiStatusReducer';
import { isSettingsPath } from '../../../../utils/uiStatusUtils';
import { moduleType } from '../../../../ducks/appTypes';
import SenderListItem from './SenderListItem';
import roveFocusHOC from '../../../../common/Hoc/roveFocusHOC';

class SenderListItemContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openPopupMenu: false
    };
    props.registerHandleSplKeyShortcutClicked(this.handleSplKeyShortcutClicked);
  }

  handlePopoverOpen = () => {
    this.setState({ openPopupMenu: true });
  };

  handlePopoverClose = () => {
    this.setState({ openPopupMenu: false });
  };

  handleSplKeyShortcutClicked = () => {
    const { openPopupMenu } = this.state;
    if (openPopupMenu) {
      this.handlePopoverClose();
    } else {
      this.handlePopoverOpen();
    }
  };

  render() {
    const {
      id,
      configProperty,
      shouldShowDeleteUi,
      addOrRemoveRowItemDispatch,
      deleteConfigDispatch,
      selectAllRowItemDispatch,
      addSettingsPathDispatch,
      currentFocus,
      setCurrentKeyboardFocus,
      index,
      handleOnFocus,
      handleOnBlur
    } = this.props;
    const { openPopupMenu } = this.state;
    const clearSelection = () => {
      selectAllRowItemDispatch(false, moduleType.SENDER_LIST);
    };

    return (
      <div role="cell">
        <SenderListItem
          id={id}
          configProperty={configProperty}
          selectItemFunc={addOrRemoveRowItemDispatch}
          deleteConfigFunc={deleteConfigDispatch}
          index={index}
          handleOnFocus={handleOnFocus}
          handleOnBlur={handleOnBlur}
          currentFocus={currentFocus}
          openPopupMenu={openPopupMenu}
          handlePopoverOpen={this.handlePopoverOpen}
          handlePopoverClose={this.handlePopoverClose}
          setCurrentKeyboardFocus={setCurrentKeyboardFocus}
          clearAllSelectionFunc={clearSelection}
          addSettingsPathFunc={addSettingsPathDispatch}
          shouldShowDeleteUi={shouldShowDeleteUi}
        />
      </div>
    );
  }
}

SenderListItemContainer.propTypes = {
  id: PropTypes.string.isRequired,
  shouldShowDeleteUi: PropTypes.bool.isRequired,
  addOrRemoveRowItemDispatch: PropTypes.func.isRequired,
  deleteConfigDispatch: PropTypes.func.isRequired,
  selectAllRowItemDispatch: PropTypes.func.isRequired,
  addSettingsPathDispatch: PropTypes.func.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  registerHandleSplKeyShortcutClicked: PropTypes.func.isRequired,
  configProperty: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  currentFocus: PropTypes.number.isRequired,
  setCurrentKeyboardFocus: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const props = {};
  props.id = id;

  props.shouldShowDeleteUi = isSettingsPath(getSettingsPath(state), `/options[${id}]`, true);
  props.listType = contentTypes.SENDER_LIST;

  return props;
};

export default connect(mapStateToProps, {
  selectAllRowItemDispatch: selectAllRowItem,
  addOrRemoveRowItemDispatch: addOrRemoveRowItem,
  deleteConfigDispatch: deleteConfig,
  addSettingsPathDispatch: addSettingsPath
})(roveFocusHOC(SenderListItemContainer));
