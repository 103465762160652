import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import cx from 'classnames';

import './UserBadge.scss';
import { renderInitialsFromString } from '../../utils/renderUtils';
import Tooltip from '../Tooltip';

function UserBadge({ name, count, className, brandingColorPrimary, tooltip }) {
  let themeClass = {};
  // Custom class overrides
  const wrapperClass = `userBadge ${className}`;
  const userBadgeColorStyle = cx('userBadge--color');

  // Apply primary background colour
  if (brandingColorPrimary) {
    themeClass = {
      background: brandingColorPrimary
    };
  }
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="bottom">
        <Avatar className={wrapperClass} style={themeClass}>
          {name ? renderInitialsFromString(name) : `+${count}`}
        </Avatar>
      </Tooltip>
    );
  }
  return (
    <ReactPlaceholder
      ready={Boolean(name && name.length > 0)}
      type="round"
      className="placeholder placeholder--initial"
    >
      <Avatar classes={{ colorDefault: userBadgeColorStyle }} className={wrapperClass} style={themeClass}>
        {name ? renderInitialsFromString(name) : `+${count}`}
      </Avatar>
    </ReactPlaceholder>
  );
}

UserBadge.defaultProps = {
  className: '',
  name: false,
  count: 0,
  brandingColorPrimary: false,
  tooltip: false
};

UserBadge.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  count: PropTypes.number,
  brandingColorPrimary: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default UserBadge;
