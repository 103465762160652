import { injectIntl } from 'react-intl';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './ActionList.scss';
import { buttonElementRetriever } from '../../../../utils/buttonUtils';
import ActionButton from '../../../ActionButton';
import EllipsisMenu from '../../../Menu/EllipsisMenu';
import withPrimaryStyles from '../../../withPrimaryStyles';

function ActionList({
  commands,
  hasEllipsis,
  areActionsDisabled,
  currentGuid,
  handleMessageAction,
  classes,
  intl,
  totalChecked
}) {
  const actionButtonClass = cx('c-action-list__action-btn', {
    'c-action-list__action-btn--disabled': areActionsDisabled,
    [`${classes.secondaryColor}`]: classes.secondaryColor
  });

  const ellipsisButtonClass = cx('c-action-list__ellipsis-btn', {
    'c-action-list__ellipsis-btn--disabled': areActionsDisabled
  });

  // No actions configured
  if (commands.length === 0) {
    return null;
  }

  let iconCount = 0;
  return (
    <ul className="c-action-list">
      <Hidden smDown>
        {/* Icon List - Limit to 4 icons (ordered by pps admin) */}
        {commands.map((command, index) => {
          const buttonElement = buttonElementRetriever(command, true);
          const buttonKey = `ActionList_Button_${index}`;
          iconCount += 1;
          if (iconCount > 4) {
            return null;
          }
          return (
            <li key={`action-${index.toString()}`} className={actionButtonClass}>
              <ActionButton
                key={buttonKey}
                tooltip={areActionsDisabled ? null : buttonElement.tooltipId}
                tooltipPosition="bottom"
                allowKeyboardTab={!areActionsDisabled}
                type={command}
                ariaLabel={intl.formatMessage({ id: buttonElement.tooltipId })}
                onClick={(e) => handleMessageAction(command, [currentGuid], e)}
                showIcon
                disabled={areActionsDisabled}
              />
            </li>
          );
        })}
      </Hidden>
      {/* Action Dropdown - Order by group relevance */}
      {!totalChecked && hasEllipsis && (
        <li>
          <EllipsisMenu
            menuItems={commands}
            targetGuids={[currentGuid]}
            handleMessageAction={handleMessageAction}
            isDisabled={areActionsDisabled}
            wrapperClass={ellipsisButtonClass}
          />
        </li>
      )}
    </ul>
  );
}

ActionList.defaultProps = {
  commands: [],
  areActionsDisabled: false,
  hasEllipsis: false,
  handleMessageAction: null,
  currentGuid: null
};

ActionList.propTypes = {
  commands: PropTypes.arrayOf(PropTypes.string),
  areActionsDisabled: PropTypes.bool,
  hasEllipsis: PropTypes.bool,
  handleMessageAction: PropTypes.func,
  currentGuid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  totalChecked: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired
};

export default injectIntl(withPrimaryStyles(ActionList));
