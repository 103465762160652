/**
 * tring key that carries API call info interpreted by apiMiddleware middleware.
 */
export const API_CALL = 'API_CALL';
export const apiActionState = Object.freeze({
  API_ATTEMPT: '_ATTEMPT',
  API_SUCCESS: '_SUCCESS',
  API_ERROR: '_ERROR'
});

export const actionAttempt = (actionType) => `${actionType}${apiActionState.API_ATTEMPT}`;
export const actionSuccess = (actionType) => `${actionType}${apiActionState.API_SUCCESS}`;
export const actionError = (actionType) => `${actionType}${apiActionState.API_ERROR}`;

/**
 * Keyed by the corresponding "action prefix"
 */

export const apiEndpoints = Object.freeze({
  FOLDERS_URL: '/v2/user/folder',
  FOLDER_URL: '/v2/user/folder/{folderId}',
  FETCH_MESSAGES_CQS_DISABLED: '/v2/user/message',
  FETCH_MESSAGES_CQS_ENABLED: '/v2/user/quarantine/search',
  SCROLL_SEARCH_MESSAGES_CQS_ENABLED: '/v2/user/quarantine/search/scroll',
  MESSAGE_BODY_URL: '/v2/user/{username}/message/{guid}/body',
  MESSAGE_BODY_TEXT_URL: '/v2/user/message/{guid}/text',
  USER_URL: '/v2/user',
  USER_SPAM_URL: '/v2/user/config',
  CONFIG_URL: '/v2/user/config',
  SENDERLIST_URL: '/v2/user/config',
  USERKEY_URL: '/v2/user/keys',
  ENCRYPTED_MESSAGE_URL: '/v2/user/keys/{guid}',
  MINDTOUCH_HELP_URL: '/v2/user/help'
});

/**
 * The exposed, namespaced available API actions.
 */
export const apiActions = Object.freeze({
  // Quarantine
  FETCH_FOLDERS: 'FETCH_FOLDERS',
  FETCH_FOLDERS_COUNT: 'FETCH_FOLDERS_COUNT',
  FETCH_FOLDER_COUNT: 'FETCH_FOLDER_COUNT',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
  FETCH_MESSAGE_BODY: 'FETCH_MESSAGE_BODY',
  FETCH_MESSAGE_BODY_TEXT: 'FETCH_MESSAGE_BODY_TEXT',
  FETCH_SENDERLIST: 'FETCH_SENDERLIST',
  EXECUTE_MESSAGE_FOLDER_COMMAND: 'EXECUTE_MESSAGE_FOLDER_COMMAND',
  EXECUTE_CONFIG_FOLDER_COMMAND: 'EXECUTE_CONFIG_FOLDER_COMMAND',
  // Config
  FETCH_PROFILE: 'FETCH_PROFILE',
  FETCH_USER_SPAM: 'FETCH_USER_SPAM',
  FETCH_BRANDING_LOGO: 'FETCH_BRANDING_LOGO',
  GET_CONFIG: 'GET_CONFIG',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  POST_PROFILE: 'POST_PROFILE',
  VALIDATE_EXISTENCE_TOKEN: 'VALIDATE_EXISTENCE_TOKEN',
  GET_FEATURES: 'GET_FEATURES',
  // Encryption
  FETCH_ACTIVE_ENCRYPTED_MESSAGES: 'FETCH_ACTIVE_ENCRYPTED_MESSAGES',
  FETCH_EXPIRED_ENCRYPTED_MESSAGES: 'FETCH_EXPIRED_ENCRYPTED_MESSAGES',
  FETCH_REVOKED_ENCRYPTED_MESSAGES: 'FETCH_INACTIVE_ENCRYPTED_MESSAGES',
  FETCH_ALL_ENCRYPTED_MESSAGES: 'FETCH_ALL_ENCRYPTED_MESSAGES',
  FETCH_TOTAL_SENT_MESSAGES: 'FETCH_TOTAL_SENT_MESSAGES',
  FETCH_ENCRYPTED_MESSAGE_BY_GUID: 'FETCH_ENCRYPTED_MESSAGE_BY_GUID',
  RESTORE_ENCRYPTED_RECIPIENT: 'RESTORE_ENCRYPTED_RECIPIENT',
  REVOKE_ENCRYPTED_RECIPIENT: 'REVOKE_ENCRYPTED_RECIPIENT',
  RESTORE_ENCRYPTED_MESSAGE: 'RESTORE_ENCRYPTED_MESSAGE',
  REVOKE_ENCRYPTED_MESSAGE: 'REVOKE_ENCRYPTED_MESSAGE',
  UPDATE_EXPIRY: 'UPDATE_EXPIRY',
  SET_MESSAGE_READY: 'SET_MESSAGE_READY',
  UPDATE_SELECTED_GUIDS: 'UPDATE_SELECTED_GUIDS',
  // mindtouch
  REDIRECT_HELP_PAGE: 'REDIRECT_HELP_PAGE',
});

export const apiConstants = Object.freeze({
  pageSize: 100
});
