import { all, call, put, race, select, spawn, take, takeLatest } from 'redux-saga/effects';

import { actionError, actionSuccess, apiActions } from '../app/ducks/apiConstants';
import { addSettingsPath, setComponentDisable, setCurrentApp } from '../app/ducks/uiStatusActions';
import { buttonElements } from '../app/constants/uiConstants';
import { commandActions, moduleType } from '../app/ducks/appTypes';
import {
  decreaseFolderCount,
  decreaseFolderUnreadCount,
  increaseFolderCount
} from '../app/SideBar/ducks/sideBarActions';
import { executeMessageFolderCommand } from '../app/Quarantine/ducks/folderApi';
import { getCurrentEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import { getFolderPath, getHomePath, getMessageBodyPath } from '../app/utils/routeUtils';
import {
  getMessageFromMessages,
  getMessageMeta,
  getNextGuid,
  getTotalRows
} from '../app/Quarantine/ducks/messageReducer';
import { goToMessage, resetMessageEmptyStatus, setMessageEmptyStatus, updateCurrentGuid } from '../app/Quarantine/ducks/messageActions';
import { hideListItems, revealListItems } from '../app/common/RowItem/ducks/rowItemActions';
import { isEmpty } from '../app/utils/strUtils';
import { messageListFilterUtils } from '../app/utils/filterUtils';
import {
  notificationOnTimeout,
  notificationOnUndoAction,
  pushNotification
} from '../app/common/Notifier/ducks/notifierActions';
import { notifierTypes } from '../app/common/Notifier/ducks/notifierTypes';
import { sideBarTypes } from '../app/SideBar/ducks/sideBarTypes';
import history from '../app/utils/historyUtils';
import logger from '../tools/logger';
import { notificationConstants } from '../app/common/Notifier/ducks/notifierConstants';

const log = logger.child({ childName: 'commandSaga' });
let notificationId = 0;
// scheduleFolderCommandHandler() accepts request for a folder command execution
// such as delete, relese, etc.
// pops a notification offering the user to perform a secondary action (onAction, in this case undo/cancel)
// if the notification times out, onTimeout is fired and we go ahead with command execution
// if user opts for a secondary action (clicks undo button) the onTimeout is ignored
export function* scheduleFolderCommandHandler(action) {
  notificationId += 1;
  const id = notificationId;
  const { command, guids, folderId } = action;

  const totalGuids = guids.length;
  const lastSelectedGuid = [...guids].pop();
  const username = yield select(getCurrentEmailAddressFromState);
  const lastSelectedMessage = yield select(getMessageFromMessages, lastSelectedGuid);


  // Ensure current guid is the last selected message
  if (totalGuids > 1) {
    const visibleMessages = yield select(messageListFilterUtils.getVisibleMessages);
    const messageMeta = yield select(getMessageMeta) || {};
    let lastSelectedMessageMeta = {};
    if(messageMeta){
      lastSelectedMessageMeta = (messageMeta)[lastSelectedGuid];
    }
    if (lastSelectedMessage && !lastSelectedMessage.read && (!lastSelectedMessageMeta || !lastSelectedMessageMeta.isRead)) {
      yield put(decreaseFolderUnreadCount(folderId));
    }
    yield put(updateCurrentGuid(lastSelectedGuid, visibleMessages));
  }
  const nextGuid = yield select(getNextGuid);

  try {
    // Hide items to be deleted or released. Select the next visible message
    if (buttonElements[command].hideAffectedElements) {
      yield put(hideListItems(guids, username, moduleType.MESSAGE_LIST));
      yield put(decreaseFolderCount(totalGuids, folderId));
      // Check if last message in list and show empty status
      const totalRows = yield select(getTotalRows);
      if (totalRows <= 1) {
        yield put(setMessageEmptyStatus());
      }
      yield put(
        goToMessage({
          guid: nextGuid,
          folderId
        })
      );
    }
    let senderVal = guids.length;
    if(guids.length === 1 && lastSelectedMessage && (lastSelectedMessage.envelopeSender || lastSelectedMessage.headerSender)){
       senderVal = (lastSelectedMessage.envelopeSender || lastSelectedMessage.headerSender );
    }
    // prepare a notification
    const notificationAction = pushNotification({
      intlId: buttonElements[command].message || buttonElements.defaults.message,
      actionIntlId: 'notification.button.undo',
      onAction: notificationOnUndoAction(id), // action to be fired on secondary action (undo) button click
      values: { command, counter: guids.length, sender: senderVal},
      onTimeout: notificationOnTimeout(id) // action to be fired on timeout or close
    });

    yield put(notificationAction);

    // wait for timeout or secondary action (undo)
    const { timeout, undo, redirect } = yield race({
      timeout: take(notifierTypes.NOTIFICATION_ON_TIME_OUT),
      undo: take(notifierTypes.NOTIFICATION_ON_UNDO_ACTION),
      redirect: take([
        sideBarTypes.NAVIGATE_TO_FOLDER,
        sideBarTypes.NAVIGATE_TO_SECURE_MESSAGE_CENTER,
        sideBarTypes.NAVIGATE_TO_LANDING_PAGE
      ])
    });
    if (timeout || redirect) {
      // create post/put data
      const commandData = {
        operation: command,
        guids,
        folder: folderId
      };
      // fire REST action
      yield put(executeMessageFolderCommand({ username }, commandData));
      // Enable mail action buttons
      yield put(setComponentDisable('ActionButton', false));
      // wait for success or failure
      const result = yield take([
        actionSuccess(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND),
        actionError(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND)
      ]);
      let affectedGuids;
      let errorReasons;
      let reasonsCount = 0;

      if(result.type === actionSuccess(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND) && (command === 'msgsafelist' || command === 'msgblocklist')){
        // Remove the sender from the hidden list when the same sender is added to allow list or block list  
        if(lastSelectedMessage && lastSelectedMessage.envelopeSender){          
          yield put(revealListItems([lastSelectedMessage.envelopeSender.toLowerCase()].flat(), username, moduleType.SENDER_LIST));
        }
      }

      if (result.type === actionError(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND)) {
        // REST request failed (didn't return 200), restore previously hidden items
        yield put(revealListItems(guids, username, moduleType.MESSAGE_LIST));
        yield put(increaseFolderCount(totalGuids, folderId));
        affectedGuids = guids;
      } else if (result.response.result.hasError) {
        // REST request succeded. Check the response has errors, get items that were not processed successfully
        affectedGuids = result.response.result.statuses.reduce((acc, current) => {
          if (current.status !== 'SUCCESS') {
            acc.push(current.guid);
            if (!isEmpty(current.error) && !isEmpty(current.error.errorMessage)) {
              reasonsCount += 1;
              if (!isEmpty(errorReasons)) {
                errorReasons = `${errorReasons} [${reasonsCount}] ${current.error.errorMessage}`;
              } else {
                errorReasons = `[${reasonsCount}] ${current.error.errorMessage}`;
              }
            }
          }
          return acc;
        }, []);
        // restore hidden items that returned error
        yield put(revealListItems(affectedGuids, username, moduleType.MESSAGE_LIST));
        yield put(increaseFolderCount(totalGuids, folderId));
      }
      if (!isEmpty(affectedGuids)) {
        // if there were errors - notify the user
        const errorNotificationAction = pushNotification({
          intlId: isEmpty(errorReasons) ? 'command.execution.error' : 'command.execution.error.with.reasons',
          values: { counter: affectedGuids.length, reasons: errorReasons },
          timeout: notificationConstants.LONG_NOTIFICATION_TIMEOUT,
          isError: true
        });
        yield put(errorNotificationAction);
      }
    }
    if (undo ) {
      // Reset Message "isEmpty" status to false when there is only one message in the list and the operation performed on it is undone.
      const totalRows = yield select(getTotalRows);
      if (totalRows <= 1) {
        yield put(resetMessageEmptyStatus());
      } 
      const undoneNotificatn = pushNotification({
        intlId: 'notification.button.undo.message'
      });
      yield put(undoneNotificatn);
      // show previously hidden items and don't go ahead with command execution
      yield put(revealListItems(guids, username, moduleType.MESSAGE_LIST));
      yield put(increaseFolderCount(totalGuids, folderId));
      yield put(setComponentDisable('ActionButton', false));
    }
  } catch (err) {
    log.error(err, action);
  }
}

export function* scheduleDigestFolderCommandHandler(action) {
  const { command, guids, folderId } = action;
  const username = yield select(getCurrentEmailAddressFromState);
  try {
    // create post/put data
    const commandData = {
      operation: command,
      guids,
      folder: folderId,
      isDigest: true
    };

    let localisedKey;
    switch (command) {
      case 'view':
      case 'editprofile':
      case 'generate':
        break;
      case 'gendigest':
        localisedKey = 'digest.gendigest.success';
        break;
      default: {
        // fire REST action
        yield put(executeMessageFolderCommand({ username }, commandData));
        // hide items to be deleted, released, etc.
        if (buttonElements[command] && buttonElements[command].hideAffectedElements) {
          yield put(hideListItems(guids, username, moduleType.MESSAGE_LIST));
        }
        // wait for success or failure
        const result = yield take([
          actionSuccess(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND),
          actionError(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND)
        ]);
        let errorReasons;
        let reasonsCount = 0;
        let failedGuids;
        if (result.type === actionError(apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND)) {
          // REST request failed (didn't return 200), restore previously hidden items
          failedGuids = guids;
        } else if (result.response.result.hasError) {
          // REST request succeded. Check the response has errors, get items that were not processed successfully
          failedGuids = result.response.result.statuses.reduce((acc, current) => {
            if (current.status !== 'SUCCESS') {
              acc.push(current.guid);
              if (!isEmpty(current.error) && !isEmpty(current.error.errorMessage)) {
                reasonsCount += 1;
                if (!isEmpty(errorReasons)) {
                  errorReasons = `${errorReasons} [${reasonsCount}] ${current.error.errorMessage}`;
                } else {
                  errorReasons = `[${reasonsCount}] ${current.error.errorMessage}`;
                }
              }
            }
            return acc;
          }, []);
          // restore hidden items that returned error
          yield put(revealListItems(failedGuids, username, moduleType.MESSAGE_LIST));
        }
        if (isEmpty(failedGuids)) {
          localisedKey = 'digest';
          switch (command) {
            case 'release':
            case 'delete':
            case 'msgsafelist':
            case 'msgblocklist':
            case 'releasewhitelist':
            case 'reportfalsepositive':
            case 'reportfalsenegative':
            case 'reportfnphishing':
            case 'send':
            case 'sendenc':
            case 'block':
            case 'recommendwhitelist':
              localisedKey += `.${command}`;
              break;
            default:
              localisedKey += '.opperation';
              break;
          }
          localisedKey += '.';
          localisedKey += result.response.result.hasError ? 'error' : 'success';
        } else {
          let messageId;
          if (isEmpty(errorReasons)) {
            messageId = isEmpty(folderId) ? 'command.execution.error.without.module' : 'command.execution.error';
          } else {
            messageId = 'command.execution.error.with.reasons';
          }
          // if there were errors - notify the user
          const errorNotificationAction = pushNotification({
            intlId: messageId,
            values: { counter: failedGuids.length, reasons: errorReasons },
            timeout: notificationConstants.LONG_NOTIFICATION_TIMEOUT,
            isError: true
          });
          yield put(errorNotificationAction);
        }
        break;
      }
    }

    if (localisedKey) {
      yield put(
        pushNotification({
          intlId: localisedKey,       
          timeout: notificationConstants.DEFAULT_NOTIFICATION_TIMEOUT,
          isError: false
        })
      );
    }

    let location = '';
    switch (command) {
      case 'release':
      case 'delete':
      case 'reportfalsepositive':
      case 'reportfalsenegative':
      case 'reportfnphishing':
      case 'send':
      case 'sendenc':
      case 'block':
      case 'recommendwhitelist':
        location = getFolderPath(folderId);
        break;
      case 'releasewhitelist':
      case 'msgsafelist':
        location = getFolderPath(folderId);
        yield put(addSettingsPath('/settings/settings.quarantine.allowList'));
        yield put(setCurrentApp('quarantine'));
        break;
      case 'msgblocklist':
        location = getFolderPath(folderId);
        yield put(addSettingsPath('/settings/settings.quarantine.blockList'));
        yield put(setCurrentApp('quarantine'));
        break;
      case 'view':
        // Display error when the module parameter is missing from the url.
        if (isEmpty(folderId) || isEmpty(guids)) {
          const errorNotificationAction = pushNotification({
            intlId: isEmpty(folderId) ? 'command.execution.error.without.module' : 'command.execution.error',
            values: { counter: 1 },
            timeout: notificationConstants.LONG_NOTIFICATION_TIMEOUT,
            isError: true
          });
          yield put(errorNotificationAction);
        }
        location = getMessageBodyPath(folderId, guids[0]);
        break;
      case 'generate':
      case 'editprofile':
        location = getFolderPath(folderId);
        yield put(addSettingsPath('/settings/settings.quarantine.general'));
        yield put(setCurrentApp('quarantine'));
        break;
      default:
        location = getHomePath();
        break;
    }
    if (location) {
      yield call(history.push, location);
    }
  } catch (err) {
    log.error(err, action);
  }
}

export function* watchScheduleFolderCommand() {
  yield takeLatest(commandActions.SCHEDULE_FOLDER_COMMAND, scheduleFolderCommandHandler);
  yield takeLatest(commandActions.SCHEDULE_DIGEST_FOLDER_COMMAND, scheduleDigestFolderCommandHandler);
}

/* root schedule folder command saga */
export default function* scheduleFolderCommandSaga() {
  yield all([spawn(watchScheduleFolderCommand)]);
}
