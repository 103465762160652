import { all, call, put,select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchProfile, fetchUserSpam, postUserSpam } from '../app/Settings/ducks/profileApi';
import { profileTypes } from '../app/Settings/ducks/profileTypes';
import { pushNotification } from '../app/common/Notifier/ducks/notifierActions';
import { setProfile, setSpamPolicyOptionDefault, submitManageDelegateChange } from '../app/Settings/ducks/profileActions';
import apiHandler from '../app/ducks/utils/apiHandler';
import logger from '../tools/logger';
import { profileActions } from '../app/ducks/appTypes';
import { resetMessagesAndMeta } from '../app/Quarantine/ducks/messageActions';
import { isEmpty, putStringToStorage, removeItemfromStorage } from '../app/utils/strUtils';
import { storageKeys } from '../app/constants/uiConstants';
import { getDelegateObj, getLoggedInUserEmailAddressFromState } from '../app/Settings/ducks/profileReducer';
import { getStore } from '../store/storeUtil';
import { actionError, actionSuccess, apiActions } from '../app/ducks/apiConstants';


const log = logger.child({ childName: 'profileSaga' });
export function* fetchProfileAndSpam(action) {
  const loggedInUserEmail = yield select(getLoggedInUserEmailAddressFromState);

  // loggedInUserEmail is the default email address to fetch if username is not set in action.
  const { username = loggedInUserEmail} = action;
  try {
    // add spinner here
    const [profile, spam] = yield all([
      call(apiHandler, fetchProfile()),
      call(apiHandler, fetchUserSpam({ username }))
    ]);
    const { spamPolicies, currentSpamPolicy } = spam.properties;

    // We are doing two things at the same time here:
    // 1. Converting the policy to an array so that our dropdown can comsume easily.
    // 2. Getting the default index, which is a string instead of an index :(
    let spamPolicyOptionDefault = 0;
    spamPolicies.map((option, index) => {
      if (option.id === currentSpamPolicy) {
        spamPolicyOptionDefault = index;
      }
      return option.description || option.id;
    });

    yield put(
      setProfile({
        ...profile,
        spamPolicyOptionDefault,
        // spamPolicyOptions,
        spamPolicies
      })
    );
  } catch (err) {
    log.error(err, action);
  }
}

export function* changeDelegate(action){  
  const { delegate } = action;
  if(isEmpty(delegate)){
    // Resetting manage delegate and return to the account logged in user
    yield call(removeItemfromStorage, sessionStorage, storageKeys.STORAGE_KEY_FOR_DELEGATE);
    yield put(submitManageDelegateChange(delegate));
  }else {

    // getDelegateObj method call gets the full delegate object {displayname, userId, email} with either email or userId
    // this is required since digest url will not have the display name or userId of the delegates
    // and only userId is stored in session storage and fetched when the page is refreshed.

    const delegateObj = yield call(getDelegateObj, getStore().getState(), delegate);
    if(!isEmpty(delegateObj)){      
      // Setting managed delegate and switch to the account of delegate
      yield call(putStringToStorage, sessionStorage, storageKeys.STORAGE_KEY_FOR_DELEGATE, delegateObj.userId );
      yield put(submitManageDelegateChange(delegateObj));
    }
  }  
  yield put(resetMessagesAndMeta());
}

export function* submitProfile(action) {
  const { username, data } = action;
  // make notification or something
  try {
    yield call(apiHandler, postUserSpam({ username }, data));
    yield put(setSpamPolicyOptionDefault(action.spamPolicyOptionDefault));
    yield put(
      pushNotification({
        intlId: 'notification.updated.message'
      })
    );
  } catch (err) {
    log.error(err, action);
    // make notification or something
  }
}

export function* watchFetchProfile() {
  yield takeLatest(profileTypes.FETCH_PROFILE_AND_SPAM, fetchProfileAndSpam);
}

export function* watchSubmitProfile() {
  yield takeLatest(profileTypes.SUBMIT_PROFILE, submitProfile);
}


export function redirectToHelpPage(action) {
  window.open(action.response.redirectUri);
}

export function* redirectToHelpPageError() {
  yield put(pushNotification({ intlId: 'error.nav.help', timeout: 5000 }));
}

export function* watchDelegateChange() {
  yield takeEvery([profileActions.SET_MANAGING_DELEGATE, profileActions.RESET_MANAGING_DELEGATE], changeDelegate);
  yield takeLatest(actionSuccess(apiActions.REDIRECT_HELP_PAGE), redirectToHelpPage);
  yield takeLatest(actionError(apiActions.REDIRECT_HELP_PAGE), redirectToHelpPageError);
}

export default function* profileSaga() {
  yield all([spawn(watchFetchProfile), spawn(watchSubmitProfile), spawn(watchDelegateChange)]);
}
