import React, { useContext, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Portal } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import WarningIcon from '@material-ui/icons/Warning';
import cx from 'classnames';

import './Notifier.scss';
import { APP_NAMES } from '../../ducks/appConstants';
import { RootContext } from '../../RootContext';
import { isEmpty } from '../../utils/strUtils';
import { notificationConstants } from './ducks/notifierConstants';
import { setMessageListFocus, setSettingsDialogFocus } from '../../utils/accessibilityUtils';

const Notifier = ({
  isOpen,
  settingsPath,
  currentApp,
  notification,
  dispatch,
  removeNotification,
  closeNotifier,
  intl
}) => {
  const { appRef } = useContext(RootContext);
  let settingsContainer;
  if (appRef && appRef.current) {
    [settingsContainer] = appRef.current.offsetParent.querySelectorAll('div.settingsPanel');
  }
  let timeoutId = null;
  const ref = useRef();
  const handleClose = (event, reason) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    if (reason !== 'cancel' && notification.onTimeout) {
      dispatch(notification.onTimeout);
    }
    // close notifier
    closeNotifier();

    // remove notifier from queue
    setTimeout(removeNotification, notificationConstants.TRANSITION_DURATION);
  };

  const actionButtonStyle = cx('notifier-action-button');

  const handleButtonClick = () => {
    if (notification.onAction) {
      dispatch(notification.onAction);
    }
    handleClose(null, 'cancel');
  };

  if (!notification) {
    return null;
  }
  const classnames = cx('notifier-snackbar-wrapper', {
    'notifier-snackbar--error': notification.isError,
    'notifier-snackbar--toBottom': notification.intlId.includes('senderlist')
  });

  // Note: actionButtons are buttons that appear on the Right side of the snack bar Eg: Undo, Cancel.
  const actionButtons = [
    <>
      {notification.onAction && (
        <Button classes={{ root: actionButtonStyle }} size="small" onClick={handleButtonClick}>
          <FormattedMessage id={notification.actionIntlId} />
        </Button>
      )}
    </>,
    <IconButton
      tabIndex={0}
      key="close"
      disableRipple
      aria-label="Close"
      color="inherit"
      className="notifier--close"
      onClick={handleClose}
    >
      <CloseIcon />
    </IconButton>
  ];
  let timeout =
    notification.timeout !== undefined ? notification.timeout : notificationConstants.LONG_NOTIFICATION_TIMEOUT;
  timeout = timeout <= 0 ? undefined : timeout;
  let { verticalPosition, horizontalPosition } = notification;
  if (!verticalPosition) {
    verticalPosition = notification.isError ? 'top' : 'bottom';
  }
  if (!horizontalPosition) {
    horizontalPosition = notification.isError ? 'center' : 'left';
  }
  const messageText = intl.formatMessage({ id: notification.intlId }, notification.values);
  if (isOpen) {
    timeoutId = setTimeout(() => handleClose(null, 'timeout'), timeout);
  }

  return (
    <>
      {notification.isError && <div className="notifier-overlay--error" />}
      <Portal container={isEmpty(settingsPath) && appRef ? appRef.current : settingsContainer}>
        <Snackbar
          ref={ref}
          aria-label="Notification"
          name={`NOTIFIER_${notification.isError ? 'ERROR' : 'INFO'}`}
          tabIndex={0}
          anchorOrigin={{
            vertical: verticalPosition,
            horizontal: horizontalPosition
          }}
          TransitionProps={{
            // Note : OnEntering the snackbar transition the focus should move to the first button in the snackbar action items
            // OnExiting the snackbar transition the focus should shift to the next logical element on the screen which is passed
            // as a callback function "onCloseFocus" in the notification props. if "onCloseFocus" is not set it focusses on AppSwitcher by default.

            onEntering: () => {
              setTimeout(() => {
                if (ref && ref.current) {
                  ref.current.focus();
                }
              });
            },
            onExiting: () => {
              if (currentApp === APP_NAMES.quarantine) {
                if (isEmpty(settingsPath)) setMessageListFocus(appRef);
                else setSettingsDialogFocus(appRef);
              }
            }
          }}
          className={classnames}
          TransitionComponent={Fade}
          transitionDuration={notificationConstants.DEFAULT_TRANSITION_DURATION}
          open={isOpen}
          onClose={handleClose}
          ContentProps={{
            'aria-label': messageText,
            role: 'alert',
            classes: {
              root: cx('notifier-snackbar', {
                'notifier-snackbar--error': notification.isError
              }),
              message: 'notifier-snackbar-message',
              action: 'notifier-snackbar-action'
            }
          }}
          message={
            <div className="notifier-snackbar__message-container">
              <Grid container direction="row" alignItems="center">
                <Grid item>{notification.isError ? <WarningIcon /> : <InfoIcon />}</Grid>
                <Grid item xs zeroMinWidth>
                  {messageText}
                </Grid>
              </Grid>
            </div>
          }
          action={actionButtons}
        />
      </Portal>
    </>
  );
};

Notifier.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  notification: PropTypes.object,
  removeNotification: PropTypes.func.isRequired,
  closeNotifier: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  settingsPath: PropTypes.string,
  currentApp: PropTypes.string
};

Notifier.defaultProps = {
  notification: undefined,
  settingsPath: '',
  currentApp: ''
};

export default injectIntl(Notifier);
