import React, { Component } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';

import './RowItem.scss';
import { elementSizes } from '../../constants/uiConstants';
import { qaTags } from '../../constants/qaConstants';
import ScoreBadge from '../ScoreBadge';
import Tooltip from '../Tooltip';

class RowItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false
    };
    this.ref = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    const { isFocussed } = this.props;
    if (isFocussed && prevProps.isFocussed !== isFocussed) {
      this.ref.current.firstChild.focus();
    }
  };

  hideActions = () => {
    this.setState({ showActions: false });
  };

  onRowClick = (e, index) => {
    const { onClickRowItem, setCurrentKeyboardFocus } = this.props;
    onClickRowItem(e);
    setCurrentKeyboardFocus(index);
  };

  renderActions = () => {
    this.setState({ showActions: !isMobile });
  };

  handleOnMenuFocus = (e) => {
    const { alwaysShowCommandActionItems, handleOnFocus } = this.props;
    if (!alwaysShowCommandActionItems) {
      handleOnFocus(e);
    }
  };

  renderRightContent() {
    const { rightText, hoverCommandActionItems, isRead, alwaysShowCommandActionItems } = this.props;
    const { showActions } = this.state;
    const classnames = cx('rowItem-right-content', {
      'rowItem-right-content--is-unread': !isRead,
      'hover-obscure': showActions && hoverCommandActionItems
    });
    return (
      <>
        {rightText && <ListItemText className={classnames} primary={rightText} />}
        {(showActions || alwaysShowCommandActionItems) && (
          <div className="rowItem-right-button in-front-of-text">{hoverCommandActionItems}</div>
        )}
      </>
    );
  }

  renderCenterContent() {
    const { centerText, isRead } = this.props;
    const classnames = cx('rowItem-content', {
      'rowItem-content--is-unread': !isRead
    });
    return (
      <>
        {centerText && centerText.length >= 0 && (
          <div className="rowItem-center-content-box">
            <div className="rowItem-center-content">
              <Tooltip enterDelay={500} title={centerText} placement="bottom-start" alwaysRender={false}>
                <ListItemText className={classnames} primary={centerText} />
              </Tooltip>
            </div>
            {this.renderAttachFileIcon()}
          </div>
        )}
      </>
    );
  }

  renderAttachFileIcon() {
    const { showAttachFileIcon } = this.props;
    return (
      showAttachFileIcon && (
        <div className="rowItem-content-listitemicon-box">
          <ListItemIcon>
            <AttachFileIcon className="rowItem-content-listitemicon" />
          </ListItemIcon>
        </div>
      )
    );
  }

  renderLeftContent() {
    const { leftText, isRead } = this.props;
    const classnames = cx('rowItem-content', {
      'rowItem-content--is-unread': !isRead
    });
    return (
      leftText &&
      leftText.length >= 0 && (
        <div className="rowItem-left-content" aria-label={leftText}>
          <ListItemText className={classnames} primary={leftText} />
        </div>
      )
    );
  }

  render() {
    const {
      score,
      onSelectionChange,
      index,
      isSelected,
      rowItemQaTag,
      isFocussed,
      showCheckboxes,
      children,
      handleOnBlur
    } = this.props;

    const classnames = cx('rowItem', {
      'rowItem--selected': isSelected,
      [`${elementSizes.MESSAGE_ITEM}-px`]: true,
      'rowItem-checkBox--hidden': !showCheckboxes
    });
    return (
      <div role="tablist" ref={this.ref}>
        <div
          role="tab"
          button={false}
          onFocus={(e) => {
            this.renderActions(e);
            this.handleOnMenuFocus(e);
          }}
          onBlur={(e) => {
            this.hideActions(e);
            handleOnBlur(e);
          }}
          onMouseOver={(e) => {
            this.renderActions(e);
          }}
          onMouseLeave={(e) => {
            this.hideActions(e);
          }}
          tabIndex={isFocussed ? 0 : -1}
          name={rowItemQaTag}
          className={classnames}
          onClick={(e) => {
            this.onRowClick(e, index);
          }}
        >
          {onSelectionChange && showCheckboxes && (
            <Checkbox
              disableRipple
              name={qaTags.ROWITEM_LIST_CHECKBOX}
              style={{ height: 'inherit' }}
              checked={isSelected}
              onChange={onSelectionChange}
              onClick={(event) => event.stopPropagation()}
            />
          )}
          {!isNil(score) && !isMobileOnly && <ScoreBadge score={score} />}
          {this.renderLeftContent()}
          {this.renderCenterContent()}
          {this.renderRightContent()}
          {children}
        </div>
      </div>
    );
  }
}

RowItem.defaultProps = {
  onClickRowItem: noop,
  setCurrentKeyboardFocus: noop,
  showCheckboxes: true,
  children: undefined,
  hoverCommandActionItems: [],
  score: undefined,
  centerText: undefined,
  showAttachFileIcon: undefined,
  rightText: undefined,
  isRead: true,
  alwaysShowCommandActionItems: undefined,
  onSelectionChange: undefined,
  isSelected: false
};

RowItem.propTypes = {
  onSelectionChange: PropTypes.func,
  score: PropTypes.number,
  isFocussed: PropTypes.bool.isRequired,
  isRead: PropTypes.bool,
  leftText: PropTypes.string.isRequired,
  centerText: PropTypes.string,
  showAttachFileIcon: PropTypes.bool,
  rightText: PropTypes.object,
  isSelected: PropTypes.bool,
  children: PropTypes.node,
  rowItemQaTag: PropTypes.string.isRequired,
  onClickRowItem: PropTypes.func,
  index: PropTypes.number.isRequired,
  hoverCommandActionItems: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showCheckboxes: PropTypes.bool,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  alwaysShowCommandActionItems: PropTypes.bool,
  setCurrentKeyboardFocus: PropTypes.func
};

export default RowItem;
