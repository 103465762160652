import { APP_NAMES } from '../ducks/appConstants';
import { featureName, featuresForDisplay } from '../constants/featureConstants';
import { isHomePath, isSecureMessageCenterPath } from './routeUtils';

export function getFeaturesForDisplay(feature) {
  return featuresForDisplay[feature];
}

export function getAppName(app) {
  return featureName[app];
}

export function getCurrentApp(path, shouldShowLandingPage) {
  if (isSecureMessageCenterPath(path)) {
    return APP_NAMES.keyManagement;
  }
  if (shouldShowLandingPage && isHomePath(path)) {
    return APP_NAMES.landingPage;
  }
  return APP_NAMES.quarantine;
}
