import { uiStatusActions } from './appTypes';

export const setContentUpdate = (contentType, isUpdating, reason) => ({
  type: uiStatusActions.SET_CONTENT_UPDATE,
  contentType,
  isUpdating,
  reason
});

export const setComponentDisable = (componentType, isDisabled, reason) => ({
  type: uiStatusActions.SET_COMPONENT_DISABLE,
  componentType,
  isDisabled,
  reason
});

export function setCurrentKeyboardFocus(focus, componentName) {
  return {
    type: uiStatusActions.SET_CURRENT_KEYBOARD_FOCUS,
    focus,
    componentName
  };
}

export function setFolderListKeyboardFocus(focus) {
  return {
    type: uiStatusActions.SET_FOLDER_LIST_KEYBOARD_FOCUS,
    focus
  };
}

export function setMessageListKeyboardFocus(focus) {
  return {
    type: uiStatusActions.SET_MESSAGE_LIST_KEYBOARD_FOCUS,
    focus
  };
}

export function setSenderListKeyboardFocus(focus) {
  return {
    type: uiStatusActions.SET_SENDER_LIST_KEYBOARD_FOCUS,
    focus
  };
}

export const showAppSwitcher = () => ({
  type: uiStatusActions.SHOW_APP_SWITCHER
});

export const showMobileViewSideBar = () => ({
  type: uiStatusActions.SHOW_MOBILE_VIEW_SIDEBAR
});

export const toggleMobileViewSideBar = () => ({
  type: uiStatusActions.TOGGLE_MOBILE_SIDEBAR
});

export const toggleMobileSearch = () => ({
  type: uiStatusActions.TOGGLE_MOBILE_SEARCH
});

export const addSelectedSettingsOption = (selectedSettingsOption) => ({
  type: uiStatusActions.ADD_SELECTED_SETTINGS_OPTION,
  selectedSettingsOption
});

export const addSettingsPath = (path) => ({
  type: uiStatusActions.ADD_SETTINGS_PATH,
  settingsPath: path
});

export const removeSettingsPath = (path, shouldEscape) => ({
  type: uiStatusActions.REMOVE_SETTINGS_PATH,
  settingsPath: path,
  shouldEscape
});

export const closeMobileViewSideBar = () => ({
  type: uiStatusActions.CLOSE_MOBILE_VIEW_SIDEBAR
});

export const setEncryptionAnimationDirection = (encryptionAnimationDirection) => ({
  type: uiStatusActions.SET_ENCRYPTION_ANIMATION_DIRECTION,
  encryptionAnimationDirection
});

export const setCurrentApp = (currentApp) => ({
  type: uiStatusActions.SET_CURRENT_APP_TITLE,
  currentApp
});

export const toggleSingleMessageActions = () => ({
  type: uiStatusActions.TOGGLE_SINGLE_MESSAGE_ACTIONS
});
