import { rowItemTypes } from './rowItemTypes';

export const selectAllRowItem = (isAllRowItemSelected, moduleType) => ({
  type: rowItemTypes.ROW_ITEM_SELECT_ALL,
  select: isAllRowItemSelected,
  moduleType
});

export const addOrRemoveRowItem = (id, isRowItemSelected, moduleType) => ({
  type: rowItemTypes.ROW_ITEM_SELECT,
  id,
  moduleType,
  select: isRowItemSelected
});

// Hide list items with specified IDs
export const hideListItems = (guids, userName, moduleType, noStorageCache) => ({
  type: rowItemTypes.HIDE_LIST_ITEMS,
  guids,
  userName,
  noStorageCache,
  moduleType
});

// Stop hiding list items with specified IDs
export const revealListItems = (guids, userName, moduleType) => ({
  type: rowItemTypes.REVEAL_LIST_ITEMS,
  guids,
  userName,
  moduleType
});

// Load hidden items from storage
export const loadHiddenRowItems = (userName, moduleType) => ({
  type: rowItemTypes.LOAD_HIDDEN_ROW_ITEMS,
  userName,
  moduleType
});
