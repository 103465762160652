import { getNotificationCountInQueue } from '../common/Notifier/ducks/notifierReducer';
import { getSettingsPath } from '../ducks/uiStatusReducer';
import { getStore } from '../../store/storeUtil';
import { isEmpty } from './strUtils';
import { keyCodes } from '../constants/uiConstants';
import { showMessageActionsMenu } from '../Quarantine/ducks/messageActions';

// Keyboard Accessibility navigation for lists
export function handleKeyboardKeyDown(
  e,
  firstFocussableElemInList = 0,
  elementsArrayLength,
  currentFocus,
  setCurrentFocus,
  lastVisibleIndex,
  firstVisibleIndex
) {
  let isEdgeElement = false;

  switch (e.keyCode) {
    case keyCodes.KEY_PERIOD: {
      const settingsPath = getSettingsPath(getStore().getState());
      if (isEmpty(settingsPath)) {
        getStore().dispatch(showMessageActionsMenu(true));
      }
      isEdgeElement = true;
      break;
    }
    case keyCodes.ARROW_LEFT:
    case keyCodes.ARROW_UP: {
      if (currentFocus - 1 <= firstFocussableElemInList) {
        setCurrentFocus(firstFocussableElemInList);
        isEdgeElement = true;
        break;
      }
      if (currentFocus - 1 < firstVisibleIndex) {
        setCurrentFocus(currentFocus - 1, keyCodes.ARROW_UP);
        isEdgeElement = false;
        break;
      }
      setCurrentFocus(currentFocus - 1, keyCodes.ARROW_UP);
      break;
    }
    case keyCodes.ARROW_RIGHT:
    case keyCodes.ARROW_DOWN: {
      if (currentFocus >= elementsArrayLength - 1) {
        setCurrentFocus(currentFocus);
        isEdgeElement = true;
        break;
      }
      if (currentFocus + 1 > lastVisibleIndex) {
        setCurrentFocus(currentFocus + 1, keyCodes.ARROW_DOWN);
        isEdgeElement = false;
        break;
      }
      setCurrentFocus(currentFocus + 1, keyCodes.ARROW_DOWN);
      break;
    }
    default:
      setCurrentFocus(currentFocus);
      isEdgeElement = true;
      break;
  }
  return isEdgeElement;
}

// Keyboard Accessibility. Selecting list items with space/enter
export function handleKeyboardBtnSelect(event, fn) {
  const { keyCode, key } = event;
  if (
    keyCode === keyCodes.KEY_ENTER ||
    key === keyCodes.KEYNAME_ENTER ||
    keyCode === keyCodes.KEY_SPACE ||
    key === keyCodes.KEYNAME_SPACE
  ) {
    fn();
  }
}

// Keyboard Accessibility. Setting keyboard focus to previously focussed message in quarantine messagelist

export function setMessageListFocus(ref) {
  const notificationCount = getNotificationCountInQueue(getStore().getState());
  // Focus on MessageList while exiting notifier only when there are no other notifications queued.
  if (ref && ref.current) {
    if (notificationCount === 1) {
      const section = ref.current.querySelector('div#virtualized-list');
      if (section) {
        section.focus();
        const message = section.querySelector("div.singleMessage--quarantine[tabindex='0']");
        if (message) message.focus();
      }
    }
  }
}

// Keyboard Accessibility. Setting keyboard focus to first interactive element in Settings dialog
export function setSettingsDialogFocus(appRef) {
  const notificationCount = getNotificationCountInQueue(getStore().getState());
  // Focus on Settingspanel while exiting notifier only when there are no other notifications queued.
  if (appRef && appRef.current) {
    if (notificationCount === 1) {
      const [section] = appRef.current.offsetParent.querySelectorAll('div.settingsPanel');
      if (section) {
        section.focus();
        const firstInteractiveChild = section.querySelectorAll('input')[0];
        if (firstInteractiveChild && firstInteractiveChild.focus) firstInteractiveChild.focus();
      }
    }
  }
}
