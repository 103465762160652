import { connect } from 'react-redux';
import CellMeasurerCache from 'react-virtualized/dist/es/CellMeasurer/CellMeasurerCache';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { apiConstants } from '../../ducks/apiConstants';
import { contentTypes, keyCodes } from '../../constants/uiConstants';
import { getCurrentFolderId, getCurrentMessageListFocus, isLoadingMore } from '../../ducks/uiStatusReducer';
import { getFirstGuid, getSearchCursor, getTotalMessages, getTotalRows } from '../ducks/messageReducer';
import { getFolderErrorStatus } from '../../SideBar/ducks/folderReducer';
import { getQuarantineGuidFromPath } from '../../utils/routeUtils';
import { messageListFilterUtils } from '../../utils/filterUtils';
import { setCurrentKeyboardFocus } from '../../ducks/uiStatusActions';
import { showLandingPage } from '../../ducks/featureReducer';
import MessageList from './MessageList';
import checkingIfMessageIsHeader from '../../utils/messageUtils';
import { getManagingDelegate } from '../../Settings/ducks/profileReducer';

class MessageListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFolderChanged: false,
      currentFolderId: null
    };
    this.offset = 1;
    this.searchCursor = '';
    this.offsetRowsLoaded = [1];
    this.rowCache = new CellMeasurerCache({
      fixedWidth: true
    });
    this.test = React.createRef();
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.currentFolderId !== state.currentFolderId) {
      return {
        isFolderChanged: true,
        currentFolderId: props.currentFolderId
      };
    }
    return {
      isFolderChanged: false
    };
  };

  componentDidUpdate(prevProps) {
    const {
      handleOnMessageClick,
      setCurrentKeyboardFocusDispatch,
      isReady,
      visibleQuarantineMessages,
      currentFocus,
      visibleQuarantineMessagesCount
    } = this.props;
    const { isFolderChanged } = this.state;
    const currentGuid = getQuarantineGuidFromPath();
    // Reset the offsetRowsLoaded in messagelist table when the folders are switched
    if (isFolderChanged) {
      this.offset = 1;
      this.offsetRowsLoaded = [1];
      this.searchCursor = '';
    }
    // Change the keyboard focus to next message in list if the currently focused message is a header.
    if (
      visibleQuarantineMessages &&
      visibleQuarantineMessagesCount > 0 &&
      checkingIfMessageIsHeader(visibleQuarantineMessages, currentFocus)
    ) {
      setCurrentKeyboardFocusDispatch(currentFocus + 1, contentTypes.MESSAGE_LIST);
    }
    const isCurrentGuidPresentInVisibleMsgList = Object.hasOwnProperty.call(visibleQuarantineMessages, currentGuid);

    // Select message upon reload or direct access
    if (isReady && isReady !== prevProps.isReady) {
      let nextGuid = null;
      if (visibleQuarantineMessagesCount !== 0)
        nextGuid = isCurrentGuidPresentInVisibleMsgList
          ? currentGuid
          : visibleQuarantineMessages[Object.keys(visibleQuarantineMessages)[1]].guid;
      handleOnMessageClick(nextGuid, false);
      return true;
    }
    return false;
  }

  setFocus = (value, keyCode) => {
    const {
      setCurrentKeyboardFocusDispatch,
      visibleQuarantineMessagesCount,
      visibleQuarantineMessages,
      isReady
    } = this.props;

    if (!isReady) return;
    let focussedElement = value;
    //  Skip message group headers in keyboard navigation
    if (checkingIfMessageIsHeader(visibleQuarantineMessages, value)) {
      focussedElement =
        keyCode === keyCodes.ARROW_UP
          ? Math.max(focussedElement - 1, 1)
          : Math.min(focussedElement + 1, visibleQuarantineMessagesCount - 1);
    }
    setCurrentKeyboardFocusDispatch(focussedElement, contentTypes.MESSAGE_LIST);
  };

  isRowLoaded = ({ index }) => {
    const { visibleQuarantineMessagesCount } = this.props;
    return !!(visibleQuarantineMessagesCount > index); // STATUS_LOADING or STATUS_LOADED
  };

  loadMoreRows = ({ stopIndex }) => {
    const {
      fetchMessagesDispatch,
      searchCursor,
      isLoadingMoreDispatch,
      totalMessages,
      getTotalHiddenMessages,
      visibleQuarantineMessagesCount
    } = this.props;
    const { currentFolderId } = this.state;

    // Fetching messages only when the total of visibleQuarantineMessagesCount + hidden messages
    //  that are stored in session storage is lesser than the "totalMessages" count from the API response.
    // getTotalHiddenMessages - Hidden messages in session storage are messages that are scheduled to be deleted or released but the operation
    // is not yet completed in the backend. These messages continue to be present in the fetchMessages API response but should be filtered in the UI.

    if (!isLoadingMoreDispatch && visibleQuarantineMessagesCount + getTotalHiddenMessages < totalMessages) {
      fetchMessagesDispatch({
        folderId: currentFolderId,
        offset: this.offset,
        scrollId: stopIndex,
        searchCursor
      });
      this.offset += 1;
    }
    this.offsetRowsLoaded.push(this.offset1);
  };

  render() {
    const {
      handleOnMessageClick,
      handleMessageAction,
      totalRows,
      managedDelegate,
      isReady,
      isEmpty,
      visibleQuarantineMessages,
      selectedMessages,
      totalMessages,
      isLandingPageFeatureOn,
      isFolderError,
      currentFocus,
      searchString
    } = this.props;

    const { isFolderChanged } = this.state;
    return (
      <MessageList
        currentFocus={currentFocus}
        ref={this.test}
        managedDelegate={managedDelegate}
        checkingIfMessageIsHeader={checkingIfMessageIsHeader}
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        isFolderError={isFolderError}
        setCurrentKeyboardFocus={this.setFocus}
        rowCount={totalRows}
        rowRenderer={this.rowRenderer}
        isReady={isReady}
        searchString={searchString}
        isMessageListEmpty={isEmpty}
        isFolderChanged={isFolderChanged}
        rowCache={this.rowCache}
        offset={this.offset}
        totalMessages={totalMessages}
        handleOnMessageClick={handleOnMessageClick}
        quarantineMessages={visibleQuarantineMessages}
        selectedMessages={selectedMessages}
        handleMessageAction={handleMessageAction}
        isLandingPageFeatureOn={isLandingPageFeatureOn}
      />
    );
  }
}

MessageListContainer.defaultProps = {
  selectedMessages: {},
  totalRows: apiConstants.pageSize,
  totalMessages: 0,
  getTotalHiddenMessages: 0,
  currentFolderId: '',
  currentFocus: 0,
  searchCursor: null,
  visibleQuarantineMessagesCount: 0,
  searchString: ''
};

MessageListContainer.propTypes = {
  handleOnMessageClick: PropTypes.func.isRequired,
  handleMessageAction: PropTypes.func.isRequired,
  visibleQuarantineMessages: PropTypes.object.isRequired,
  isReady: PropTypes.bool.isRequired,
  selectedMessages: PropTypes.object,
  fetchMessagesDispatch: PropTypes.func.isRequired,
  totalRows: PropTypes.number,
  getTotalHiddenMessages: PropTypes.number,
  searchString: PropTypes.string,
  visibleQuarantineMessagesCount: PropTypes.number,
  totalMessages: PropTypes.number,
  currentFolderId: PropTypes.string,
  isLandingPageFeatureOn: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isFolderError: PropTypes.bool.isRequired,
  managedDelegate: PropTypes.object.isRequired,
  setCurrentKeyboardFocusDispatch: PropTypes.func.isRequired,
  currentFocus: PropTypes.number,
  searchCursor: PropTypes.string,
  isLoadingMoreDispatch: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.totalMessages = getTotalMessages(state);
  props.totalRows = getTotalRows(state);
  props.firstGuid = getFirstGuid(state);
  props.currentFolderId = getCurrentFolderId(state);
  props.visibleQuarantineMessages = messageListFilterUtils.groupMessagesByDate(state);
  props.getTotalHiddenMessages = messageListFilterUtils.getTotalHiddenMessages(state);
  props.searchString = messageListFilterUtils.getFilterStringFromState(state);
  props.isLandingPageFeatureOn = showLandingPage(state);
  props.isFolderError = getFolderErrorStatus(state);
  props.currentFocus = getCurrentMessageListFocus(state);
  props.searchCursor = getSearchCursor(state);
  props.isLoadingMoreDispatch = isLoadingMore(state);
  props.managedDelegate = getManagingDelegate(state);

  props.visibleQuarantineMessagesCount = Object.keys(props.visibleQuarantineMessages).length;
  return props;
};

export default connect(mapStateToProps, {
  setCurrentKeyboardFocusDispatch: setCurrentKeyboardFocus
})(MessageListContainer);
