import AddIcon from '@material-ui/icons/Add';
import AppBar from '@proofpoint/fuse-ui/components/AppBar';
import BackIcon from '@material-ui/icons/ArrowBack';
import Button from '@proofpoint/fuse-ui/components/Button';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import SearchIcon from '@material-ui/icons/Search';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import './BasicNav.scss';
import { getApplicationTitle } from '../../../utils/appUtils';
import { renderString } from '../../../utils/renderUtils';
import Hamburger from '../../Icons/Hamburger';
import NavRightContainer from '../NavRightContainer';
import ConfirmationDialog from '../../Dialogs/Confirmation';
import { navConstant } from '../../../constants/uiConstants';

// Map icon name prop to component
const iconMap = {
  default: <Hamburger />,
  back: <BackIcon />,
  search: <SearchIcon />,
  MoreVert: <MoreVert />,
  close: <Close />,
  add: <AddIcon />
};

function BasicNav({
  totalCheckedMessages,
  handleMultiSelectCancel,
  buttonLeft,
  buttonCenter,
  buttonRight,
  intl,
  mainTitle,
  subTitle,
  mainClass,
  innerClass,
  toggleShowSettings,
  managedDelegate,
  stopManagingDispatch,
  fetchProfileDispatch,
  logout,
  userEmailAddress,
  openDialog,
  isReady,
  isPrimaryNav,
  openStopManagementConfirmationDialog,
  handleDialogClose
}) {
  const leftTextButtonClass = cx('', {
    'basic-nav__left-btn': isPrimaryNav,
    'basic-nav__left-text-btn': !isPrimaryNav
  });

  const rightTextButtonClass = cx('', {
    'basic-nav__right-btn': isPrimaryNav,
    'basic-nav__right-text-btn': !isPrimaryNav
  });

  const shouldHideButtons = isPrimaryNav ? totalCheckedMessages > 0 : false;
  const stopManagingConfirmDialog = intl.formatMessage({id: 'stop.managing.confirm.dialog.content'},{username: managedDelegate.displayName});

  return (
    <AppBar
      productName=""
      companyLogo=""
      classes={{ root: 'basic-nav-wrapper' }}
      className={mainClass}
      position="static"
    >
      <Toolbar classes={{ root: 'basic-nav' }} className={innerClass}>
        {/* Left Button */}
        {buttonLeft && buttonLeft.text && !shouldHideButtons && (
          <Button
            color="inherit"
            aria-label={buttonLeft.icon}
            disableRipple
            classes={{ root: leftTextButtonClass }}
            onClick={() => buttonLeft.handleAction()}
          >
            {iconMap[buttonLeft.icon]}
            {buttonLeft.text}
          </Button>
        )}
        {buttonLeft && !buttonLeft.text && !shouldHideButtons && (
          <>
            <IconButton
              color="inherit"
              aria-label={buttonLeft.icon}
              disableRipple
              classes={{ root: 'basic-nav__left-btn basic-nav__left-btn--icon' }}
              onClick={() => buttonLeft.handleAction()}
            >
              {iconMap[buttonLeft.icon]}
            </IconButton>
          </>
        )}

        {/* Title */}
        {isPrimaryNav && totalCheckedMessages > 0 ? (
          <ReactPlaceholder type="textRow" ready={isReady} className="placeholder placeholder--subject">
            <Typography variant="h1" color="inherit" classes={{ root: 'basic-nav__title basic-nav__title--selected' }}>
              {renderString('selection.counter', { counter: totalCheckedMessages })}
            </Typography>
          </ReactPlaceholder>
        ) : (
          isPrimaryNav &&
          mainTitle && (
            <Typography variant="h1" color="inherit" classes={{ root: 'basic-nav__title' }}>
              {mainTitle || getApplicationTitle('folder.SecureMessageCenter')}
            </Typography>
          )
        )}
        {subTitle && (
          <Typography variant="h2" color="inherit" classes={{ root: 'basic-nav__subtitle' }}>
            {subTitle}
          </Typography>
        )}

        {/* Center Button */}
        {buttonCenter && buttonCenter.text && (
          <>
            <Button
              color="inherit"
              aria-label={buttonCenter.text}
              disableRipple
              classes={{ root: 'basic-nav__center-btn' }}
              onClick={() => buttonCenter.handleAction()}
            >
              {buttonCenter.text}
            </Button>
          </>
        )}

        {/* Right Button */}
        {buttonRight && buttonRight.text && !shouldHideButtons && (
          <Button
            color="inherit"
            aria-label={buttonRight.icon}
            disableRipple
            classes={{ root: rightTextButtonClass }}
            onClick={() => buttonRight.handleAction()}
          >
            {iconMap[buttonRight.icon]}
            {buttonRight.text}
          </Button>
        )}
        {buttonRight && !buttonRight.text && !shouldHideButtons && (
          <>
            <IconButton
              color="inherit"
              aria-label={buttonRight.icon}
              disableRipple
              classes={{ root: 'basic-nav__right-btn' }}
              onClick={() => buttonRight.handleAction()}
            >
              {iconMap[buttonRight.icon]}
            </IconButton>
          </>
        )}                 
              <NavRightContainer 
                toggleShowSettings={toggleShowSettings}
                managedDelegate={managedDelegate}
                logout={logout}
                navType={navConstant.BASIC_NAV}
                intl={intl}
                openStopManagementConfirmationDialog={openStopManagementConfirmationDialog}
              />       
        {openDialog && <ConfirmationDialog open={openDialog} handleClose={handleDialogClose} handleAction={()=>{stopManagingDispatch();fetchProfileDispatch(userEmailAddress);}} dialogContent={stopManagingConfirmDialog} />}

        {isPrimaryNav && totalCheckedMessages > 0 && (
          <Button
            color="inherit"
            disableRipple
            classes={{ root: 'basic-nav__right-btn basic-nav__right-btn--cancel' }}
            onClick={() => handleMultiSelectCancel()}
          >
            {renderString('action.button.cancel')}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

BasicNav.defaultProps = {
  mainTitle: null,
  subTitle: null,
  buttonLeft: null,
  buttonCenter: null,
  buttonRight: null,
  mainClass: '',
  innerClass: '',
  isReady: true,
  totalCheckedMessages: 0,
  handleMultiSelectCancel: null,
  isPrimaryNav: true,
  managedDelegate: {},
  userEmailAddress:''
};

BasicNav.propTypes = {
  buttonLeft: PropTypes.shape({
    icon: PropTypes.string,
    handleAction: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object])
  }),
  buttonCenter: PropTypes.shape({
    handleAction: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  buttonRight: PropTypes.shape({
    icon: PropTypes.string,
    handleAction: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  mainTitle: PropTypes.object,
  subTitle: PropTypes.string,
  mainClass: PropTypes.string,
  innerClass: PropTypes.string,
  isReady: PropTypes.bool,
  totalCheckedMessages: PropTypes.number,
  handleMultiSelectCancel: PropTypes.func,
  isPrimaryNav: PropTypes.bool,
  toggleShowSettings: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  openStopManagementConfirmationDialog: PropTypes.func.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object,
  userEmailAddress: PropTypes.string,
  openDialog: PropTypes.bool.isRequired,
  stopManagingDispatch: PropTypes.func.isRequired,
  fetchProfileDispatch: PropTypes.func.isRequired,
};

export default (injectIntl(BasicNav));
