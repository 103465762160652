import { folderIntlId } from '../constants/folderConstants';

export function getFolderTotalCount(folderMap, folderId) {
  return folderMap[folderId] ? folderMap[folderId].totalCount : 0;
}

export function getFolderScrollId(folderMap, folderId) {
  return folderMap[folderId] ? folderMap[folderId].scrollId : null;
}

export function isValidFolder(folderId) {
  return folderIntlId[folderId];
}
