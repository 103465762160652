import { isAuthenticatedSelector } from '../SignIn/ducks/authReducer';

/* eslint-disable import/prefer-default-export */

export function isFirstTimeLoginSelector() {
  // TODO: get the value from store
  return false;
}

export const isUserAuthenticated = (state) => isAuthenticatedSelector(state);
