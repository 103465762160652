export function getQuarantinePath() {
  return '/quarantine';
}

export function folderRootPath() {
  return `${getQuarantinePath()}/folder`;
}

export function getFolderPath(name) {
  return `${folderRootPath()}/${name}`;
}

export function getMessageBodyPath(folderId, msgId) {
  return `${folderRootPath()}/${folderId}/message/${msgId}`;
}

export function getHomePath() {
  return '/';
}

export function getAuthPath() {
  return '/auth';
}

export function getEncryptionPath() {
  return '/encryption';
}

export function getDigestPath() {
  return '/euweb/digest';
}

export function getSecureMessageCenterPath() {
  return '/secure-message-center';
}

export function isFolderPath(path) {
  return /^\/quarantine\/folder\/\w+$/i.test(path);
}

export function isSenderPath(path) {
  return /^\/quarantine\/sender\/\w+$/i.test(path);
}

export function isMessageBodyPath(path) {
  return /^\/quarantine\/folder\/\w+\/message\/.+$/i.test(path);
}

export function isHomePath(path) {
  return path === getHomePath();
}

export function isDigestPath(path) {
  return /^\/euweb\/digest$/i.test(path);
}

export function isQuarantinePath(path) {
  return /^\/quarantine\/folder+$/i.test(path) || isFolderPath(path) || isMessageBodyPath(path);
}

export function isSecureMessageCenterPath(path) {
  return /^\/secure-message-center$/i.test(path);
}

export function isValidPath(path) {
  return (
    isFolderPath(path) ||
    isSenderPath(path) ||
    isMessageBodyPath(path) ||
    isDigestPath(path) ||
    isSecureMessageCenterPath(path)
  );
}

export function getRedirectPath() {
  // Check if the user attempted to redirect to an authenticated
  // page before logging in.
  const path = localStorage.getItem('redirectPath');
  localStorage.removeItem('redirectPath');
  // If the path is a valid CEUWEB endpoint proceed
  if (isValidPath(path)) {
    return path;
  }
  return null;
}

export function setRedirectPath(path) {
  if (isValidPath(path)) {
    localStorage.setItem('redirectPath', path);
  }
}

export function getRedirectSearch() {
  const search = localStorage.getItem('redirectsearch');
  localStorage.removeItem('redirectsearch');
  return search || '';
}

export function setRedirectSearch(search) {
  localStorage.setItem('redirectsearch', search);
}

export function isUrlHash(hash) {
  return hash;
}

export function getQuarantineFolderFromPath(path = window.location.pathname) {
  if (!isQuarantinePath(path)) {
    return null;
  }
  const [, , , folderId] = path.split('/');
  return folderId;
}

export function getQuarantineGuidFromPath(path = window.location.pathname) {
  if (!isQuarantinePath(path)) {
    return null;
  }
  const [, , , , , guid] = path.split('/');
  return guid;
}
