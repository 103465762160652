import { profileActions } from '../../ducks/appTypes';
import { profileTypes } from './profileTypes';

export const fetchProfile = (username, data) => ({
  type: profileTypes.FETCH_PROFILE_AND_SPAM,
  username,
  data
});

export const setProfile = (user) => ({
  type: profileTypes.SET_PROFILE,
  user
});

export const setManagingDelegate = (delegate) => ({
  type: profileActions.SET_MANAGING_DELEGATE,
  delegate
});

export const resetManagingDelegate = () => ({
  type: profileActions.RESET_MANAGING_DELEGATE
})

export const submitManageDelegateChange = (delegate = {}) => ({
  type: profileTypes.SUBMIT_MANAGE_DELEGATE_CHANGE,
  delegate
})

export const profileOnSubmit = (username, data, spamPolicyOptionDefault) => ({
  type: profileTypes.SUBMIT_PROFILE,
  username,
  data,
  spamPolicyOptionDefault
});

export const setSpamPolicyOptionDefault = (spamPolicyOptionDefault) => ({
  type: profileTypes.SET_SPAM_POLICY_OPTION_DEFAULT,
  spamPolicyOptionDefault
});
