import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './Encryption.scss';
import { APP_NAMES } from '../ducks/appConstants';
import { getBrandingColorPrimaryFromState } from '../ducks/configReducer';
import {
  getEmptyFromState,
  getEncryptedMessages,
  getFirstGuidFromState,
  getReadyFromState,
  getSearchStringFromState,
  getTotalMessagesFromState,
  getTotalResultsFromState
} from './MessageList/ducks/MessageListReducer';
import { getEncryptionAnimationDirection, getMobileSearchStatus, isComponentDisabled } from '../ducks/uiStatusReducer';
import { getFeature } from '../ducks/featureReducer';
import { retrieveEncryptedMessages, updateCurrentGuid } from './MessageList/ducks/MessageListActions';
import { setCurrentApp, setEncryptionAnimationDirection, toggleSingleMessageActions } from '../ducks/uiStatusActions';
import Encryption from './Encryption';

class EncryptionContainer extends Component {
  constructor(props) {
    super(props);
    const { encryptionAnimationDirection } = this.props;
    this.state = {
      viewAnimateDirection: encryptionAnimationDirection
    };
  }

  componentDidMount() {
    const { retrieveEncryptedMessagesDispatch } = this.props;
    // Fetch 'All' messages
    retrieveEncryptedMessagesDispatch({
      offset: 0,
      type: 'FETCH_ALL_ENCRYPTED_MESSAGES',
      status: 'ALL'
    });
  }

  componentDidUpdate() {
    const { isEncryptionFeatureOn, history } = this.props;

    // Redirect to root if encryption feature is turned off
    if (!isEncryptionFeatureOn) {
      history.replace('/');
    }
  }

  handleViewAnimation = () => {
    const { viewAnimateDirection } = this.state;
    const {
      history,
      encryptionAnimationDirection,
      setEncryptionAnimationDirectionDispatch,
      setCurrentAppDispatch
    } = this.props;
    if (encryptionAnimationDirection && isMobile) {
      // message options back button returns user to landing page
      history.replace('/');
      setEncryptionAnimationDirectionDispatch();
      setCurrentAppDispatch(APP_NAMES.landingPage);
    } else {
      let getDirection = '';
      if (viewAnimateDirection === '' || viewAnimateDirection === 'right') {
        getDirection = 'left';
      } else {
        getDirection = 'right';
      }
      this.setState({ viewAnimateDirection: getDirection });
    }
  };

  handleOnMessageClick = (guid, animate = true) => {
    if (guid) {
      const { history, updateCurrentGuidDispatch } = this.props;
      updateCurrentGuidDispatch(guid);
      history.replace(`/secure-message-center#${guid}`);
      if (animate) {
        this.handleViewAnimation();
      }
    }
  };

  render() {
    const { viewAnimateDirection } = this.state;
    const {
      encryptedMessages,
      brandingColorPrimary,
      isReady,
      isEmpty,
      showMobileSearch,
      encryptedMessageCount,
      totalSearchResults,
      searchString,
      showSingleMessageActions,
      toggleSingleMessageActionsDispatch
    } = this.props;
    return (
      <Encryption
        handleOnMessageClick={this.handleOnMessageClick}
        handleViewAnimation={this.handleViewAnimation}
        viewAnimateDirection={viewAnimateDirection}
        encryptedMessages={encryptedMessages}
        brandingColorPrimary={brandingColorPrimary}
        isReady={isReady}
        isEmpty={isEmpty}
        showMobileSearch={showMobileSearch}
        encryptedMessageCount={encryptedMessageCount}
        toggleSingleMessageActions={toggleSingleMessageActionsDispatch}
        showSingleMessageActions={showSingleMessageActions}
        totalSearchResults={totalSearchResults}
        searchString={searchString}
      />
    );
  }
}

EncryptionContainer.defaultProps = {
  showMobileSearch: false,
  encryptionAnimationDirection: '',
  showSingleMessageActions: true
};

EncryptionContainer.propTypes = {
  isEncryptionFeatureOn: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  retrieveEncryptedMessagesDispatch: PropTypes.func.isRequired,
  encryptedMessages: PropTypes.object.isRequired,
  brandingColorPrimary: PropTypes.string.isRequired,
  isReady: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  showMobileSearch: PropTypes.bool,
  encryptedMessageCount: PropTypes.number.isRequired,
  totalSearchResults: PropTypes.number.isRequired,
  searchString: PropTypes.string.isRequired,
  encryptionAnimationDirection: PropTypes.string,
  setEncryptionAnimationDirectionDispatch: PropTypes.func.isRequired,
  setCurrentAppDispatch: PropTypes.func.isRequired,
  updateCurrentGuidDispatch: PropTypes.func.isRequired,
  toggleSingleMessageActionsDispatch: PropTypes.func.isRequired,
  showSingleMessageActions: PropTypes.bool
};

const mapStateToProps = (state) => {
  const props = {};
  props.isEncryptionFeatureOn = getFeature(state, 'keyManagement');
  props.encryptedMessages = getEncryptedMessages(state);
  props.isReady = getReadyFromState(state);
  props.isEmpty = getEmptyFromState(state);
  props.firstGuid = getFirstGuidFromState(state);
  props.brandingColorPrimary = getBrandingColorPrimaryFromState(state);
  props.showMobileSearch = getMobileSearchStatus(state);
  props.encryptedMessageCount = getTotalMessagesFromState(state);
  props.totalSearchResults = getTotalResultsFromState(state);
  props.searchString = getSearchStringFromState(state);
  props.encryptionAnimationDirection = getEncryptionAnimationDirection(state);
  props.showSingleMessageActions = !isComponentDisabled(state, 'SingleEncryptionActions');
  return props;
};

export default withRouter(
  connect(mapStateToProps, {
    retrieveEncryptedMessagesDispatch: retrieveEncryptedMessages,
    setEncryptionAnimationDirectionDispatch: setEncryptionAnimationDirection,
    setCurrentAppDispatch: setCurrentApp,
    updateCurrentGuidDispatch: updateCurrentGuid,
    toggleSingleMessageActionsDispatch: toggleSingleMessageActions
  })(EncryptionContainer)
);
