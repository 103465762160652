import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getAuthPath, setRedirectPath, setRedirectSearch } from '../../../utils/routeUtils';
import { getEnabledFeaturesCount, getEnabledFeaturesLoaded } from '../../../ducks/featureReducer';
import { isAuthenticatedSelector } from '../../../SignIn/ducks/authReducer';

export default function(ComposedComponent) {
  class Authentication extends Component {
    componentDidMount() {
      const { authenticated, noFeatureEnabled, history, location } = this.props;
      if (!authenticated || noFeatureEnabled) {
        console.log(`Redirecting to login - Authenticated: ${authenticated} noFeatureEnabled: ${noFeatureEnabled}`); // eslint-disable-line
        setRedirectPath(`${location.pathname}`);
        setRedirectSearch(`${location.search}`);
        history.replace(getAuthPath());
      }
    }

    componentDidUpdate(prevProps) {
      const { authenticated } = this.props;
      if (authenticated !== prevProps.authenticated) {
        const { history, location } = this.props;
        // Set current URL for user to redirect back to upon login
        setRedirectPath(location.pathname);
        // Redirect to login
        history.replace(getAuthPath());
      }
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ComposedComponent {...this.props} />;
    }
  }

  Authentication.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    noFeatureEnabled: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  function mapStateToProps(state) {
    return {
      authenticated: isAuthenticatedSelector(state),
      noFeatureEnabled: !getEnabledFeaturesCount(state) && getEnabledFeaturesLoaded(state)
    };
  }

  return connect(mapStateToProps)(Authentication);
}
