import { getContrastRatio } from '@material-ui/core/styles/colorManipulator';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './LandingPage.scss';
import { renderString, renderUserGreeting } from '../utils/renderUtils';
import AppSwitcherContainer from '../common/AppSwitcher/AppSwitcherContainer';
import EncryptionSummaryCard from './EncryptionSummaryCard/EncryptionSummaryCard';
import HelperText from '../common/HelperText';
import QuarantineSummaryCard from './QuarantineSummaryCard/QuarantineSummaryCard';
import SideBarContainer from '../common/SideBar';
import withPrimaryStyles from '../common/withPrimaryStyles';

const LandingPage = ({
  displayName,
  classes,
  colorPrimary,
  navigateToSecureMessageCenter,
  handleOnMessageClick,
  encryptedMessages,
  isReady,
  isFoldersReady,
  encryptedMessageCount,
  folders,
  navigateToFolder,
  isEncryptionFeatureOn,
  isQuarantineFeatureOn
}) => {
  const landingPageBannerClass = cx('landing-page__banner', {
    [classes.primaryBackgroundColor]: classes.primaryBackgroundColor,
    'landing-page__banner_light': getContrastRatio('#000', colorPrimary) < getContrastRatio('#FFF', colorPrimary)
  });
  const userGreeting = renderUserGreeting(displayName);
  return (
    <>
      <Hidden lgUp>
        <SideBarContainer />
      </Hidden>
      <div className="landing-page">
        <div className="landing-page__wrapper">
          <div className={landingPageBannerClass}>
            <HelperText title={userGreeting} subtitle={renderString('landing.page.greeting.message')} />
          </div>
          <div className="landing-page__summary">
            <Hidden smDown>
              <AppSwitcherContainer mobileSwitcher horizontal />
            </Hidden>
            {isEncryptionFeatureOn && (
              <EncryptionSummaryCard
                encryptedMessageCount={encryptedMessageCount}
                encryptedMessages={encryptedMessages}
                navigateToSecureMessageCenter={navigateToSecureMessageCenter}
                handleOnMessageClick={handleOnMessageClick}
                isReady={isReady}
              />
            )}
            {isQuarantineFeatureOn && (
              <QuarantineSummaryCard folders={folders} isFoldersReady={isFoldersReady} navigateToFolder={navigateToFolder} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

LandingPage.defaultProps = {
  colorPrimary: null,
  isFoldersReady: true
};

LandingPage.propTypes = {
  displayName: PropTypes.string.isRequired,
  colorPrimary: PropTypes.string,
  classes: PropTypes.object.isRequired,
  navigateToSecureMessageCenter: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired,
  encryptedMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOnMessageClick: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  isFoldersReady: PropTypes.bool,
  encryptedMessageCount: PropTypes.number.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEncryptionFeatureOn: PropTypes.bool.isRequired,
  isQuarantineFeatureOn: PropTypes.bool.isRequired
};

export default withPrimaryStyles(LandingPage);
