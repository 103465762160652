import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import Slide from '@material-ui/core/Slide';

import BasicNav from '../../Nav/Basic';

const FullScreenDialog = ({
  isOpen,
  toggleDialog,
  buttonLeft,
  buttonRight,
  mainTitle,
  dialogClassName,
  navClassName,
  dialogContent,
  transitionDirection
}) => (
  <Dialog
    fullScreen
    open={isOpen}
    onClose={toggleDialog}
    className={dialogClassName}
    PaperProps={{ className: `${dialogClassName}__wrapper` }}
    TransitionComponent={Slide}
    transitionDuration={{
      enter: 600,
      exit: 600
    }}
    TransitionProps={{
      direction: transitionDirection
    }}
  >
    {buttonLeft && buttonRight && (
      <BasicNav
        buttonLeft={{
          icon: buttonLeft.icon,
          handleAction: buttonLeft.handleAction
        }}
        mainTitle={mainTitle}
        mainClass="basic-nav-wrapper--white-bg"
        innerClass={navClassName}
        buttonRight={{
          icon: buttonRight.icon,
          handleAction: buttonRight.handleAction
        }}
      />
    )}
    {buttonLeft && !buttonRight && (
      <BasicNav
        buttonLeft={{
          icon: buttonLeft.icon,
          handleAction: buttonLeft.handleAction
        }}
        mainTitle={mainTitle}
        mainClass="basic-nav-wrapper--white-bg"
        innerClass={navClassName}
      />
    )}
    {!buttonLeft && buttonRight && (
      <BasicNav
        mainTitle={mainTitle}
        mainClass="basic-nav-wrapper--white-bg"
        innerClass={navClassName}
        buttonRight={{
          icon: buttonRight.icon,
          handleAction: buttonRight.handleAction
        }}
      />
    )}
    {dialogContent}
  </Dialog>
);

FullScreenDialog.defaultProps = {
  buttonLeft: null,
  buttonRight: null,
  mainTitle: null,
  dialogClassName: '',
  navClassName: '',
  transitionDirection: 'up'
};

FullScreenDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  buttonLeft: PropTypes.object,
  buttonRight: PropTypes.object,
  mainTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dialogClassName: PropTypes.string,
  navClassName: PropTypes.string,
  dialogContent: PropTypes.object.isRequired,
  transitionDirection: PropTypes.string
};

export default FullScreenDialog;
