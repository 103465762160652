import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchProfile, profileOnSubmit } from '../../ducks/profileActions';
import GeneralSettings from './GeneralSettings';

class GeneralSettingsContainer extends Component {
  componentDidMount() {
    const { primaryEmailAddress, fetchProfileDispatch } = this.props;
    fetchProfileDispatch(primaryEmailAddress);
  }

  render() {
    const {
      managedDelegate,
      primaryEmailAddress,
      spamPolicyOptionDefault,
      spamPolicies,
      profileOnSubmitDispatch,
      closeFunc,
      backFunc
    } = this.props;
    return (
      <>
        <GeneralSettings
          primaryEmailAddress={primaryEmailAddress}
          spamPolicyOptionDefault={spamPolicyOptionDefault}
          spamPolicies={spamPolicies}
          managedDelegate={managedDelegate}
          profileOnSubmit={profileOnSubmitDispatch}
          closeFunc={closeFunc}
          backFunc={backFunc}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile } = state;
  return {
    primaryEmailAddress: profile.primaryEmailAddress,
    displayName: profile.displayName,
    spamPolicyOptionDefault: profile.spamPolicyOptionDefault,
    spamPolicyOptions: profile.spamPolicyOptions,
    alternateEmailAddresses: profile.alternateEmailAddresses,
    spamPolicies: profile.spamPolicies
  };
};

GeneralSettingsContainer.propTypes = {
  primaryEmailAddress: PropTypes.string.isRequired,
  spamPolicyOptionDefault: PropTypes.number.isRequired,
  spamPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
  profileOnSubmitDispatch: PropTypes.func.isRequired,
  fetchProfileDispatch: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired,
  backFunc: PropTypes.func.isRequired,
  managedDelegate: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  profileOnSubmitDispatch: profileOnSubmit,
  fetchProfileDispatch: fetchProfile
})(GeneralSettingsContainer);
