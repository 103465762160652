import { all, call, put, spawn, take } from 'redux-saga/effects';

import { appActions } from '../app/ducks/appConstants';
import { initComplete } from '../app/ducks/appActions';
import logger from '../tools/logger';

const log = logger.child({ childName: 'applicationSaga' });

/**
 * Bootstrap application
 */
export function* initializeApplication() {
  log.info('initializing application.');
  // TODO: hook to do application level logic for example redirect to difftrent url

  yield put(initComplete());
  log.info('initializing application completed.');
}

/**
 * Watch for action APPLICATION_INITIALIZE and bootstrap app
 */
export function* watchApplicationInitialize() {
  yield take(appActions.APPLICATION_INITIALIZE);
  try {
    yield call(initializeApplication);
  } catch (e) {
    log.error(`Error-${e}`);
  }
}

/* root applicationSaga  */
export default function* applicationSaga() {
  yield all([spawn(watchApplicationInitialize)]);
}
