import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { buttonType } from '../../../constants/uiConstants';
import { getCheckedGuids } from '../../ducks/messageReducer';
import { getCurrentFolderId } from '../../../ducks/uiStatusReducer';
import { getFolderCommands } from '../../../SideBar/ducks/folderReducer';
import { resetCheckedMessages } from '../../ducks/messageActions';
import BottomActions from './BottomActions';

class BottomActionsContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOption: buttonType.BUTTON_RELEASE
    };
  }

  toggleDialog = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  handleConfirmAndClose = () => {
    const { handleMessageAction, handleResetQuarantineMessagesDispatch, checkedGuids } = this.props;
    const { selectedOption } = this.state;
    // Close dialog
    this.toggleDialog();

    if (selectedOption) {
      // Trigger message action
      handleMessageAction(selectedOption, Object.keys(checkedGuids));
      // Clear checked messages
      handleResetQuarantineMessagesDispatch();
    }
  };

  handleOption = (option) => {
    this.setState({ selectedOption: option });
  };

  render() {
    const { commands, checkedGuids } = this.props;
    const { isOpen, selectedOption } = this.state;

    if (Object.keys(checkedGuids).length === 0) {
      return null;
    }

    return (
      <BottomActions
        isOpen={isOpen}
        toggleDialog={this.toggleDialog}
        handleConfirmAndClose={this.handleConfirmAndClose}
        handleOption={this.handleOption}
        selectedOption={selectedOption}
        commands={commands}
      />
    );
  }
}

BottomActionsContainer.defaultProps = {
  commands: [],
  checkedGuids: {}
};

BottomActionsContainer.propTypes = {
  commands: PropTypes.arrayOf(PropTypes.string),
  handleMessageAction: PropTypes.func.isRequired,
  checkedGuids: PropTypes.object,
  handleResetQuarantineMessagesDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  const folderId = getCurrentFolderId(state);
  props.commands = getFolderCommands(state, folderId);
  props.checkedGuids = getCheckedGuids(state);
  return props;
};

export default connect(
  mapStateToProps,
  {
    handleResetQuarantineMessagesDispatch: resetCheckedMessages
  }
)(BottomActionsContainer);
