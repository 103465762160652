import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import './SideBar.scss';
import { isHomePath } from '../../utils/routeUtils';
import SideBarAppDrawerContainer from './AppDrawer/SideBarAppDrawerContainer';

function SideBar({ shouldShowMobileView, toggleMobileView, children }) {
  const isSingleTier = isHomePath(window.location.pathname);
  const secondTierNavClass = cx('region-sidebar--mobile', {
    'region-sidebar--hidden': isSingleTier
  });
  return (
    <>
      <Hidden mdDown>
        <div className="region-sidebar">{children}</div>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          className="sidebar-drawer-container"
          open={shouldShowMobileView}
          onClose={() => toggleMobileView()}
          transitionDuration={400}
        >
          <SideBarAppDrawerContainer
            shouldShowMobileView={shouldShowMobileView}
            toggleMobileView={toggleMobileView}
            isSingleTier={isSingleTier}
          />
          <div
            tabIndex={0}
            className={secondTierNavClass}
            role="button"
            onClick={() => toggleMobileView()}
            onKeyDown={() => toggleMobileView()}
          >
            {children}
          </div>
        </Drawer>
      </Hidden>
    </>
  );
}

SideBar.propTypes = {
  shouldShowMobileView: PropTypes.bool.isRequired,
  toggleMobileView: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default SideBar;
