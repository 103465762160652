import FolderIcon from '@material-ui/icons/Folder';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import SendIcon from '@material-ui/icons/Send';

import './SideBarFolderListItem.scss';
import { getFolderPath, isMessageBodyPath, isSecureMessageCenterPath } from '../../utils/routeUtils';
import { qaTags } from '../../constants/qaConstants';
import { renderFolderName } from '../../utils/renderUtils';
import SideBarListItem from '../ListItem';
import Tooltip from '../../common/Tooltip';
import withPrimaryStyles from '../../common/withPrimaryStyles/withPrimaryStyles';

const SideBarFolderListItem = ({
  path,
  folderId,
  totalMessageCount,
  folderOnClick,
  secureMessageCenterOnClick,
  classes
}) => {
  function isLinkActive(urlPath) {
    // special case: if the url path is message, need to set active selection on sidebar
    if (isMessageBodyPath(path)) {
      return path.startsWith(urlPath);
    }
    return urlPath === path;
  }

  /**
   * Returns the classname to use for the button icons
   * @param {boolean} isThisLinkActive
   */
  function getIconClassname(isThisLinkActive) {
    // sidebar-listItem-icon--selected
    return isThisLinkActive ? classes.primaryColor : '';
  }

  const urlPath = getFolderPath(folderId);
  const isThisLinkActive = isLinkActive(urlPath);

  return (
    <>
      {!isSecureMessageCenterPath(path) && (
        <SideBarListItem
          key={folderId}
          isSelected={isThisLinkActive}
          onClick={() => folderOnClick(folderId)}
          leftIcon={
            <ListItemIcon>
              <FolderIcon className={getIconClassname(isThisLinkActive)} />
            </ListItemIcon>
          }
          leftText={
            <Tooltip alwaysRender={false} title={renderFolderName(folderId)}>
              <ListItemText
                disableTypography
                name={qaTags.SIDEBAR_LIST_ITEM_LEFT_TEXT}
                primary={renderFolderName(folderId)}
                className="sidebar-folder-name"
              />
            </Tooltip>
          }
          rightText={
            <ListItemText
              primaryTypographyProps={{ className: 'countText' }}
              name={qaTags.SIDEBAR_LIST_ITEM_MESSAGE_COUNT}
              primary={totalMessageCount}
              className={`sidebar-msg-count ${isThisLinkActive && classes.lightColor}`}
            />
          }
        />
      )}
      {isSecureMessageCenterPath(path) && (
        <SideBarListItem
          key={folderId}
          isSelected
          onClick={() => secureMessageCenterOnClick()}
          leftIcon={
            <ListItemIcon>
              <SendIcon className={classes.primaryColor} />
            </ListItemIcon>
          }
          leftText={
            <Tooltip alwaysRender={false} title={renderFolderName(folderId)}>
              <ListItemText
                disableTypography
                name={qaTags.SIDEBAR_LIST_ITEM_LEFT_TEXT}
                primary={renderFolderName(folderId)}
                className={`sidebar-folder-name ${classes.primaryColor}`}
              />
            </Tooltip>
          }
          rightText={
            <ListItemText
              primaryTypographyProps={{ className: 'countText' }}
              name={qaTags.SIDEBAR_LIST_ITEM_MESSAGE_COUNT}
              primary={totalMessageCount}
              className={`sidebar-msg-count ${classes.darkColor}`}
            />
          }
        />
      )}
    </>
  );
};

SideBarFolderListItem.propTypes = {
  path: PropTypes.string.isRequired,
  folderId: PropTypes.string.isRequired,
  totalMessageCount: PropTypes.number.isRequired,
  folderOnClick: PropTypes.func.isRequired,
  secureMessageCenterOnClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(SideBarFolderListItem);
