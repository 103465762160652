import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import './Icons.scss';

const BlockAndDelete = ({ className }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={`icon-container ${className}`}>
    <defs>
      <path
        d="M1.35714286,22.2222222 C1.35714286,23.75 2.57857143,25 4.07142857,25 L14.9285714,25 C16.4214286,25 17.6428571,23.75 17.6428571,22.2222222 L17.6428571,5.55555556 L1.35714286,5.55555556 L1.35714286,22.2222222 Z M19,1.38888889 L14.25,1.38888889 L12.8928571,0 L6.10714286,0 L4.75,1.38888889 L0,1.38888889 L0,4.16666667 L19,4.16666667 L19,1.38888889 Z"
        id="path-1"
      />
    </defs>
    <g id="64x64-copy" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Group">
        <path
          d="M19.5,18 C8.736,18 0,26.736 0,37.5 C0,48.264 8.736,57 19.5,57 C30.264,57 39,48.264 39,37.5 C39,26.736 30.264,18 19.5,18 L19.5,18 Z M3.9,37.5 C3.9,28.881 10.881,21.9 19.5,21.9 C23.1075,21.9 26.4225,23.1285 29.055,25.1955 L7.1955,47.055 C5.1285,44.4225 3.9,41.1075 3.9,37.5 L3.9,37.5 Z M19.5,53.1 C15.8925,53.1 12.5775,51.8715 9.945,49.8045 L31.8045,27.945 C33.8715,30.5775 35.1,33.8925 35.1,37.5 C35.1,46.119 28.119,53.1 19.5,53.1 L19.5,53.1 Z"
          id="Combined-Shape"
        />
        <g id="icon/action/delete_24px-copy" transform="translate(37.000000, 0.000000)">
          <mask id="mask-2">
            <use xlinkHref="#path-1" />
          </mask>
          <use id="icon/action/delete_24px" fillRule="nonzero" xlinkHref="#path-1" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

BlockAndDelete.defaultProps = {
  className: ''
};

BlockAndDelete.propTypes = {
  className: PropTypes.string
};

export default BlockAndDelete;
