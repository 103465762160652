import { getStore } from '../../../../../store/storeUtil';
import { API_CALL, apiActions, apiEndpoints } from '../../../../ducks/apiConstants';
import { parseSenderList } from '../../../../utils/appUtils';
import { isEmpty } from '../../../../utils/strUtils';
import { getManagingDelegate } from '../../../ducks/profileReducer';

/* eslint-disable import/prefer-default-export */

/**
 * @description Actions creator to retrieve safe users email
 * @export
 * @param {any} params
 * @returns action Object
 */

export function fetchSenderList(params) {
  const { property, username } = params;
  const managingDelegate = getManagingDelegate(getStore().getState());
  const data = { username, property: [property] };
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.SENDERLIST_URL,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.FETCH_SENDERLIST,
      parser: parseSenderList
    }
  };
}
