import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addSettingsPath, removeSettingsPath } from '../../../../ducks/uiStatusActions';
import { deleteConfig } from '../../../../ducks/configActions';
import OptionSelectSenderListItem from './OptionSelectSenderListItem';

class OptionSelectSenderListItemContainer extends PureComponent {
  editConfigFunc = () => {
    const { id, addSettingsPathDispatch } = this.props;
    addSettingsPathDispatch(`edit[${id}]`);
  };

  closeFunc = () => {
    const { id, removeSettingsPathDispatch } = this.props;
    removeSettingsPathDispatch(`options[${id}]`, true);
  };

  deleteConfigFunc = () => {
    const { configProperty, deleteConfigDispatch } = this.props;
    deleteConfigDispatch(configProperty);
    this.closeFunc();
  };

  render() {
    const { id, configProperty } = this.props;

    return (
      <OptionSelectSenderListItem
        id={id}
        configProperty={configProperty}
        deleteConfigFunc={this.deleteConfigFunc}
        editConfigFunc={this.editConfigFunc}
        closeFunc={this.closeFunc}
      />
    );
  }
}

OptionSelectSenderListItemContainer.propTypes = {
  id: PropTypes.string.isRequired,
  deleteConfigDispatch: PropTypes.func.isRequired,
  configProperty: PropTypes.string.isRequired,
  addSettingsPathDispatch: PropTypes.func.isRequired,
  removeSettingsPathDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (_, ownProps) => {
  const { id } = ownProps;

  const props = {};
  props.id = id;

  return props;
};

export default connect(
  mapStateToProps,
  {
    deleteConfigDispatch: deleteConfig,
    removeSettingsPathDispatch: removeSettingsPath,
    addSettingsPathDispatch: addSettingsPath
  }
)(OptionSelectSenderListItemContainer);
