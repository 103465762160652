import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
  createTheme,
  jssPreset
} from '@material-ui/core/styles';
import { create } from 'jss';
import { themeOptions as fuseThemeOptions } from '@proofpoint/fuse-ui/theme/theme-provider';
import { merge } from 'lodash';
import FuseTheme from '@proofpoint/fuse-ui/components/FuseTheme';
import PropTypes from 'prop-types';
import React from 'react';

import AppContainer from './AppContainer';
import ConnectedReactIntl from './common/Hoc/ConnectedReactIntl';
import RequireAuth from './common/Hoc/RequireAuth/RequireAuth';
import SignIn from './SignIn';
import WithRedirect from './common/Hoc/WithRedirect';

const App = RequireAuth(WithRedirect(AppContainer));
const jss = create({ ...jssPreset() });

const generateClassName = createGenerateClassName();
const updatedThemeOptions = merge(
  { ...fuseThemeOptions },
  {
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: '24px'
        }
      },
      MuiMenuItem: {
        root: {
          boxSizing: 'content-box',
          height: 'auto'
        }
      },
      MuiSnackbarContent:{
        root: {
          maxHeight: 'fit-content'
        }
      }
    }
  }
);
const Root = ({ store, history }) => (
  <Provider store={store}>
    {/* ConnectedReactIntl will use the store from Provider automatically */}
    <ConnectedReactIntl>
      <ConnectedRouter history={history}>
        {/* your usual react-router v4 routing */}

        <StylesProvider jss={jss} generateClassName={generateClassName} injectFirst>
          <ThemeProvider theme={createTheme(updatedThemeOptions)}>
            <FuseTheme fonts={false} />
            <Switch>
              <Route exact path="/auth" component={SignIn} />
              <Route path="/" component={App} />
            </Switch>
          </ThemeProvider>
        </StylesProvider>
      </ConnectedRouter>
    </ConnectedReactIntl>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
