import { actionAttempt, actionError, actionSuccess, apiActions } from '../../../ducks/apiConstants';
import { createReducer } from '../../../utils/reducerUtils';

export const moduleName = 'help';

const INITIAL_STATE = {
  isRequestingURL: false,
};

const requestingURL = () => ({
  isRequestingURL: true,
});

const resetState = () => {
  return {
    ...INITIAL_STATE,
  };
};

export default createReducer(INITIAL_STATE, {
  [actionAttempt(apiActions.REDIRECT_HELP_PAGE)]: requestingURL,
  [actionSuccess(apiActions.REDIRECT_HELP_PAGE)]: resetState,
  [actionError(apiActions.REDIRECT_HELP_PAGE)]: resetState
});

function getHelpStatusfromState(state) {
    return state[moduleName];
  }
  
export const getHelpPageRequestingStatus = (state) => getHelpStatusfromState(state).isRequestingURL;
