import { normalize } from 'normalizr';
/**
 * @module utils/ApiHandler
 * Provides utility functions for making authenticated api requests to Proofpoint
 * backend services
 */
import axios from 'axios';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';

import { API_CALL, actionAttempt, actionError, actionSuccess } from '../apiConstants';
import { Schemas } from '../../../store/schema';
import { getSchemaByActions, insertUrlParams, makeApiEndpoint } from '../../utils/apiUtils';
import { getStore } from '../../../store/storeUtil';
import { serviceOk } from '../appActions';
import ApiError from '../../utils/error/ApiError';
import InvalidApi from '../../utils/error/InvalidApi';
import logger from '../../../tools/logger';

const log = logger.child({
  childName: 'utils/apiHandler',
  level: 'debug'
});

let axiosInstance;
// supported method
const METHODS = ['get', 'post', 'put', 'delete'];

/**
 * Get or create an instance of Axios with default config for each request
 * @return {Object} Axios instance
 */
function getAxiosInstance() {
  const config = {
    // xsrfCookieName, xsrfHeaderName will change base on back-end api retuning xsrf in Cookie
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    // add custome header based on back-end
    headers: {},
    withCredentials: true
  };

  if (isUndefined(axiosInstance)) {
    axiosInstance = axios.create(config);
  }
  return axiosInstance;
}

/**
 * Make asyc request from axios
 * @returns {Promise} - promise object
 */
function makeAsynApiRequest({
  schema,
  endpoint,
  method = 'get',
  params = {},
  data = {},
  headers = {},
  clusterRegion,
  responseType = 'json',
  parser = null
}) {
  const apiInstance = getAxiosInstance();

  // TODO: configure user custom header based on backend
  // full api path
  const apiTargetEndpoint = makeApiEndpoint(endpoint, clusterRegion);
  return apiInstance
    .request({
      url: apiTargetEndpoint,
      method: method.toLowerCase(),
      data,
      params,
      headers,
      responseType
    })
    .then((response) => {
      if (response.status !== 200 && response.status !== 204) {
        return Promise.reject(response);
      }
      // only interested in data of response object https://github.com/axios/axios
      if (typeof parser === 'function') {
        response.data = parser(response.data);
      }
      if (schema === Schemas.NO_ENTITY) {
        return response.data;
      }
      if (!isObject(response.data)) {
        log.info(`Unexpected successful return without data object for ${endpoint}`);
        return response.data;
      }
      return normalize(response.data, schema);
    })
    .catch((error) => Promise.reject(error));
}

/**
 * Make an API request to dispaching actios for attemps, success and fail status
 * @param  {Object} action -  action creator
 * {
 *   [API_CALL]: {
 *     endpoint: 'http://www.example.com/v1/api',
 *     method: 'get' (default) | 'post' | 'put' | 'delete',
 *     params: {},
 *     data: {}, // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
 *     headers:{},
 *     responseType: json (default)
 *   }
 * }
 * @return {Promise} promise object
 */
export default (action) => {
  const apiCall = action[API_CALL];
  const { endpoint, method = 'get', actionType, params } = apiCall;
  // `data` is the data to be sent as the request body
  // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
  apiCall.data = method === 'get' ? null : apiCall.data;
  if (!isPlainObject(apiCall)) {
    throw new InvalidApi(`${apiCall}: must be plain JavaScript objects with an [API_CALL] property.`);
  }

  if (typeof endpoint !== 'string') {
    throw new InvalidApi(`${apiCall}: must have an endpoint URL property.`);
  }

  if (isUndefined(actionType)) {
    throw new InvalidApi(`${apiCall}: must have an  actionType`);
  }

  if (!method || METHODS.indexOf(method.toLowerCase()) === -1) {
    throw new InvalidApi(`${apiCall}: Supported methods are "get", "post", "put" and "delete"`);
  }

  // processing endpoint and replace param placeholder
  const { processedUrl, processedParams } = insertUrlParams(endpoint, params);
  apiCall.endpoint = processedUrl;
  apiCall.params = processedParams;
  // get schema by api end Point
  apiCall.schema = getSchemaByActions(actionType);
  if (isUndefined(apiCall.schema)) {
    throw new Error('Please specify for the endpoint Schemas.');
  }

  const [requestType, successType, errorType] = [
    actionAttempt(actionType),
    actionSuccess(actionType),
    actionError(actionType)
  ];

  const { dispatch } = getStore();

  const requestParams = apiCall;

  // dispatch attempt action
  dispatch({
    type: requestType,
    requestParams
  });

  return new Promise((resolve, reject) =>
    makeAsynApiRequest(requestParams)
      .then((response) => {
        // dispatch success action
        dispatch({
          type: successType,
          response,
          requestParams
        });

        // signal network service is fine
        dispatch(serviceOk());

        return resolve(response);
      })
      .catch((err) => {
        // if error is not generic(such as timeout), construct an ApiError (which is response from backend)
        const error = err.response === undefined ? err : new ApiError(err.response, apiCall);
        dispatch({
          type: errorType,
          error,
          requestParams
        });
        return reject(error);
      })
  );
};
