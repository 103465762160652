import {
  A_WEEK_OLD,
  LAST_12_HOURS,
  LAST_4_HOURS,
  LAST_DAY,
  LAST_HOUR,
  THIRTY_DAYS_OLD
} from '../../../../utils/dateUtils';

export const dateSearchOptions = Object.freeze({
  0: 'search.by.date.default',
  1: 'search.by.date.lastHour',
  2: 'search.by.date.last4hours',
  3: 'search.by.date.last12hours',
  4: 'search.by.date.last24hours',
  5: 'search.by.date.lastWeek',
  6: 'search.by.date.lastMonth'
});

export const dateSearchValues = Object.freeze({
  0: '',
  1: LAST_HOUR,
  2: LAST_4_HOURS,
  3: LAST_12_HOURS,
  4: LAST_DAY,
  5: A_WEEK_OLD,
  6: THIRTY_DAYS_OLD
});

export default dateSearchOptions;
