import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import AddIcon from '@material-ui/icons/AddSharp';
import BackIcon from '@material-ui/icons/ArrowBack';
import Button from '@proofpoint/fuse-ui/components/Button';
import CloseIcon from '@material-ui/icons/CloseSharp';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import './ProfileTile.scss';
import BasicNav from '../../common/Nav/Basic';
import withPrimaryStyles from '../../common/withPrimaryStyles';

// Map icon name prop to component
const iconMap = {
  add: <AddIcon />,
  back: <BackIcon />,
  close: <CloseIcon />
};

class ProfileTile extends PureComponent {
  closeClick = () => {
    const { closeFunc } = this.props;
    closeFunc();
  };

  backClick = () => {
    const { backFunc } = this.props;
    backFunc();
  };

  renderTopButtons = (closeFunc, showBackButton = false) => (
    <div className="profile_top_buttons">
      {showBackButton && (
        <Button
          variant="text"
          className="profile_top_buttons__back"
          color="inherit"
          icon={iconMap.back}
          onClick={this.backClick}
        >
          <FormattedMessage id="action.button.back" />
        </Button>
      )}
      {closeFunc && (
        <IconButton className="profile_top_buttons__close" color="inherit" aria-label="close" onClick={this.closeClick}>
          {iconMap.close}
        </IconButton>
      )}
    </div>
  );

  render() {
    const { header, classes, subHeader, closeFunc, buttonRight, children, showFullScreenBackButton } = this.props;

    return (
      <>
        <Hidden smUp>
          <BasicNav
            buttonLeft={{
              icon: 'back',
              handleAction: this.backClick
            }}
            buttonRight={{
              icon: buttonRight ? buttonRight.icon : null,
              handleAction: buttonRight ? buttonRight.handleAction : null
            }}
            mainClass="basic-nav-wrapper--white-bg"
            innerClass="basic-nav--innerpage"
            mainTitle={<FormattedMessage id={subHeader != null ? subHeader : header} />}
          />
          {children}
        </Hidden>

        <Hidden xsDown>
          {this.renderTopButtons(closeFunc, showFullScreenBackButton)}
          <div className="profile_tile">
            <div className="profile_tile__header">
              <Typography variant="h5" color="inherit" className="profile_tile_header_text">
                <FormattedMessage id={header} />
              </Typography>
              {buttonRight && (
                <Button
                  autoFocus
                  variant="text"
                  className={classes.darkSecondaryColor10}
                  icon={iconMap[buttonRight.icon]}
                  onClick={buttonRight.handleAction}
                >
                  {buttonRight.text}
                </Button>
              )}
            </div>
            {subHeader && (
              <Typography variant="subtitle1" color="inherit" className="profile_tile__sub_header">
                <FormattedMessage id={subHeader} />
              </Typography>
            )}
            {children}
          </div>
        </Hidden>
      </>
    );
  }
}


ProfileTile.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeFunc: PropTypes.func,
  backFunc: PropTypes.func,
  classes: PropTypes.object.isRequired,
  showFullScreenBackButton: PropTypes.bool,
  buttonRight: PropTypes.shape({ icon: PropTypes.string, text: PropTypes.string, handleAction: PropTypes.func }),
};


export default withPrimaryStyles(ProfileTile);

ProfileTile.defaultProps = {
  subHeader: null,
  closeFunc: null,
  backFunc: null,
  buttonRight: null,
  showFullScreenBackButton: false
};