import { CLUSTER_REGION_MAPPING } from '../ducks/appConstants';
import { Schemas } from '../../store/schema';
import { apiActions } from '../ducks/apiConstants';
import { contains, isEmpty } from './strUtils';

const ApiActionsToSchemaMap = Object.freeze({
  [apiActions.UPDATE_CONFIG]: Schemas.EMAILS,
  [apiActions.FETCH_SENDERLIST]: Schemas.EMAILS,
  [apiActions.EXECUTE_MESSAGE_FOLDER_COMMAND]: Schemas.MESSAGES,
  [apiActions.FETCH_MESSAGES]: Schemas.MESSAGES,
  [apiActions.FETCH_ACTIVE_ENCRYPTED_MESSAGES]: Schemas.ENCRYPTEDMESSAGES,
  [apiActions.FETCH_EXPIRED_ENCRYPTED_MESSAGES]: Schemas.ENCRYPTEDMESSAGES,
  [apiActions.FETCH_REVOKED_ENCRYPTED_MESSAGES]: Schemas.ENCRYPTEDMESSAGES,
  [apiActions.FETCH_ALL_ENCRYPTED_MESSAGES]: Schemas.ENCRYPTEDMESSAGES,
  [apiActions.FETCH_TOTAL_SENT_MESSAGES]: Schemas.ENCRYPTEDMESSAGES
});

export function getSchemaByActions(apiAction) {
  // Get the schema for the apiAction, defaults to no schema
  const schema = ApiActionsToSchemaMap[apiAction];
  if (!schema) {
    return Schemas.NO_ENTITY;
  }
  return schema;
}

// full api path
export const makeApiEndpoint = (endpoint, clusterRegion) => {
  return clusterRegion === CLUSTER_REGION_MAPPING.EU
    ? `${atob(window.redirect.euweb_eu_api_target)}${endpoint}`
    : `${atob(window.redirect.euweb_api_target)}${endpoint}`;
};

// Insert params into the endpoint URL, such as 'http://get/{username}/folder'
// Remove params properties, such as params.username once they are inserted into the URL
// Return processed URL and params object
export const insertUrlParams = (url, params) => {
  let processedUrl = url;
  let processedParams = params;
  if (!isEmpty(params)) {
    processedParams = { ...params };
    const keys = Object.keys(processedParams);
    keys.forEach((key) => {
      const urlToken = `{${key}}`;
      if (contains(processedUrl, urlToken)) {
        const param = encodeURIComponent(processedParams[key]);
        processedUrl = processedUrl.replace(new RegExp(`\\{${key}\\}`, 'g'), param);
        delete processedParams[key];
      }
    });
  }
  return { processedUrl, processedParams };
};
