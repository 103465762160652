import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import cx from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';
import './GeneralSettings.scss';
import { qaTags } from '../../constants/qaConstants';
import { renderString } from '../../utils/renderUtils';
import ProfileTile from '../ProfileTile';
import { isEmpty } from '../../utils/strUtils';

const GeneralSettings = ({ managedDelegate, primaryEmailAddress, displayName, isProfileUpdating, aliases, closeFunc, backFunc, intl }) => {
  return (
    <>
      <Formik
        isInitialValid
        enableReinitialize
        initialValues={{
          email: primaryEmailAddress || '',
          displayName,
          aliases,
          valuesChanged: false
        }}
        render={(formik) => {
          const { handleSubmit, values } = formik;
          const label = intl.formatMessage({ id: 'settings.general.displayName' });
          const email = intl.formatMessage({ id: 'settings.general.email' });
          
          const generalSettingsContainer = cx('general-settings-container', {
            'managed-delegate': !isEmpty(managedDelegate),
          });   
          return (
            <div className="profile-view">
              <form className="profile-form" onSubmit={handleSubmit}>
              {!isProfileUpdating && (<ProfileTile
                profileTileQaTag={qaTags.PROFILE_EMAIL_SETTINGS}
                header="settings.general.profile"
                closeFunc={closeFunc}
                backFunc={backFunc}
              >
                  <div className={generalSettingsContainer}>
                    <TextField
                      fullWidth
                      label={label}
                      autoFocus
                      defaultValue={values.displayName}
                      InputProps={{
                        readOnly: true,
                        inputProps: {
                          'aria-label': label
                        }
                      }}
                      className="general-settings-text"
                    />
                    <TextField
                      fullWidth
                      label={email}
                      defaultValue={values.email}
                      InputProps={{
                        readOnly: true,
                        inputProps: {
                          'aria-label': email
                        }
                      }}
                      className="general-settings-text"
                    />
                    <List
                      subheader={
                        <ListSubheader className="settings-list-header">
                          {renderString('settings.general.aliases')}
                        </ListSubheader>
                      }
                    >
                      {values.aliases && values.aliases.length === 0 && (
                        <ListItem className="settings-list-item">
                          <ListItemText primary={renderString('settings.general.aliases.none')} />
                        </ListItem>
                      )}
                      {values.aliases && values.aliases.length > 0 &&
                        values.aliases.map((alias) => (
                          <ListItem className="settings-list-item">
                            <ListItemText primary={alias} />
                          </ListItem>
                        ))}
                    </List>
                  </div>
                  </ProfileTile>)}
                {isProfileUpdating && (
                  <CircularProgress
                    variant="indeterminate"
                    classes={{ indeterminate: 'profiletile-spinner-indeterminate' }}
                    className="profiletile-spinner"
                    size={50}
                    thickness={4}
                  />
                )}
              </form>
            </div>
          );
        }}
      />
    </>
  );
};
GeneralSettings.propTypes = {
  primaryEmailAddress: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  aliases: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeFunc: PropTypes.func.isRequired,
  backFunc: PropTypes.func.isRequired,
  isProfileUpdating: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  managedDelegate: PropTypes.object.isRequired
};

export default injectIntl(GeneralSettings);
