import PropTypes from 'prop-types';
import React from 'react';
import Schedule from '@material-ui/icons/Schedule';
import cx from 'classnames';

import './StatusBadge.scss';
import withPrimaryStyles from '../withPrimaryStyles';

const StatusBadge = ({ content, solid, largeBadge, showIcon, classes }) => {
  const classnames = cx([`statusBadge ${classes.darkSecondaryColor10}`], {
    'statusBadge--solid': solid,
    [classes.primaryBackgroundColor10]: solid,
    [classes.primaryBorderColor10]: solid,
    [classes.primaryBorderColor]: !solid,
    'statusBadge--large': largeBadge,
    'statusBadge--withIcon': showIcon
  });
  return (
    <div className={classnames}>
      {showIcon && <Schedule />}
      {content}
    </div>
  );
};

StatusBadge.defaultProps = {
  solid: false,
  largeBadge: false,
  showIcon: false
};

StatusBadge.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  solid: PropTypes.bool,
  largeBadge: PropTypes.bool,
  showIcon: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

export default withPrimaryStyles(StatusBadge);
