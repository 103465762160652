import { isEmpty } from 'lodash';
import { getStore } from '../../../store/storeUtil';
import { API_CALL, apiActions, apiEndpoints } from '../../ducks/apiConstants';
import { getManagingDelegate } from './profileReducer';
/**
 * @description Actions creator to retrieve safe users email
 * @export
 * @param {any} params
 * @returns action Object
 */

// Set onBehalfOf in the payload of message related API calls only when managing delegates

export const fetchProfile = () => {
  const managingDelegate = getManagingDelegate(getStore().getState());
  const data = {};
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.USER_URL,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.FETCH_PROFILE
    }
  };
};

export const fetchUserSpam = (payload) => {
  const data = { ...payload, property: ['spamPolicies', 'currentSpamPolicy'] };
  
  const managingDelegate = getManagingDelegate(getStore().getState());
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.USER_SPAM_URL,
      method: 'post',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.FETCH_USER_SPAM
    }
  };
};

export const postUserSpam = (params, payload) => {
  const data = { ...payload, username: params.username };
  const managingDelegate = getManagingDelegate(getStore().getState());
  return {
    [API_CALL]: {
      endpoint: apiEndpoints.CONFIG_URL,
      method: 'put',
      params: { isDelegateUser: !isEmpty(managingDelegate.email) },
      data:  !isEmpty(managingDelegate.email)? { ...data, onBehalfOf:managingDelegate.email } : data,
      actionType: apiActions.POST_PROFILE
    }
  };
};
