import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { getCurrentFolderId } from '../../ducks/uiStatusReducer';
import {
  getCurrentGuid,
  getMessageFromMessages,
  getMetaKeyByGuid,
  getTotalCheckedGuids
} from '../ducks/messageReducer';
import { getFolderCommands, getFolderErrorStatus } from '../../SideBar/ducks/folderReducer';
import { showLandingPage } from '../../ducks/featureReducer';
import FullScreenActions from './FullScreenActions';
import MessageContent from './MessageContent';

class MessageContentContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isRawHeadersOpen: false,
      isAttachmentListOpen: false,
      isMobileSingleActionOpen: false
    };
  }

  toggleRawHeaders = () => {
    const { isRawHeadersOpen } = this.state;
    this.setState({ isRawHeadersOpen: !isRawHeadersOpen });
  };

  toggleSingleMessageActions = () => {
    const { isMobileSingleActionOpen } = this.state;
    this.setState({
      isMobileSingleActionOpen: !isMobileSingleActionOpen
    });
  };

  render() {
    const { isRawHeadersOpen, isAttachmentListOpen, isMobileSingleActionOpen } = this.state;
    const {
      currentGuid,
      message,
      viewAnimateDirection,
      handleViewAnimation,
      commands,
      handleMessageAction,
      isLandingPageFeatureOn,
      isError,
      totalCheckedMessages,
      isFolderError,
      managedDelegate
    } = this.props;
    const isReady = get(message, 'messageBodyText') !== undefined || false;

    // Show bulk message actions for tablet and desktop
    if (!isMobileOnly && totalCheckedMessages > 0) {
      return (
        <FullScreenActions totalCheckedMessages={totalCheckedMessages} handleMessageAction={handleMessageAction} />
      );
    }

    // Show message body
    return (
      <MessageContent
        isReady={isReady}
        managedDelegate={managedDelegate}
        isFolderError={isFolderError}
        isError={isError}
        currentGuid={currentGuid}
        handleTabChange={this.handleTabChange}
        headerSender={message.headerSender}
        envelopeSender={message.envelopeSender}
        subject={message.subject}
        headers={message.headers}
        bodyPlain={message.messageBodyText}
        date={message.date}
        attachments={message.attachments}
        hasLinks={message.hasLinks}
        viewAnimateDirection={viewAnimateDirection}
        handleViewAnimation={handleViewAnimation}
        toggleRawHeaders={this.toggleRawHeaders}
        toggleAttachmentList={this.toggleAttachmentList}
        toggleMessageActions={this.toggleMessageActions}
        isRawHeadersOpen={isRawHeadersOpen}
        isAttachmentListOpen={isAttachmentListOpen}
        commands={commands}
        handleMessageAction={handleMessageAction}
        isMobileSingleActionOpen={isMobileSingleActionOpen}
        toggleSingleMessageActions={this.toggleSingleMessageActions}
        isLandingPageFeatureOn={isLandingPageFeatureOn}
      />
    );
  }
}

MessageContentContainer.defaultProps = {
  commands: [],
  currentGuid: null,
  viewAnimateDirection: null,
  message: {},
  isError: false,
  totalCheckedMessages: 0,
  isFolderError: false
};

MessageContentContainer.propTypes = {
  commands: PropTypes.arrayOf(PropTypes.string),
  currentGuid: PropTypes.string,
  viewAnimateDirection: PropTypes.string,
  message: PropTypes.object,
  handleViewAnimation: PropTypes.func.isRequired,
  handleMessageAction: PropTypes.func.isRequired,
  isLandingPageFeatureOn: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  totalCheckedMessages: PropTypes.number,
  isFolderError: PropTypes.bool,
  managedDelegate: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.currentGuid = getCurrentGuid(state);
  props.message = getMessageFromMessages(state, props.currentGuid);
  props.commands = getFolderCommands(state, getCurrentFolderId(state));
  props.isError = getMetaKeyByGuid(state, props.currentGuid, 'hasErrors');
  props.isFolderError = getFolderErrorStatus(state);
  props.isLandingPageFeatureOn = showLandingPage(state);
  props.totalCheckedMessages = getTotalCheckedGuids(state);
  return props;
};

export default connect(mapStateToProps)(MessageContentContainer);
