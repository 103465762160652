import PropTypes from 'prop-types';
import React from 'react';

import '../LandingPage.scss';
import { renderString } from '../../utils/renderUtils';
import Envelope from '../../static/images/envelope.png';
import HelperText from '../../common/HelperText';
import SingleMessageRow from '../../common/Inbox/SingleMessageRow';
import SummaryCard from '../../common/SummaryCard';
import withPrimaryStyles from '../../common/withPrimaryStyles';

const EncryptionSummaryCard = ({
  navigateToSecureMessageCenter,
  handleOnMessageClick,
  encryptedMessages,
  isReady,
  encryptedMessageCount
}) => {
  return (
    <div className="landing-page-smc-summary-card">
      {!isReady && (
        <SummaryCard
          cardTitleId="landing.page.smc.card.title"
          content={
            <>
              <SingleMessageRow isReady={false} showCheckbox={false} canBeSelected={false} />
              <SingleMessageRow isReady={false} showCheckbox={false} canBeSelected={false} />
              <SingleMessageRow isReady={false} showCheckbox={false} canBeSelected={false} />
            </>
          }
          actionButton={{
            buttonText: 'landing.page.smc.card.action.button.text',
            handleOnClick: () => navigateToSecureMessageCenter()
          }}
        />
      )}
      {encryptedMessageCount > 0 && isReady && (
        <SummaryCard
          cardTitleId="landing.page.smc.card.title"
          content={
            <div className="smc-summary-card">
              {Object.values(encryptedMessages).map((encryptedMessage) => (
                <SingleMessageRow
                  key={`smc-summary-card-message-${encryptedMessage.guid}`}
                  handleOnMessageClick={handleOnMessageClick}
                  guid={encryptedMessage.guid}
                  active={encryptedMessage.active}
                  recipients={encryptedMessage.recipients}
                  subject={encryptedMessage.subject}
                  deliveryDate={encryptedMessage.createTime}
                  isReady={isReady}
                  showCheckbox={false}
                  expiryDate={encryptedMessage.expireTime}
                  canBeSelected={false}
                />
              ))}
            </div>
          }
          actionButton={{
            buttonText: 'landing.page.smc.card.action.button.text',
            handleOnClick: () => navigateToSecureMessageCenter()
          }}
        />
      )}
      {encryptedMessageCount === 0 && isReady && (
        <SummaryCard
          cardTitleId="landing.page.smc.card.title"
          content={
            <div className="summary-card summary-card--empty">
              <img name="summary-card--empty__icon" src={Envelope} alt="" className="summary-card--empty__icon" />
              <HelperText subtitle={renderString('landing.page.smc.card.empty')} />
            </div>
          }
        />
      )}
    </div>
  );
};

EncryptionSummaryCard.propTypes = {
  navigateToSecureMessageCenter: PropTypes.func.isRequired,
  encryptedMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOnMessageClick: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  encryptedMessageCount: PropTypes.number.isRequired
};

export default withPrimaryStyles(EncryptionSummaryCard);
