import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { buttonElementRetriever, sortButtonsByGroup } from '../../../utils/buttonUtils';
import { getCheckedGuids } from '../../ducks/messageReducer';
import { getCurrentFolderId } from '../../../ducks/uiStatusReducer';
import { getFolderCommands } from '../../../SideBar/ducks/folderReducer';
import { renderString } from '../../../utils/renderUtils';
import { resetCheckedMessages } from '../../ducks/messageActions';
import FullScreenActions from './FullScreenActions';
import withPrimaryStyles from '../../../common/withPrimaryStyles';

class FullScreenActionsContainer extends PureComponent {
  handleClearSelectedGuids = () => {
    const { handleResetMessagesDispatch } = this.props;
    handleResetMessagesDispatch();
  };

  renderMessageActions = () => {
    const { classes, isDisabled, commands, targetGuids, handleMessageAction } = this.props;

    const fullscreenMessageActionClass = cx({
      [classes.darkColor]: true
    });

    // Ignore PPS ordering and order by group relevance
    const commandGroups = [...(commands || [])].sort(sortButtonsByGroup);

    const messageActions = [];
    let prevGroupIndex = 0;

    commandGroups.forEach((command) => {
      const { intlId, icon, groupIndex } = buttonElementRetriever(command);
      // Visually split actions into groups
      if (prevGroupIndex > 0 && prevGroupIndex !== groupIndex) {
        messageActions.push(<Divider key={`action-divider-${groupIndex}`} />);
      }
      messageActions.push(
        <MenuItem
          className={fullscreenMessageActionClass}
          tabIndex={0}
          key={`action-${command}`}
          onClick={() => {
            handleMessageAction(command, Object.keys(targetGuids));
            this.handleClearSelectedGuids();
          }}
          disabled={isDisabled}
        >
          {icon} {renderString(intlId)}
        </MenuItem>
      );
      prevGroupIndex = groupIndex;
    });

    return messageActions;
  };

  render() {
    const { commands, totalCheckedMessages } = this.props;
    return (
      <FullScreenActions
        commands={commands}
        totalCheckedMessages={totalCheckedMessages}
        messageActions={this.renderMessageActions()}
      />
    );
  }
}

FullScreenActionsContainer.defaultProps = {
  commands: [],
  isDisabled: false,
  handleMessageAction: null
};

FullScreenActionsContainer.propTypes = {
  commands: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object.isRequired,
  totalCheckedMessages: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  targetGuids: PropTypes.object.isRequired,
  handleMessageAction: PropTypes.func,
  handleResetMessagesDispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const props = {};
  props.commands = getFolderCommands(state, getCurrentFolderId(state));
  props.targetGuids = getCheckedGuids(state);
  return props;
};

export default connect(mapStateToProps, {
  handleResetMessagesDispatch: resetCheckedMessages
})(withPrimaryStyles(FullScreenActionsContainer));
