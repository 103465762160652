import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import StopIcon from '@material-ui/icons/Stop';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './UserSettingsMenu.scss';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import Menu from '@proofpoint/fuse-ui/components/Menu';

import MenuItem from '@material-ui/core/MenuItem';
import { getCurrentEmailAddressFromState } from '../../../Settings/ducks/profileReducer';
import { qaTags } from '../../../constants/qaConstants';
import { renderString } from '../../../utils/renderUtils';
import withPrimaryStyles from '../../withPrimaryStyles';
import { isEmpty } from '../../../utils/strUtils';
import { fetchProfile, resetManagingDelegate, setManagingDelegate } from '../../../Settings/ducks/profileActions';
import DelegateMenu from '../../DelegateMenu';
import { navConstant } from '../../../constants/uiConstants';

class UserSettingsMenu extends Component {
  renderDelegates = () => {
    const { delegatedUsers, handleClose, managedDelegate, setManagingDelegateDispatch, userEmailAddress, fetchProfileDispatch } = this.props; 
    return (      
      <>
        <div disabled role="presentation" className="delegate-menu__header" tabIndex={-1}>
          {renderString('manage.users')}
        </div>
        <DelegateMenu 
          handleClose={handleClose}
          delegatedUsers={delegatedUsers}
          managedDelegate={managedDelegate}
          setManagingDelegateDispatch={setManagingDelegateDispatch} 
          userEmailAddress={userEmailAddress}
          fetchProfileDispatch={fetchProfileDispatch}
        />
      </>
    )
  };
  
  render() {
    const { anchorEl, handleClose, navType, logout, intl, classes, openStopManagementConfirmationDialog, loggedInUserName, loggedInUserEmail, managedDelegate, delegatedUsers } = this.props;
    const isManagingDelegate = !isEmpty(managedDelegate);
    const hasDelegatedUsers = !isEmpty(delegatedUsers);
    const isMobileView = (navType === navConstant.BASIC_NAV);
    const settingsMenuClass = cx('logout-menu',{
      [`${classes.darkSecondaryColor10}`]: classes.darkSecondaryColor10
    });
    const managingDelegateClass = cx('nav-account-btn', {
      'nav-account-btn__managing': isManagingDelegate
    });    
   
    return (
      <>      
        <Menu
          className="setting-menu"
          PopoverClasses="menu-root"
          anchorEl={anchorEl}
          modifiers={{
            offset: {
            enabled: true,
            offset: '0, 0'
          }
          }}       
          action={this.menuAction}
          classes={{ paper: 'setting-menu-paper' }}
          open={Boolean(anchorEl)}
          alignment="right"
          onClose={handleClose}
          PaperProps={{sx: {width: '800px'}}}
        >
          {/* Show logged in user details in nav right container delegate dropdown if the user is not managing any delegate though there are delegates assigned  */}
          {!isManagingDelegate && hasDelegatedUsers && <MenuItem className="user-info">
            <div>
            <Typography className="user-info-name" variant="h6" component="h6">
              {loggedInUserName}
            </Typography>
              {(loggedInUserName!==loggedInUserEmail) && <div className='user-info-email'>{loggedInUserEmail}</div>}
            </div>
          </MenuItem>}
        
          {/* Show "stop managing" button in the nav right container delegate dropdown if the user is managing a delegate  */}
          {isManagingDelegate && <IconButton
            className={managingDelegateClass}
            aria-label={intl.formatMessage({ id: 'stop.managing' })}
            aria-haspopup="true"
            color="inherit"
            onClick={()=>{
              openStopManagementConfirmationDialog();         
              handleClose();         
            }}          
          >                      
            <div>              
              <div className='stop-managing-header'>
                <div className='stop-circle-wrapper'>
                  <StopIcon name={qaTags.NAV_USER_ACCOUNT} /> 
                </div>
                <Typography className="stop-managing-label" variant="h6" component="h6">
                  {renderString('stop.managing')}       
                </Typography>
              </div>
              <div className='managing-wrapper'>
                <span className='managing-label'>{renderString('managing')}:</span>              
                {managedDelegate.displayName && <div className='delegate-name'>{managedDelegate.displayName}</div>}
                {/* Display  email only when it is different from displayname */}
                {managedDelegate.email && (managedDelegate.email !== managedDelegate.displayName) && <div className='delegate-email'>{managedDelegate.email}</div>}                
              </div>
            </div>                                                                    
          </IconButton>}
          
          {/* Render delegates in the nav right container dropdown only when there are delegates assigned */}
          {hasDelegatedUsers && this.renderDelegates()}
          {!isMobileView && <MenuItem className={settingsMenuClass} name={qaTags.NAV_USER_MENU_LOGOUT} onClick={logout}>
            {renderString('profile.Logout')}
          </MenuItem>}
        </Menu>
      </>
    );   
  }}

UserSettingsMenu.defaultProps = {
  userEmailAddress: '',  
  delegatedUsers: [],
  managedDelegate: {}
};

UserSettingsMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired, 
  openStopManagementConfirmationDialog: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  userEmailAddress: PropTypes.string,
  fetchProfileDispatch: PropTypes.func.isRequired,
  delegatedUsers: PropTypes.arrayOf(PropTypes.string),
  managedDelegate: PropTypes.object,
  setManagingDelegateDispatch: PropTypes.func.isRequired,
  loggedInUserName: PropTypes.string.isRequired,
  loggedInUserEmail: PropTypes.string.isRequired,
  anchorEl: PropTypes.object.isRequired,
  navType: PropTypes.string.isRequired,

};

const mapStateToProps = (state) => {
  const props = {}; 
  props.userEmailAddress= getCurrentEmailAddressFromState(state)
  return props;
};

const mapDispatchToProps = () => ({
  setManagingDelegateDispatch: setManagingDelegate,
  stopManagingDispatch: resetManagingDelegate,
  fetchProfileDispatch: fetchProfile
})

export default connect(mapStateToProps, mapDispatchToProps ())(injectIntl(withPrimaryStyles(UserSettingsMenu)));

